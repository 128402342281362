.p-dropdown {
    width: 100%;
    text-align: left;
    transition: $formElementTransition;

    @include input-field();

    &:not(.p-disabled).p-focus,
    &:not(.p-disabled).p-inputwrapper-focus {
        @include focused-input();
    }

    &.p-dropdown-clearable {
        .p-dropdown-label {
            padding-right: nth($inputPadding, 2) + $primeIconFontSize;
        }
    }

    .p-dropdown-label {
        height: var(--input-height);
        line-height: 24px;

        @include input-field-clear();


        &.p-placeholder {
            margin-top: 0;
            color: $inputPlaceholderTextColor;
            line-height: 23px;
        }

        &:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }

        &:focus-visible {
            outline: 0 none;
        }
    }

    .p-dropdown-trigger {
        background: transparent;
        color: var(--gray-600);
        border-left: 2px solid var(--gray-500);
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    .p-dropdown-clear-icon {
        color: var(--gray-600);
        right: 3rem;
    }


}

.p-inputwrapper-focus span.p-inputtext {
    visibility: hidden;
}

.p-dropdown-panel {
    margin-top: 3px;
    background: $inputOverlayBg;
    color: $inputListTextColor;
    border: none;
    border-radius: var(--border-radius);
    box-shadow: 0 2px 20px var(--shadow-color-5);

    ::-webkit-scrollbar {
        width: 15px;
    }

    ::-webkit-scrollbar:horizontal {
        height: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--gray-600);
        border: 5px solid transparent;
        border-radius: 7px;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
        background: var(--gray-600);
        border: 5px solid transparent;
        border-radius: 7px;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-track:hover {
        border: 1px solid var(--gray-650);
    }

    .p-dropdown-header {
        position: absolute;
        left: 0;
        width: 90%;
        padding: 0;
        background: transparent !important;
        border-bottom: $inputListHeaderBorder;
        color: $inputListHeaderTextColor;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
        margin: calc(-1 * var(--input-height) - 3px) 0 0;

        .p-dropdown-filter {
            padding-right: nth($inputPadding, 2) + $primeIconFontSize;
            margin-right: -1 * (nth($inputPadding, 2) + $primeIconFontSize);

            &.p-inputtext.p-component {
                padding: 0 16px;
                border: 0;
                transition: none;
                background: transparent !important;
            }

            &.p-inputtext:enabled:focus {
                box-shadow: none;
            }
        }

        .p-dropdown-filter-icon {
            right: nth($inputPadding, 2);
            color: $inputIconColor;

            &.pi.pi-search {
                display: none;
            }
        }
    }

    .p-dropdown-items {
        list-style: none;

        &:not(.p-dropdown-virtualscroll) {
            padding: $inputListPadding;
            max-height: 200px;
        }

        &.p-dropdown-virtualscroll .cdk-virtual-scroll-content-wrapper {
            padding: $inputListPadding;
        }

        p-dropdownitem {
            .p-dropdown-item {
                background-color: var(--gray-100) !important;
            }

            &:nth-child(odd) .p-dropdown-item {
                background-color: var(--gray-150) !important;
            }
        }

        .p-dropdown-item {
            margin: $inputListItemMargin;
            padding: $inputListItemPadding;
            border: $inputListItemBorder;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
            transition: $listItemTransition;
            border-radius: $inputListItemBorderRadius;

            &.p-highlight {
                color: inherit;
                background: $highlightBg;

                &.editable {
                    background-color: var(--yellow-300);
                    border-color: var(--yellow-300) !important;
                    color: #000;

                    &:hover {
                        color: #000;
                        background-color: var(--yellow-300);
                        border-color: var(--yellow-300);
                    }
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover {
                color: $inputListItemTextHoverColor;
                background: $inputListItemHoverBg !important;
            }
        }

        .p-dropdown-empty-message {
            padding: $inputListItemPadding;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
        }

        .p-dropdown-item-group {
            margin: $submenuHeaderMargin;
            padding: $submenuHeaderPadding;
            color: $submenuHeaderTextColor;
            background: $submenuHeaderBg;
            font-weight: $submenuHeaderFontWeight;
        }

        #p-highlighted-option {
            background: var(--green-730) !important;
        }

        &-wrapper {
            border-radius: var(--border-radius);
        }
    }
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
    @include invalid-input();
}

.p-input-filled {
    .p-dropdown {
        background: $inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: $inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: $inputFilledFocusBg;
        }
    }
}

.c-select-icon {
    .p-dropdown-trigger {
        border-left: none !important;
    }

    chevrondownicon svg {
        width: 18px;
        height: 18px;
        color: var(--gray-600);
    }
}
