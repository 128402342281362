.p-selectbutton {
    .p-button {
        display: inline-flex;
        justify-content: center;
        width: 180px;
        margin-right: 10px !important;
        margin-bottom: 10px !important;
        border: 2px solid var(--gray-500) !important;
        border-radius: 10px !important;
        text-align: center;
        font-weight: 500;
        background: $toggleButtonBg;
        color: $toggleButtonTextColor;
        transition: $formElementTransition;

        &:focus {
            box-shadow: none !important;
        }

        .p-button-icon-left,
        .p-button-icon-right {
            color: $toggleButtonIconColor;
        }

        &:not(.p-disabled):not(.p-highlight):hover {
            background: var(--white);
            border-color: var(--gray-500);
            color: var(--text-color);

            .p-button-icon-left,
            .p-button-icon-right {
                color: $toggleButtonIconHoverColor;
            }
        }

        &.p-highlight {
            background: $toggleButtonActiveBg;
            border-color: $toggleButtonActiveBorderColor !important;
            color: $toggleButtonTextActiveColor;

            .p-button-icon-left,
            .p-button-icon-right {
                color: $toggleButtonIconActiveColor;
            }

            &:hover {
                background: $toggleButtonActiveHoverBg;
                border-color: $toggleButtonActiveHoverBorderColor;
                color: $toggleButtonTextActiveHoverColor;

                .p-button-icon-left,
                .p-button-icon-right {
                    color: $toggleButtonIconActiveHoverColor;
                }
            }
        }
    }
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    @include invalid-input();
}
