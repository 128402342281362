.p-toast {
    z-index: 10050 !important;
    opacity: $toastOpacity;
    max-width: 25rem;
    width: 100% !important;

    .p-toast-message {
        margin: $toastMargin;
        box-shadow: $toastShadow;
        border-radius: var(--border-radius);
        background-color: var(--white) !important;

        .p-toast-message-content {
            padding: $toastPadding;
            padding-right: 2.2rem;
            font-size: 20px;
            font-weight: 500;

            .p-toast-message-icon {
                display: none;
            }

            .p-toast-summary {
                font-weight: $toastTitleFontWeight;
            }

            .p-toast-detail {
                margin: 0;
                word-wrap: break-word;
                max-width: 20rem;
                line-height: 20px;

                @media #{$mobile-only} {
                    max-width: 250px;
                }
            }
        }

        .p-toast-icon-close {
            width: $actionIconWidth;
            height: $actionIconWidth;
            border-radius: $actionIconBorderRadius;
            transition: $actionIconTransition;
            background-color: var(--gray-100);
            color: $textSecondaryColor;
            position: absolute;
            top: 5px;
            right: 5px;
            border: $actionIconBorder;
            background: $actionIconBg;
            box-shadow: none;

            &:hover {
                color: var(--blue-760);
                background-color: var(--gray-150);
            }

            &:focus {
                color: var(--white);
                background-color: var(--blue-900);
            }
        }

        &.p-toast-message-info {
            background: $infoMessageBg;
            border: $infoMessageBorder;
            border-width: $messageBorderWidth;
            color: $infoMessageTextColor;

            .p-toast-message-icon {
                color: $infoMessageIconColor;
            }
        }

        &.p-toast-message-success {
            background: $successMessageBg;
            border: $successMessageBorder;
            border-width: $messageBorderWidth;
            color: $successMessageTextColor;

            .p-toast-message-icon {
                color: $successMessageIconColor;
            }
        }

        &.p-toast-message-warn {
            background: $warningMessageBg;
            border: $warningMessageBorder;
            border-width: $messageBorderWidth;
            color: $warningMessageTextColor;

            .p-toast-message-icon {
                color: $warningMessageIconColor;
            }
        }

        &.p-toast-message-error {
            background: $errorMessageBg;
            border: $errorMessageBorder;
            border-width: $messageBorderWidth;
            color: $errorMessageTextColor;

            .p-toast-message-icon {
                color: var(--red-850);
            }
        }
    }
}
