/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: 0 none;

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg: $panelContentBg;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: var(--white);

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: 2px solid var(--gray-blue-200);

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: $panelHeaderTextColor;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: 500;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: 30px;

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 30px 0 20px 0;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 20px 0 30px 0;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: 1px solid #dee2e6;

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 1.5rem;

/// Background of a tooltip
/// @group overlay
$tooltipBg: var(--white);

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: #000000;

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: $inputPadding;

$tooltipArrowSize: 12px;
