.p-dialog-content .c-page {
    margin: 0 !important;
}

.datepicker-tochno-day {
    background-color: var(--purple-600);
}

td.p-datepicker-other-month .datepicker-tochno-day {
    background-color: #f6eaff;
}

p-table.p-element.blue-table {
    th,
    td {
        height: 40px;
        padding: 10px;
        color: var(--text-color);
        border-right: 2px solid var(--white);
        border-bottom: 2px solid var(--white);

        &:last-child {
            border-right: 0;
        }

        &.align-right {
            text-align: right;
        }
    }

    thead {
        tr {
            th {
                color: var(--white);
                font-size: 16px;
                font-weight: 500;
                background-color: var(--blue-900);

                &:first-child {
                    border-radius: 6px 0 0 0;
                }

                &:last-child {
                    border-radius: 0 6px 0 0;
                }
            }
        }
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: var(--gray-150);

                &:hover {
                    background-color: var(--gray-200);
                }
            }

            &:nth-child(even) {
                background-color: var(--gray-100);

                &:hover {
                    background-color: var(--gray-150);
                }
            }

            &.corrected {
                background-color: var(--green-100);

                &:hover {
                    background-color: var(--green-200);
                }
            }

            &.deleted {
                background-color: var(--gray-500);

                &:hover {
                    background-color: #DAE0E4;
                }

                td {
                    text-decoration: line-through;
                }
            }

            &.edited {
                background-color: var(--green-730);

                &:hover {
                    background-color: var(--green-740);
                }
            }

            &.lost {
                background-color: var(--red-700);

                &:hover {
                    background-color: var(--red-850);
                }
            }

            &.nulled,
            &.cancellation {
                background-color: var(--orange-700);

          &:hover {
              background-color: var(--orange-830);
          }
      }

            &.lost-back,
            &.loss-back {
                background-color: var(--yellow-600);

                &:hover {
                    background-color: var(--yellow-700);
                }
            }

            &.personal-substitution {
                background-color: var(--blue-790);

                &:hover {
                    background-color: var(--blue-830);
                }
            }

            &.in-bottom {
                background-color: var(--purple-600);

          &:hover {
              background-color: var(--purple-830);
          }
      }

            td {
                padding: 10px;
                font-size: 14px;
                font-weight: 400;
                line-height: 14px;
                white-space: inherit;
            }
        }
    }

    tfoot {
        td {
            font-size: 14px;
            font-weight: 500;
        }
    }
}

p-fileupload {
    .p-fileupload {
        &-buttonbar {
            display: none;
        }

        &-row {
            flex-direction: column;

            > div {
                width: 100%;
                padding: 5px 0;
                text-align: center;

                &:first-child {
                    display: none;
                }

                &:last-child {
                    padding: 0;
                }
            }
        }

        &-content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 120px;
            padding: 30px 40px;
            background-color: var(--white);
            border: 2px dashed var(--blue-770) !important;
            border-radius: 6px !important;
            cursor: pointer;

            .p-progressbar {
                display: none;
            }

            &.p-fileupload-highlight {
                background-color: var(--blue-100);
                border-color: var(--blue-770);
            }
        }
    }
}

.ruts-confirm-dialog {
    .p-dialog-footer {
        padding-top: 0 !important;
    }

    .p-button-icon,
    .p-dialog-title {
        display: none;
    }

    .p-dialog-header {
        padding: 0;
        border: none;
    }

    .p-dialog-header-icons {
        position: absolute;
        top: 0;
        right: 0;
    }

    .p-dialog-content {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .p-confirm-dialog-message {
        margin: 20px 0 0 !important;
        line-height: 30px;
        font-size: 20px;
    }
}

tr {
    &.marked {
        background-color: var(--yellow-300) !important;

        &.target {
            background-color: var(--yellow-500) !important;
        }
    }
}

p-confirmdialog {
    z-index: 10055 !important;
    position: relative;
}

@media #{$mobile-only} {
    .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
        display: none;
    }
}

[hidden] {
    display: none !important;
}

.--h-40 {
    height: 40px;
    max-height: 40px;
}

.--sm-h-40 {
    @media screen and (min-width: 576px) {
        height: 40px;
        max-height: 40px;
    }
}

.--shadow-3 {
    box-shadow: 0 2px 20px var(--shadow-color-3);
}

.--shadow-5 {
    box-shadow: 0 2px 20px var(--shadow-color-5);
}

.--shadow-8 {
    box-shadow: 0 2px 20px var(--shadow-color-8);
}

.--shadow-9 {
    box-shadow: 4px 0 40px 0 var(--shadow-color-9);
}

.--down-arrow-hover {
    &:hover {
        color: var(--gray-700) !important;
    }
}

.--icon-color-hover {
    &:hover {
        color: var(--blue-760) !important;
    }
}

.--checked-icon-color {
    color: var(--green-900) !important;
}

.--icon-bg-hover {
    &:hover {
        background: var(--blue-760) !important;
    }
}

.hover-card-shadow:hover {
    box-shadow: 0 2px 5px var(--shadow-color-7);
}

.item-card {
    background: var(--white);
    box-shadow: 0 2px 20px var(--shadow-color-3);

    &:hover {
        background: var(--gray-100);
        box-shadow: 0 2px 5px var(--shadow-color-7);
    }
}

// номер заявки в карточках
// https://www.figma.com/design/h0ND44Z1AcfOqJbaST7DpV/RUTS.Pro?node-id=8186-167082&t=m7DvpW3i94Cset4a-4
.item-card-number {
    color: var(--gray-900);
    background: var(--gray-blue-200);
    padding: 2px 6px;

    &:hover {
        color: var(--blue-760);
        background: var(--gray-blue-300);
    }
}

.item-card-header {
    padding: 2px 6px;
}

.search-marked {
    background: #f7f9b8 !important;
}

.block-info {
    color: var(--blue-900);
    font-weight: 500;
    background-color: var(--gray-blue-300);
    border: 2px solid var(--white);
    border-radius: 6px;

    a {
        color: var(--blue-900);
    }
}
