.p-tooltip {
    display: inline-table !important;
    z-index: 10006 !important;

    .p-tooltip-text {
        background: $tooltipBg;
        color: $tooltipTextColor;
        padding: $tooltipPadding;
        box-shadow: $inputOverlayShadow;
        border-radius: 4px;
        font-size: 14px;
        text-align: center;
    }

    &.p-tooltip-right {
        .p-tooltip-arrow {
            left: calc(-#{$tooltipArrowSize} + 4px);
            margin-top: -#{$tooltipArrowSize};
            border-width: 1.5*$tooltipArrowSize $tooltipArrowSize 1.5*$tooltipArrowSize 0;
            border-right-color: $tooltipBg;
        }
    }

    &.p-tooltip-left {
        .p-tooltip-arrow {
            right: calc(-#{$tooltipArrowSize} + 4px);
            margin-top: -#{$tooltipArrowSize};
            border-width: 1.5*$tooltipArrowSize 0 1.5*$tooltipArrowSize $tooltipArrowSize;
            border-left-color: $tooltipBg;
        }
    }

    &.p-tooltip-top {
        .p-tooltip-arrow {
            bottom: calc(-#{$tooltipArrowSize} - 2px);
            border-width: 1.5*$tooltipArrowSize $tooltipArrowSize 0;
            border-top-color: $tooltipBg;
            margin-left: -$tooltipArrowSize;
        }
    }

    &.p-tooltip-bottom {
        .p-tooltip-arrow {
            top: calc(-#{$tooltipArrowSize} - 2px);
            border-width: 0 $tooltipArrowSize 1.5*$tooltipArrowSize;
            border-bottom-color: $tooltipBg;
            margin-left: -$tooltipArrowSize;
        }
    }
}
