@use "sass:math";

.p-treeselect {
    display: flex;
    align-items: center;
    height: 50px;
    transition: $formElementTransition;

    @include input-field();

    .p-treeselect-label {
        padding: $inputPadding;
        transition: $formElementTransition;

        &.p-placeholder {
            color: $inputPlaceholderTextColor;
        }

        &-container {
            border-right: $inputBorder;
        }
    }

    &.p-treeselect-chip {
        .p-treeselect-token {
            padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            margin-right: $inlineSpacing;
            background: $chipBg;
            color: $chipTextColor;
            border-radius: $chipBorderRadius;
        }
    }

    .p-treeselect-trigger {
        background: transparent;
        color: var(--gray-600);
        width: 50px;
        border-top-right-radius: $borderRadius;
        border-bottom-right-radius: $borderRadius;

        .p-icon-wrapper {
            transform: rotate(180deg);
        }

        &:not([aria-expanded="true"]) {
            .p-icon-wrapper {
                transform: initial;
            }
        }

        svg {
            &:hover {
                color: var(--gray-700) !important;
            }

            width: 20px;
            height: 20px;
        }
    }

    &.p-invalid.p-component {
        @include invalid-input();
    }
}

.p-inputwrapper-filled {
    .p-treeselect {
        &.p-treeselect-chip {
            .p-treeselect-label {
                padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            }
        }
    }
}

.p-treeselect-panel {
    background: $inputOverlayBg;
    color: $inputListTextColor;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;

    .p-treeselect-items-wrapper {
        border-radius: 10px;

        .p-tree {
            border: 0 none;
        }

        .p-treeselect-empty-message, .p-tree-empty-message {
            padding: $inputListItemPadding;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
        }
    }
}

.p-input-filled {
    .p-treeselect {
        background: $inputFilledBg;

        &:not(.p-disabled):hover {
            background-color: $inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: $inputFilledFocusBg;
        }
    }
}
