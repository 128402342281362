.p-panel {
    .p-panel-header {
        border: $panelHeaderBorder;
        padding: $panelHeaderPadding;
        background: $panelHeaderBg;
        color: $panelHeaderTextColor;
        border-radius: $borderRadius;

        .p-panel-title {
            font-weight: $panelHeaderFontWeight;
        }

        .p-panel-header-icon {
            @include action-icon();
        }
    }

    &.p-panel-toggleable {
        .p-panel-header {
            padding: $panelToggleableHeaderPadding;
        }
    }

    &.p-panel-expanded {
      .p-panel-header {
        border-radius: $borderRadius $borderRadius 0 0;
      }
    }

    .p-panel-content {
        padding: $panelContentPadding;
        border: $panelContentBorder;
        background: $panelContentBg;
        color: $panelContentTextColor;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
        border-top: 0 none;
    }

    .p-panel-footer {
        padding: $panelFooterPadding;
        border: $panelFooterBorder;
        background: $panelFooterBg;
        color: $panelFooterTextColor;
        border-top: 0 none;
    }


    // theme

    &.panel-white {
      box-shadow: 0 4px 20px 0 var(--shadow-color-3);

      .p-panel-header,
      .p-panel-content,
      .p-panel-footer {
        border: none;
        background-color: var(--white);
      }

      .p-panel-header {
        padding: 1rem 2rem;

        .p-panel-header-icon {
          width: auto;
          height: auto;
          color: var(--blue-770);
          background-color: var(--white);

          &:enabled:hover {
            color: var(--blue-770);
            background-color: var(--white);
            border: none;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

      .panel-title {
        min-width: 170px;
      }

      .p-panel-content {
        padding-top: 0;
      }
    }
}
