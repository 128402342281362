.p-checkbox {
    width: $checkboxWidth;
    height: $checkboxHeight;

    .p-checkbox-box {
        border: $checkboxBorder;
        background: var(--gray-100);
        width: $checkboxWidth;
        height: $checkboxHeight;
        color: $textColor;
        border-radius: 6px;
        transition: $formElementTransition;

        &:hover {
            border: $checkboxBorderHover;
        }

        .p-checkbox-icon {
            margin: 0;
            transition-duration: $transitionDuration;
            color: $checkboxIconActiveColor;
            font-size: $checkboxIconFontSize;
        }

        &.p-highlight {
            border-color: $checkboxActiveBorderColor;
            background: $checkboxActiveBg;
        }
    }

    &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
            //&:hover {
            //    border-color: var(--clr-gray-blue-700);
            //}
            //
            //&.p-highlight:hover {
            //    border-color: var(--clr-gray-blue-700);
            //    color: $checkboxIconActiveHoverColor;
            //}
        }
    }
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    @include invalid-input();
}

.p-input-filled {
    .p-checkbox {
        .p-checkbox-box {
            background-color: $inputFilledBg;

            &.p-highlight {
                background: $checkboxActiveBg;
            }
        }

        &:not(.p-checkbox-disabled) {
            .p-checkbox-box {
                &:hover {
                    background-color: $inputFilledHoverBg;
                }

                &.p-highlight:hover {
                    background: $checkboxActiveHoverBg;
                }
            }
        }
    }
}

.p-checkbox-label {
    margin-left: $inlineSpacing;
}

// TODO: check this logic
@if (var(--gray-500) == $checkboxActiveBg) {
    .p-highlight {
        .p-checkbox {
            .p-checkbox-box {
                border-color: $checkboxIconActiveColor;
            }
        }
    }
}
