@mixin ruts-color-class($name, $prop, $colors: $palette) {
  @each $family, $set in $colors {
    @each $saturate, $clr in $set {
      .#{$name}-#{"" + $family}-#{$saturate} {
        #{$prop}: var(getCssVar($family, $saturate)) !important;
      }
    }

    @each $saturate, $clr in $set {
      .focus#{$separator} {
        &#{$name}-#{"" + $family}-#{$saturate}:focus {
          #{$prop}: var(getCssVar($family, $saturate)) !important;
        }
      }

      .hover#{$separator} {
        &#{$name}-#{"" + $family}-#{$saturate}:hover {
          #{$prop}: var(getCssVar($family, $saturate)) !important;
        }
      }

      .active#{$separator} {
        &#{$name}-#{"" + $family}-#{$saturate}:active {
          #{$prop}: var(getCssVar($family, $saturate)) !important;
        }
      }
    }
  }
}

@import '../../../../node_modules/primeflex/core/mixins';
