$buttonShadow: 0px 2px 2px 0px #96B7CB80;


/// Padding of a button, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group button
$buttonPadding: 0.5rem 1rem;

/// Width of a button having an icon with no label
/// @group button
$buttonIconOnlyWidth: 2.357rem;

/// Padding of a button having an icon with no label
/// @group button
$buttonIconOnlyPadding: 0.5rem 0;

/// Background of a button
/// @group button
$buttonBg: var(--blue-900);

/// Text color of a button
/// @group button
$buttonTextColor: $primaryTextColor;

/// Border of a button
/// @group button
$buttonBorder: 1px solid $buttonBg;

/// Background of a button in hover state
/// @group button
$buttonHoverBg: var(--blue-850);

/// Text color of a button in hover state
/// @group button
$buttonTextHoverColor: $primaryTextColor;

/// Border color of a button in hover state
/// @group button
$buttonHoverBorderColor: $buttonHoverBg;

/// Background of a button in pressed state
/// @group button
$buttonActiveBg: var(--blue-900);

/// Color of a button in pressed state
/// @group button
$buttonTextActiveColor: $primaryTextColor;

/// Border color of a button in pressed state
/// @group button
$buttonActiveBorderColor: $buttonActiveBg;

/// Shadow of a raised button
/// @group button
$raisedButtonShadow: 0 2px 10px var(--shadow-color-5);

/// Border radius of a rounded button
/// @group button
$roundedButtonBorderRadius: 2rem;

/// Alpha level of a text button background in hover state
/// @group button
$textButtonHoverBgOpacity: 0.04;

/// Alpha level of a text button background in active state
/// @group button
$textButtonActiveBgOpacity: 0.16;

/// Border style of a outlined button
/// @group button
$outlinedButtonBorder: 1px solid;

/// Text color of a plain text button
/// @group button
$plainButtonTextColor: #6c757d;

/// Background color of a plain text button in hover state
/// @group button
$plainButtonHoverBgColor: #e9ecef;

/// Background color of a plain text button in active state
/// @group button
$plainButtonActiveBgColor: #dee2e6;

/// Background of a secondary button
/// @group button
$secondaryButtonBg: var(--white);

/// Text color of a secondary button
/// @group button
$secondaryButtonTextColor: var(--blue-770);

/// Border of a secondary button
/// @group button
$secondaryButtonBorder: 1px solid $secondaryButtonBg;

/// Background of a secondary button in hover state
/// @group button
$secondaryButtonHoverBg: var(--gray-150);

/// Text color of a secondary button in hover state
/// @group button
$secondaryButtonTextHoverColor: var(--blue-770);

/// Border color of a secondary button in hover state
/// @group button
$secondaryButtonHoverBorderColor: $secondaryButtonHoverBg;

/// Background of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBg: var(--gray-200);

/// Text color of a secondary button in pressed state
/// @group button
$secondaryButtonTextActiveColor: var(--blue-770);

/// Border color of a secondary button in pressed state
/// @group button
$secondaryButtonActiveBorderColor: $secondaryButtonActiveBg;

/// Box shadow of a secondary button in focused state
/// @group button
$secondaryButtonFocusShadow: $buttonShadow;

/// Background of an info button
/// @group button
$infoButtonBg: var(--gray-blue-900);

/// Text color of an info button
/// @group button
$infoButtonTextColor: var(--white);

/// Border of an info button
/// @group button
$infoButtonBorder: 1px solid $infoButtonBg;

/// Background of an info button in hover state
/// @group button
$infoButtonHoverBg: var(--gray-blue-850);

/// Text color of an info button in hover state
/// @group button
$infoButtonTextHoverColor: var(--white);

/// Border color of an info button in hover state
/// @group button
$infoButtonHoverBorderColor: $infoButtonHoverBg;

/// Background of an info button in pressed state
/// @group button
$infoButtonActiveBg: var(--gray-blue-900);

/// Text color of an info button in pressed state
/// @group button
$infoButtonTextActiveColor: var(--white);

/// Border color of an info button in pressed state
/// @group button
$infoButtonActiveBorderColor: $infoButtonActiveBg;

/// Box shadow of an info button in focused state
/// @group button
$infoButtonFocusShadow: $buttonShadow;

/// Background of a success button
/// @group button
$successButtonBg: var(--green-900);

/// Text color of a success button
/// @group button
$successButtonTextColor: var(--white);

/// Border of a success button
/// @group button
$successButtonBorder: 1px solid $successButtonBg;

/// Background of a success button in hover state
/// @group button
$successButtonHoverBg: var(--green-850);

/// Text color of a success button in hover state
/// @group button
$successButtonTextHoverColor: var(--white);

/// Border color of a success button in hover state
/// @group button
$successButtonHoverBorderColor: $successButtonHoverBg;

/// Background of a success button in pressed state
/// @group button
$successButtonActiveBg: var(--green-900);

/// Text Color of a success button in pressed state
/// @group button
$successButtonTextActiveColor: var(--white);

/// Border color of a success button in pressed state
/// @group button
$successButtonActiveBorderColor: $successButtonActiveBg;

/// Box shadow of a success button in focused state
/// @group button
$successButtonFocusShadow: $buttonShadow;

/// Background of a warning button
/// @group button
$warningButtonBg: var(--orange-900);

/// Text color of a warning button
/// @group button
$warningButtonTextColor: var(--white);

/// Border of a warning button
/// @group button
$warningButtonBorder: 1px solid $warningButtonBg;

/// Background of a warning button in hover state
/// @group button
$warningButtonHoverBg: var(--orange-850);

/// Text color of a warning button in hover state
/// @group button
$warningButtonTextHoverColor: var(--white);

/// Border color of a warning button in hover state
/// @group button
$warningButtonHoverBorderColor: $warningButtonHoverBg;

/// Background of a warning button in pressed state
/// @group button
$warningButtonActiveBg: var(--orange-900);

/// Text color of a warning button in pressed state
/// @group button
$warningButtonTextActiveColor: var(--white);

/// Border color of a warning button in pressed state
/// @group button
$warningButtonActiveBorderColor: $warningButtonActiveBg;

/// Box shadow of a warning button in focused state
/// @group button
$warningButtonFocusShadow: $buttonShadow;

/// Background of a help button
/// @group button
$helpButtonBg: var(--purple-900);

/// Text color of a help button
/// @group button
$helpButtonTextColor: var(--white);

/// Border of a help button
/// @group button
$helpButtonBorder: 1px solid $helpButtonBg;

/// Background of a help help in hover state
/// @group button
$helpButtonHoverBg: var(--purple-850);

/// Text color of a help button in hover state
/// @group button
$helpButtonTextHoverColor: var(--white);

/// Border color of a help button in hover state
/// @group button
$helpButtonHoverBorderColor: $helpButtonHoverBg;

/// Background of a help button in pressed state
/// @group button
$helpButtonActiveBg: var(--purple-900);

/// Text color of a help button in pressed state
/// @group button
$helpButtonTextActiveColor: var(--white);

/// Border color of a help button in pressed state
/// @group button
$helpButtonActiveBorderColor: $helpButtonActiveBg;

/// Box shadow of a help button in focused state
/// @group button
$helpButtonFocusShadow: $buttonShadow;

/// Background of a danger button
/// @group button
$dangerButtonBg: var(--red-900);

/// Text color of a danger button
/// @group button
$dangerButtonTextColor: var(--white);

/// Border of a danger button
/// @group button
$dangerButtonBorder: 1px solid $dangerButtonBg;

/// Background of a danger button in hover state
/// @group button
$dangerButtonHoverBg: var(--red-850);

/// Text color of a danger button in hover state
/// @group button
$dangerButtonTextHoverColor: var(--white);

/// Border color of a danger button in hover state
/// @group button
$dangerButtonHoverBorderColor: $dangerButtonHoverBg;

/// Background of a danger button in pressed state
/// @group button
$dangerButtonActiveBg: var(--red-900);

/// Text color of a danger button in pressed state
/// @group button
$dangerButtonTextActiveColor: var(--white);

/// Border color of a danger button in pressed state
/// @group button
$dangerButtonActiveBorderColor: $dangerButtonActiveBg;

/// Box shadow of a danger button in focused state
/// @group button
$dangerButtonFocusShadow: $buttonShadow;

/// Text color of a link button
/// @group button
$linkButtonColor: $primaryDarkerColor;

/// Text color of a link button in hover state
/// @group button
$linkButtonHoverColor: $primaryDarkerColor;

/// Text decoration of a link button in hover state
/// @group button
$linkButtonTextHoverDecoration: underline;

/// Box shadow of a link button in focused state
/// @group button
$linkButtonFocusShadow: 0 0 0 0.1rem $focusOutlineColor;

/// Background of a toggle button
/// @group button
$toggleButtonBg: var(--white);

/// Border of a toggle button
/// @group button
$toggleButtonBorder: 1px solid #ced4da;

/// Text color of a toggle button
/// @group button
$toggleButtonTextColor: $textColor;

/// Icon color of a toggle button
/// @group button
$toggleButtonIconColor: $textSecondaryColor;

/// Background of a toggle button in hover state
/// @group button
$toggleButtonHoverBg: #e9ecef;

/// Border color of a toggle button in hover state
/// @group button
$toggleButtonHoverBorderColor: #ced4da;

/// Text color of a toggle button in hover state
/// @group button
$toggleButtonTextHoverColor: $textColor;

/// Icon color of a toggle button in hover state
/// @group button
$toggleButtonIconHoverColor: $textSecondaryColor;

/// Background of a toggle button in selected state
/// @group button
$toggleButtonActiveBg: $primaryGreenColor;

/// Border color of a toggle button in selected state
/// @group button
$toggleButtonActiveBorderColor: $primaryGreenColor;

/// Text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveColor: $primaryTextColor;

/// Icon color of a toggle button in selected state
/// @group button
$toggleButtonIconActiveColor: $primaryTextColor;

/// Hover background of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBg: $primaryGreenColor;

/// Hover border color of a toggle button in selected state
/// @group button
$toggleButtonActiveHoverBorderColor: $primaryGreenColor;

/// Hover text color of a toggle button in selected state
/// @group button
$toggleButtonTextActiveHoverColor: $primaryTextColor;

/// Hover icon of a toggle button in selected state
/// @group button
$toggleButtonIconActiveHoverColor: $primaryTextColor;

/// Width of a floating action button on speed dial
/// @group button
$speedDialButtonWidth: 4rem;

/// Height of a floating action button on speed dial
/// @group button
$speedDialButtonHeight: 4rem;

/// Font Size of a floating action button on speed dial
/// @group button
$speedDialButtonIconFontSize: 1.3rem;

/// Width of any action button on speed dial
/// @group button
$speedDialActionWidth: 3rem;

/// Height of any action button on speed dial
/// @group button
$speedDialActionHeight: 3rem;

/// Background color of any action button on speed dial
/// @group button
$speedDialActionBg: #495057;

/// Background color of any action button on speed dial in hover state
/// @group button
$speedDialActionHoverBg: #343a40;

/// Color of any action button on speed dial
/// @group button
$speedDialActionTextColor: var(--white);

/// Color of any action button on speed dial in hover state
/// @group button
$speedDialActionTextHoverColor: var(--white);


$disabledBg: var(--gray-blue-650);
$disabledHoverBg: var(--gray-blue-670);
