@font-face {
    font-family: 'Golos UI';
    font-weight: 400;
    src: url('../assets/fonts/Golos-UI_Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Golos UI';
    font-weight: 500;
    src: url('../assets/fonts/Golos-UI_Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Golos UI';
    font-weight: 700;
    src: url('../assets/fonts/Golos-UI_Bold.ttf') format('truetype');
}

body {
    color: var(--text-color);
    font-size: 16px;
    line-height: 16px;
    font-family: 'Golos UI', sans-serif;
}
