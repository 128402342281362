.p-overlaypanel {
  max-width: 300px;
  background: $overlayContentBg;
  color: $panelContentTextColor;
  border: $overlayContentBorder;
  border-radius: $borderRadius;
  //box-shadow: $overlayContainerShadow;
  box-shadow: 0 2px 20px var(--shadow-color-5);

  .p-overlaypanel-content {
    max-height: 300px;
    min-width: 100px;
    padding: $panelContentPadding;
    overflow: auto;
  }

  .p-overlaypanel-title {
    margin-bottom: 16px;
  }

  .p-overlaypanel-close {
    position: absolute;
    top: 0;
    right: 10px;
    width: 30px;
    height: 30px;
    color: var(--gray-600);
    box-shadow: none;
    background: transparent !important;

    &:enabled:hover {
      background: $buttonHoverBg;
      color: var(--blue-900);
    }
  }

  &:before {
    border: solid transparent;
    border-color: transparent;
    border-bottom-color: var(--white);
  }

  &.p-overlaypanel-flipped {
    &:after {
      border-top-color: $overlayContentBg;
    }

    &:before {
      @if (nth($overlayContentBorder, 2) == 'none') {
        border-top-color: $overlayContentBg;
      } @else {
        border-top-color: nth($overlayContentBorder, 3);
      }
    }
  }

  ::-webkit-scrollbar {
    width: 15px;
  }

  ::-webkit-scrollbar:horizontal {
    height: 15px;
  }

  ::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb:hover {
    background: var(--gray-600);
    border: 5px solid transparent;
    border-radius: 7px;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-track:hover {
    border: 1px solid var(--gray-650);
  }
}

.p-overlaypanel-flipped {
  z-index: 10007 !important;
}
