.p-breadcrumb {
    overflow-x: visible !important;

    .p-menuitem-link {
        font-size: 14px;
        color: var(--breadcrumb-color);
        text-decoration: underline;
        text-underline-offset: 2px;
    }

    .p-menuitem-link:hover {
        color: var(--breadcrumb-color-hover);
    }
}
