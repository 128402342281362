:root {
    @each $family, $set in $palette {
        @each $saturate, $clr in $set {
            #{getCssVar($family, $saturate)}: #{$clr};
        }
    }

    // TODO: replace with palette colors
    --surface-0: #ffffff;
    --surface-50: #fafafa;
    --surface-100: #f5f5f5;
    --surface-200: var(--gray-blue-300);
    --surface-300: #e0e0e0;
    --surface-400: #bdbdbd;
    --surface-500: #9e9e9e;
    --surface-600: #757575;
    --surface-700: #616161;
    --surface-750: #ffffff26;
    --surface-800: #424242;
    --surface-900: #212121;

    --white: #ffffff;
    --black: var(--gray-900);

    --breadcrumb-color: var(--gray-blue-850);
    --breadcrumb-color-hover: var(--blue-760);

    --link-color: var(--blue-770);
    --link-color-dark: #477896;

    --focus-outline-color: var(--blue-900);

    --text-color: var(--black);
    --text-color-light: var(--gray-700);

    --shadow-color-9: rgba(150, 183, 203, 0.9);
    --shadow-color-8: rgba(150, 183, 203, 0.8);
    --shadow-color-8-light: rgba(205, 218, 226, 0.8);
    --shadow-color-7: rgba(150, 183, 203, 0.7);
    --shadow-color-6: rgba(150, 183, 203, 0.6);
    --shadow-color-5: rgba(150, 183, 203, 0.5);
    --shadow-color-3: rgba(150, 183, 203, 0.3);

    --background-color: var(--gray-blue-200);
    --border-color: var(--gray-500);
    --table-border-color: var(--gray-500);

    --maskbg: rgba(0, 0, 0, 0.4);

    --border-radius: 10px;
    --border-radius-sm: 6px;

    --input-height: 40px;

    --input-bg: var(--white);
    --input-bg-hover: var(--gray-100);

    --input-active-border: var(--blue-760);

    --input-border: none;
    --input-border-hover: none;

    --input-box-shadow: 0px 4px 20px 0px #96b7cb80;
    --input-box-shadow-hover: 0px 2px 10px 0px #96b7cbb2;

    --calendar-input-width: 160px;
    --calendar-input-height: calc(var(--input-height) - 10px);
}
