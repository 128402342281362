.grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.5rem;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
}

.grid > .col,
.grid > [class*=col] {
  box-sizing: border-box;
}

.grid-nogutter {
  margin-right: 0;
  margin-left: 0;
  margin-top: 0;
}

.grid-nogutter > .col,
.grid-nogutter > [class*=col-] {
  padding: 0;
}

.col {
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.5rem;
}

.col-fixed {
  flex: 0 0 auto;
  padding: 0.5rem;
}

.col-1 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 8.3333%;
}

.col-2 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 16.6667%;
}

.col-3 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 33.3333%;
}

.col-5 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 41.6667%;
}

.col-6 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 58.3333%;
}

.col-8 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 66.6667%;
}

.col-9 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 83.3333%;
}

.col-11 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 91.6667%;
}

.col-12 {
  flex: 0 0 auto;
  padding: 0.5rem;
  width: 100%;
}

@media screen and (min-width: 480px) {
  .xs\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .xs\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .xs\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .xs\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .xs\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .xs\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .xs\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .xs\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .xs\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .xs\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .xs\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .xs\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .xs\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .xs\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 576px) {
  .sm\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .sm\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .sm\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .sm\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .sm\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .sm\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .sm\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .sm\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .sm\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .sm\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .sm\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .sm\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .sm\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .sm\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .md\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .md\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .md\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .md\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .md\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .md\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .md\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .md\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .md\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .md\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .md\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .md\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .md\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .md\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .lg\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .lg\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .lg\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .lg\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .lg\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .lg\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .lg\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .lg\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .lg\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .lg\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .lg\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .lg\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .lg\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .lg\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .xl\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .xl\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .xl\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .xl\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .xl\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .xl\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .xl\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .xl\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .xl\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .xl\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .xl\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .xl\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .xl\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:col {
    flex-grow: 1;
    flex-basis: 0;
    padding: 0.5rem;
  }
  .xxl\:col-fixed {
    flex: 0 0 auto;
    padding: 0.5rem;
  }
  .xxl\:col-1 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 8.3333%;
  }
  .xxl\:col-2 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 16.6667%;
  }
  .xxl\:col-3 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 25%;
  }
  .xxl\:col-4 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 33.3333%;
  }
  .xxl\:col-5 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 41.6667%;
  }
  .xxl\:col-6 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 50%;
  }
  .xxl\:col-7 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 58.3333%;
  }
  .xxl\:col-8 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 66.6667%;
  }
  .xxl\:col-9 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 75%;
  }
  .xxl\:col-10 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 83.3333%;
  }
  .xxl\:col-11 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 91.6667%;
  }
  .xxl\:col-12 {
    flex: 0 0 auto;
    padding: 0.5rem;
    width: 100%;
  }
}
.col-offset-0 {
  margin-left: 0 !important;
}

.col-offset-1 {
  margin-left: 8.3333% !important;
}

.col-offset-2 {
  margin-left: 16.6667% !important;
}

.col-offset-3 {
  margin-left: 25% !important;
}

.col-offset-4 {
  margin-left: 33.3333% !important;
}

.col-offset-5 {
  margin-left: 41.6667% !important;
}

.col-offset-6 {
  margin-left: 50% !important;
}

.col-offset-7 {
  margin-left: 58.3333% !important;
}

.col-offset-8 {
  margin-left: 66.6667% !important;
}

.col-offset-9 {
  margin-left: 75% !important;
}

.col-offset-10 {
  margin-left: 83.3333% !important;
}

.col-offset-11 {
  margin-left: 91.6667% !important;
}

.col-offset-12 {
  margin-left: 100% !important;
}

@media screen and (min-width: 480px) {
  .xs\:col-offset-0 {
    margin-left: 0 !important;
  }
  .xs\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .xs\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .xs\:col-offset-3 {
    margin-left: 25% !important;
  }
  .xs\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .xs\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .xs\:col-offset-6 {
    margin-left: 50% !important;
  }
  .xs\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .xs\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .xs\:col-offset-9 {
    margin-left: 75% !important;
  }
  .xs\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .xs\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .xs\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:col-offset-0 {
    margin-left: 0 !important;
  }
  .sm\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .sm\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .sm\:col-offset-3 {
    margin-left: 25% !important;
  }
  .sm\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .sm\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .sm\:col-offset-6 {
    margin-left: 50% !important;
  }
  .sm\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .sm\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .sm\:col-offset-9 {
    margin-left: 75% !important;
  }
  .sm\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .sm\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .sm\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:col-offset-0 {
    margin-left: 0 !important;
  }
  .md\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .md\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .md\:col-offset-3 {
    margin-left: 25% !important;
  }
  .md\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .md\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .md\:col-offset-6 {
    margin-left: 50% !important;
  }
  .md\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .md\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .md\:col-offset-9 {
    margin-left: 75% !important;
  }
  .md\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .md\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .md\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:col-offset-0 {
    margin-left: 0 !important;
  }
  .lg\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .lg\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .lg\:col-offset-3 {
    margin-left: 25% !important;
  }
  .lg\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .lg\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .lg\:col-offset-6 {
    margin-left: 50% !important;
  }
  .lg\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .lg\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .lg\:col-offset-9 {
    margin-left: 75% !important;
  }
  .lg\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .lg\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .lg\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:col-offset-0 {
    margin-left: 0 !important;
  }
  .xl\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .xl\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .xl\:col-offset-3 {
    margin-left: 25% !important;
  }
  .xl\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .xl\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .xl\:col-offset-6 {
    margin-left: 50% !important;
  }
  .xl\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .xl\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .xl\:col-offset-9 {
    margin-left: 75% !important;
  }
  .xl\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .xl\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .xl\:col-offset-12 {
    margin-left: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:col-offset-0 {
    margin-left: 0 !important;
  }
  .xxl\:col-offset-1 {
    margin-left: 8.3333% !important;
  }
  .xxl\:col-offset-2 {
    margin-left: 16.6667% !important;
  }
  .xxl\:col-offset-3 {
    margin-left: 25% !important;
  }
  .xxl\:col-offset-4 {
    margin-left: 33.3333% !important;
  }
  .xxl\:col-offset-5 {
    margin-left: 41.6667% !important;
  }
  .xxl\:col-offset-6 {
    margin-left: 50% !important;
  }
  .xxl\:col-offset-7 {
    margin-left: 58.3333% !important;
  }
  .xxl\:col-offset-8 {
    margin-left: 66.6667% !important;
  }
  .xxl\:col-offset-9 {
    margin-left: 75% !important;
  }
  .xxl\:col-offset-10 {
    margin-left: 83.3333% !important;
  }
  .xxl\:col-offset-11 {
    margin-left: 91.6667% !important;
  }
  .xxl\:col-offset-12 {
    margin-left: 100% !important;
  }
}
.text-0 {
  color: var(--surface-0) !important;
}

.text-50 {
  color: var(--surface-50) !important;
}

.text-100 {
  color: var(--surface-100) !important;
}

.text-200 {
  color: var(--surface-200) !important;
}

.text-300 {
  color: var(--surface-300) !important;
}

.text-400 {
  color: var(--surface-400) !important;
}

.text-500 {
  color: var(--surface-500) !important;
}

.text-600 {
  color: var(--surface-600) !important;
}

.text-700 {
  color: var(--surface-700) !important;
}

.text-800 {
  color: var(--surface-800) !important;
}

.text-900 {
  color: var(--surface-900) !important;
}

.focus\:text-0:focus {
  color: var(--surface-0) !important;
}

.hover\:text-0:hover {
  color: var(--surface-0) !important;
}

.active\:text-0:active {
  color: var(--surface-0) !important;
}

.focus\:text-50:focus {
  color: var(--surface-50) !important;
}

.hover\:text-50:hover {
  color: var(--surface-50) !important;
}

.active\:text-50:active {
  color: var(--surface-50) !important;
}

.focus\:text-100:focus {
  color: var(--surface-100) !important;
}

.hover\:text-100:hover {
  color: var(--surface-100) !important;
}

.active\:text-100:active {
  color: var(--surface-100) !important;
}

.focus\:text-200:focus {
  color: var(--surface-200) !important;
}

.hover\:text-200:hover {
  color: var(--surface-200) !important;
}

.active\:text-200:active {
  color: var(--surface-200) !important;
}

.focus\:text-300:focus {
  color: var(--surface-300) !important;
}

.hover\:text-300:hover {
  color: var(--surface-300) !important;
}

.active\:text-300:active {
  color: var(--surface-300) !important;
}

.focus\:text-400:focus {
  color: var(--surface-400) !important;
}

.hover\:text-400:hover {
  color: var(--surface-400) !important;
}

.active\:text-400:active {
  color: var(--surface-400) !important;
}

.focus\:text-500:focus {
  color: var(--surface-500) !important;
}

.hover\:text-500:hover {
  color: var(--surface-500) !important;
}

.active\:text-500:active {
  color: var(--surface-500) !important;
}

.focus\:text-600:focus {
  color: var(--surface-600) !important;
}

.hover\:text-600:hover {
  color: var(--surface-600) !important;
}

.active\:text-600:active {
  color: var(--surface-600) !important;
}

.focus\:text-700:focus {
  color: var(--surface-700) !important;
}

.hover\:text-700:hover {
  color: var(--surface-700) !important;
}

.active\:text-700:active {
  color: var(--surface-700) !important;
}

.focus\:text-800:focus {
  color: var(--surface-800) !important;
}

.hover\:text-800:hover {
  color: var(--surface-800) !important;
}

.active\:text-800:active {
  color: var(--surface-800) !important;
}

.focus\:text-900:focus {
  color: var(--surface-900) !important;
}

.hover\:text-900:hover {
  color: var(--surface-900) !important;
}

.active\:text-900:active {
  color: var(--surface-900) !important;
}

.surface-0 {
  background-color: var(--surface-0) !important;
}

.surface-50 {
  background-color: var(--surface-50) !important;
}

.surface-100 {
  background-color: var(--surface-100) !important;
}

.surface-200 {
  background-color: var(--surface-200) !important;
}

.surface-300 {
  background-color: var(--surface-300) !important;
}

.surface-400 {
  background-color: var(--surface-400) !important;
}

.surface-500 {
  background-color: var(--surface-500) !important;
}

.surface-600 {
  background-color: var(--surface-600) !important;
}

.surface-700 {
  background-color: var(--surface-700) !important;
}

.surface-800 {
  background-color: var(--surface-800) !important;
}

.surface-900 {
  background-color: var(--surface-900) !important;
}

.focus\:surface-0:focus {
  background-color: var(--surface-0) !important;
}

.hover\:surface-0:hover {
  background-color: var(--surface-0) !important;
}

.active\:surface-0:active {
  background-color: var(--surface-0) !important;
}

.focus\:surface-50:focus {
  background-color: var(--surface-50) !important;
}

.hover\:surface-50:hover {
  background-color: var(--surface-50) !important;
}

.active\:surface-50:active {
  background-color: var(--surface-50) !important;
}

.focus\:surface-100:focus {
  background-color: var(--surface-100) !important;
}

.hover\:surface-100:hover {
  background-color: var(--surface-100) !important;
}

.active\:surface-100:active {
  background-color: var(--surface-100) !important;
}

.focus\:surface-200:focus {
  background-color: var(--surface-200) !important;
}

.hover\:surface-200:hover {
  background-color: var(--surface-200) !important;
}

.active\:surface-200:active {
  background-color: var(--surface-200) !important;
}

.focus\:surface-300:focus {
  background-color: var(--surface-300) !important;
}

.hover\:surface-300:hover {
  background-color: var(--surface-300) !important;
}

.active\:surface-300:active {
  background-color: var(--surface-300) !important;
}

.focus\:surface-400:focus {
  background-color: var(--surface-400) !important;
}

.hover\:surface-400:hover {
  background-color: var(--surface-400) !important;
}

.active\:surface-400:active {
  background-color: var(--surface-400) !important;
}

.focus\:surface-500:focus {
  background-color: var(--surface-500) !important;
}

.hover\:surface-500:hover {
  background-color: var(--surface-500) !important;
}

.active\:surface-500:active {
  background-color: var(--surface-500) !important;
}

.focus\:surface-600:focus {
  background-color: var(--surface-600) !important;
}

.hover\:surface-600:hover {
  background-color: var(--surface-600) !important;
}

.active\:surface-600:active {
  background-color: var(--surface-600) !important;
}

.focus\:surface-700:focus {
  background-color: var(--surface-700) !important;
}

.hover\:surface-700:hover {
  background-color: var(--surface-700) !important;
}

.active\:surface-700:active {
  background-color: var(--surface-700) !important;
}

.focus\:surface-800:focus {
  background-color: var(--surface-800) !important;
}

.hover\:surface-800:hover {
  background-color: var(--surface-800) !important;
}

.active\:surface-800:active {
  background-color: var(--surface-800) !important;
}

.focus\:surface-900:focus {
  background-color: var(--surface-900) !important;
}

.hover\:surface-900:hover {
  background-color: var(--surface-900) !important;
}

.active\:surface-900:active {
  background-color: var(--surface-900) !important;
}

.border-0 {
  border-color: var(--surface-0) !important;
}

.border-50 {
  border-color: var(--surface-50) !important;
}

.border-100 {
  border-color: var(--surface-100) !important;
}

.border-200 {
  border-color: var(--surface-200) !important;
}

.border-300 {
  border-color: var(--surface-300) !important;
}

.border-400 {
  border-color: var(--surface-400) !important;
}

.border-500 {
  border-color: var(--surface-500) !important;
}

.border-600 {
  border-color: var(--surface-600) !important;
}

.border-700 {
  border-color: var(--surface-700) !important;
}

.border-800 {
  border-color: var(--surface-800) !important;
}

.border-900 {
  border-color: var(--surface-900) !important;
}

.focus\:border-0:focus {
  border-color: var(--surface-0) !important;
}

.hover\:border-0:hover {
  border-color: var(--surface-0) !important;
}

.active\:border-0:active {
  border-color: var(--surface-0) !important;
}

.focus\:border-50:focus {
  border-color: var(--surface-50) !important;
}

.hover\:border-50:hover {
  border-color: var(--surface-50) !important;
}

.active\:border-50:active {
  border-color: var(--surface-50) !important;
}

.focus\:border-100:focus {
  border-color: var(--surface-100) !important;
}

.hover\:border-100:hover {
  border-color: var(--surface-100) !important;
}

.active\:border-100:active {
  border-color: var(--surface-100) !important;
}

.focus\:border-200:focus {
  border-color: var(--surface-200) !important;
}

.hover\:border-200:hover {
  border-color: var(--surface-200) !important;
}

.active\:border-200:active {
  border-color: var(--surface-200) !important;
}

.focus\:border-300:focus {
  border-color: var(--surface-300) !important;
}

.hover\:border-300:hover {
  border-color: var(--surface-300) !important;
}

.active\:border-300:active {
  border-color: var(--surface-300) !important;
}

.focus\:border-400:focus {
  border-color: var(--surface-400) !important;
}

.hover\:border-400:hover {
  border-color: var(--surface-400) !important;
}

.active\:border-400:active {
  border-color: var(--surface-400) !important;
}

.focus\:border-500:focus {
  border-color: var(--surface-500) !important;
}

.hover\:border-500:hover {
  border-color: var(--surface-500) !important;
}

.active\:border-500:active {
  border-color: var(--surface-500) !important;
}

.focus\:border-600:focus {
  border-color: var(--surface-600) !important;
}

.hover\:border-600:hover {
  border-color: var(--surface-600) !important;
}

.active\:border-600:active {
  border-color: var(--surface-600) !important;
}

.focus\:border-700:focus {
  border-color: var(--surface-700) !important;
}

.hover\:border-700:hover {
  border-color: var(--surface-700) !important;
}

.active\:border-700:active {
  border-color: var(--surface-700) !important;
}

.focus\:border-800:focus {
  border-color: var(--surface-800) !important;
}

.hover\:border-800:hover {
  border-color: var(--surface-800) !important;
}

.active\:border-800:active {
  border-color: var(--surface-800) !important;
}

.focus\:border-900:focus {
  border-color: var(--surface-900) !important;
}

.hover\:border-900:hover {
  border-color: var(--surface-900) !important;
}

.active\:border-900:active {
  border-color: var(--surface-900) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

@media screen and (min-width: 480px) {
  .xs\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-transparent {
    background-color: transparent !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:bg-transparent {
    background-color: transparent !important;
  }
}
.border-transparent {
  border-color: transparent !important;
}

@media screen and (min-width: 480px) {
  .xs\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-transparent {
    border-color: transparent !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:border-transparent {
    border-color: transparent !important;
  }
}
.text-blue-910 {
  color: var(--blue-910) !important;
}

.text-blue-900 {
  color: var(--blue-900) !important;
}

.text-blue-850 {
  color: var(--blue-850) !important;
}

.text-blue-830 {
  color: var(--blue-830) !important;
}

.text-blue-800 {
  color: var(--blue-800) !important;
}

.text-blue-790 {
  color: var(--blue-790) !important;
}

.text-blue-785 {
  color: var(--blue-785) !important;
}

.text-blue-780 {
  color: var(--blue-780) !important;
}

.text-blue-770 {
  color: var(--blue-770) !important;
}

.text-blue-760 {
  color: var(--blue-760) !important;
}

.text-blue-600 {
  color: var(--blue-600) !important;
}

.text-blue-550 {
  color: var(--blue-550) !important;
}

.text-blue-300 {
  color: var(--blue-300) !important;
}

.text-blue-200 {
  color: var(--blue-200) !important;
}

.text-blue-120 {
  color: var(--blue-120) !important;
}

.text-blue-100 {
  color: var(--blue-100) !important;
}

.focus\:text-blue-910:focus {
  color: var(--blue-910) !important;
}

.hover\:text-blue-910:hover {
  color: var(--blue-910) !important;
}

.active\:text-blue-910:active {
  color: var(--blue-910) !important;
}

.focus\:text-blue-900:focus {
  color: var(--blue-900) !important;
}

.hover\:text-blue-900:hover {
  color: var(--blue-900) !important;
}

.active\:text-blue-900:active {
  color: var(--blue-900) !important;
}

.focus\:text-blue-850:focus {
  color: var(--blue-850) !important;
}

.hover\:text-blue-850:hover {
  color: var(--blue-850) !important;
}

.active\:text-blue-850:active {
  color: var(--blue-850) !important;
}

.focus\:text-blue-830:focus {
  color: var(--blue-830) !important;
}

.hover\:text-blue-830:hover {
  color: var(--blue-830) !important;
}

.active\:text-blue-830:active {
  color: var(--blue-830) !important;
}

.focus\:text-blue-800:focus {
  color: var(--blue-800) !important;
}

.hover\:text-blue-800:hover {
  color: var(--blue-800) !important;
}

.active\:text-blue-800:active {
  color: var(--blue-800) !important;
}

.focus\:text-blue-790:focus {
  color: var(--blue-790) !important;
}

.hover\:text-blue-790:hover {
  color: var(--blue-790) !important;
}

.active\:text-blue-790:active {
  color: var(--blue-790) !important;
}

.focus\:text-blue-785:focus {
  color: var(--blue-785) !important;
}

.hover\:text-blue-785:hover {
  color: var(--blue-785) !important;
}

.active\:text-blue-785:active {
  color: var(--blue-785) !important;
}

.focus\:text-blue-780:focus {
  color: var(--blue-780) !important;
}

.hover\:text-blue-780:hover {
  color: var(--blue-780) !important;
}

.active\:text-blue-780:active {
  color: var(--blue-780) !important;
}

.focus\:text-blue-770:focus {
  color: var(--blue-770) !important;
}

.hover\:text-blue-770:hover {
  color: var(--blue-770) !important;
}

.active\:text-blue-770:active {
  color: var(--blue-770) !important;
}

.focus\:text-blue-760:focus {
  color: var(--blue-760) !important;
}

.hover\:text-blue-760:hover {
  color: var(--blue-760) !important;
}

.active\:text-blue-760:active {
  color: var(--blue-760) !important;
}

.focus\:text-blue-600:focus {
  color: var(--blue-600) !important;
}

.hover\:text-blue-600:hover {
  color: var(--blue-600) !important;
}

.active\:text-blue-600:active {
  color: var(--blue-600) !important;
}

.focus\:text-blue-550:focus {
  color: var(--blue-550) !important;
}

.hover\:text-blue-550:hover {
  color: var(--blue-550) !important;
}

.active\:text-blue-550:active {
  color: var(--blue-550) !important;
}

.focus\:text-blue-300:focus {
  color: var(--blue-300) !important;
}

.hover\:text-blue-300:hover {
  color: var(--blue-300) !important;
}

.active\:text-blue-300:active {
  color: var(--blue-300) !important;
}

.focus\:text-blue-200:focus {
  color: var(--blue-200) !important;
}

.hover\:text-blue-200:hover {
  color: var(--blue-200) !important;
}

.active\:text-blue-200:active {
  color: var(--blue-200) !important;
}

.focus\:text-blue-120:focus {
  color: var(--blue-120) !important;
}

.hover\:text-blue-120:hover {
  color: var(--blue-120) !important;
}

.active\:text-blue-120:active {
  color: var(--blue-120) !important;
}

.focus\:text-blue-100:focus {
  color: var(--blue-100) !important;
}

.hover\:text-blue-100:hover {
  color: var(--blue-100) !important;
}

.active\:text-blue-100:active {
  color: var(--blue-100) !important;
}

.text-gray-900 {
  color: var(--gray-900) !important;
}

.text-gray-700 {
  color: var(--gray-700) !important;
}

.text-gray-650 {
  color: var(--gray-650) !important;
}

.text-gray-600 {
  color: var(--gray-600) !important;
}

.text-gray-500 {
  color: var(--gray-500) !important;
}

.text-gray-200 {
  color: var(--gray-200) !important;
}

.text-gray-150 {
  color: var(--gray-150) !important;
}

.text-gray-100 {
  color: var(--gray-100) !important;
}

.focus\:text-gray-900:focus {
  color: var(--gray-900) !important;
}

.hover\:text-gray-900:hover {
  color: var(--gray-900) !important;
}

.active\:text-gray-900:active {
  color: var(--gray-900) !important;
}

.focus\:text-gray-700:focus {
  color: var(--gray-700) !important;
}

.hover\:text-gray-700:hover {
  color: var(--gray-700) !important;
}

.active\:text-gray-700:active {
  color: var(--gray-700) !important;
}

.focus\:text-gray-650:focus {
  color: var(--gray-650) !important;
}

.hover\:text-gray-650:hover {
  color: var(--gray-650) !important;
}

.active\:text-gray-650:active {
  color: var(--gray-650) !important;
}

.focus\:text-gray-600:focus {
  color: var(--gray-600) !important;
}

.hover\:text-gray-600:hover {
  color: var(--gray-600) !important;
}

.active\:text-gray-600:active {
  color: var(--gray-600) !important;
}

.focus\:text-gray-500:focus {
  color: var(--gray-500) !important;
}

.hover\:text-gray-500:hover {
  color: var(--gray-500) !important;
}

.active\:text-gray-500:active {
  color: var(--gray-500) !important;
}

.focus\:text-gray-200:focus {
  color: var(--gray-200) !important;
}

.hover\:text-gray-200:hover {
  color: var(--gray-200) !important;
}

.active\:text-gray-200:active {
  color: var(--gray-200) !important;
}

.focus\:text-gray-150:focus {
  color: var(--gray-150) !important;
}

.hover\:text-gray-150:hover {
  color: var(--gray-150) !important;
}

.active\:text-gray-150:active {
  color: var(--gray-150) !important;
}

.focus\:text-gray-100:focus {
  color: var(--gray-100) !important;
}

.hover\:text-gray-100:hover {
  color: var(--gray-100) !important;
}

.active\:text-gray-100:active {
  color: var(--gray-100) !important;
}

.text-gray-blue-900 {
  color: var(--gray-blue-900) !important;
}

.text-gray-blue-850 {
  color: var(--gray-blue-850) !important;
}

.text-gray-blue-830 {
  color: var(--gray-blue-830) !important;
}

.text-gray-blue-700 {
  color: var(--gray-blue-700) !important;
}

.text-gray-blue-670 {
  color: var(--gray-blue-670) !important;
}

.text-gray-blue-650 {
  color: var(--gray-blue-650) !important;
}

.text-gray-blue-600 {
  color: var(--gray-blue-600) !important;
}

.text-gray-blue-550 {
  color: var(--gray-blue-550) !important;
}

.text-gray-blue-300 {
  color: var(--gray-blue-300) !important;
}

.text-gray-blue-250 {
  color: var(--gray-blue-250) !important;
}

.text-gray-blue-200 {
  color: var(--gray-blue-200) !important;
}

.text-gray-blue-100 {
  color: var(--gray-blue-100) !important;
}

.text-gray-blue-50 {
  color: var(--gray-blue-50) !important;
}

.focus\:text-gray-blue-900:focus {
  color: var(--gray-blue-900) !important;
}

.hover\:text-gray-blue-900:hover {
  color: var(--gray-blue-900) !important;
}

.active\:text-gray-blue-900:active {
  color: var(--gray-blue-900) !important;
}

.focus\:text-gray-blue-850:focus {
  color: var(--gray-blue-850) !important;
}

.hover\:text-gray-blue-850:hover {
  color: var(--gray-blue-850) !important;
}

.active\:text-gray-blue-850:active {
  color: var(--gray-blue-850) !important;
}

.focus\:text-gray-blue-830:focus {
  color: var(--gray-blue-830) !important;
}

.hover\:text-gray-blue-830:hover {
  color: var(--gray-blue-830) !important;
}

.active\:text-gray-blue-830:active {
  color: var(--gray-blue-830) !important;
}

.focus\:text-gray-blue-700:focus {
  color: var(--gray-blue-700) !important;
}

.hover\:text-gray-blue-700:hover {
  color: var(--gray-blue-700) !important;
}

.active\:text-gray-blue-700:active {
  color: var(--gray-blue-700) !important;
}

.focus\:text-gray-blue-670:focus {
  color: var(--gray-blue-670) !important;
}

.hover\:text-gray-blue-670:hover {
  color: var(--gray-blue-670) !important;
}

.active\:text-gray-blue-670:active {
  color: var(--gray-blue-670) !important;
}

.focus\:text-gray-blue-650:focus {
  color: var(--gray-blue-650) !important;
}

.hover\:text-gray-blue-650:hover {
  color: var(--gray-blue-650) !important;
}

.active\:text-gray-blue-650:active {
  color: var(--gray-blue-650) !important;
}

.focus\:text-gray-blue-600:focus {
  color: var(--gray-blue-600) !important;
}

.hover\:text-gray-blue-600:hover {
  color: var(--gray-blue-600) !important;
}

.active\:text-gray-blue-600:active {
  color: var(--gray-blue-600) !important;
}

.focus\:text-gray-blue-550:focus {
  color: var(--gray-blue-550) !important;
}

.hover\:text-gray-blue-550:hover {
  color: var(--gray-blue-550) !important;
}

.active\:text-gray-blue-550:active {
  color: var(--gray-blue-550) !important;
}

.focus\:text-gray-blue-300:focus {
  color: var(--gray-blue-300) !important;
}

.hover\:text-gray-blue-300:hover {
  color: var(--gray-blue-300) !important;
}

.active\:text-gray-blue-300:active {
  color: var(--gray-blue-300) !important;
}

.focus\:text-gray-blue-250:focus {
  color: var(--gray-blue-250) !important;
}

.hover\:text-gray-blue-250:hover {
  color: var(--gray-blue-250) !important;
}

.active\:text-gray-blue-250:active {
  color: var(--gray-blue-250) !important;
}

.focus\:text-gray-blue-200:focus {
  color: var(--gray-blue-200) !important;
}

.hover\:text-gray-blue-200:hover {
  color: var(--gray-blue-200) !important;
}

.active\:text-gray-blue-200:active {
  color: var(--gray-blue-200) !important;
}

.focus\:text-gray-blue-100:focus {
  color: var(--gray-blue-100) !important;
}

.hover\:text-gray-blue-100:hover {
  color: var(--gray-blue-100) !important;
}

.active\:text-gray-blue-100:active {
  color: var(--gray-blue-100) !important;
}

.focus\:text-gray-blue-50:focus {
  color: var(--gray-blue-50) !important;
}

.hover\:text-gray-blue-50:hover {
  color: var(--gray-blue-50) !important;
}

.active\:text-gray-blue-50:active {
  color: var(--gray-blue-50) !important;
}

.text-green-900 {
  color: var(--green-900) !important;
}

.text-green-860 {
  color: var(--green-860) !important;
}

.text-green-850 {
  color: var(--green-850) !important;
}

.text-green-830 {
  color: var(--green-830) !important;
}

.text-green-800 {
  color: var(--green-800) !important;
}

.text-green-750 {
  color: var(--green-750) !important;
}

.text-green-740 {
  color: var(--green-740) !important;
}

.text-green-730 {
  color: var(--green-730) !important;
}

.text-green-650 {
  color: var(--green-650) !important;
}

.text-green-300 {
  color: var(--green-300) !important;
}

.text-green-200 {
  color: var(--green-200) !important;
}

.text-green-120 {
  color: var(--green-120) !important;
}

.text-green-100 {
  color: var(--green-100) !important;
}

.focus\:text-green-900:focus {
  color: var(--green-900) !important;
}

.hover\:text-green-900:hover {
  color: var(--green-900) !important;
}

.active\:text-green-900:active {
  color: var(--green-900) !important;
}

.focus\:text-green-860:focus {
  color: var(--green-860) !important;
}

.hover\:text-green-860:hover {
  color: var(--green-860) !important;
}

.active\:text-green-860:active {
  color: var(--green-860) !important;
}

.focus\:text-green-850:focus {
  color: var(--green-850) !important;
}

.hover\:text-green-850:hover {
  color: var(--green-850) !important;
}

.active\:text-green-850:active {
  color: var(--green-850) !important;
}

.focus\:text-green-830:focus {
  color: var(--green-830) !important;
}

.hover\:text-green-830:hover {
  color: var(--green-830) !important;
}

.active\:text-green-830:active {
  color: var(--green-830) !important;
}

.focus\:text-green-800:focus {
  color: var(--green-800) !important;
}

.hover\:text-green-800:hover {
  color: var(--green-800) !important;
}

.active\:text-green-800:active {
  color: var(--green-800) !important;
}

.focus\:text-green-750:focus {
  color: var(--green-750) !important;
}

.hover\:text-green-750:hover {
  color: var(--green-750) !important;
}

.active\:text-green-750:active {
  color: var(--green-750) !important;
}

.focus\:text-green-740:focus {
  color: var(--green-740) !important;
}

.hover\:text-green-740:hover {
  color: var(--green-740) !important;
}

.active\:text-green-740:active {
  color: var(--green-740) !important;
}

.focus\:text-green-730:focus {
  color: var(--green-730) !important;
}

.hover\:text-green-730:hover {
  color: var(--green-730) !important;
}

.active\:text-green-730:active {
  color: var(--green-730) !important;
}

.focus\:text-green-650:focus {
  color: var(--green-650) !important;
}

.hover\:text-green-650:hover {
  color: var(--green-650) !important;
}

.active\:text-green-650:active {
  color: var(--green-650) !important;
}

.focus\:text-green-300:focus {
  color: var(--green-300) !important;
}

.hover\:text-green-300:hover {
  color: var(--green-300) !important;
}

.active\:text-green-300:active {
  color: var(--green-300) !important;
}

.focus\:text-green-200:focus {
  color: var(--green-200) !important;
}

.hover\:text-green-200:hover {
  color: var(--green-200) !important;
}

.active\:text-green-200:active {
  color: var(--green-200) !important;
}

.focus\:text-green-120:focus {
  color: var(--green-120) !important;
}

.hover\:text-green-120:hover {
  color: var(--green-120) !important;
}

.active\:text-green-120:active {
  color: var(--green-120) !important;
}

.focus\:text-green-100:focus {
  color: var(--green-100) !important;
}

.hover\:text-green-100:hover {
  color: var(--green-100) !important;
}

.active\:text-green-100:active {
  color: var(--green-100) !important;
}

.text-orange-900 {
  color: var(--orange-900) !important;
}

.text-orange-850 {
  color: var(--orange-850) !important;
}

.text-orange-830 {
  color: var(--orange-830) !important;
}

.text-orange-700 {
  color: var(--orange-700) !important;
}

.text-orange-600 {
  color: var(--orange-600) !important;
}

.text-orange-500 {
  color: var(--orange-500) !important;
}

.text-orange-100 {
  color: var(--orange-100) !important;
}

.focus\:text-orange-900:focus {
  color: var(--orange-900) !important;
}

.hover\:text-orange-900:hover {
  color: var(--orange-900) !important;
}

.active\:text-orange-900:active {
  color: var(--orange-900) !important;
}

.focus\:text-orange-850:focus {
  color: var(--orange-850) !important;
}

.hover\:text-orange-850:hover {
  color: var(--orange-850) !important;
}

.active\:text-orange-850:active {
  color: var(--orange-850) !important;
}

.focus\:text-orange-830:focus {
  color: var(--orange-830) !important;
}

.hover\:text-orange-830:hover {
  color: var(--orange-830) !important;
}

.active\:text-orange-830:active {
  color: var(--orange-830) !important;
}

.focus\:text-orange-700:focus {
  color: var(--orange-700) !important;
}

.hover\:text-orange-700:hover {
  color: var(--orange-700) !important;
}

.active\:text-orange-700:active {
  color: var(--orange-700) !important;
}

.focus\:text-orange-600:focus {
  color: var(--orange-600) !important;
}

.hover\:text-orange-600:hover {
  color: var(--orange-600) !important;
}

.active\:text-orange-600:active {
  color: var(--orange-600) !important;
}

.focus\:text-orange-500:focus {
  color: var(--orange-500) !important;
}

.hover\:text-orange-500:hover {
  color: var(--orange-500) !important;
}

.active\:text-orange-500:active {
  color: var(--orange-500) !important;
}

.focus\:text-orange-100:focus {
  color: var(--orange-100) !important;
}

.hover\:text-orange-100:hover {
  color: var(--orange-100) !important;
}

.active\:text-orange-100:active {
  color: var(--orange-100) !important;
}

.text-purple-900 {
  color: var(--purple-900) !important;
}

.text-purple-850 {
  color: var(--purple-850) !important;
}

.text-purple-830 {
  color: var(--purple-830) !important;
}

.text-purple-600 {
  color: var(--purple-600) !important;
}

.text-purple-400 {
  color: var(--purple-400) !important;
}

.text-purple-300 {
  color: var(--purple-300) !important;
}

.text-purple-200 {
  color: var(--purple-200) !important;
}

.focus\:text-purple-900:focus {
  color: var(--purple-900) !important;
}

.hover\:text-purple-900:hover {
  color: var(--purple-900) !important;
}

.active\:text-purple-900:active {
  color: var(--purple-900) !important;
}

.focus\:text-purple-850:focus {
  color: var(--purple-850) !important;
}

.hover\:text-purple-850:hover {
  color: var(--purple-850) !important;
}

.active\:text-purple-850:active {
  color: var(--purple-850) !important;
}

.focus\:text-purple-830:focus {
  color: var(--purple-830) !important;
}

.hover\:text-purple-830:hover {
  color: var(--purple-830) !important;
}

.active\:text-purple-830:active {
  color: var(--purple-830) !important;
}

.focus\:text-purple-600:focus {
  color: var(--purple-600) !important;
}

.hover\:text-purple-600:hover {
  color: var(--purple-600) !important;
}

.active\:text-purple-600:active {
  color: var(--purple-600) !important;
}

.focus\:text-purple-400:focus {
  color: var(--purple-400) !important;
}

.hover\:text-purple-400:hover {
  color: var(--purple-400) !important;
}

.active\:text-purple-400:active {
  color: var(--purple-400) !important;
}

.focus\:text-purple-300:focus {
  color: var(--purple-300) !important;
}

.hover\:text-purple-300:hover {
  color: var(--purple-300) !important;
}

.active\:text-purple-300:active {
  color: var(--purple-300) !important;
}

.focus\:text-purple-200:focus {
  color: var(--purple-200) !important;
}

.hover\:text-purple-200:hover {
  color: var(--purple-200) !important;
}

.active\:text-purple-200:active {
  color: var(--purple-200) !important;
}

.text-red-950 {
  color: var(--red-950) !important;
}

.text-red-900 {
  color: var(--red-900) !important;
}

.text-red-850 {
  color: var(--red-850) !important;
}

.text-red-830 {
  color: var(--red-830) !important;
}

.text-red-700 {
  color: var(--red-700) !important;
}

.text-red-650 {
  color: var(--red-650) !important;
}

.text-red-300 {
  color: var(--red-300) !important;
}

.text-red-200 {
  color: var(--red-200) !important;
}

.text-red-100 {
  color: var(--red-100) !important;
}

.focus\:text-red-950:focus {
  color: var(--red-950) !important;
}

.hover\:text-red-950:hover {
  color: var(--red-950) !important;
}

.active\:text-red-950:active {
  color: var(--red-950) !important;
}

.focus\:text-red-900:focus {
  color: var(--red-900) !important;
}

.hover\:text-red-900:hover {
  color: var(--red-900) !important;
}

.active\:text-red-900:active {
  color: var(--red-900) !important;
}

.focus\:text-red-850:focus {
  color: var(--red-850) !important;
}

.hover\:text-red-850:hover {
  color: var(--red-850) !important;
}

.active\:text-red-850:active {
  color: var(--red-850) !important;
}

.focus\:text-red-830:focus {
  color: var(--red-830) !important;
}

.hover\:text-red-830:hover {
  color: var(--red-830) !important;
}

.active\:text-red-830:active {
  color: var(--red-830) !important;
}

.focus\:text-red-700:focus {
  color: var(--red-700) !important;
}

.hover\:text-red-700:hover {
  color: var(--red-700) !important;
}

.active\:text-red-700:active {
  color: var(--red-700) !important;
}

.focus\:text-red-650:focus {
  color: var(--red-650) !important;
}

.hover\:text-red-650:hover {
  color: var(--red-650) !important;
}

.active\:text-red-650:active {
  color: var(--red-650) !important;
}

.focus\:text-red-300:focus {
  color: var(--red-300) !important;
}

.hover\:text-red-300:hover {
  color: var(--red-300) !important;
}

.active\:text-red-300:active {
  color: var(--red-300) !important;
}

.focus\:text-red-200:focus {
  color: var(--red-200) !important;
}

.hover\:text-red-200:hover {
  color: var(--red-200) !important;
}

.active\:text-red-200:active {
  color: var(--red-200) !important;
}

.focus\:text-red-100:focus {
  color: var(--red-100) !important;
}

.hover\:text-red-100:hover {
  color: var(--red-100) !important;
}

.active\:text-red-100:active {
  color: var(--red-100) !important;
}

.text-yellow-900 {
  color: var(--yellow-900) !important;
}

.text-yellow-850 {
  color: var(--yellow-850) !important;
}

.text-yellow-800 {
  color: var(--yellow-800) !important;
}

.text-yellow-700 {
  color: var(--yellow-700) !important;
}

.text-yellow-600 {
  color: var(--yellow-600) !important;
}

.text-yellow-570 {
  color: var(--yellow-570) !important;
}

.text-yellow-550 {
  color: var(--yellow-550) !important;
}

.text-yellow-500 {
  color: var(--yellow-500) !important;
}

.text-yellow-400 {
  color: var(--yellow-400) !important;
}

.text-yellow-300 {
  color: var(--yellow-300) !important;
}

.focus\:text-yellow-900:focus {
  color: var(--yellow-900) !important;
}

.hover\:text-yellow-900:hover {
  color: var(--yellow-900) !important;
}

.active\:text-yellow-900:active {
  color: var(--yellow-900) !important;
}

.focus\:text-yellow-850:focus {
  color: var(--yellow-850) !important;
}

.hover\:text-yellow-850:hover {
  color: var(--yellow-850) !important;
}

.active\:text-yellow-850:active {
  color: var(--yellow-850) !important;
}

.focus\:text-yellow-800:focus {
  color: var(--yellow-800) !important;
}

.hover\:text-yellow-800:hover {
  color: var(--yellow-800) !important;
}

.active\:text-yellow-800:active {
  color: var(--yellow-800) !important;
}

.focus\:text-yellow-700:focus {
  color: var(--yellow-700) !important;
}

.hover\:text-yellow-700:hover {
  color: var(--yellow-700) !important;
}

.active\:text-yellow-700:active {
  color: var(--yellow-700) !important;
}

.focus\:text-yellow-600:focus {
  color: var(--yellow-600) !important;
}

.hover\:text-yellow-600:hover {
  color: var(--yellow-600) !important;
}

.active\:text-yellow-600:active {
  color: var(--yellow-600) !important;
}

.focus\:text-yellow-570:focus {
  color: var(--yellow-570) !important;
}

.hover\:text-yellow-570:hover {
  color: var(--yellow-570) !important;
}

.active\:text-yellow-570:active {
  color: var(--yellow-570) !important;
}

.focus\:text-yellow-550:focus {
  color: var(--yellow-550) !important;
}

.hover\:text-yellow-550:hover {
  color: var(--yellow-550) !important;
}

.active\:text-yellow-550:active {
  color: var(--yellow-550) !important;
}

.focus\:text-yellow-500:focus {
  color: var(--yellow-500) !important;
}

.hover\:text-yellow-500:hover {
  color: var(--yellow-500) !important;
}

.active\:text-yellow-500:active {
  color: var(--yellow-500) !important;
}

.focus\:text-yellow-400:focus {
  color: var(--yellow-400) !important;
}

.hover\:text-yellow-400:hover {
  color: var(--yellow-400) !important;
}

.active\:text-yellow-400:active {
  color: var(--yellow-400) !important;
}

.focus\:text-yellow-300:focus {
  color: var(--yellow-300) !important;
}

.hover\:text-yellow-300:hover {
  color: var(--yellow-300) !important;
}

.active\:text-yellow-300:active {
  color: var(--yellow-300) !important;
}

.bg-blue-910 {
  background-color: var(--blue-910) !important;
}

.bg-blue-900 {
  background-color: var(--blue-900) !important;
}

.bg-blue-850 {
  background-color: var(--blue-850) !important;
}

.bg-blue-830 {
  background-color: var(--blue-830) !important;
}

.bg-blue-800 {
  background-color: var(--blue-800) !important;
}

.bg-blue-790 {
  background-color: var(--blue-790) !important;
}

.bg-blue-785 {
  background-color: var(--blue-785) !important;
}

.bg-blue-780 {
  background-color: var(--blue-780) !important;
}

.bg-blue-770 {
  background-color: var(--blue-770) !important;
}

.bg-blue-760 {
  background-color: var(--blue-760) !important;
}

.bg-blue-600 {
  background-color: var(--blue-600) !important;
}

.bg-blue-550 {
  background-color: var(--blue-550) !important;
}

.bg-blue-300 {
  background-color: var(--blue-300) !important;
}

.bg-blue-200 {
  background-color: var(--blue-200) !important;
}

.bg-blue-120 {
  background-color: var(--blue-120) !important;
}

.bg-blue-100 {
  background-color: var(--blue-100) !important;
}

.focus\:bg-blue-910:focus {
  background-color: var(--blue-910) !important;
}

.hover\:bg-blue-910:hover {
  background-color: var(--blue-910) !important;
}

.active\:bg-blue-910:active {
  background-color: var(--blue-910) !important;
}

.focus\:bg-blue-900:focus {
  background-color: var(--blue-900) !important;
}

.hover\:bg-blue-900:hover {
  background-color: var(--blue-900) !important;
}

.active\:bg-blue-900:active {
  background-color: var(--blue-900) !important;
}

.focus\:bg-blue-850:focus {
  background-color: var(--blue-850) !important;
}

.hover\:bg-blue-850:hover {
  background-color: var(--blue-850) !important;
}

.active\:bg-blue-850:active {
  background-color: var(--blue-850) !important;
}

.focus\:bg-blue-830:focus {
  background-color: var(--blue-830) !important;
}

.hover\:bg-blue-830:hover {
  background-color: var(--blue-830) !important;
}

.active\:bg-blue-830:active {
  background-color: var(--blue-830) !important;
}

.focus\:bg-blue-800:focus {
  background-color: var(--blue-800) !important;
}

.hover\:bg-blue-800:hover {
  background-color: var(--blue-800) !important;
}

.active\:bg-blue-800:active {
  background-color: var(--blue-800) !important;
}

.focus\:bg-blue-790:focus {
  background-color: var(--blue-790) !important;
}

.hover\:bg-blue-790:hover {
  background-color: var(--blue-790) !important;
}

.active\:bg-blue-790:active {
  background-color: var(--blue-790) !important;
}

.focus\:bg-blue-785:focus {
  background-color: var(--blue-785) !important;
}

.hover\:bg-blue-785:hover {
  background-color: var(--blue-785) !important;
}

.active\:bg-blue-785:active {
  background-color: var(--blue-785) !important;
}

.focus\:bg-blue-780:focus {
  background-color: var(--blue-780) !important;
}

.hover\:bg-blue-780:hover {
  background-color: var(--blue-780) !important;
}

.active\:bg-blue-780:active {
  background-color: var(--blue-780) !important;
}

.focus\:bg-blue-770:focus {
  background-color: var(--blue-770) !important;
}

.hover\:bg-blue-770:hover {
  background-color: var(--blue-770) !important;
}

.active\:bg-blue-770:active {
  background-color: var(--blue-770) !important;
}

.focus\:bg-blue-760:focus {
  background-color: var(--blue-760) !important;
}

.hover\:bg-blue-760:hover {
  background-color: var(--blue-760) !important;
}

.active\:bg-blue-760:active {
  background-color: var(--blue-760) !important;
}

.focus\:bg-blue-600:focus {
  background-color: var(--blue-600) !important;
}

.hover\:bg-blue-600:hover {
  background-color: var(--blue-600) !important;
}

.active\:bg-blue-600:active {
  background-color: var(--blue-600) !important;
}

.focus\:bg-blue-550:focus {
  background-color: var(--blue-550) !important;
}

.hover\:bg-blue-550:hover {
  background-color: var(--blue-550) !important;
}

.active\:bg-blue-550:active {
  background-color: var(--blue-550) !important;
}

.focus\:bg-blue-300:focus {
  background-color: var(--blue-300) !important;
}

.hover\:bg-blue-300:hover {
  background-color: var(--blue-300) !important;
}

.active\:bg-blue-300:active {
  background-color: var(--blue-300) !important;
}

.focus\:bg-blue-200:focus {
  background-color: var(--blue-200) !important;
}

.hover\:bg-blue-200:hover {
  background-color: var(--blue-200) !important;
}

.active\:bg-blue-200:active {
  background-color: var(--blue-200) !important;
}

.focus\:bg-blue-120:focus {
  background-color: var(--blue-120) !important;
}

.hover\:bg-blue-120:hover {
  background-color: var(--blue-120) !important;
}

.active\:bg-blue-120:active {
  background-color: var(--blue-120) !important;
}

.focus\:bg-blue-100:focus {
  background-color: var(--blue-100) !important;
}

.hover\:bg-blue-100:hover {
  background-color: var(--blue-100) !important;
}

.active\:bg-blue-100:active {
  background-color: var(--blue-100) !important;
}

.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.bg-gray-700 {
  background-color: var(--gray-700) !important;
}

.bg-gray-650 {
  background-color: var(--gray-650) !important;
}

.bg-gray-600 {
  background-color: var(--gray-600) !important;
}

.bg-gray-500 {
  background-color: var(--gray-500) !important;
}

.bg-gray-200 {
  background-color: var(--gray-200) !important;
}

.bg-gray-150 {
  background-color: var(--gray-150) !important;
}

.bg-gray-100 {
  background-color: var(--gray-100) !important;
}

.focus\:bg-gray-900:focus {
  background-color: var(--gray-900) !important;
}

.hover\:bg-gray-900:hover {
  background-color: var(--gray-900) !important;
}

.active\:bg-gray-900:active {
  background-color: var(--gray-900) !important;
}

.focus\:bg-gray-700:focus {
  background-color: var(--gray-700) !important;
}

.hover\:bg-gray-700:hover {
  background-color: var(--gray-700) !important;
}

.active\:bg-gray-700:active {
  background-color: var(--gray-700) !important;
}

.focus\:bg-gray-650:focus {
  background-color: var(--gray-650) !important;
}

.hover\:bg-gray-650:hover {
  background-color: var(--gray-650) !important;
}

.active\:bg-gray-650:active {
  background-color: var(--gray-650) !important;
}

.focus\:bg-gray-600:focus {
  background-color: var(--gray-600) !important;
}

.hover\:bg-gray-600:hover {
  background-color: var(--gray-600) !important;
}

.active\:bg-gray-600:active {
  background-color: var(--gray-600) !important;
}

.focus\:bg-gray-500:focus {
  background-color: var(--gray-500) !important;
}

.hover\:bg-gray-500:hover {
  background-color: var(--gray-500) !important;
}

.active\:bg-gray-500:active {
  background-color: var(--gray-500) !important;
}

.focus\:bg-gray-200:focus {
  background-color: var(--gray-200) !important;
}

.hover\:bg-gray-200:hover {
  background-color: var(--gray-200) !important;
}

.active\:bg-gray-200:active {
  background-color: var(--gray-200) !important;
}

.focus\:bg-gray-150:focus {
  background-color: var(--gray-150) !important;
}

.hover\:bg-gray-150:hover {
  background-color: var(--gray-150) !important;
}

.active\:bg-gray-150:active {
  background-color: var(--gray-150) !important;
}

.focus\:bg-gray-100:focus {
  background-color: var(--gray-100) !important;
}

.hover\:bg-gray-100:hover {
  background-color: var(--gray-100) !important;
}

.active\:bg-gray-100:active {
  background-color: var(--gray-100) !important;
}

.bg-gray-blue-900 {
  background-color: var(--gray-blue-900) !important;
}

.bg-gray-blue-850 {
  background-color: var(--gray-blue-850) !important;
}

.bg-gray-blue-830 {
  background-color: var(--gray-blue-830) !important;
}

.bg-gray-blue-700 {
  background-color: var(--gray-blue-700) !important;
}

.bg-gray-blue-670 {
  background-color: var(--gray-blue-670) !important;
}

.bg-gray-blue-650 {
  background-color: var(--gray-blue-650) !important;
}

.bg-gray-blue-600 {
  background-color: var(--gray-blue-600) !important;
}

.bg-gray-blue-550 {
  background-color: var(--gray-blue-550) !important;
}

.bg-gray-blue-300 {
  background-color: var(--gray-blue-300) !important;
}

.bg-gray-blue-250 {
  background-color: var(--gray-blue-250) !important;
}

.bg-gray-blue-200 {
  background-color: var(--gray-blue-200) !important;
}

.bg-gray-blue-100 {
  background-color: var(--gray-blue-100) !important;
}

.bg-gray-blue-50 {
  background-color: var(--gray-blue-50) !important;
}

.focus\:bg-gray-blue-900:focus {
  background-color: var(--gray-blue-900) !important;
}

.hover\:bg-gray-blue-900:hover {
  background-color: var(--gray-blue-900) !important;
}

.active\:bg-gray-blue-900:active {
  background-color: var(--gray-blue-900) !important;
}

.focus\:bg-gray-blue-850:focus {
  background-color: var(--gray-blue-850) !important;
}

.hover\:bg-gray-blue-850:hover {
  background-color: var(--gray-blue-850) !important;
}

.active\:bg-gray-blue-850:active {
  background-color: var(--gray-blue-850) !important;
}

.focus\:bg-gray-blue-830:focus {
  background-color: var(--gray-blue-830) !important;
}

.hover\:bg-gray-blue-830:hover {
  background-color: var(--gray-blue-830) !important;
}

.active\:bg-gray-blue-830:active {
  background-color: var(--gray-blue-830) !important;
}

.focus\:bg-gray-blue-700:focus {
  background-color: var(--gray-blue-700) !important;
}

.hover\:bg-gray-blue-700:hover {
  background-color: var(--gray-blue-700) !important;
}

.active\:bg-gray-blue-700:active {
  background-color: var(--gray-blue-700) !important;
}

.focus\:bg-gray-blue-670:focus {
  background-color: var(--gray-blue-670) !important;
}

.hover\:bg-gray-blue-670:hover {
  background-color: var(--gray-blue-670) !important;
}

.active\:bg-gray-blue-670:active {
  background-color: var(--gray-blue-670) !important;
}

.focus\:bg-gray-blue-650:focus {
  background-color: var(--gray-blue-650) !important;
}

.hover\:bg-gray-blue-650:hover {
  background-color: var(--gray-blue-650) !important;
}

.active\:bg-gray-blue-650:active {
  background-color: var(--gray-blue-650) !important;
}

.focus\:bg-gray-blue-600:focus {
  background-color: var(--gray-blue-600) !important;
}

.hover\:bg-gray-blue-600:hover {
  background-color: var(--gray-blue-600) !important;
}

.active\:bg-gray-blue-600:active {
  background-color: var(--gray-blue-600) !important;
}

.focus\:bg-gray-blue-550:focus {
  background-color: var(--gray-blue-550) !important;
}

.hover\:bg-gray-blue-550:hover {
  background-color: var(--gray-blue-550) !important;
}

.active\:bg-gray-blue-550:active {
  background-color: var(--gray-blue-550) !important;
}

.focus\:bg-gray-blue-300:focus {
  background-color: var(--gray-blue-300) !important;
}

.hover\:bg-gray-blue-300:hover {
  background-color: var(--gray-blue-300) !important;
}

.active\:bg-gray-blue-300:active {
  background-color: var(--gray-blue-300) !important;
}

.focus\:bg-gray-blue-250:focus {
  background-color: var(--gray-blue-250) !important;
}

.hover\:bg-gray-blue-250:hover {
  background-color: var(--gray-blue-250) !important;
}

.active\:bg-gray-blue-250:active {
  background-color: var(--gray-blue-250) !important;
}

.focus\:bg-gray-blue-200:focus {
  background-color: var(--gray-blue-200) !important;
}

.hover\:bg-gray-blue-200:hover {
  background-color: var(--gray-blue-200) !important;
}

.active\:bg-gray-blue-200:active {
  background-color: var(--gray-blue-200) !important;
}

.focus\:bg-gray-blue-100:focus {
  background-color: var(--gray-blue-100) !important;
}

.hover\:bg-gray-blue-100:hover {
  background-color: var(--gray-blue-100) !important;
}

.active\:bg-gray-blue-100:active {
  background-color: var(--gray-blue-100) !important;
}

.focus\:bg-gray-blue-50:focus {
  background-color: var(--gray-blue-50) !important;
}

.hover\:bg-gray-blue-50:hover {
  background-color: var(--gray-blue-50) !important;
}

.active\:bg-gray-blue-50:active {
  background-color: var(--gray-blue-50) !important;
}

.bg-green-900 {
  background-color: var(--green-900) !important;
}

.bg-green-860 {
  background-color: var(--green-860) !important;
}

.bg-green-850 {
  background-color: var(--green-850) !important;
}

.bg-green-830 {
  background-color: var(--green-830) !important;
}

.bg-green-800 {
  background-color: var(--green-800) !important;
}

.bg-green-750 {
  background-color: var(--green-750) !important;
}

.bg-green-740 {
  background-color: var(--green-740) !important;
}

.bg-green-730 {
  background-color: var(--green-730) !important;
}

.bg-green-650 {
  background-color: var(--green-650) !important;
}

.bg-green-300 {
  background-color: var(--green-300) !important;
}

.bg-green-200 {
  background-color: var(--green-200) !important;
}

.bg-green-120 {
  background-color: var(--green-120) !important;
}

.bg-green-100 {
  background-color: var(--green-100) !important;
}

.focus\:bg-green-900:focus {
  background-color: var(--green-900) !important;
}

.hover\:bg-green-900:hover {
  background-color: var(--green-900) !important;
}

.active\:bg-green-900:active {
  background-color: var(--green-900) !important;
}

.focus\:bg-green-860:focus {
  background-color: var(--green-860) !important;
}

.hover\:bg-green-860:hover {
  background-color: var(--green-860) !important;
}

.active\:bg-green-860:active {
  background-color: var(--green-860) !important;
}

.focus\:bg-green-850:focus {
  background-color: var(--green-850) !important;
}

.hover\:bg-green-850:hover {
  background-color: var(--green-850) !important;
}

.active\:bg-green-850:active {
  background-color: var(--green-850) !important;
}

.focus\:bg-green-830:focus {
  background-color: var(--green-830) !important;
}

.hover\:bg-green-830:hover {
  background-color: var(--green-830) !important;
}

.active\:bg-green-830:active {
  background-color: var(--green-830) !important;
}

.focus\:bg-green-800:focus {
  background-color: var(--green-800) !important;
}

.hover\:bg-green-800:hover {
  background-color: var(--green-800) !important;
}

.active\:bg-green-800:active {
  background-color: var(--green-800) !important;
}

.focus\:bg-green-750:focus {
  background-color: var(--green-750) !important;
}

.hover\:bg-green-750:hover {
  background-color: var(--green-750) !important;
}

.active\:bg-green-750:active {
  background-color: var(--green-750) !important;
}

.focus\:bg-green-740:focus {
  background-color: var(--green-740) !important;
}

.hover\:bg-green-740:hover {
  background-color: var(--green-740) !important;
}

.active\:bg-green-740:active {
  background-color: var(--green-740) !important;
}

.focus\:bg-green-730:focus {
  background-color: var(--green-730) !important;
}

.hover\:bg-green-730:hover {
  background-color: var(--green-730) !important;
}

.active\:bg-green-730:active {
  background-color: var(--green-730) !important;
}

.focus\:bg-green-650:focus {
  background-color: var(--green-650) !important;
}

.hover\:bg-green-650:hover {
  background-color: var(--green-650) !important;
}

.active\:bg-green-650:active {
  background-color: var(--green-650) !important;
}

.focus\:bg-green-300:focus {
  background-color: var(--green-300) !important;
}

.hover\:bg-green-300:hover {
  background-color: var(--green-300) !important;
}

.active\:bg-green-300:active {
  background-color: var(--green-300) !important;
}

.focus\:bg-green-200:focus {
  background-color: var(--green-200) !important;
}

.hover\:bg-green-200:hover {
  background-color: var(--green-200) !important;
}

.active\:bg-green-200:active {
  background-color: var(--green-200) !important;
}

.focus\:bg-green-120:focus {
  background-color: var(--green-120) !important;
}

.hover\:bg-green-120:hover {
  background-color: var(--green-120) !important;
}

.active\:bg-green-120:active {
  background-color: var(--green-120) !important;
}

.focus\:bg-green-100:focus {
  background-color: var(--green-100) !important;
}

.hover\:bg-green-100:hover {
  background-color: var(--green-100) !important;
}

.active\:bg-green-100:active {
  background-color: var(--green-100) !important;
}

.bg-orange-900 {
  background-color: var(--orange-900) !important;
}

.bg-orange-850 {
  background-color: var(--orange-850) !important;
}

.bg-orange-830 {
  background-color: var(--orange-830) !important;
}

.bg-orange-700 {
  background-color: var(--orange-700) !important;
}

.bg-orange-600 {
  background-color: var(--orange-600) !important;
}

.bg-orange-500 {
  background-color: var(--orange-500) !important;
}

.bg-orange-100 {
  background-color: var(--orange-100) !important;
}

.focus\:bg-orange-900:focus {
  background-color: var(--orange-900) !important;
}

.hover\:bg-orange-900:hover {
  background-color: var(--orange-900) !important;
}

.active\:bg-orange-900:active {
  background-color: var(--orange-900) !important;
}

.focus\:bg-orange-850:focus {
  background-color: var(--orange-850) !important;
}

.hover\:bg-orange-850:hover {
  background-color: var(--orange-850) !important;
}

.active\:bg-orange-850:active {
  background-color: var(--orange-850) !important;
}

.focus\:bg-orange-830:focus {
  background-color: var(--orange-830) !important;
}

.hover\:bg-orange-830:hover {
  background-color: var(--orange-830) !important;
}

.active\:bg-orange-830:active {
  background-color: var(--orange-830) !important;
}

.focus\:bg-orange-700:focus {
  background-color: var(--orange-700) !important;
}

.hover\:bg-orange-700:hover {
  background-color: var(--orange-700) !important;
}

.active\:bg-orange-700:active {
  background-color: var(--orange-700) !important;
}

.focus\:bg-orange-600:focus {
  background-color: var(--orange-600) !important;
}

.hover\:bg-orange-600:hover {
  background-color: var(--orange-600) !important;
}

.active\:bg-orange-600:active {
  background-color: var(--orange-600) !important;
}

.focus\:bg-orange-500:focus {
  background-color: var(--orange-500) !important;
}

.hover\:bg-orange-500:hover {
  background-color: var(--orange-500) !important;
}

.active\:bg-orange-500:active {
  background-color: var(--orange-500) !important;
}

.focus\:bg-orange-100:focus {
  background-color: var(--orange-100) !important;
}

.hover\:bg-orange-100:hover {
  background-color: var(--orange-100) !important;
}

.active\:bg-orange-100:active {
  background-color: var(--orange-100) !important;
}

.bg-purple-900 {
  background-color: var(--purple-900) !important;
}

.bg-purple-850 {
  background-color: var(--purple-850) !important;
}

.bg-purple-830 {
  background-color: var(--purple-830) !important;
}

.bg-purple-600 {
  background-color: var(--purple-600) !important;
}

.bg-purple-400 {
  background-color: var(--purple-400) !important;
}

.bg-purple-300 {
  background-color: var(--purple-300) !important;
}

.bg-purple-200 {
  background-color: var(--purple-200) !important;
}

.focus\:bg-purple-900:focus {
  background-color: var(--purple-900) !important;
}

.hover\:bg-purple-900:hover {
  background-color: var(--purple-900) !important;
}

.active\:bg-purple-900:active {
  background-color: var(--purple-900) !important;
}

.focus\:bg-purple-850:focus {
  background-color: var(--purple-850) !important;
}

.hover\:bg-purple-850:hover {
  background-color: var(--purple-850) !important;
}

.active\:bg-purple-850:active {
  background-color: var(--purple-850) !important;
}

.focus\:bg-purple-830:focus {
  background-color: var(--purple-830) !important;
}

.hover\:bg-purple-830:hover {
  background-color: var(--purple-830) !important;
}

.active\:bg-purple-830:active {
  background-color: var(--purple-830) !important;
}

.focus\:bg-purple-600:focus {
  background-color: var(--purple-600) !important;
}

.hover\:bg-purple-600:hover {
  background-color: var(--purple-600) !important;
}

.active\:bg-purple-600:active {
  background-color: var(--purple-600) !important;
}

.focus\:bg-purple-400:focus {
  background-color: var(--purple-400) !important;
}

.hover\:bg-purple-400:hover {
  background-color: var(--purple-400) !important;
}

.active\:bg-purple-400:active {
  background-color: var(--purple-400) !important;
}

.focus\:bg-purple-300:focus {
  background-color: var(--purple-300) !important;
}

.hover\:bg-purple-300:hover {
  background-color: var(--purple-300) !important;
}

.active\:bg-purple-300:active {
  background-color: var(--purple-300) !important;
}

.focus\:bg-purple-200:focus {
  background-color: var(--purple-200) !important;
}

.hover\:bg-purple-200:hover {
  background-color: var(--purple-200) !important;
}

.active\:bg-purple-200:active {
  background-color: var(--purple-200) !important;
}

.bg-red-950 {
  background-color: var(--red-950) !important;
}

.bg-red-900 {
  background-color: var(--red-900) !important;
}

.bg-red-850 {
  background-color: var(--red-850) !important;
}

.bg-red-830 {
  background-color: var(--red-830) !important;
}

.bg-red-700 {
  background-color: var(--red-700) !important;
}

.bg-red-650 {
  background-color: var(--red-650) !important;
}

.bg-red-300 {
  background-color: var(--red-300) !important;
}

.bg-red-200 {
  background-color: var(--red-200) !important;
}

.bg-red-100 {
  background-color: var(--red-100) !important;
}

.focus\:bg-red-950:focus {
  background-color: var(--red-950) !important;
}

.hover\:bg-red-950:hover {
  background-color: var(--red-950) !important;
}

.active\:bg-red-950:active {
  background-color: var(--red-950) !important;
}

.focus\:bg-red-900:focus {
  background-color: var(--red-900) !important;
}

.hover\:bg-red-900:hover {
  background-color: var(--red-900) !important;
}

.active\:bg-red-900:active {
  background-color: var(--red-900) !important;
}

.focus\:bg-red-850:focus {
  background-color: var(--red-850) !important;
}

.hover\:bg-red-850:hover {
  background-color: var(--red-850) !important;
}

.active\:bg-red-850:active {
  background-color: var(--red-850) !important;
}

.focus\:bg-red-830:focus {
  background-color: var(--red-830) !important;
}

.hover\:bg-red-830:hover {
  background-color: var(--red-830) !important;
}

.active\:bg-red-830:active {
  background-color: var(--red-830) !important;
}

.focus\:bg-red-700:focus {
  background-color: var(--red-700) !important;
}

.hover\:bg-red-700:hover {
  background-color: var(--red-700) !important;
}

.active\:bg-red-700:active {
  background-color: var(--red-700) !important;
}

.focus\:bg-red-650:focus {
  background-color: var(--red-650) !important;
}

.hover\:bg-red-650:hover {
  background-color: var(--red-650) !important;
}

.active\:bg-red-650:active {
  background-color: var(--red-650) !important;
}

.focus\:bg-red-300:focus {
  background-color: var(--red-300) !important;
}

.hover\:bg-red-300:hover {
  background-color: var(--red-300) !important;
}

.active\:bg-red-300:active {
  background-color: var(--red-300) !important;
}

.focus\:bg-red-200:focus {
  background-color: var(--red-200) !important;
}

.hover\:bg-red-200:hover {
  background-color: var(--red-200) !important;
}

.active\:bg-red-200:active {
  background-color: var(--red-200) !important;
}

.focus\:bg-red-100:focus {
  background-color: var(--red-100) !important;
}

.hover\:bg-red-100:hover {
  background-color: var(--red-100) !important;
}

.active\:bg-red-100:active {
  background-color: var(--red-100) !important;
}

.bg-yellow-900 {
  background-color: var(--yellow-900) !important;
}

.bg-yellow-850 {
  background-color: var(--yellow-850) !important;
}

.bg-yellow-800 {
  background-color: var(--yellow-800) !important;
}

.bg-yellow-700 {
  background-color: var(--yellow-700) !important;
}

.bg-yellow-600 {
  background-color: var(--yellow-600) !important;
}

.bg-yellow-570 {
  background-color: var(--yellow-570) !important;
}

.bg-yellow-550 {
  background-color: var(--yellow-550) !important;
}

.bg-yellow-500 {
  background-color: var(--yellow-500) !important;
}

.bg-yellow-400 {
  background-color: var(--yellow-400) !important;
}

.bg-yellow-300 {
  background-color: var(--yellow-300) !important;
}

.focus\:bg-yellow-900:focus {
  background-color: var(--yellow-900) !important;
}

.hover\:bg-yellow-900:hover {
  background-color: var(--yellow-900) !important;
}

.active\:bg-yellow-900:active {
  background-color: var(--yellow-900) !important;
}

.focus\:bg-yellow-850:focus {
  background-color: var(--yellow-850) !important;
}

.hover\:bg-yellow-850:hover {
  background-color: var(--yellow-850) !important;
}

.active\:bg-yellow-850:active {
  background-color: var(--yellow-850) !important;
}

.focus\:bg-yellow-800:focus {
  background-color: var(--yellow-800) !important;
}

.hover\:bg-yellow-800:hover {
  background-color: var(--yellow-800) !important;
}

.active\:bg-yellow-800:active {
  background-color: var(--yellow-800) !important;
}

.focus\:bg-yellow-700:focus {
  background-color: var(--yellow-700) !important;
}

.hover\:bg-yellow-700:hover {
  background-color: var(--yellow-700) !important;
}

.active\:bg-yellow-700:active {
  background-color: var(--yellow-700) !important;
}

.focus\:bg-yellow-600:focus {
  background-color: var(--yellow-600) !important;
}

.hover\:bg-yellow-600:hover {
  background-color: var(--yellow-600) !important;
}

.active\:bg-yellow-600:active {
  background-color: var(--yellow-600) !important;
}

.focus\:bg-yellow-570:focus {
  background-color: var(--yellow-570) !important;
}

.hover\:bg-yellow-570:hover {
  background-color: var(--yellow-570) !important;
}

.active\:bg-yellow-570:active {
  background-color: var(--yellow-570) !important;
}

.focus\:bg-yellow-550:focus {
  background-color: var(--yellow-550) !important;
}

.hover\:bg-yellow-550:hover {
  background-color: var(--yellow-550) !important;
}

.active\:bg-yellow-550:active {
  background-color: var(--yellow-550) !important;
}

.focus\:bg-yellow-500:focus {
  background-color: var(--yellow-500) !important;
}

.hover\:bg-yellow-500:hover {
  background-color: var(--yellow-500) !important;
}

.active\:bg-yellow-500:active {
  background-color: var(--yellow-500) !important;
}

.focus\:bg-yellow-400:focus {
  background-color: var(--yellow-400) !important;
}

.hover\:bg-yellow-400:hover {
  background-color: var(--yellow-400) !important;
}

.active\:bg-yellow-400:active {
  background-color: var(--yellow-400) !important;
}

.focus\:bg-yellow-300:focus {
  background-color: var(--yellow-300) !important;
}

.hover\:bg-yellow-300:hover {
  background-color: var(--yellow-300) !important;
}

.active\:bg-yellow-300:active {
  background-color: var(--yellow-300) !important;
}

.border-blue-910 {
  border-color: var(--blue-910) !important;
}

.border-blue-900 {
  border-color: var(--blue-900) !important;
}

.border-blue-850 {
  border-color: var(--blue-850) !important;
}

.border-blue-830 {
  border-color: var(--blue-830) !important;
}

.border-blue-800 {
  border-color: var(--blue-800) !important;
}

.border-blue-790 {
  border-color: var(--blue-790) !important;
}

.border-blue-785 {
  border-color: var(--blue-785) !important;
}

.border-blue-780 {
  border-color: var(--blue-780) !important;
}

.border-blue-770 {
  border-color: var(--blue-770) !important;
}

.border-blue-760 {
  border-color: var(--blue-760) !important;
}

.border-blue-600 {
  border-color: var(--blue-600) !important;
}

.border-blue-550 {
  border-color: var(--blue-550) !important;
}

.border-blue-300 {
  border-color: var(--blue-300) !important;
}

.border-blue-200 {
  border-color: var(--blue-200) !important;
}

.border-blue-120 {
  border-color: var(--blue-120) !important;
}

.border-blue-100 {
  border-color: var(--blue-100) !important;
}

.focus\:border-blue-910:focus {
  border-color: var(--blue-910) !important;
}

.hover\:border-blue-910:hover {
  border-color: var(--blue-910) !important;
}

.active\:border-blue-910:active {
  border-color: var(--blue-910) !important;
}

.focus\:border-blue-900:focus {
  border-color: var(--blue-900) !important;
}

.hover\:border-blue-900:hover {
  border-color: var(--blue-900) !important;
}

.active\:border-blue-900:active {
  border-color: var(--blue-900) !important;
}

.focus\:border-blue-850:focus {
  border-color: var(--blue-850) !important;
}

.hover\:border-blue-850:hover {
  border-color: var(--blue-850) !important;
}

.active\:border-blue-850:active {
  border-color: var(--blue-850) !important;
}

.focus\:border-blue-830:focus {
  border-color: var(--blue-830) !important;
}

.hover\:border-blue-830:hover {
  border-color: var(--blue-830) !important;
}

.active\:border-blue-830:active {
  border-color: var(--blue-830) !important;
}

.focus\:border-blue-800:focus {
  border-color: var(--blue-800) !important;
}

.hover\:border-blue-800:hover {
  border-color: var(--blue-800) !important;
}

.active\:border-blue-800:active {
  border-color: var(--blue-800) !important;
}

.focus\:border-blue-790:focus {
  border-color: var(--blue-790) !important;
}

.hover\:border-blue-790:hover {
  border-color: var(--blue-790) !important;
}

.active\:border-blue-790:active {
  border-color: var(--blue-790) !important;
}

.focus\:border-blue-785:focus {
  border-color: var(--blue-785) !important;
}

.hover\:border-blue-785:hover {
  border-color: var(--blue-785) !important;
}

.active\:border-blue-785:active {
  border-color: var(--blue-785) !important;
}

.focus\:border-blue-780:focus {
  border-color: var(--blue-780) !important;
}

.hover\:border-blue-780:hover {
  border-color: var(--blue-780) !important;
}

.active\:border-blue-780:active {
  border-color: var(--blue-780) !important;
}

.focus\:border-blue-770:focus {
  border-color: var(--blue-770) !important;
}

.hover\:border-blue-770:hover {
  border-color: var(--blue-770) !important;
}

.active\:border-blue-770:active {
  border-color: var(--blue-770) !important;
}

.focus\:border-blue-760:focus {
  border-color: var(--blue-760) !important;
}

.hover\:border-blue-760:hover {
  border-color: var(--blue-760) !important;
}

.active\:border-blue-760:active {
  border-color: var(--blue-760) !important;
}

.focus\:border-blue-600:focus {
  border-color: var(--blue-600) !important;
}

.hover\:border-blue-600:hover {
  border-color: var(--blue-600) !important;
}

.active\:border-blue-600:active {
  border-color: var(--blue-600) !important;
}

.focus\:border-blue-550:focus {
  border-color: var(--blue-550) !important;
}

.hover\:border-blue-550:hover {
  border-color: var(--blue-550) !important;
}

.active\:border-blue-550:active {
  border-color: var(--blue-550) !important;
}

.focus\:border-blue-300:focus {
  border-color: var(--blue-300) !important;
}

.hover\:border-blue-300:hover {
  border-color: var(--blue-300) !important;
}

.active\:border-blue-300:active {
  border-color: var(--blue-300) !important;
}

.focus\:border-blue-200:focus {
  border-color: var(--blue-200) !important;
}

.hover\:border-blue-200:hover {
  border-color: var(--blue-200) !important;
}

.active\:border-blue-200:active {
  border-color: var(--blue-200) !important;
}

.focus\:border-blue-120:focus {
  border-color: var(--blue-120) !important;
}

.hover\:border-blue-120:hover {
  border-color: var(--blue-120) !important;
}

.active\:border-blue-120:active {
  border-color: var(--blue-120) !important;
}

.focus\:border-blue-100:focus {
  border-color: var(--blue-100) !important;
}

.hover\:border-blue-100:hover {
  border-color: var(--blue-100) !important;
}

.active\:border-blue-100:active {
  border-color: var(--blue-100) !important;
}

.border-gray-900 {
  border-color: var(--gray-900) !important;
}

.border-gray-700 {
  border-color: var(--gray-700) !important;
}

.border-gray-650 {
  border-color: var(--gray-650) !important;
}

.border-gray-600 {
  border-color: var(--gray-600) !important;
}

.border-gray-500 {
  border-color: var(--gray-500) !important;
}

.border-gray-200 {
  border-color: var(--gray-200) !important;
}

.border-gray-150 {
  border-color: var(--gray-150) !important;
}

.border-gray-100 {
  border-color: var(--gray-100) !important;
}

.focus\:border-gray-900:focus {
  border-color: var(--gray-900) !important;
}

.hover\:border-gray-900:hover {
  border-color: var(--gray-900) !important;
}

.active\:border-gray-900:active {
  border-color: var(--gray-900) !important;
}

.focus\:border-gray-700:focus {
  border-color: var(--gray-700) !important;
}

.hover\:border-gray-700:hover {
  border-color: var(--gray-700) !important;
}

.active\:border-gray-700:active {
  border-color: var(--gray-700) !important;
}

.focus\:border-gray-650:focus {
  border-color: var(--gray-650) !important;
}

.hover\:border-gray-650:hover {
  border-color: var(--gray-650) !important;
}

.active\:border-gray-650:active {
  border-color: var(--gray-650) !important;
}

.focus\:border-gray-600:focus {
  border-color: var(--gray-600) !important;
}

.hover\:border-gray-600:hover {
  border-color: var(--gray-600) !important;
}

.active\:border-gray-600:active {
  border-color: var(--gray-600) !important;
}

.focus\:border-gray-500:focus {
  border-color: var(--gray-500) !important;
}

.hover\:border-gray-500:hover {
  border-color: var(--gray-500) !important;
}

.active\:border-gray-500:active {
  border-color: var(--gray-500) !important;
}

.focus\:border-gray-200:focus {
  border-color: var(--gray-200) !important;
}

.hover\:border-gray-200:hover {
  border-color: var(--gray-200) !important;
}

.active\:border-gray-200:active {
  border-color: var(--gray-200) !important;
}

.focus\:border-gray-150:focus {
  border-color: var(--gray-150) !important;
}

.hover\:border-gray-150:hover {
  border-color: var(--gray-150) !important;
}

.active\:border-gray-150:active {
  border-color: var(--gray-150) !important;
}

.focus\:border-gray-100:focus {
  border-color: var(--gray-100) !important;
}

.hover\:border-gray-100:hover {
  border-color: var(--gray-100) !important;
}

.active\:border-gray-100:active {
  border-color: var(--gray-100) !important;
}

.border-gray-blue-900 {
  border-color: var(--gray-blue-900) !important;
}

.border-gray-blue-850 {
  border-color: var(--gray-blue-850) !important;
}

.border-gray-blue-830 {
  border-color: var(--gray-blue-830) !important;
}

.border-gray-blue-700 {
  border-color: var(--gray-blue-700) !important;
}

.border-gray-blue-670 {
  border-color: var(--gray-blue-670) !important;
}

.border-gray-blue-650 {
  border-color: var(--gray-blue-650) !important;
}

.border-gray-blue-600 {
  border-color: var(--gray-blue-600) !important;
}

.border-gray-blue-550 {
  border-color: var(--gray-blue-550) !important;
}

.border-gray-blue-300 {
  border-color: var(--gray-blue-300) !important;
}

.border-gray-blue-250 {
  border-color: var(--gray-blue-250) !important;
}

.border-gray-blue-200 {
  border-color: var(--gray-blue-200) !important;
}

.border-gray-blue-100 {
  border-color: var(--gray-blue-100) !important;
}

.border-gray-blue-50 {
  border-color: var(--gray-blue-50) !important;
}

.focus\:border-gray-blue-900:focus {
  border-color: var(--gray-blue-900) !important;
}

.hover\:border-gray-blue-900:hover {
  border-color: var(--gray-blue-900) !important;
}

.active\:border-gray-blue-900:active {
  border-color: var(--gray-blue-900) !important;
}

.focus\:border-gray-blue-850:focus {
  border-color: var(--gray-blue-850) !important;
}

.hover\:border-gray-blue-850:hover {
  border-color: var(--gray-blue-850) !important;
}

.active\:border-gray-blue-850:active {
  border-color: var(--gray-blue-850) !important;
}

.focus\:border-gray-blue-830:focus {
  border-color: var(--gray-blue-830) !important;
}

.hover\:border-gray-blue-830:hover {
  border-color: var(--gray-blue-830) !important;
}

.active\:border-gray-blue-830:active {
  border-color: var(--gray-blue-830) !important;
}

.focus\:border-gray-blue-700:focus {
  border-color: var(--gray-blue-700) !important;
}

.hover\:border-gray-blue-700:hover {
  border-color: var(--gray-blue-700) !important;
}

.active\:border-gray-blue-700:active {
  border-color: var(--gray-blue-700) !important;
}

.focus\:border-gray-blue-670:focus {
  border-color: var(--gray-blue-670) !important;
}

.hover\:border-gray-blue-670:hover {
  border-color: var(--gray-blue-670) !important;
}

.active\:border-gray-blue-670:active {
  border-color: var(--gray-blue-670) !important;
}

.focus\:border-gray-blue-650:focus {
  border-color: var(--gray-blue-650) !important;
}

.hover\:border-gray-blue-650:hover {
  border-color: var(--gray-blue-650) !important;
}

.active\:border-gray-blue-650:active {
  border-color: var(--gray-blue-650) !important;
}

.focus\:border-gray-blue-600:focus {
  border-color: var(--gray-blue-600) !important;
}

.hover\:border-gray-blue-600:hover {
  border-color: var(--gray-blue-600) !important;
}

.active\:border-gray-blue-600:active {
  border-color: var(--gray-blue-600) !important;
}

.focus\:border-gray-blue-550:focus {
  border-color: var(--gray-blue-550) !important;
}

.hover\:border-gray-blue-550:hover {
  border-color: var(--gray-blue-550) !important;
}

.active\:border-gray-blue-550:active {
  border-color: var(--gray-blue-550) !important;
}

.focus\:border-gray-blue-300:focus {
  border-color: var(--gray-blue-300) !important;
}

.hover\:border-gray-blue-300:hover {
  border-color: var(--gray-blue-300) !important;
}

.active\:border-gray-blue-300:active {
  border-color: var(--gray-blue-300) !important;
}

.focus\:border-gray-blue-250:focus {
  border-color: var(--gray-blue-250) !important;
}

.hover\:border-gray-blue-250:hover {
  border-color: var(--gray-blue-250) !important;
}

.active\:border-gray-blue-250:active {
  border-color: var(--gray-blue-250) !important;
}

.focus\:border-gray-blue-200:focus {
  border-color: var(--gray-blue-200) !important;
}

.hover\:border-gray-blue-200:hover {
  border-color: var(--gray-blue-200) !important;
}

.active\:border-gray-blue-200:active {
  border-color: var(--gray-blue-200) !important;
}

.focus\:border-gray-blue-100:focus {
  border-color: var(--gray-blue-100) !important;
}

.hover\:border-gray-blue-100:hover {
  border-color: var(--gray-blue-100) !important;
}

.active\:border-gray-blue-100:active {
  border-color: var(--gray-blue-100) !important;
}

.focus\:border-gray-blue-50:focus {
  border-color: var(--gray-blue-50) !important;
}

.hover\:border-gray-blue-50:hover {
  border-color: var(--gray-blue-50) !important;
}

.active\:border-gray-blue-50:active {
  border-color: var(--gray-blue-50) !important;
}

.border-green-900 {
  border-color: var(--green-900) !important;
}

.border-green-860 {
  border-color: var(--green-860) !important;
}

.border-green-850 {
  border-color: var(--green-850) !important;
}

.border-green-830 {
  border-color: var(--green-830) !important;
}

.border-green-800 {
  border-color: var(--green-800) !important;
}

.border-green-750 {
  border-color: var(--green-750) !important;
}

.border-green-740 {
  border-color: var(--green-740) !important;
}

.border-green-730 {
  border-color: var(--green-730) !important;
}

.border-green-650 {
  border-color: var(--green-650) !important;
}

.border-green-300 {
  border-color: var(--green-300) !important;
}

.border-green-200 {
  border-color: var(--green-200) !important;
}

.border-green-120 {
  border-color: var(--green-120) !important;
}

.border-green-100 {
  border-color: var(--green-100) !important;
}

.focus\:border-green-900:focus {
  border-color: var(--green-900) !important;
}

.hover\:border-green-900:hover {
  border-color: var(--green-900) !important;
}

.active\:border-green-900:active {
  border-color: var(--green-900) !important;
}

.focus\:border-green-860:focus {
  border-color: var(--green-860) !important;
}

.hover\:border-green-860:hover {
  border-color: var(--green-860) !important;
}

.active\:border-green-860:active {
  border-color: var(--green-860) !important;
}

.focus\:border-green-850:focus {
  border-color: var(--green-850) !important;
}

.hover\:border-green-850:hover {
  border-color: var(--green-850) !important;
}

.active\:border-green-850:active {
  border-color: var(--green-850) !important;
}

.focus\:border-green-830:focus {
  border-color: var(--green-830) !important;
}

.hover\:border-green-830:hover {
  border-color: var(--green-830) !important;
}

.active\:border-green-830:active {
  border-color: var(--green-830) !important;
}

.focus\:border-green-800:focus {
  border-color: var(--green-800) !important;
}

.hover\:border-green-800:hover {
  border-color: var(--green-800) !important;
}

.active\:border-green-800:active {
  border-color: var(--green-800) !important;
}

.focus\:border-green-750:focus {
  border-color: var(--green-750) !important;
}

.hover\:border-green-750:hover {
  border-color: var(--green-750) !important;
}

.active\:border-green-750:active {
  border-color: var(--green-750) !important;
}

.focus\:border-green-740:focus {
  border-color: var(--green-740) !important;
}

.hover\:border-green-740:hover {
  border-color: var(--green-740) !important;
}

.active\:border-green-740:active {
  border-color: var(--green-740) !important;
}

.focus\:border-green-730:focus {
  border-color: var(--green-730) !important;
}

.hover\:border-green-730:hover {
  border-color: var(--green-730) !important;
}

.active\:border-green-730:active {
  border-color: var(--green-730) !important;
}

.focus\:border-green-650:focus {
  border-color: var(--green-650) !important;
}

.hover\:border-green-650:hover {
  border-color: var(--green-650) !important;
}

.active\:border-green-650:active {
  border-color: var(--green-650) !important;
}

.focus\:border-green-300:focus {
  border-color: var(--green-300) !important;
}

.hover\:border-green-300:hover {
  border-color: var(--green-300) !important;
}

.active\:border-green-300:active {
  border-color: var(--green-300) !important;
}

.focus\:border-green-200:focus {
  border-color: var(--green-200) !important;
}

.hover\:border-green-200:hover {
  border-color: var(--green-200) !important;
}

.active\:border-green-200:active {
  border-color: var(--green-200) !important;
}

.focus\:border-green-120:focus {
  border-color: var(--green-120) !important;
}

.hover\:border-green-120:hover {
  border-color: var(--green-120) !important;
}

.active\:border-green-120:active {
  border-color: var(--green-120) !important;
}

.focus\:border-green-100:focus {
  border-color: var(--green-100) !important;
}

.hover\:border-green-100:hover {
  border-color: var(--green-100) !important;
}

.active\:border-green-100:active {
  border-color: var(--green-100) !important;
}

.border-orange-900 {
  border-color: var(--orange-900) !important;
}

.border-orange-850 {
  border-color: var(--orange-850) !important;
}

.border-orange-830 {
  border-color: var(--orange-830) !important;
}

.border-orange-700 {
  border-color: var(--orange-700) !important;
}

.border-orange-600 {
  border-color: var(--orange-600) !important;
}

.border-orange-500 {
  border-color: var(--orange-500) !important;
}

.border-orange-100 {
  border-color: var(--orange-100) !important;
}

.focus\:border-orange-900:focus {
  border-color: var(--orange-900) !important;
}

.hover\:border-orange-900:hover {
  border-color: var(--orange-900) !important;
}

.active\:border-orange-900:active {
  border-color: var(--orange-900) !important;
}

.focus\:border-orange-850:focus {
  border-color: var(--orange-850) !important;
}

.hover\:border-orange-850:hover {
  border-color: var(--orange-850) !important;
}

.active\:border-orange-850:active {
  border-color: var(--orange-850) !important;
}

.focus\:border-orange-830:focus {
  border-color: var(--orange-830) !important;
}

.hover\:border-orange-830:hover {
  border-color: var(--orange-830) !important;
}

.active\:border-orange-830:active {
  border-color: var(--orange-830) !important;
}

.focus\:border-orange-700:focus {
  border-color: var(--orange-700) !important;
}

.hover\:border-orange-700:hover {
  border-color: var(--orange-700) !important;
}

.active\:border-orange-700:active {
  border-color: var(--orange-700) !important;
}

.focus\:border-orange-600:focus {
  border-color: var(--orange-600) !important;
}

.hover\:border-orange-600:hover {
  border-color: var(--orange-600) !important;
}

.active\:border-orange-600:active {
  border-color: var(--orange-600) !important;
}

.focus\:border-orange-500:focus {
  border-color: var(--orange-500) !important;
}

.hover\:border-orange-500:hover {
  border-color: var(--orange-500) !important;
}

.active\:border-orange-500:active {
  border-color: var(--orange-500) !important;
}

.focus\:border-orange-100:focus {
  border-color: var(--orange-100) !important;
}

.hover\:border-orange-100:hover {
  border-color: var(--orange-100) !important;
}

.active\:border-orange-100:active {
  border-color: var(--orange-100) !important;
}

.border-purple-900 {
  border-color: var(--purple-900) !important;
}

.border-purple-850 {
  border-color: var(--purple-850) !important;
}

.border-purple-830 {
  border-color: var(--purple-830) !important;
}

.border-purple-600 {
  border-color: var(--purple-600) !important;
}

.border-purple-400 {
  border-color: var(--purple-400) !important;
}

.border-purple-300 {
  border-color: var(--purple-300) !important;
}

.border-purple-200 {
  border-color: var(--purple-200) !important;
}

.focus\:border-purple-900:focus {
  border-color: var(--purple-900) !important;
}

.hover\:border-purple-900:hover {
  border-color: var(--purple-900) !important;
}

.active\:border-purple-900:active {
  border-color: var(--purple-900) !important;
}

.focus\:border-purple-850:focus {
  border-color: var(--purple-850) !important;
}

.hover\:border-purple-850:hover {
  border-color: var(--purple-850) !important;
}

.active\:border-purple-850:active {
  border-color: var(--purple-850) !important;
}

.focus\:border-purple-830:focus {
  border-color: var(--purple-830) !important;
}

.hover\:border-purple-830:hover {
  border-color: var(--purple-830) !important;
}

.active\:border-purple-830:active {
  border-color: var(--purple-830) !important;
}

.focus\:border-purple-600:focus {
  border-color: var(--purple-600) !important;
}

.hover\:border-purple-600:hover {
  border-color: var(--purple-600) !important;
}

.active\:border-purple-600:active {
  border-color: var(--purple-600) !important;
}

.focus\:border-purple-400:focus {
  border-color: var(--purple-400) !important;
}

.hover\:border-purple-400:hover {
  border-color: var(--purple-400) !important;
}

.active\:border-purple-400:active {
  border-color: var(--purple-400) !important;
}

.focus\:border-purple-300:focus {
  border-color: var(--purple-300) !important;
}

.hover\:border-purple-300:hover {
  border-color: var(--purple-300) !important;
}

.active\:border-purple-300:active {
  border-color: var(--purple-300) !important;
}

.focus\:border-purple-200:focus {
  border-color: var(--purple-200) !important;
}

.hover\:border-purple-200:hover {
  border-color: var(--purple-200) !important;
}

.active\:border-purple-200:active {
  border-color: var(--purple-200) !important;
}

.border-red-950 {
  border-color: var(--red-950) !important;
}

.border-red-900 {
  border-color: var(--red-900) !important;
}

.border-red-850 {
  border-color: var(--red-850) !important;
}

.border-red-830 {
  border-color: var(--red-830) !important;
}

.border-red-700 {
  border-color: var(--red-700) !important;
}

.border-red-650 {
  border-color: var(--red-650) !important;
}

.border-red-300 {
  border-color: var(--red-300) !important;
}

.border-red-200 {
  border-color: var(--red-200) !important;
}

.border-red-100 {
  border-color: var(--red-100) !important;
}

.focus\:border-red-950:focus {
  border-color: var(--red-950) !important;
}

.hover\:border-red-950:hover {
  border-color: var(--red-950) !important;
}

.active\:border-red-950:active {
  border-color: var(--red-950) !important;
}

.focus\:border-red-900:focus {
  border-color: var(--red-900) !important;
}

.hover\:border-red-900:hover {
  border-color: var(--red-900) !important;
}

.active\:border-red-900:active {
  border-color: var(--red-900) !important;
}

.focus\:border-red-850:focus {
  border-color: var(--red-850) !important;
}

.hover\:border-red-850:hover {
  border-color: var(--red-850) !important;
}

.active\:border-red-850:active {
  border-color: var(--red-850) !important;
}

.focus\:border-red-830:focus {
  border-color: var(--red-830) !important;
}

.hover\:border-red-830:hover {
  border-color: var(--red-830) !important;
}

.active\:border-red-830:active {
  border-color: var(--red-830) !important;
}

.focus\:border-red-700:focus {
  border-color: var(--red-700) !important;
}

.hover\:border-red-700:hover {
  border-color: var(--red-700) !important;
}

.active\:border-red-700:active {
  border-color: var(--red-700) !important;
}

.focus\:border-red-650:focus {
  border-color: var(--red-650) !important;
}

.hover\:border-red-650:hover {
  border-color: var(--red-650) !important;
}

.active\:border-red-650:active {
  border-color: var(--red-650) !important;
}

.focus\:border-red-300:focus {
  border-color: var(--red-300) !important;
}

.hover\:border-red-300:hover {
  border-color: var(--red-300) !important;
}

.active\:border-red-300:active {
  border-color: var(--red-300) !important;
}

.focus\:border-red-200:focus {
  border-color: var(--red-200) !important;
}

.hover\:border-red-200:hover {
  border-color: var(--red-200) !important;
}

.active\:border-red-200:active {
  border-color: var(--red-200) !important;
}

.focus\:border-red-100:focus {
  border-color: var(--red-100) !important;
}

.hover\:border-red-100:hover {
  border-color: var(--red-100) !important;
}

.active\:border-red-100:active {
  border-color: var(--red-100) !important;
}

.border-yellow-900 {
  border-color: var(--yellow-900) !important;
}

.border-yellow-850 {
  border-color: var(--yellow-850) !important;
}

.border-yellow-800 {
  border-color: var(--yellow-800) !important;
}

.border-yellow-700 {
  border-color: var(--yellow-700) !important;
}

.border-yellow-600 {
  border-color: var(--yellow-600) !important;
}

.border-yellow-570 {
  border-color: var(--yellow-570) !important;
}

.border-yellow-550 {
  border-color: var(--yellow-550) !important;
}

.border-yellow-500 {
  border-color: var(--yellow-500) !important;
}

.border-yellow-400 {
  border-color: var(--yellow-400) !important;
}

.border-yellow-300 {
  border-color: var(--yellow-300) !important;
}

.focus\:border-yellow-900:focus {
  border-color: var(--yellow-900) !important;
}

.hover\:border-yellow-900:hover {
  border-color: var(--yellow-900) !important;
}

.active\:border-yellow-900:active {
  border-color: var(--yellow-900) !important;
}

.focus\:border-yellow-850:focus {
  border-color: var(--yellow-850) !important;
}

.hover\:border-yellow-850:hover {
  border-color: var(--yellow-850) !important;
}

.active\:border-yellow-850:active {
  border-color: var(--yellow-850) !important;
}

.focus\:border-yellow-800:focus {
  border-color: var(--yellow-800) !important;
}

.hover\:border-yellow-800:hover {
  border-color: var(--yellow-800) !important;
}

.active\:border-yellow-800:active {
  border-color: var(--yellow-800) !important;
}

.focus\:border-yellow-700:focus {
  border-color: var(--yellow-700) !important;
}

.hover\:border-yellow-700:hover {
  border-color: var(--yellow-700) !important;
}

.active\:border-yellow-700:active {
  border-color: var(--yellow-700) !important;
}

.focus\:border-yellow-600:focus {
  border-color: var(--yellow-600) !important;
}

.hover\:border-yellow-600:hover {
  border-color: var(--yellow-600) !important;
}

.active\:border-yellow-600:active {
  border-color: var(--yellow-600) !important;
}

.focus\:border-yellow-570:focus {
  border-color: var(--yellow-570) !important;
}

.hover\:border-yellow-570:hover {
  border-color: var(--yellow-570) !important;
}

.active\:border-yellow-570:active {
  border-color: var(--yellow-570) !important;
}

.focus\:border-yellow-550:focus {
  border-color: var(--yellow-550) !important;
}

.hover\:border-yellow-550:hover {
  border-color: var(--yellow-550) !important;
}

.active\:border-yellow-550:active {
  border-color: var(--yellow-550) !important;
}

.focus\:border-yellow-500:focus {
  border-color: var(--yellow-500) !important;
}

.hover\:border-yellow-500:hover {
  border-color: var(--yellow-500) !important;
}

.active\:border-yellow-500:active {
  border-color: var(--yellow-500) !important;
}

.focus\:border-yellow-400:focus {
  border-color: var(--yellow-400) !important;
}

.hover\:border-yellow-400:hover {
  border-color: var(--yellow-400) !important;
}

.active\:border-yellow-400:active {
  border-color: var(--yellow-400) !important;
}

.focus\:border-yellow-300:focus {
  border-color: var(--yellow-300) !important;
}

.hover\:border-yellow-300:hover {
  border-color: var(--yellow-300) !important;
}

.active\:border-yellow-300:active {
  border-color: var(--yellow-300) !important;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.bg-primary-reverse {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.border-primary {
  border-color: var(--primary-color) !important;
}

.text-white {
  color: #ffffff !important;
}

.border-white {
  border-color: #ffffff !important;
}

.text-color {
  color: var(--text-color) !important;
}

.text-color-secondary {
  color: var(--text-color-secondary) !important;
}

.surface-ground {
  background-color: var(--surface-ground) !important;
}

.surface-section {
  background-color: var(--surface-section) !important;
}

.surface-card {
  background-color: var(--surface-card) !important;
}

.surface-overlay {
  background-color: var(--surface-overlay) !important;
}

.surface-hover {
  background-color: var(--surface-hover) !important;
}

.surface-border {
  border-color: var(--surface-border) !important;
}

.focus\:text-primary:focus {
  color: var(--primary-color) !important;
}

.hover\:text-primary:hover {
  color: var(--primary-color) !important;
}

.active\:text-primary:active {
  color: var(--primary-color) !important;
}

.focus\:bg-primary:focus {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.hover\:bg-primary:hover {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.active\:bg-primary:active {
  color: var(--primary-color-text) !important;
  background-color: var(--primary-color) !important;
}

.focus\:bg-primary-reverse:focus {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.hover\:bg-primary-reverse:hover {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.active\:bg-primary-reverse:active {
  color: var(--primary-color) !important;
  background-color: var(--primary-color-text) !important;
}

.focus\:bg-white:focus {
  background-color: #ffffff !important;
}

.hover\:bg-white:hover {
  background-color: #ffffff !important;
}

.active\:bg-white:active {
  background-color: #ffffff !important;
}

.focus\:border-primary:focus {
  border-color: var(--primary-color) !important;
}

.hover\:border-primary:hover {
  border-color: var(--primary-color) !important;
}

.active\:border-primary:active {
  border-color: var(--primary-color) !important;
}

.focus\:text-white:focus {
  color: #ffffff !important;
}

.hover\:text-white:hover {
  color: #ffffff !important;
}

.active\:text-white:active {
  color: #ffffff !important;
}

.focus\:border-white:focus {
  border-color: #ffffff !important;
}

.hover\:border-white:hover {
  border-color: #ffffff !important;
}

.active\:border-white:active {
  border-color: #ffffff !important;
}

.focus\:text-color:focus {
  color: var(--text-color) !important;
}

.hover\:text-color:hover {
  color: var(--text-color) !important;
}

.active\:text-color:active {
  color: var(--text-color) !important;
}

.focus\:text-color-secondary:focus {
  color: var(--text-color-secondary) !important;
}

.hover\:text-color-secondary:hover {
  color: var(--text-color-secondary) !important;
}

.active\:text-color-secondary:active {
  color: var(--text-color-secondary) !important;
}

.focus\:surface-ground:focus {
  background-color: var(--surface-ground) !important;
}

.hover\:surface-ground:hover {
  background-color: var(--surface-ground) !important;
}

.active\:surface-ground:active {
  background-color: var(--surface-ground) !important;
}

.focus\:surface-section:focus {
  background-color: var(--surface-section) !important;
}

.hover\:surface-section:hover {
  background-color: var(--surface-section) !important;
}

.active\:surface-section:active {
  background-color: var(--surface-section) !important;
}

.focus\:surface-card:focus {
  background-color: var(--surface-card) !important;
}

.hover\:surface-card:hover {
  background-color: var(--surface-card) !important;
}

.active\:surface-card:active {
  background-color: var(--surface-card) !important;
}

.focus\:surface-overlay:focus {
  background-color: var(--surface-overlay) !important;
}

.hover\:surface-overlay:hover {
  background-color: var(--surface-overlay) !important;
}

.active\:surface-overlay:active {
  background-color: var(--surface-overlay) !important;
}

.focus\:surface-hover:focus {
  background-color: var(--surface-hover) !important;
}

.hover\:surface-hover:hover {
  background-color: var(--surface-hover) !important;
}

.active\:surface-hover:active {
  background-color: var(--surface-hover) !important;
}

.focus\:surface-border:focus {
  border-color: var(--surface-border) !important;
}

.hover\:surface-border:hover {
  border-color: var(--surface-border) !important;
}

.active\:surface-border:active {
  border-color: var(--surface-border) !important;
}

@media screen and (min-width: 480px) {
  .xs\:text-primary {
    color: var(--primary-color) !important;
  }
  .xs\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xs\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xs\:bg-white {
    background-color: #ffffff !important;
  }
  .xs\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .xs\:text-white {
    color: #ffffff !important;
  }
  .xs\:border-white {
    border-color: #ffffff !important;
  }
  .xs\:text-color {
    color: var(--text-color) !important;
  }
  .xs\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .xs\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .xs\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .xs\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .xs\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .xs\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .xs\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .xs\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .xs\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .xs\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .xs\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xs\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xs\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xs\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xs\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xs\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xs\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .xs\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .xs\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .xs\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .xs\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .xs\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .xs\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .xs\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .xs\:active\:text-white:active {
    color: #ffffff !important;
  }
  .xs\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .xs\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .xs\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .xs\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .xs\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .xs\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .xs\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .xs\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .xs\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .xs\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .xs\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .xs\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .xs\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .xs\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .xs\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .xs\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .xs\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .xs\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .xs\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .xs\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .xs\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .xs\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .xs\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .xs\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .xs\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .xs\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .xs\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:text-primary {
    color: var(--primary-color) !important;
  }
  .sm\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:bg-white {
    background-color: #ffffff !important;
  }
  .sm\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .sm\:text-white {
    color: #ffffff !important;
  }
  .sm\:border-white {
    border-color: #ffffff !important;
  }
  .sm\:text-color {
    color: var(--text-color) !important;
  }
  .sm\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .sm\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .sm\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .sm\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .sm\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .sm\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .sm\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .sm\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .sm\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .sm\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .sm\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .sm\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .sm\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .sm\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .sm\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .sm\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .sm\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .sm\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .sm\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .sm\:active\:text-white:active {
    color: #ffffff !important;
  }
  .sm\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .sm\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .sm\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .sm\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .sm\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .sm\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .sm\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .sm\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .sm\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .sm\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .sm\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .sm\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .sm\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .sm\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .sm\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .sm\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .sm\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .sm\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .sm\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .sm\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .sm\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .sm\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .sm\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .sm\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .sm\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-primary {
    color: var(--primary-color) !important;
  }
  .md\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:bg-white {
    background-color: #ffffff !important;
  }
  .md\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .md\:text-white {
    color: #ffffff !important;
  }
  .md\:border-white {
    border-color: #ffffff !important;
  }
  .md\:text-color {
    color: var(--text-color) !important;
  }
  .md\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .md\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .md\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .md\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .md\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .md\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .md\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .md\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .md\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .md\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .md\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .md\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .md\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .md\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .md\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .md\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .md\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .md\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .md\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .md\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .md\:active\:text-white:active {
    color: #ffffff !important;
  }
  .md\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .md\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .md\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .md\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .md\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .md\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .md\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .md\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .md\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .md\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .md\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .md\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .md\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .md\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .md\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .md\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .md\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .md\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .md\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .md\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .md\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .md\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .md\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .md\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .md\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .md\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .md\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-primary {
    color: var(--primary-color) !important;
  }
  .lg\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:bg-white {
    background-color: #ffffff !important;
  }
  .lg\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .lg\:text-white {
    color: #ffffff !important;
  }
  .lg\:border-white {
    border-color: #ffffff !important;
  }
  .lg\:text-color {
    color: var(--text-color) !important;
  }
  .lg\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .lg\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .lg\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .lg\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .lg\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .lg\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .lg\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .lg\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .lg\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .lg\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .lg\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .lg\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .lg\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .lg\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .lg\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .lg\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .lg\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .lg\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .lg\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .lg\:active\:text-white:active {
    color: #ffffff !important;
  }
  .lg\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .lg\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .lg\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .lg\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .lg\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .lg\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .lg\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .lg\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .lg\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .lg\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .lg\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .lg\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .lg\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .lg\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .lg\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .lg\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .lg\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .lg\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .lg\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .lg\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .lg\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .lg\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .lg\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .lg\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .lg\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-primary {
    color: var(--primary-color) !important;
  }
  .xl\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:bg-white {
    background-color: #ffffff !important;
  }
  .xl\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .xl\:text-white {
    color: #ffffff !important;
  }
  .xl\:border-white {
    border-color: #ffffff !important;
  }
  .xl\:text-color {
    color: var(--text-color) !important;
  }
  .xl\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .xl\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .xl\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .xl\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .xl\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .xl\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .xl\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .xl\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .xl\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .xl\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xl\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xl\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .xl\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .xl\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .xl\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .xl\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .xl\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .xl\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .xl\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .xl\:active\:text-white:active {
    color: #ffffff !important;
  }
  .xl\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .xl\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .xl\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .xl\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .xl\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .xl\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .xl\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .xl\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .xl\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .xl\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .xl\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .xl\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .xl\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .xl\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .xl\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .xl\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .xl\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .xl\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .xl\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .xl\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .xl\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .xl\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .xl\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .xl\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .xl\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:text-primary {
    color: var(--primary-color) !important;
  }
  .xxl\:bg-primary {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xxl\:bg-primary-reverse {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xxl\:bg-white {
    background-color: #ffffff !important;
  }
  .xxl\:border-primary {
    border-color: var(--primary-color) !important;
  }
  .xxl\:text-white {
    color: #ffffff !important;
  }
  .xxl\:border-white {
    border-color: #ffffff !important;
  }
  .xxl\:text-color {
    color: var(--text-color) !important;
  }
  .xxl\:text-color-secondary {
    color: var(--text-color-secondary) !important;
  }
  .xxl\:surface-ground {
    background-color: var(--surface-ground) !important;
  }
  .xxl\:surface-section {
    background-color: var(--surface-section) !important;
  }
  .xxl\:surface-card {
    background-color: var(--surface-card) !important;
  }
  .xxl\:surface-overlay {
    background-color: var(--surface-overlay) !important;
  }
  .xxl\:surface-hover {
    background-color: var(--surface-hover) !important;
  }
  .xxl\:surface-border {
    border-color: var(--surface-border) !important;
  }
  .xxl\:focus\:text-primary:focus {
    color: var(--primary-color) !important;
  }
  .xxl\:hover\:text-primary:hover {
    color: var(--primary-color) !important;
  }
  .xxl\:active\:text-primary:active {
    color: var(--primary-color) !important;
  }
  .xxl\:focus\:bg-primary:focus {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xxl\:hover\:bg-primary:hover {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xxl\:active\:bg-primary:active {
    color: var(--primary-color-text) !important;
    background-color: var(--primary-color) !important;
  }
  .xxl\:focus\:bg-primary-reverse:focus {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xxl\:hover\:bg-primary-reverse:hover {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xxl\:active\:bg-primary-reverse:active {
    color: var(--primary-color) !important;
    background-color: var(--primary-color-text) !important;
  }
  .xxl\:focus\:bg-white:focus {
    background-color: #ffffff !important;
  }
  .xxl\:hover\:bg-white:hover {
    background-color: #ffffff !important;
  }
  .xxl\:active\:bg-white:active {
    background-color: #ffffff !important;
  }
  .xxl\:focus\:border-primary:focus {
    border-color: var(--primary-color) !important;
  }
  .xxl\:hover\:border-primary:hover {
    border-color: var(--primary-color) !important;
  }
  .xxl\:active\:border-primary:active {
    border-color: var(--primary-color) !important;
  }
  .xxl\:focus\:text-white:focus {
    color: #ffffff !important;
  }
  .xxl\:hover\:text-white:hover {
    color: #ffffff !important;
  }
  .xxl\:active\:text-white:active {
    color: #ffffff !important;
  }
  .xxl\:focus\:border-white:focus {
    border-color: #ffffff !important;
  }
  .xxl\:hover\:border-white:hover {
    border-color: #ffffff !important;
  }
  .xxl\:active\:border-white:active {
    border-color: #ffffff !important;
  }
  .xxl\:focus\:text-color:focus {
    color: var(--text-color) !important;
  }
  .xxl\:hover\:text-color:hover {
    color: var(--text-color) !important;
  }
  .xxl\:active\:text-color:active {
    color: var(--text-color) !important;
  }
  .xxl\:focus\:text-color-secondary:focus {
    color: var(--text-color-secondary) !important;
  }
  .xxl\:hover\:text-color-secondary:hover {
    color: var(--text-color-secondary) !important;
  }
  .xxl\:active\:text-color-secondary:active {
    color: var(--text-color-secondary) !important;
  }
  .xxl\:focus\:surface-ground:focus {
    background-color: var(--surface-ground) !important;
  }
  .xxl\:hover\:surface-ground:hover {
    background-color: var(--surface-ground) !important;
  }
  .xxl\:active\:surface-ground:active {
    background-color: var(--surface-ground) !important;
  }
  .xxl\:focus\:surface-section:focus {
    background-color: var(--surface-section) !important;
  }
  .xxl\:hover\:surface-section:hover {
    background-color: var(--surface-section) !important;
  }
  .xxl\:active\:surface-section:active {
    background-color: var(--surface-section) !important;
  }
  .xxl\:focus\:surface-card:focus {
    background-color: var(--surface-card) !important;
  }
  .xxl\:hover\:surface-card:hover {
    background-color: var(--surface-card) !important;
  }
  .xxl\:active\:surface-card:active {
    background-color: var(--surface-card) !important;
  }
  .xxl\:focus\:surface-overlay:focus {
    background-color: var(--surface-overlay) !important;
  }
  .xxl\:hover\:surface-overlay:hover {
    background-color: var(--surface-overlay) !important;
  }
  .xxl\:active\:surface-overlay:active {
    background-color: var(--surface-overlay) !important;
  }
  .xxl\:focus\:surface-hover:focus {
    background-color: var(--surface-hover) !important;
  }
  .xxl\:hover\:surface-hover:hover {
    background-color: var(--surface-hover) !important;
  }
  .xxl\:active\:surface-hover:active {
    background-color: var(--surface-hover) !important;
  }
  .xxl\:focus\:surface-border:focus {
    border-color: var(--surface-border) !important;
  }
  .xxl\:hover\:surface-border:hover {
    border-color: var(--surface-border) !important;
  }
  .xxl\:active\:surface-border:active {
    border-color: var(--surface-border) !important;
  }
}
.field {
  margin-bottom: 1rem;
}

.field > label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.field.grid > label {
  display: flex;
  align-items: center;
}

.field > small {
  margin-top: 0.25rem;
}

.field.grid,
.formgrid.grid {
  margin-top: 0;
}

.field.grid .col-fixed,
.formgrid.grid .col-fixed,
.field.grid .col,
.formgrid.grid .col,
.field.grid .col-1,
.formgrid.grid .col-1,
.field.grid .col-2,
.formgrid.grid .col-2,
.field.grid .col-3,
.formgrid.grid .col-3,
.field.grid .col-4,
.formgrid.grid .col-4,
.field.grid .col-5,
.formgrid.grid .col-5,
.field.grid .col-6,
.formgrid.grid .col-6,
.field.grid .col-7,
.formgrid.grid .col-7,
.field.grid .col-8,
.formgrid.grid .col-8,
.field.grid .col-9,
.formgrid.grid .col-9,
.field.grid .col-10,
.formgrid.grid .col-10,
.field.grid .col-11,
.formgrid.grid .col-11,
.field.grid .col-12,
.formgrid.grid .col-12 {
  padding-top: 0;
  padding-bottom: 0;
}

.formgroup-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.formgroup-inline .field,
.formgroup-inline .field-checkbox,
.formgroup-inline .field-radiobutton {
  margin-right: 1rem;
}

.formgroup-inline .field > label,
.formgroup-inline .field-checkbox > label,
.formgroup-inline .field-radiobutton > label {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.field-checkbox,
.field-radiobutton {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.field-checkbox > label,
.field-radiobutton > label {
  margin-left: 0.5rem;
  line-height: 1;
}

.hidden {
  display: none !important;
}

.block {
  display: block !important;
}

.inline {
  display: inline !important;
}

.inline-block {
  display: inline-block !important;
}

.flex {
  display: flex !important;
}

.inline-flex {
  display: inline-flex !important;
}

@media screen and (min-width: 480px) {
  .xs\:hidden {
    display: none !important;
  }
  .xs\:block {
    display: block !important;
  }
  .xs\:inline {
    display: inline !important;
  }
  .xs\:inline-block {
    display: inline-block !important;
  }
  .xs\:flex {
    display: flex !important;
  }
  .xs\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:hidden {
    display: none !important;
  }
  .sm\:block {
    display: block !important;
  }
  .sm\:inline {
    display: inline !important;
  }
  .sm\:inline-block {
    display: inline-block !important;
  }
  .sm\:flex {
    display: flex !important;
  }
  .sm\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:hidden {
    display: none !important;
  }
  .md\:block {
    display: block !important;
  }
  .md\:inline {
    display: inline !important;
  }
  .md\:inline-block {
    display: inline-block !important;
  }
  .md\:flex {
    display: flex !important;
  }
  .md\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:hidden {
    display: none !important;
  }
  .lg\:block {
    display: block !important;
  }
  .lg\:inline {
    display: inline !important;
  }
  .lg\:inline-block {
    display: inline-block !important;
  }
  .lg\:flex {
    display: flex !important;
  }
  .lg\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:hidden {
    display: none !important;
  }
  .xl\:block {
    display: block !important;
  }
  .xl\:inline {
    display: inline !important;
  }
  .xl\:inline-block {
    display: inline-block !important;
  }
  .xl\:flex {
    display: flex !important;
  }
  .xl\:inline-flex {
    display: inline-flex !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:hidden {
    display: none !important;
  }
  .xxl\:block {
    display: block !important;
  }
  .xxl\:inline {
    display: inline !important;
  }
  .xxl\:inline-block {
    display: inline-block !important;
  }
  .xxl\:flex {
    display: flex !important;
  }
  .xxl\:inline-flex {
    display: inline-flex !important;
  }
}
.text-center {
  text-align: center !important;
}

.text-justify {
  text-align: justify !important;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

@media screen and (min-width: 480px) {
  .xs\:text-center {
    text-align: center !important;
  }
  .xs\:text-justify {
    text-align: justify !important;
  }
  .xs\:text-left {
    text-align: left !important;
  }
  .xs\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:text-center {
    text-align: center !important;
  }
  .sm\:text-justify {
    text-align: justify !important;
  }
  .sm\:text-left {
    text-align: left !important;
  }
  .sm\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-center {
    text-align: center !important;
  }
  .md\:text-justify {
    text-align: justify !important;
  }
  .md\:text-left {
    text-align: left !important;
  }
  .md\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-center {
    text-align: center !important;
  }
  .lg\:text-justify {
    text-align: justify !important;
  }
  .lg\:text-left {
    text-align: left !important;
  }
  .lg\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-center {
    text-align: center !important;
  }
  .xl\:text-justify {
    text-align: justify !important;
  }
  .xl\:text-left {
    text-align: left !important;
  }
  .xl\:text-right {
    text-align: right !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:text-center {
    text-align: center !important;
  }
  .xxl\:text-justify {
    text-align: justify !important;
  }
  .xxl\:text-left {
    text-align: left !important;
  }
  .xxl\:text-right {
    text-align: right !important;
  }
}
.underline {
  text-decoration: underline !important;
}

.line-through {
  text-decoration: line-through !important;
}

.no-underline {
  text-decoration: none !important;
}

.focus\:underline:focus {
  text-decoration: underline !important;
}

.hover\:underline:hover {
  text-decoration: underline !important;
}

.active\:underline:active {
  text-decoration: underline !important;
}

.focus\:line-through:focus {
  text-decoration: line-through !important;
}

.hover\:line-through:hover {
  text-decoration: line-through !important;
}

.active\:line-through:active {
  text-decoration: line-through !important;
}

.focus\:no-underline:focus {
  text-decoration: none !important;
}

.hover\:no-underline:hover {
  text-decoration: none !important;
}

.active\:no-underline:active {
  text-decoration: none !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.text-overflow-clip {
  text-overflow: clip !important;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

@media screen and (min-width: 480px) {
  .xs\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .xs\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .sm\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .md\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .lg\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .xl\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:text-overflow-clip {
    text-overflow: clip !important;
  }
  .xxl\:text-overflow-ellipsis {
    text-overflow: ellipsis !important;
  }
}
.font-normal {
  font-weight: 400 !important;
}

.font-medium {
  font-weight: 500 !important;
}

.font-bold {
  font-weight: 700 !important;
}

@media screen and (min-width: 480px) {
  .xs\:font-normal {
    font-weight: 400 !important;
  }
  .xs\:font-medium {
    font-weight: 500 !important;
  }
  .xs\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:font-normal {
    font-weight: 400 !important;
  }
  .sm\:font-medium {
    font-weight: 500 !important;
  }
  .sm\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:font-normal {
    font-weight: 400 !important;
  }
  .md\:font-medium {
    font-weight: 500 !important;
  }
  .md\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:font-normal {
    font-weight: 400 !important;
  }
  .lg\:font-medium {
    font-weight: 500 !important;
  }
  .lg\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:font-normal {
    font-weight: 400 !important;
  }
  .xl\:font-medium {
    font-weight: 500 !important;
  }
  .xl\:font-bold {
    font-weight: 700 !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:font-normal {
    font-weight: 400 !important;
  }
  .xxl\:font-medium {
    font-weight: 500 !important;
  }
  .xxl\:font-bold {
    font-weight: 700 !important;
  }
}
.font-italic {
  font-style: italic !important;
}

.text-xxs {
  font-size: 11px !important;
}

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 13px !important;
}

.text-smm {
  font-size: 14px !important;
}

.text-base {
  font-size: 16px !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-xl {
  font-size: 20px !important;
}

.text-2xl {
  font-size: 22px !important;
}

.text-3xl {
  font-size: 24px !important;
}

.text-4xl {
  font-size: 26px !important;
}

.text-5xl {
  font-size: 30px !important;
}

.text-6xl {
  font-size: 36px !important;
}

@media screen and (min-width: 480px) {
  .xs\:text-xxs {
    font-size: 11px !important;
  }
  .xs\:text-xs {
    font-size: 12px !important;
  }
  .xs\:text-sm {
    font-size: 13px !important;
  }
  .xs\:text-smm {
    font-size: 14px !important;
  }
  .xs\:text-base {
    font-size: 16px !important;
  }
  .xs\:text-lg {
    font-size: 18px !important;
  }
  .xs\:text-xl {
    font-size: 20px !important;
  }
  .xs\:text-2xl {
    font-size: 22px !important;
  }
  .xs\:text-3xl {
    font-size: 24px !important;
  }
  .xs\:text-4xl {
    font-size: 26px !important;
  }
  .xs\:text-5xl {
    font-size: 30px !important;
  }
  .xs\:text-6xl {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:text-xxs {
    font-size: 11px !important;
  }
  .sm\:text-xs {
    font-size: 12px !important;
  }
  .sm\:text-sm {
    font-size: 13px !important;
  }
  .sm\:text-smm {
    font-size: 14px !important;
  }
  .sm\:text-base {
    font-size: 16px !important;
  }
  .sm\:text-lg {
    font-size: 18px !important;
  }
  .sm\:text-xl {
    font-size: 20px !important;
  }
  .sm\:text-2xl {
    font-size: 22px !important;
  }
  .sm\:text-3xl {
    font-size: 24px !important;
  }
  .sm\:text-4xl {
    font-size: 26px !important;
  }
  .sm\:text-5xl {
    font-size: 30px !important;
  }
  .sm\:text-6xl {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:text-xxs {
    font-size: 11px !important;
  }
  .md\:text-xs {
    font-size: 12px !important;
  }
  .md\:text-sm {
    font-size: 13px !important;
  }
  .md\:text-smm {
    font-size: 14px !important;
  }
  .md\:text-base {
    font-size: 16px !important;
  }
  .md\:text-lg {
    font-size: 18px !important;
  }
  .md\:text-xl {
    font-size: 20px !important;
  }
  .md\:text-2xl {
    font-size: 22px !important;
  }
  .md\:text-3xl {
    font-size: 24px !important;
  }
  .md\:text-4xl {
    font-size: 26px !important;
  }
  .md\:text-5xl {
    font-size: 30px !important;
  }
  .md\:text-6xl {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:text-xxs {
    font-size: 11px !important;
  }
  .lg\:text-xs {
    font-size: 12px !important;
  }
  .lg\:text-sm {
    font-size: 13px !important;
  }
  .lg\:text-smm {
    font-size: 14px !important;
  }
  .lg\:text-base {
    font-size: 16px !important;
  }
  .lg\:text-lg {
    font-size: 18px !important;
  }
  .lg\:text-xl {
    font-size: 20px !important;
  }
  .lg\:text-2xl {
    font-size: 22px !important;
  }
  .lg\:text-3xl {
    font-size: 24px !important;
  }
  .lg\:text-4xl {
    font-size: 26px !important;
  }
  .lg\:text-5xl {
    font-size: 30px !important;
  }
  .lg\:text-6xl {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:text-xxs {
    font-size: 11px !important;
  }
  .xl\:text-xs {
    font-size: 12px !important;
  }
  .xl\:text-sm {
    font-size: 13px !important;
  }
  .xl\:text-smm {
    font-size: 14px !important;
  }
  .xl\:text-base {
    font-size: 16px !important;
  }
  .xl\:text-lg {
    font-size: 18px !important;
  }
  .xl\:text-xl {
    font-size: 20px !important;
  }
  .xl\:text-2xl {
    font-size: 22px !important;
  }
  .xl\:text-3xl {
    font-size: 24px !important;
  }
  .xl\:text-4xl {
    font-size: 26px !important;
  }
  .xl\:text-5xl {
    font-size: 30px !important;
  }
  .xl\:text-6xl {
    font-size: 36px !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:text-xxs {
    font-size: 11px !important;
  }
  .xxl\:text-xs {
    font-size: 12px !important;
  }
  .xxl\:text-sm {
    font-size: 13px !important;
  }
  .xxl\:text-smm {
    font-size: 14px !important;
  }
  .xxl\:text-base {
    font-size: 16px !important;
  }
  .xxl\:text-lg {
    font-size: 18px !important;
  }
  .xxl\:text-xl {
    font-size: 20px !important;
  }
  .xxl\:text-2xl {
    font-size: 22px !important;
  }
  .xxl\:text-3xl {
    font-size: 24px !important;
  }
  .xxl\:text-4xl {
    font-size: 26px !important;
  }
  .xxl\:text-5xl {
    font-size: 30px !important;
  }
  .xxl\:text-6xl {
    font-size: 36px !important;
  }
}
.line-height-1 {
  line-height: 1 !important;
}

.lh {
  line-height: 1.2 !important;
}

.line-height-2 {
  line-height: 1.25 !important;
}

.line-height-3 {
  line-height: 1.5 !important;
}

.line-height-4 {
  line-height: 2 !important;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-nowrap {
  white-space: nowrap !important;
}

.vertical-align-baseline {
  vertical-align: baseline !important;
}

.vertical-align-top {
  vertical-align: top !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.vertical-align-bottom {
  vertical-align: bottom !important;
}

.vertical-align-text-top {
  vertical-align: text-top !important;
}

.vertical-align-text-bottom {
  vertical-align: text-bottom !important;
}

.vertical-align-sub {
  vertical-align: sub !important;
}

.vertical-align-super {
  vertical-align: super !important;
}

@media screen and (min-width: 480px) {
  .xs\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .xs\:vertical-align-top {
    vertical-align: top !important;
  }
  .xs\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .xs\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .xs\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .xs\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .xs\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .xs\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .sm\:vertical-align-top {
    vertical-align: top !important;
  }
  .sm\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .sm\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .sm\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .sm\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .sm\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .sm\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .md\:vertical-align-top {
    vertical-align: top !important;
  }
  .md\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .md\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .md\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .md\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .md\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .md\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .lg\:vertical-align-top {
    vertical-align: top !important;
  }
  .lg\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .lg\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .lg\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .lg\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .lg\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .lg\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .xl\:vertical-align-top {
    vertical-align: top !important;
  }
  .xl\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .xl\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .xl\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .xl\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .xl\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .xl\:vertical-align-super {
    vertical-align: super !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:vertical-align-baseline {
    vertical-align: baseline !important;
  }
  .xxl\:vertical-align-top {
    vertical-align: top !important;
  }
  .xxl\:vertical-align-middle {
    vertical-align: middle !important;
  }
  .xxl\:vertical-align-bottom {
    vertical-align: bottom !important;
  }
  .xxl\:vertical-align-text-top {
    vertical-align: text-top !important;
  }
  .xxl\:vertical-align-text-bottom {
    vertical-align: text-bottom !important;
  }
  .xxl\:vertical-align-sub {
    vertical-align: sub !important;
  }
  .xxl\:vertical-align-super {
    vertical-align: super !important;
  }
}
.flex-row {
  flex-direction: row !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

@media screen and (min-width: 480px) {
  .xs\:flex-row {
    flex-direction: row !important;
  }
  .xs\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xs\:flex-column {
    flex-direction: column !important;
  }
  .xs\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:flex-row {
    flex-direction: row !important;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .sm\:flex-column {
    flex-direction: column !important;
  }
  .sm\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-row {
    flex-direction: row !important;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .md\:flex-column {
    flex-direction: column !important;
  }
  .md\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-row {
    flex-direction: row !important;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .lg\:flex-column {
    flex-direction: column !important;
  }
  .lg\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-row {
    flex-direction: row !important;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xl\:flex-column {
    flex-direction: column !important;
  }
  .xl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:flex-row {
    flex-direction: row !important;
  }
  .xxl\:flex-row-reverse {
    flex-direction: row-reverse !important;
  }
  .xxl\:flex-column {
    flex-direction: column !important;
  }
  .xxl\:flex-column-reverse {
    flex-direction: column-reverse !important;
  }
}
.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

@media screen and (min-width: 480px) {
  .xs\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .xs\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .xs\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .sm\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .md\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .xl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:flex-wrap {
    flex-wrap: wrap !important;
  }
  .xxl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .xxl\:flex-nowrap {
    flex-wrap: nowrap !important;
  }
}
.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

@media screen and (min-width: 480px) {
  .xs\:justify-content-start {
    justify-content: flex-start !important;
  }
  .xs\:justify-content-end {
    justify-content: flex-end !important;
  }
  .xs\:justify-content-center {
    justify-content: center !important;
  }
  .xs\:justify-content-between {
    justify-content: space-between !important;
  }
  .xs\:justify-content-around {
    justify-content: space-around !important;
  }
  .xs\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:justify-content-start {
    justify-content: flex-start !important;
  }
  .sm\:justify-content-end {
    justify-content: flex-end !important;
  }
  .sm\:justify-content-center {
    justify-content: center !important;
  }
  .sm\:justify-content-between {
    justify-content: space-between !important;
  }
  .sm\:justify-content-around {
    justify-content: space-around !important;
  }
  .sm\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:justify-content-start {
    justify-content: flex-start !important;
  }
  .md\:justify-content-end {
    justify-content: flex-end !important;
  }
  .md\:justify-content-center {
    justify-content: center !important;
  }
  .md\:justify-content-between {
    justify-content: space-between !important;
  }
  .md\:justify-content-around {
    justify-content: space-around !important;
  }
  .md\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:justify-content-start {
    justify-content: flex-start !important;
  }
  .lg\:justify-content-end {
    justify-content: flex-end !important;
  }
  .lg\:justify-content-center {
    justify-content: center !important;
  }
  .lg\:justify-content-between {
    justify-content: space-between !important;
  }
  .lg\:justify-content-around {
    justify-content: space-around !important;
  }
  .lg\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:justify-content-start {
    justify-content: flex-start !important;
  }
  .xl\:justify-content-end {
    justify-content: flex-end !important;
  }
  .xl\:justify-content-center {
    justify-content: center !important;
  }
  .xl\:justify-content-between {
    justify-content: space-between !important;
  }
  .xl\:justify-content-around {
    justify-content: space-around !important;
  }
  .xl\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:justify-content-start {
    justify-content: flex-start !important;
  }
  .xxl\:justify-content-end {
    justify-content: flex-end !important;
  }
  .xxl\:justify-content-center {
    justify-content: center !important;
  }
  .xxl\:justify-content-between {
    justify-content: space-between !important;
  }
  .xxl\:justify-content-around {
    justify-content: space-around !important;
  }
  .xxl\:justify-content-evenly {
    justify-content: space-evenly !important;
  }
}
.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-evenly {
  align-content: space-evenly !important;
}

@media screen and (min-width: 480px) {
  .xs\:align-content-start {
    align-content: flex-start !important;
  }
  .xs\:align-content-end {
    align-content: flex-end !important;
  }
  .xs\:align-content-center {
    align-content: center !important;
  }
  .xs\:align-content-between {
    align-content: space-between !important;
  }
  .xs\:align-content-around {
    align-content: space-around !important;
  }
  .xs\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:align-content-start {
    align-content: flex-start !important;
  }
  .sm\:align-content-end {
    align-content: flex-end !important;
  }
  .sm\:align-content-center {
    align-content: center !important;
  }
  .sm\:align-content-between {
    align-content: space-between !important;
  }
  .sm\:align-content-around {
    align-content: space-around !important;
  }
  .sm\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-content-start {
    align-content: flex-start !important;
  }
  .md\:align-content-end {
    align-content: flex-end !important;
  }
  .md\:align-content-center {
    align-content: center !important;
  }
  .md\:align-content-between {
    align-content: space-between !important;
  }
  .md\:align-content-around {
    align-content: space-around !important;
  }
  .md\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-content-start {
    align-content: flex-start !important;
  }
  .lg\:align-content-end {
    align-content: flex-end !important;
  }
  .lg\:align-content-center {
    align-content: center !important;
  }
  .lg\:align-content-between {
    align-content: space-between !important;
  }
  .lg\:align-content-around {
    align-content: space-around !important;
  }
  .lg\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-content-start {
    align-content: flex-start !important;
  }
  .xl\:align-content-end {
    align-content: flex-end !important;
  }
  .xl\:align-content-center {
    align-content: center !important;
  }
  .xl\:align-content-between {
    align-content: space-between !important;
  }
  .xl\:align-content-around {
    align-content: space-around !important;
  }
  .xl\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:align-content-start {
    align-content: flex-start !important;
  }
  .xxl\:align-content-end {
    align-content: flex-end !important;
  }
  .xxl\:align-content-center {
    align-content: center !important;
  }
  .xxl\:align-content-between {
    align-content: space-between !important;
  }
  .xxl\:align-content-around {
    align-content: space-around !important;
  }
  .xxl\:align-content-evenly {
    align-content: space-evenly !important;
  }
}
.align-items-stretch {
  align-items: stretch !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

@media screen and (min-width: 480px) {
  .xs\:align-items-stretch {
    align-items: stretch !important;
  }
  .xs\:align-items-start {
    align-items: flex-start !important;
  }
  .xs\:align-items-center {
    align-items: center !important;
  }
  .xs\:align-items-end {
    align-items: flex-end !important;
  }
  .xs\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:align-items-stretch {
    align-items: stretch !important;
  }
  .sm\:align-items-start {
    align-items: flex-start !important;
  }
  .sm\:align-items-center {
    align-items: center !important;
  }
  .sm\:align-items-end {
    align-items: flex-end !important;
  }
  .sm\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-items-stretch {
    align-items: stretch !important;
  }
  .md\:align-items-start {
    align-items: flex-start !important;
  }
  .md\:align-items-center {
    align-items: center !important;
  }
  .md\:align-items-end {
    align-items: flex-end !important;
  }
  .md\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-items-stretch {
    align-items: stretch !important;
  }
  .lg\:align-items-start {
    align-items: flex-start !important;
  }
  .lg\:align-items-center {
    align-items: center !important;
  }
  .lg\:align-items-end {
    align-items: flex-end !important;
  }
  .lg\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-items-stretch {
    align-items: stretch !important;
  }
  .xl\:align-items-start {
    align-items: flex-start !important;
  }
  .xl\:align-items-center {
    align-items: center !important;
  }
  .xl\:align-items-end {
    align-items: flex-end !important;
  }
  .xl\:align-items-baseline {
    align-items: baseline !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:align-items-stretch {
    align-items: stretch !important;
  }
  .xxl\:align-items-start {
    align-items: flex-start !important;
  }
  .xxl\:align-items-center {
    align-items: center !important;
  }
  .xxl\:align-items-end {
    align-items: flex-end !important;
  }
  .xxl\:align-items-baseline {
    align-items: baseline !important;
  }
}
.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

@media screen and (min-width: 480px) {
  .xs\:align-self-auto {
    align-self: auto !important;
  }
  .xs\:align-self-start {
    align-self: flex-start !important;
  }
  .xs\:align-self-end {
    align-self: flex-end !important;
  }
  .xs\:align-self-center {
    align-self: center !important;
  }
  .xs\:align-self-stretch {
    align-self: stretch !important;
  }
  .xs\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:align-self-auto {
    align-self: auto !important;
  }
  .sm\:align-self-start {
    align-self: flex-start !important;
  }
  .sm\:align-self-end {
    align-self: flex-end !important;
  }
  .sm\:align-self-center {
    align-self: center !important;
  }
  .sm\:align-self-stretch {
    align-self: stretch !important;
  }
  .sm\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:align-self-auto {
    align-self: auto !important;
  }
  .md\:align-self-start {
    align-self: flex-start !important;
  }
  .md\:align-self-end {
    align-self: flex-end !important;
  }
  .md\:align-self-center {
    align-self: center !important;
  }
  .md\:align-self-stretch {
    align-self: stretch !important;
  }
  .md\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:align-self-auto {
    align-self: auto !important;
  }
  .lg\:align-self-start {
    align-self: flex-start !important;
  }
  .lg\:align-self-end {
    align-self: flex-end !important;
  }
  .lg\:align-self-center {
    align-self: center !important;
  }
  .lg\:align-self-stretch {
    align-self: stretch !important;
  }
  .lg\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:align-self-auto {
    align-self: auto !important;
  }
  .xl\:align-self-start {
    align-self: flex-start !important;
  }
  .xl\:align-self-end {
    align-self: flex-end !important;
  }
  .xl\:align-self-center {
    align-self: center !important;
  }
  .xl\:align-self-stretch {
    align-self: stretch !important;
  }
  .xl\:align-self-baseline {
    align-self: baseline !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:align-self-auto {
    align-self: auto !important;
  }
  .xxl\:align-self-start {
    align-self: flex-start !important;
  }
  .xxl\:align-self-end {
    align-self: flex-end !important;
  }
  .xxl\:align-self-center {
    align-self: center !important;
  }
  .xxl\:align-self-stretch {
    align-self: stretch !important;
  }
  .xxl\:align-self-baseline {
    align-self: baseline !important;
  }
}
.flex-order-0 {
  order: 0 !important;
}

.flex-order-1 {
  order: 1 !important;
}

.flex-order-2 {
  order: 2 !important;
}

.flex-order-3 {
  order: 3 !important;
}

.flex-order-4 {
  order: 4 !important;
}

.flex-order-5 {
  order: 5 !important;
}

.flex-order-6 {
  order: 6 !important;
}

@media screen and (min-width: 480px) {
  .xs\:flex-order-0 {
    order: 0 !important;
  }
  .xs\:flex-order-1 {
    order: 1 !important;
  }
  .xs\:flex-order-2 {
    order: 2 !important;
  }
  .xs\:flex-order-3 {
    order: 3 !important;
  }
  .xs\:flex-order-4 {
    order: 4 !important;
  }
  .xs\:flex-order-5 {
    order: 5 !important;
  }
  .xs\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:flex-order-0 {
    order: 0 !important;
  }
  .sm\:flex-order-1 {
    order: 1 !important;
  }
  .sm\:flex-order-2 {
    order: 2 !important;
  }
  .sm\:flex-order-3 {
    order: 3 !important;
  }
  .sm\:flex-order-4 {
    order: 4 !important;
  }
  .sm\:flex-order-5 {
    order: 5 !important;
  }
  .sm\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-order-0 {
    order: 0 !important;
  }
  .md\:flex-order-1 {
    order: 1 !important;
  }
  .md\:flex-order-2 {
    order: 2 !important;
  }
  .md\:flex-order-3 {
    order: 3 !important;
  }
  .md\:flex-order-4 {
    order: 4 !important;
  }
  .md\:flex-order-5 {
    order: 5 !important;
  }
  .md\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-order-0 {
    order: 0 !important;
  }
  .lg\:flex-order-1 {
    order: 1 !important;
  }
  .lg\:flex-order-2 {
    order: 2 !important;
  }
  .lg\:flex-order-3 {
    order: 3 !important;
  }
  .lg\:flex-order-4 {
    order: 4 !important;
  }
  .lg\:flex-order-5 {
    order: 5 !important;
  }
  .lg\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-order-0 {
    order: 0 !important;
  }
  .xl\:flex-order-1 {
    order: 1 !important;
  }
  .xl\:flex-order-2 {
    order: 2 !important;
  }
  .xl\:flex-order-3 {
    order: 3 !important;
  }
  .xl\:flex-order-4 {
    order: 4 !important;
  }
  .xl\:flex-order-5 {
    order: 5 !important;
  }
  .xl\:flex-order-6 {
    order: 6 !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:flex-order-0 {
    order: 0 !important;
  }
  .xxl\:flex-order-1 {
    order: 1 !important;
  }
  .xxl\:flex-order-2 {
    order: 2 !important;
  }
  .xxl\:flex-order-3 {
    order: 3 !important;
  }
  .xxl\:flex-order-4 {
    order: 4 !important;
  }
  .xxl\:flex-order-5 {
    order: 5 !important;
  }
  .xxl\:flex-order-6 {
    order: 6 !important;
  }
}
.flex-1 {
  flex: 1 1 0% !important;
}

.flex-auto {
  flex: 1 1 auto !important;
}

.flex-initial {
  flex: 0 1 auto !important;
}

.flex-none {
  flex: none !important;
}

@media screen and (min-width: 480px) {
  .xs\:flex-1 {
    flex: 1 1 0% !important;
  }
  .xs\:flex-auto {
    flex: 1 1 auto !important;
  }
  .xs\:flex-initial {
    flex: 0 1 auto !important;
  }
  .xs\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:flex-1 {
    flex: 1 1 0% !important;
  }
  .sm\:flex-auto {
    flex: 1 1 auto !important;
  }
  .sm\:flex-initial {
    flex: 0 1 auto !important;
  }
  .sm\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-1 {
    flex: 1 1 0% !important;
  }
  .md\:flex-auto {
    flex: 1 1 auto !important;
  }
  .md\:flex-initial {
    flex: 0 1 auto !important;
  }
  .md\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-1 {
    flex: 1 1 0% !important;
  }
  .lg\:flex-auto {
    flex: 1 1 auto !important;
  }
  .lg\:flex-initial {
    flex: 0 1 auto !important;
  }
  .lg\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-1 {
    flex: 1 1 0% !important;
  }
  .xl\:flex-auto {
    flex: 1 1 auto !important;
  }
  .xl\:flex-initial {
    flex: 0 1 auto !important;
  }
  .xl\:flex-none {
    flex: none !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:flex-1 {
    flex: 1 1 0% !important;
  }
  .xxl\:flex-auto {
    flex: 1 1 auto !important;
  }
  .xxl\:flex-initial {
    flex: 0 1 auto !important;
  }
  .xxl\:flex-none {
    flex: none !important;
  }
}
.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

@media screen and (min-width: 480px) {
  .xs\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xs\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .sm\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .md\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .lg\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:flex-grow-0 {
    flex-grow: 0 !important;
  }
  .xxl\:flex-grow-1 {
    flex-grow: 1 !important;
  }
}
.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

@media screen and (min-width: 480px) {
  .xs\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xs\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .sm\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .md\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .lg\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:flex-shrink-0 {
    flex-shrink: 0 !important;
  }
  .xxl\:flex-shrink-1 {
    flex-shrink: 1 !important;
  }
}
.gap-0 {
  gap: 0px !important;
}

.gap-1 {
  gap: 2.5px !important;
}

.gap-2 {
  gap: 5px !important;
}

.gap-3 {
  gap: 10px !important;
}

.gap-4 {
  gap: 15px !important;
}

.gap-5 {
  gap: 20px !important;
}

.gap-6 {
  gap: 25px !important;
}

.gap-7 {
  gap: 30px !important;
}

.gap-8 {
  gap: 35px !important;
}

.gap-9 {
  gap: 40px !important;
}

.gap-10 {
  gap: 45px !important;
}

.row-gap-0 {
  row-gap: 0px !important;
}

.row-gap-1 {
  row-gap: 2.5px !important;
}

.row-gap-2 {
  row-gap: 5px !important;
}

.row-gap-3 {
  row-gap: 10px !important;
}

.row-gap-4 {
  row-gap: 15px !important;
}

.row-gap-5 {
  row-gap: 20px !important;
}

.row-gap-6 {
  row-gap: 25px !important;
}

.row-gap-7 {
  row-gap: 30px !important;
}

.row-gap-8 {
  row-gap: 35px !important;
}

.row-gap-9 {
  row-gap: 40px !important;
}

.row-gap-10 {
  row-gap: 45px !important;
}

.column-gap-0 {
  column-gap: 0px !important;
}

.column-gap-1 {
  column-gap: 2.5px !important;
}

.column-gap-2 {
  column-gap: 5px !important;
}

.column-gap-3 {
  column-gap: 10px !important;
}

.column-gap-4 {
  column-gap: 15px !important;
}

.column-gap-5 {
  column-gap: 20px !important;
}

.column-gap-6 {
  column-gap: 25px !important;
}

.column-gap-7 {
  column-gap: 30px !important;
}

.column-gap-8 {
  column-gap: 35px !important;
}

.column-gap-9 {
  column-gap: 40px !important;
}

.column-gap-10 {
  column-gap: 45px !important;
}

@media screen and (min-width: 480px) {
  .xs\:gap-0 {
    gap: 0px !important;
  }
  .xs\:gap-1 {
    gap: 2.5px !important;
  }
  .xs\:gap-2 {
    gap: 5px !important;
  }
  .xs\:gap-3 {
    gap: 10px !important;
  }
  .xs\:gap-4 {
    gap: 15px !important;
  }
  .xs\:gap-5 {
    gap: 20px !important;
  }
  .xs\:gap-6 {
    gap: 25px !important;
  }
  .xs\:gap-7 {
    gap: 30px !important;
  }
  .xs\:gap-8 {
    gap: 35px !important;
  }
  .xs\:gap-9 {
    gap: 40px !important;
  }
  .xs\:gap-10 {
    gap: 45px !important;
  }
  .xs\:row-gap-0 {
    row-gap: 0px !important;
  }
  .xs\:row-gap-1 {
    row-gap: 2.5px !important;
  }
  .xs\:row-gap-2 {
    row-gap: 5px !important;
  }
  .xs\:row-gap-3 {
    row-gap: 10px !important;
  }
  .xs\:row-gap-4 {
    row-gap: 15px !important;
  }
  .xs\:row-gap-5 {
    row-gap: 20px !important;
  }
  .xs\:row-gap-6 {
    row-gap: 25px !important;
  }
  .xs\:row-gap-7 {
    row-gap: 30px !important;
  }
  .xs\:row-gap-8 {
    row-gap: 35px !important;
  }
  .xs\:row-gap-9 {
    row-gap: 40px !important;
  }
  .xs\:row-gap-10 {
    row-gap: 45px !important;
  }
  .xs\:column-gap-0 {
    column-gap: 0px !important;
  }
  .xs\:column-gap-1 {
    column-gap: 2.5px !important;
  }
  .xs\:column-gap-2 {
    column-gap: 5px !important;
  }
  .xs\:column-gap-3 {
    column-gap: 10px !important;
  }
  .xs\:column-gap-4 {
    column-gap: 15px !important;
  }
  .xs\:column-gap-5 {
    column-gap: 20px !important;
  }
  .xs\:column-gap-6 {
    column-gap: 25px !important;
  }
  .xs\:column-gap-7 {
    column-gap: 30px !important;
  }
  .xs\:column-gap-8 {
    column-gap: 35px !important;
  }
  .xs\:column-gap-9 {
    column-gap: 40px !important;
  }
  .xs\:column-gap-10 {
    column-gap: 45px !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:gap-0 {
    gap: 0px !important;
  }
  .sm\:gap-1 {
    gap: 2.5px !important;
  }
  .sm\:gap-2 {
    gap: 5px !important;
  }
  .sm\:gap-3 {
    gap: 10px !important;
  }
  .sm\:gap-4 {
    gap: 15px !important;
  }
  .sm\:gap-5 {
    gap: 20px !important;
  }
  .sm\:gap-6 {
    gap: 25px !important;
  }
  .sm\:gap-7 {
    gap: 30px !important;
  }
  .sm\:gap-8 {
    gap: 35px !important;
  }
  .sm\:gap-9 {
    gap: 40px !important;
  }
  .sm\:gap-10 {
    gap: 45px !important;
  }
  .sm\:row-gap-0 {
    row-gap: 0px !important;
  }
  .sm\:row-gap-1 {
    row-gap: 2.5px !important;
  }
  .sm\:row-gap-2 {
    row-gap: 5px !important;
  }
  .sm\:row-gap-3 {
    row-gap: 10px !important;
  }
  .sm\:row-gap-4 {
    row-gap: 15px !important;
  }
  .sm\:row-gap-5 {
    row-gap: 20px !important;
  }
  .sm\:row-gap-6 {
    row-gap: 25px !important;
  }
  .sm\:row-gap-7 {
    row-gap: 30px !important;
  }
  .sm\:row-gap-8 {
    row-gap: 35px !important;
  }
  .sm\:row-gap-9 {
    row-gap: 40px !important;
  }
  .sm\:row-gap-10 {
    row-gap: 45px !important;
  }
  .sm\:column-gap-0 {
    column-gap: 0px !important;
  }
  .sm\:column-gap-1 {
    column-gap: 2.5px !important;
  }
  .sm\:column-gap-2 {
    column-gap: 5px !important;
  }
  .sm\:column-gap-3 {
    column-gap: 10px !important;
  }
  .sm\:column-gap-4 {
    column-gap: 15px !important;
  }
  .sm\:column-gap-5 {
    column-gap: 20px !important;
  }
  .sm\:column-gap-6 {
    column-gap: 25px !important;
  }
  .sm\:column-gap-7 {
    column-gap: 30px !important;
  }
  .sm\:column-gap-8 {
    column-gap: 35px !important;
  }
  .sm\:column-gap-9 {
    column-gap: 40px !important;
  }
  .sm\:column-gap-10 {
    column-gap: 45px !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:gap-0 {
    gap: 0px !important;
  }
  .md\:gap-1 {
    gap: 2.5px !important;
  }
  .md\:gap-2 {
    gap: 5px !important;
  }
  .md\:gap-3 {
    gap: 10px !important;
  }
  .md\:gap-4 {
    gap: 15px !important;
  }
  .md\:gap-5 {
    gap: 20px !important;
  }
  .md\:gap-6 {
    gap: 25px !important;
  }
  .md\:gap-7 {
    gap: 30px !important;
  }
  .md\:gap-8 {
    gap: 35px !important;
  }
  .md\:gap-9 {
    gap: 40px !important;
  }
  .md\:gap-10 {
    gap: 45px !important;
  }
  .md\:row-gap-0 {
    row-gap: 0px !important;
  }
  .md\:row-gap-1 {
    row-gap: 2.5px !important;
  }
  .md\:row-gap-2 {
    row-gap: 5px !important;
  }
  .md\:row-gap-3 {
    row-gap: 10px !important;
  }
  .md\:row-gap-4 {
    row-gap: 15px !important;
  }
  .md\:row-gap-5 {
    row-gap: 20px !important;
  }
  .md\:row-gap-6 {
    row-gap: 25px !important;
  }
  .md\:row-gap-7 {
    row-gap: 30px !important;
  }
  .md\:row-gap-8 {
    row-gap: 35px !important;
  }
  .md\:row-gap-9 {
    row-gap: 40px !important;
  }
  .md\:row-gap-10 {
    row-gap: 45px !important;
  }
  .md\:column-gap-0 {
    column-gap: 0px !important;
  }
  .md\:column-gap-1 {
    column-gap: 2.5px !important;
  }
  .md\:column-gap-2 {
    column-gap: 5px !important;
  }
  .md\:column-gap-3 {
    column-gap: 10px !important;
  }
  .md\:column-gap-4 {
    column-gap: 15px !important;
  }
  .md\:column-gap-5 {
    column-gap: 20px !important;
  }
  .md\:column-gap-6 {
    column-gap: 25px !important;
  }
  .md\:column-gap-7 {
    column-gap: 30px !important;
  }
  .md\:column-gap-8 {
    column-gap: 35px !important;
  }
  .md\:column-gap-9 {
    column-gap: 40px !important;
  }
  .md\:column-gap-10 {
    column-gap: 45px !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:gap-0 {
    gap: 0px !important;
  }
  .lg\:gap-1 {
    gap: 2.5px !important;
  }
  .lg\:gap-2 {
    gap: 5px !important;
  }
  .lg\:gap-3 {
    gap: 10px !important;
  }
  .lg\:gap-4 {
    gap: 15px !important;
  }
  .lg\:gap-5 {
    gap: 20px !important;
  }
  .lg\:gap-6 {
    gap: 25px !important;
  }
  .lg\:gap-7 {
    gap: 30px !important;
  }
  .lg\:gap-8 {
    gap: 35px !important;
  }
  .lg\:gap-9 {
    gap: 40px !important;
  }
  .lg\:gap-10 {
    gap: 45px !important;
  }
  .lg\:row-gap-0 {
    row-gap: 0px !important;
  }
  .lg\:row-gap-1 {
    row-gap: 2.5px !important;
  }
  .lg\:row-gap-2 {
    row-gap: 5px !important;
  }
  .lg\:row-gap-3 {
    row-gap: 10px !important;
  }
  .lg\:row-gap-4 {
    row-gap: 15px !important;
  }
  .lg\:row-gap-5 {
    row-gap: 20px !important;
  }
  .lg\:row-gap-6 {
    row-gap: 25px !important;
  }
  .lg\:row-gap-7 {
    row-gap: 30px !important;
  }
  .lg\:row-gap-8 {
    row-gap: 35px !important;
  }
  .lg\:row-gap-9 {
    row-gap: 40px !important;
  }
  .lg\:row-gap-10 {
    row-gap: 45px !important;
  }
  .lg\:column-gap-0 {
    column-gap: 0px !important;
  }
  .lg\:column-gap-1 {
    column-gap: 2.5px !important;
  }
  .lg\:column-gap-2 {
    column-gap: 5px !important;
  }
  .lg\:column-gap-3 {
    column-gap: 10px !important;
  }
  .lg\:column-gap-4 {
    column-gap: 15px !important;
  }
  .lg\:column-gap-5 {
    column-gap: 20px !important;
  }
  .lg\:column-gap-6 {
    column-gap: 25px !important;
  }
  .lg\:column-gap-7 {
    column-gap: 30px !important;
  }
  .lg\:column-gap-8 {
    column-gap: 35px !important;
  }
  .lg\:column-gap-9 {
    column-gap: 40px !important;
  }
  .lg\:column-gap-10 {
    column-gap: 45px !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:gap-0 {
    gap: 0px !important;
  }
  .xl\:gap-1 {
    gap: 2.5px !important;
  }
  .xl\:gap-2 {
    gap: 5px !important;
  }
  .xl\:gap-3 {
    gap: 10px !important;
  }
  .xl\:gap-4 {
    gap: 15px !important;
  }
  .xl\:gap-5 {
    gap: 20px !important;
  }
  .xl\:gap-6 {
    gap: 25px !important;
  }
  .xl\:gap-7 {
    gap: 30px !important;
  }
  .xl\:gap-8 {
    gap: 35px !important;
  }
  .xl\:gap-9 {
    gap: 40px !important;
  }
  .xl\:gap-10 {
    gap: 45px !important;
  }
  .xl\:row-gap-0 {
    row-gap: 0px !important;
  }
  .xl\:row-gap-1 {
    row-gap: 2.5px !important;
  }
  .xl\:row-gap-2 {
    row-gap: 5px !important;
  }
  .xl\:row-gap-3 {
    row-gap: 10px !important;
  }
  .xl\:row-gap-4 {
    row-gap: 15px !important;
  }
  .xl\:row-gap-5 {
    row-gap: 20px !important;
  }
  .xl\:row-gap-6 {
    row-gap: 25px !important;
  }
  .xl\:row-gap-7 {
    row-gap: 30px !important;
  }
  .xl\:row-gap-8 {
    row-gap: 35px !important;
  }
  .xl\:row-gap-9 {
    row-gap: 40px !important;
  }
  .xl\:row-gap-10 {
    row-gap: 45px !important;
  }
  .xl\:column-gap-0 {
    column-gap: 0px !important;
  }
  .xl\:column-gap-1 {
    column-gap: 2.5px !important;
  }
  .xl\:column-gap-2 {
    column-gap: 5px !important;
  }
  .xl\:column-gap-3 {
    column-gap: 10px !important;
  }
  .xl\:column-gap-4 {
    column-gap: 15px !important;
  }
  .xl\:column-gap-5 {
    column-gap: 20px !important;
  }
  .xl\:column-gap-6 {
    column-gap: 25px !important;
  }
  .xl\:column-gap-7 {
    column-gap: 30px !important;
  }
  .xl\:column-gap-8 {
    column-gap: 35px !important;
  }
  .xl\:column-gap-9 {
    column-gap: 40px !important;
  }
  .xl\:column-gap-10 {
    column-gap: 45px !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:gap-0 {
    gap: 0px !important;
  }
  .xxl\:gap-1 {
    gap: 2.5px !important;
  }
  .xxl\:gap-2 {
    gap: 5px !important;
  }
  .xxl\:gap-3 {
    gap: 10px !important;
  }
  .xxl\:gap-4 {
    gap: 15px !important;
  }
  .xxl\:gap-5 {
    gap: 20px !important;
  }
  .xxl\:gap-6 {
    gap: 25px !important;
  }
  .xxl\:gap-7 {
    gap: 30px !important;
  }
  .xxl\:gap-8 {
    gap: 35px !important;
  }
  .xxl\:gap-9 {
    gap: 40px !important;
  }
  .xxl\:gap-10 {
    gap: 45px !important;
  }
  .xxl\:row-gap-0 {
    row-gap: 0px !important;
  }
  .xxl\:row-gap-1 {
    row-gap: 2.5px !important;
  }
  .xxl\:row-gap-2 {
    row-gap: 5px !important;
  }
  .xxl\:row-gap-3 {
    row-gap: 10px !important;
  }
  .xxl\:row-gap-4 {
    row-gap: 15px !important;
  }
  .xxl\:row-gap-5 {
    row-gap: 20px !important;
  }
  .xxl\:row-gap-6 {
    row-gap: 25px !important;
  }
  .xxl\:row-gap-7 {
    row-gap: 30px !important;
  }
  .xxl\:row-gap-8 {
    row-gap: 35px !important;
  }
  .xxl\:row-gap-9 {
    row-gap: 40px !important;
  }
  .xxl\:row-gap-10 {
    row-gap: 45px !important;
  }
  .xxl\:column-gap-0 {
    column-gap: 0px !important;
  }
  .xxl\:column-gap-1 {
    column-gap: 2.5px !important;
  }
  .xxl\:column-gap-2 {
    column-gap: 5px !important;
  }
  .xxl\:column-gap-3 {
    column-gap: 10px !important;
  }
  .xxl\:column-gap-4 {
    column-gap: 15px !important;
  }
  .xxl\:column-gap-5 {
    column-gap: 20px !important;
  }
  .xxl\:column-gap-6 {
    column-gap: 25px !important;
  }
  .xxl\:column-gap-7 {
    column-gap: 30px !important;
  }
  .xxl\:column-gap-8 {
    column-gap: 35px !important;
  }
  .xxl\:column-gap-9 {
    column-gap: 40px !important;
  }
  .xxl\:column-gap-10 {
    column-gap: 45px !important;
  }
}
.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 2.5px !important;
}

.p-2 {
  padding: 5px !important;
}

.p-3 {
  padding: 10px !important;
}

.p-4 {
  padding: 15px !important;
}

.p-5 {
  padding: 20px !important;
}

.p-6 {
  padding: 25px !important;
}

.p-7 {
  padding: 30px !important;
}

.p-8 {
  padding: 35px !important;
}

.p-9 {
  padding: 40px !important;
}

.p-10 {
  padding: 45px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 2.5px !important;
}

.pt-2 {
  padding-top: 5px !important;
}

.pt-3 {
  padding-top: 10px !important;
}

.pt-4 {
  padding-top: 15px !important;
}

.pt-5 {
  padding-top: 20px !important;
}

.pt-6 {
  padding-top: 25px !important;
}

.pt-7 {
  padding-top: 30px !important;
}

.pt-8 {
  padding-top: 35px !important;
}

.pt-9 {
  padding-top: 40px !important;
}

.pt-10 {
  padding-top: 45px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 2.5px !important;
}

.pr-2 {
  padding-right: 5px !important;
}

.pr-3 {
  padding-right: 10px !important;
}

.pr-4 {
  padding-right: 15px !important;
}

.pr-5 {
  padding-right: 20px !important;
}

.pr-6 {
  padding-right: 25px !important;
}

.pr-7 {
  padding-right: 30px !important;
}

.pr-8 {
  padding-right: 35px !important;
}

.pr-9 {
  padding-right: 40px !important;
}

.pr-10 {
  padding-right: 45px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 2.5px !important;
}

.pl-2 {
  padding-left: 5px !important;
}

.pl-3 {
  padding-left: 10px !important;
}

.pl-4 {
  padding-left: 15px !important;
}

.pl-5 {
  padding-left: 20px !important;
}

.pl-6 {
  padding-left: 25px !important;
}

.pl-7 {
  padding-left: 30px !important;
}

.pl-8 {
  padding-left: 35px !important;
}

.pl-9 {
  padding-left: 40px !important;
}

.pl-10 {
  padding-left: 45px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 2.5px !important;
}

.pb-2 {
  padding-bottom: 5px !important;
}

.pb-3 {
  padding-bottom: 10px !important;
}

.pb-4 {
  padding-bottom: 15px !important;
}

.pb-5 {
  padding-bottom: 20px !important;
}

.pb-6 {
  padding-bottom: 25px !important;
}

.pb-7 {
  padding-bottom: 30px !important;
}

.pb-8 {
  padding-bottom: 35px !important;
}

.pb-9 {
  padding-bottom: 40px !important;
}

.pb-10 {
  padding-bottom: 45px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-1 {
  padding-left: 2.5px !important;
  padding-right: 2.5px !important;
}

.px-2 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.px-3 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.px-4 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.px-5 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.px-6 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.px-7 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.px-8 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}

.px-9 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.px-10 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.py-1 {
  padding-top: 2.5px !important;
  padding-bottom: 2.5px !important;
}

.py-2 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.py-3 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.py-4 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.py-6 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.py-7 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.py-8 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}

.py-9 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.py-10 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}

@media screen and (min-width: 480px) {
  .xs\:p-0 {
    padding: 0px !important;
  }
  .xs\:p-1 {
    padding: 2.5px !important;
  }
  .xs\:p-2 {
    padding: 5px !important;
  }
  .xs\:p-3 {
    padding: 10px !important;
  }
  .xs\:p-4 {
    padding: 15px !important;
  }
  .xs\:p-5 {
    padding: 20px !important;
  }
  .xs\:p-6 {
    padding: 25px !important;
  }
  .xs\:p-7 {
    padding: 30px !important;
  }
  .xs\:p-8 {
    padding: 35px !important;
  }
  .xs\:p-9 {
    padding: 40px !important;
  }
  .xs\:p-10 {
    padding: 45px !important;
  }
  .xs\:pt-0 {
    padding-top: 0px !important;
  }
  .xs\:pt-1 {
    padding-top: 2.5px !important;
  }
  .xs\:pt-2 {
    padding-top: 5px !important;
  }
  .xs\:pt-3 {
    padding-top: 10px !important;
  }
  .xs\:pt-4 {
    padding-top: 15px !important;
  }
  .xs\:pt-5 {
    padding-top: 20px !important;
  }
  .xs\:pt-6 {
    padding-top: 25px !important;
  }
  .xs\:pt-7 {
    padding-top: 30px !important;
  }
  .xs\:pt-8 {
    padding-top: 35px !important;
  }
  .xs\:pt-9 {
    padding-top: 40px !important;
  }
  .xs\:pt-10 {
    padding-top: 45px !important;
  }
  .xs\:pr-0 {
    padding-right: 0px !important;
  }
  .xs\:pr-1 {
    padding-right: 2.5px !important;
  }
  .xs\:pr-2 {
    padding-right: 5px !important;
  }
  .xs\:pr-3 {
    padding-right: 10px !important;
  }
  .xs\:pr-4 {
    padding-right: 15px !important;
  }
  .xs\:pr-5 {
    padding-right: 20px !important;
  }
  .xs\:pr-6 {
    padding-right: 25px !important;
  }
  .xs\:pr-7 {
    padding-right: 30px !important;
  }
  .xs\:pr-8 {
    padding-right: 35px !important;
  }
  .xs\:pr-9 {
    padding-right: 40px !important;
  }
  .xs\:pr-10 {
    padding-right: 45px !important;
  }
  .xs\:pl-0 {
    padding-left: 0px !important;
  }
  .xs\:pl-1 {
    padding-left: 2.5px !important;
  }
  .xs\:pl-2 {
    padding-left: 5px !important;
  }
  .xs\:pl-3 {
    padding-left: 10px !important;
  }
  .xs\:pl-4 {
    padding-left: 15px !important;
  }
  .xs\:pl-5 {
    padding-left: 20px !important;
  }
  .xs\:pl-6 {
    padding-left: 25px !important;
  }
  .xs\:pl-7 {
    padding-left: 30px !important;
  }
  .xs\:pl-8 {
    padding-left: 35px !important;
  }
  .xs\:pl-9 {
    padding-left: 40px !important;
  }
  .xs\:pl-10 {
    padding-left: 45px !important;
  }
  .xs\:pb-0 {
    padding-bottom: 0px !important;
  }
  .xs\:pb-1 {
    padding-bottom: 2.5px !important;
  }
  .xs\:pb-2 {
    padding-bottom: 5px !important;
  }
  .xs\:pb-3 {
    padding-bottom: 10px !important;
  }
  .xs\:pb-4 {
    padding-bottom: 15px !important;
  }
  .xs\:pb-5 {
    padding-bottom: 20px !important;
  }
  .xs\:pb-6 {
    padding-bottom: 25px !important;
  }
  .xs\:pb-7 {
    padding-bottom: 30px !important;
  }
  .xs\:pb-8 {
    padding-bottom: 35px !important;
  }
  .xs\:pb-9 {
    padding-bottom: 40px !important;
  }
  .xs\:pb-10 {
    padding-bottom: 45px !important;
  }
  .xs\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xs\:px-1 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;
  }
  .xs\:px-2 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xs\:px-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xs\:px-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xs\:px-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xs\:px-6 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xs\:px-7 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xs\:px-8 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xs\:px-9 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xs\:px-10 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xs\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xs\:py-1 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important;
  }
  .xs\:py-2 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xs\:py-3 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xs\:py-4 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xs\:py-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xs\:py-6 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xs\:py-7 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xs\:py-8 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xs\:py-9 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xs\:py-10 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:p-0 {
    padding: 0px !important;
  }
  .sm\:p-1 {
    padding: 2.5px !important;
  }
  .sm\:p-2 {
    padding: 5px !important;
  }
  .sm\:p-3 {
    padding: 10px !important;
  }
  .sm\:p-4 {
    padding: 15px !important;
  }
  .sm\:p-5 {
    padding: 20px !important;
  }
  .sm\:p-6 {
    padding: 25px !important;
  }
  .sm\:p-7 {
    padding: 30px !important;
  }
  .sm\:p-8 {
    padding: 35px !important;
  }
  .sm\:p-9 {
    padding: 40px !important;
  }
  .sm\:p-10 {
    padding: 45px !important;
  }
  .sm\:pt-0 {
    padding-top: 0px !important;
  }
  .sm\:pt-1 {
    padding-top: 2.5px !important;
  }
  .sm\:pt-2 {
    padding-top: 5px !important;
  }
  .sm\:pt-3 {
    padding-top: 10px !important;
  }
  .sm\:pt-4 {
    padding-top: 15px !important;
  }
  .sm\:pt-5 {
    padding-top: 20px !important;
  }
  .sm\:pt-6 {
    padding-top: 25px !important;
  }
  .sm\:pt-7 {
    padding-top: 30px !important;
  }
  .sm\:pt-8 {
    padding-top: 35px !important;
  }
  .sm\:pt-9 {
    padding-top: 40px !important;
  }
  .sm\:pt-10 {
    padding-top: 45px !important;
  }
  .sm\:pr-0 {
    padding-right: 0px !important;
  }
  .sm\:pr-1 {
    padding-right: 2.5px !important;
  }
  .sm\:pr-2 {
    padding-right: 5px !important;
  }
  .sm\:pr-3 {
    padding-right: 10px !important;
  }
  .sm\:pr-4 {
    padding-right: 15px !important;
  }
  .sm\:pr-5 {
    padding-right: 20px !important;
  }
  .sm\:pr-6 {
    padding-right: 25px !important;
  }
  .sm\:pr-7 {
    padding-right: 30px !important;
  }
  .sm\:pr-8 {
    padding-right: 35px !important;
  }
  .sm\:pr-9 {
    padding-right: 40px !important;
  }
  .sm\:pr-10 {
    padding-right: 45px !important;
  }
  .sm\:pl-0 {
    padding-left: 0px !important;
  }
  .sm\:pl-1 {
    padding-left: 2.5px !important;
  }
  .sm\:pl-2 {
    padding-left: 5px !important;
  }
  .sm\:pl-3 {
    padding-left: 10px !important;
  }
  .sm\:pl-4 {
    padding-left: 15px !important;
  }
  .sm\:pl-5 {
    padding-left: 20px !important;
  }
  .sm\:pl-6 {
    padding-left: 25px !important;
  }
  .sm\:pl-7 {
    padding-left: 30px !important;
  }
  .sm\:pl-8 {
    padding-left: 35px !important;
  }
  .sm\:pl-9 {
    padding-left: 40px !important;
  }
  .sm\:pl-10 {
    padding-left: 45px !important;
  }
  .sm\:pb-0 {
    padding-bottom: 0px !important;
  }
  .sm\:pb-1 {
    padding-bottom: 2.5px !important;
  }
  .sm\:pb-2 {
    padding-bottom: 5px !important;
  }
  .sm\:pb-3 {
    padding-bottom: 10px !important;
  }
  .sm\:pb-4 {
    padding-bottom: 15px !important;
  }
  .sm\:pb-5 {
    padding-bottom: 20px !important;
  }
  .sm\:pb-6 {
    padding-bottom: 25px !important;
  }
  .sm\:pb-7 {
    padding-bottom: 30px !important;
  }
  .sm\:pb-8 {
    padding-bottom: 35px !important;
  }
  .sm\:pb-9 {
    padding-bottom: 40px !important;
  }
  .sm\:pb-10 {
    padding-bottom: 45px !important;
  }
  .sm\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .sm\:px-1 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;
  }
  .sm\:px-2 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .sm\:px-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sm\:px-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .sm\:px-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .sm\:px-6 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .sm\:px-7 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .sm\:px-8 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .sm\:px-9 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .sm\:px-10 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .sm\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .sm\:py-1 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important;
  }
  .sm\:py-2 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .sm\:py-3 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .sm\:py-4 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .sm\:py-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .sm\:py-6 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .sm\:py-7 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .sm\:py-8 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .sm\:py-9 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .sm\:py-10 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:p-0 {
    padding: 0px !important;
  }
  .md\:p-1 {
    padding: 2.5px !important;
  }
  .md\:p-2 {
    padding: 5px !important;
  }
  .md\:p-3 {
    padding: 10px !important;
  }
  .md\:p-4 {
    padding: 15px !important;
  }
  .md\:p-5 {
    padding: 20px !important;
  }
  .md\:p-6 {
    padding: 25px !important;
  }
  .md\:p-7 {
    padding: 30px !important;
  }
  .md\:p-8 {
    padding: 35px !important;
  }
  .md\:p-9 {
    padding: 40px !important;
  }
  .md\:p-10 {
    padding: 45px !important;
  }
  .md\:pt-0 {
    padding-top: 0px !important;
  }
  .md\:pt-1 {
    padding-top: 2.5px !important;
  }
  .md\:pt-2 {
    padding-top: 5px !important;
  }
  .md\:pt-3 {
    padding-top: 10px !important;
  }
  .md\:pt-4 {
    padding-top: 15px !important;
  }
  .md\:pt-5 {
    padding-top: 20px !important;
  }
  .md\:pt-6 {
    padding-top: 25px !important;
  }
  .md\:pt-7 {
    padding-top: 30px !important;
  }
  .md\:pt-8 {
    padding-top: 35px !important;
  }
  .md\:pt-9 {
    padding-top: 40px !important;
  }
  .md\:pt-10 {
    padding-top: 45px !important;
  }
  .md\:pr-0 {
    padding-right: 0px !important;
  }
  .md\:pr-1 {
    padding-right: 2.5px !important;
  }
  .md\:pr-2 {
    padding-right: 5px !important;
  }
  .md\:pr-3 {
    padding-right: 10px !important;
  }
  .md\:pr-4 {
    padding-right: 15px !important;
  }
  .md\:pr-5 {
    padding-right: 20px !important;
  }
  .md\:pr-6 {
    padding-right: 25px !important;
  }
  .md\:pr-7 {
    padding-right: 30px !important;
  }
  .md\:pr-8 {
    padding-right: 35px !important;
  }
  .md\:pr-9 {
    padding-right: 40px !important;
  }
  .md\:pr-10 {
    padding-right: 45px !important;
  }
  .md\:pl-0 {
    padding-left: 0px !important;
  }
  .md\:pl-1 {
    padding-left: 2.5px !important;
  }
  .md\:pl-2 {
    padding-left: 5px !important;
  }
  .md\:pl-3 {
    padding-left: 10px !important;
  }
  .md\:pl-4 {
    padding-left: 15px !important;
  }
  .md\:pl-5 {
    padding-left: 20px !important;
  }
  .md\:pl-6 {
    padding-left: 25px !important;
  }
  .md\:pl-7 {
    padding-left: 30px !important;
  }
  .md\:pl-8 {
    padding-left: 35px !important;
  }
  .md\:pl-9 {
    padding-left: 40px !important;
  }
  .md\:pl-10 {
    padding-left: 45px !important;
  }
  .md\:pb-0 {
    padding-bottom: 0px !important;
  }
  .md\:pb-1 {
    padding-bottom: 2.5px !important;
  }
  .md\:pb-2 {
    padding-bottom: 5px !important;
  }
  .md\:pb-3 {
    padding-bottom: 10px !important;
  }
  .md\:pb-4 {
    padding-bottom: 15px !important;
  }
  .md\:pb-5 {
    padding-bottom: 20px !important;
  }
  .md\:pb-6 {
    padding-bottom: 25px !important;
  }
  .md\:pb-7 {
    padding-bottom: 30px !important;
  }
  .md\:pb-8 {
    padding-bottom: 35px !important;
  }
  .md\:pb-9 {
    padding-bottom: 40px !important;
  }
  .md\:pb-10 {
    padding-bottom: 45px !important;
  }
  .md\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .md\:px-1 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;
  }
  .md\:px-2 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .md\:px-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .md\:px-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .md\:px-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .md\:px-6 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .md\:px-7 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .md\:px-8 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .md\:px-9 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .md\:px-10 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .md\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .md\:py-1 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important;
  }
  .md\:py-2 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .md\:py-3 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .md\:py-4 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .md\:py-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .md\:py-6 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .md\:py-7 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .md\:py-8 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .md\:py-9 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .md\:py-10 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:p-0 {
    padding: 0px !important;
  }
  .lg\:p-1 {
    padding: 2.5px !important;
  }
  .lg\:p-2 {
    padding: 5px !important;
  }
  .lg\:p-3 {
    padding: 10px !important;
  }
  .lg\:p-4 {
    padding: 15px !important;
  }
  .lg\:p-5 {
    padding: 20px !important;
  }
  .lg\:p-6 {
    padding: 25px !important;
  }
  .lg\:p-7 {
    padding: 30px !important;
  }
  .lg\:p-8 {
    padding: 35px !important;
  }
  .lg\:p-9 {
    padding: 40px !important;
  }
  .lg\:p-10 {
    padding: 45px !important;
  }
  .lg\:pt-0 {
    padding-top: 0px !important;
  }
  .lg\:pt-1 {
    padding-top: 2.5px !important;
  }
  .lg\:pt-2 {
    padding-top: 5px !important;
  }
  .lg\:pt-3 {
    padding-top: 10px !important;
  }
  .lg\:pt-4 {
    padding-top: 15px !important;
  }
  .lg\:pt-5 {
    padding-top: 20px !important;
  }
  .lg\:pt-6 {
    padding-top: 25px !important;
  }
  .lg\:pt-7 {
    padding-top: 30px !important;
  }
  .lg\:pt-8 {
    padding-top: 35px !important;
  }
  .lg\:pt-9 {
    padding-top: 40px !important;
  }
  .lg\:pt-10 {
    padding-top: 45px !important;
  }
  .lg\:pr-0 {
    padding-right: 0px !important;
  }
  .lg\:pr-1 {
    padding-right: 2.5px !important;
  }
  .lg\:pr-2 {
    padding-right: 5px !important;
  }
  .lg\:pr-3 {
    padding-right: 10px !important;
  }
  .lg\:pr-4 {
    padding-right: 15px !important;
  }
  .lg\:pr-5 {
    padding-right: 20px !important;
  }
  .lg\:pr-6 {
    padding-right: 25px !important;
  }
  .lg\:pr-7 {
    padding-right: 30px !important;
  }
  .lg\:pr-8 {
    padding-right: 35px !important;
  }
  .lg\:pr-9 {
    padding-right: 40px !important;
  }
  .lg\:pr-10 {
    padding-right: 45px !important;
  }
  .lg\:pl-0 {
    padding-left: 0px !important;
  }
  .lg\:pl-1 {
    padding-left: 2.5px !important;
  }
  .lg\:pl-2 {
    padding-left: 5px !important;
  }
  .lg\:pl-3 {
    padding-left: 10px !important;
  }
  .lg\:pl-4 {
    padding-left: 15px !important;
  }
  .lg\:pl-5 {
    padding-left: 20px !important;
  }
  .lg\:pl-6 {
    padding-left: 25px !important;
  }
  .lg\:pl-7 {
    padding-left: 30px !important;
  }
  .lg\:pl-8 {
    padding-left: 35px !important;
  }
  .lg\:pl-9 {
    padding-left: 40px !important;
  }
  .lg\:pl-10 {
    padding-left: 45px !important;
  }
  .lg\:pb-0 {
    padding-bottom: 0px !important;
  }
  .lg\:pb-1 {
    padding-bottom: 2.5px !important;
  }
  .lg\:pb-2 {
    padding-bottom: 5px !important;
  }
  .lg\:pb-3 {
    padding-bottom: 10px !important;
  }
  .lg\:pb-4 {
    padding-bottom: 15px !important;
  }
  .lg\:pb-5 {
    padding-bottom: 20px !important;
  }
  .lg\:pb-6 {
    padding-bottom: 25px !important;
  }
  .lg\:pb-7 {
    padding-bottom: 30px !important;
  }
  .lg\:pb-8 {
    padding-bottom: 35px !important;
  }
  .lg\:pb-9 {
    padding-bottom: 40px !important;
  }
  .lg\:pb-10 {
    padding-bottom: 45px !important;
  }
  .lg\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .lg\:px-1 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;
  }
  .lg\:px-2 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .lg\:px-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .lg\:px-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .lg\:px-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .lg\:px-6 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .lg\:px-7 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .lg\:px-8 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .lg\:px-9 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .lg\:px-10 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .lg\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .lg\:py-1 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important;
  }
  .lg\:py-2 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .lg\:py-3 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .lg\:py-4 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .lg\:py-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .lg\:py-6 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .lg\:py-7 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .lg\:py-8 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .lg\:py-9 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .lg\:py-10 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:p-0 {
    padding: 0px !important;
  }
  .xl\:p-1 {
    padding: 2.5px !important;
  }
  .xl\:p-2 {
    padding: 5px !important;
  }
  .xl\:p-3 {
    padding: 10px !important;
  }
  .xl\:p-4 {
    padding: 15px !important;
  }
  .xl\:p-5 {
    padding: 20px !important;
  }
  .xl\:p-6 {
    padding: 25px !important;
  }
  .xl\:p-7 {
    padding: 30px !important;
  }
  .xl\:p-8 {
    padding: 35px !important;
  }
  .xl\:p-9 {
    padding: 40px !important;
  }
  .xl\:p-10 {
    padding: 45px !important;
  }
  .xl\:pt-0 {
    padding-top: 0px !important;
  }
  .xl\:pt-1 {
    padding-top: 2.5px !important;
  }
  .xl\:pt-2 {
    padding-top: 5px !important;
  }
  .xl\:pt-3 {
    padding-top: 10px !important;
  }
  .xl\:pt-4 {
    padding-top: 15px !important;
  }
  .xl\:pt-5 {
    padding-top: 20px !important;
  }
  .xl\:pt-6 {
    padding-top: 25px !important;
  }
  .xl\:pt-7 {
    padding-top: 30px !important;
  }
  .xl\:pt-8 {
    padding-top: 35px !important;
  }
  .xl\:pt-9 {
    padding-top: 40px !important;
  }
  .xl\:pt-10 {
    padding-top: 45px !important;
  }
  .xl\:pr-0 {
    padding-right: 0px !important;
  }
  .xl\:pr-1 {
    padding-right: 2.5px !important;
  }
  .xl\:pr-2 {
    padding-right: 5px !important;
  }
  .xl\:pr-3 {
    padding-right: 10px !important;
  }
  .xl\:pr-4 {
    padding-right: 15px !important;
  }
  .xl\:pr-5 {
    padding-right: 20px !important;
  }
  .xl\:pr-6 {
    padding-right: 25px !important;
  }
  .xl\:pr-7 {
    padding-right: 30px !important;
  }
  .xl\:pr-8 {
    padding-right: 35px !important;
  }
  .xl\:pr-9 {
    padding-right: 40px !important;
  }
  .xl\:pr-10 {
    padding-right: 45px !important;
  }
  .xl\:pl-0 {
    padding-left: 0px !important;
  }
  .xl\:pl-1 {
    padding-left: 2.5px !important;
  }
  .xl\:pl-2 {
    padding-left: 5px !important;
  }
  .xl\:pl-3 {
    padding-left: 10px !important;
  }
  .xl\:pl-4 {
    padding-left: 15px !important;
  }
  .xl\:pl-5 {
    padding-left: 20px !important;
  }
  .xl\:pl-6 {
    padding-left: 25px !important;
  }
  .xl\:pl-7 {
    padding-left: 30px !important;
  }
  .xl\:pl-8 {
    padding-left: 35px !important;
  }
  .xl\:pl-9 {
    padding-left: 40px !important;
  }
  .xl\:pl-10 {
    padding-left: 45px !important;
  }
  .xl\:pb-0 {
    padding-bottom: 0px !important;
  }
  .xl\:pb-1 {
    padding-bottom: 2.5px !important;
  }
  .xl\:pb-2 {
    padding-bottom: 5px !important;
  }
  .xl\:pb-3 {
    padding-bottom: 10px !important;
  }
  .xl\:pb-4 {
    padding-bottom: 15px !important;
  }
  .xl\:pb-5 {
    padding-bottom: 20px !important;
  }
  .xl\:pb-6 {
    padding-bottom: 25px !important;
  }
  .xl\:pb-7 {
    padding-bottom: 30px !important;
  }
  .xl\:pb-8 {
    padding-bottom: 35px !important;
  }
  .xl\:pb-9 {
    padding-bottom: 40px !important;
  }
  .xl\:pb-10 {
    padding-bottom: 45px !important;
  }
  .xl\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xl\:px-1 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;
  }
  .xl\:px-2 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xl\:px-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xl\:px-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xl\:px-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xl\:px-6 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xl\:px-7 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xl\:px-8 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xl\:px-9 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xl\:px-10 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xl\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xl\:py-1 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important;
  }
  .xl\:py-2 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xl\:py-3 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xl\:py-4 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xl\:py-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xl\:py-6 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xl\:py-7 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xl\:py-8 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xl\:py-9 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xl\:py-10 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:p-0 {
    padding: 0px !important;
  }
  .xxl\:p-1 {
    padding: 2.5px !important;
  }
  .xxl\:p-2 {
    padding: 5px !important;
  }
  .xxl\:p-3 {
    padding: 10px !important;
  }
  .xxl\:p-4 {
    padding: 15px !important;
  }
  .xxl\:p-5 {
    padding: 20px !important;
  }
  .xxl\:p-6 {
    padding: 25px !important;
  }
  .xxl\:p-7 {
    padding: 30px !important;
  }
  .xxl\:p-8 {
    padding: 35px !important;
  }
  .xxl\:p-9 {
    padding: 40px !important;
  }
  .xxl\:p-10 {
    padding: 45px !important;
  }
  .xxl\:pt-0 {
    padding-top: 0px !important;
  }
  .xxl\:pt-1 {
    padding-top: 2.5px !important;
  }
  .xxl\:pt-2 {
    padding-top: 5px !important;
  }
  .xxl\:pt-3 {
    padding-top: 10px !important;
  }
  .xxl\:pt-4 {
    padding-top: 15px !important;
  }
  .xxl\:pt-5 {
    padding-top: 20px !important;
  }
  .xxl\:pt-6 {
    padding-top: 25px !important;
  }
  .xxl\:pt-7 {
    padding-top: 30px !important;
  }
  .xxl\:pt-8 {
    padding-top: 35px !important;
  }
  .xxl\:pt-9 {
    padding-top: 40px !important;
  }
  .xxl\:pt-10 {
    padding-top: 45px !important;
  }
  .xxl\:pr-0 {
    padding-right: 0px !important;
  }
  .xxl\:pr-1 {
    padding-right: 2.5px !important;
  }
  .xxl\:pr-2 {
    padding-right: 5px !important;
  }
  .xxl\:pr-3 {
    padding-right: 10px !important;
  }
  .xxl\:pr-4 {
    padding-right: 15px !important;
  }
  .xxl\:pr-5 {
    padding-right: 20px !important;
  }
  .xxl\:pr-6 {
    padding-right: 25px !important;
  }
  .xxl\:pr-7 {
    padding-right: 30px !important;
  }
  .xxl\:pr-8 {
    padding-right: 35px !important;
  }
  .xxl\:pr-9 {
    padding-right: 40px !important;
  }
  .xxl\:pr-10 {
    padding-right: 45px !important;
  }
  .xxl\:pl-0 {
    padding-left: 0px !important;
  }
  .xxl\:pl-1 {
    padding-left: 2.5px !important;
  }
  .xxl\:pl-2 {
    padding-left: 5px !important;
  }
  .xxl\:pl-3 {
    padding-left: 10px !important;
  }
  .xxl\:pl-4 {
    padding-left: 15px !important;
  }
  .xxl\:pl-5 {
    padding-left: 20px !important;
  }
  .xxl\:pl-6 {
    padding-left: 25px !important;
  }
  .xxl\:pl-7 {
    padding-left: 30px !important;
  }
  .xxl\:pl-8 {
    padding-left: 35px !important;
  }
  .xxl\:pl-9 {
    padding-left: 40px !important;
  }
  .xxl\:pl-10 {
    padding-left: 45px !important;
  }
  .xxl\:pb-0 {
    padding-bottom: 0px !important;
  }
  .xxl\:pb-1 {
    padding-bottom: 2.5px !important;
  }
  .xxl\:pb-2 {
    padding-bottom: 5px !important;
  }
  .xxl\:pb-3 {
    padding-bottom: 10px !important;
  }
  .xxl\:pb-4 {
    padding-bottom: 15px !important;
  }
  .xxl\:pb-5 {
    padding-bottom: 20px !important;
  }
  .xxl\:pb-6 {
    padding-bottom: 25px !important;
  }
  .xxl\:pb-7 {
    padding-bottom: 30px !important;
  }
  .xxl\:pb-8 {
    padding-bottom: 35px !important;
  }
  .xxl\:pb-9 {
    padding-bottom: 40px !important;
  }
  .xxl\:pb-10 {
    padding-bottom: 45px !important;
  }
  .xxl\:px-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .xxl\:px-1 {
    padding-left: 2.5px !important;
    padding-right: 2.5px !important;
  }
  .xxl\:px-2 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .xxl\:px-3 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .xxl\:px-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .xxl\:px-5 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .xxl\:px-6 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .xxl\:px-7 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .xxl\:px-8 {
    padding-left: 35px !important;
    padding-right: 35px !important;
  }
  .xxl\:px-9 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .xxl\:px-10 {
    padding-left: 45px !important;
    padding-right: 45px !important;
  }
  .xxl\:py-0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .xxl\:py-1 {
    padding-top: 2.5px !important;
    padding-bottom: 2.5px !important;
  }
  .xxl\:py-2 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .xxl\:py-3 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .xxl\:py-4 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .xxl\:py-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .xxl\:py-6 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .xxl\:py-7 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .xxl\:py-8 {
    padding-top: 35px !important;
    padding-bottom: 35px !important;
  }
  .xxl\:py-9 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .xxl\:py-10 {
    padding-top: 45px !important;
    padding-bottom: 45px !important;
  }
}
.m-0 {
  margin: 0px !important;
}

.m-1 {
  margin: 2.5px !important;
}

.m-2 {
  margin: 5px !important;
}

.m-3 {
  margin: 10px !important;
}

.m-4 {
  margin: 15px !important;
}

.m-5 {
  margin: 20px !important;
}

.m-6 {
  margin: 25px !important;
}

.m-7 {
  margin: 30px !important;
}

.m-8 {
  margin: 35px !important;
}

.m-9 {
  margin: 40px !important;
}

.m-10 {
  margin: 45px !important;
}

.-m-1 {
  margin: -2.5px !important;
}

.-m-2 {
  margin: -5px !important;
}

.-m-3 {
  margin: -10px !important;
}

.-m-4 {
  margin: -15px !important;
}

.-m-5 {
  margin: -20px !important;
}

.-m-6 {
  margin: -25px !important;
}

.-m-7 {
  margin: -30px !important;
}

.-m-8 {
  margin: -35px !important;
}

.-m-9 {
  margin: -40px !important;
}

.-m-10 {
  margin: -45px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-1 {
  margin-top: 2.5px !important;
}

.mt-2 {
  margin-top: 5px !important;
}

.mt-3 {
  margin-top: 10px !important;
}

.mt-4 {
  margin-top: 15px !important;
}

.mt-5 {
  margin-top: 20px !important;
}

.mt-6 {
  margin-top: 25px !important;
}

.mt-7 {
  margin-top: 30px !important;
}

.mt-8 {
  margin-top: 35px !important;
}

.mt-9 {
  margin-top: 40px !important;
}

.mt-10 {
  margin-top: 45px !important;
}

.-mt-1 {
  margin-top: -2.5px !important;
}

.-mt-2 {
  margin-top: -5px !important;
}

.-mt-3 {
  margin-top: -10px !important;
}

.-mt-4 {
  margin-top: -15px !important;
}

.-mt-5 {
  margin-top: -20px !important;
}

.-mt-6 {
  margin-top: -25px !important;
}

.-mt-7 {
  margin-top: -30px !important;
}

.-mt-8 {
  margin-top: -35px !important;
}

.-mt-9 {
  margin-top: -40px !important;
}

.-mt-10 {
  margin-top: -45px !important;
}

.mt-auto {
  margin-top: auto !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mr-1 {
  margin-right: 2.5px !important;
}

.mr-2 {
  margin-right: 5px !important;
}

.mr-3 {
  margin-right: 10px !important;
}

.mr-4 {
  margin-right: 15px !important;
}

.mr-5 {
  margin-right: 20px !important;
}

.mr-6 {
  margin-right: 25px !important;
}

.mr-7 {
  margin-right: 30px !important;
}

.mr-8 {
  margin-right: 35px !important;
}

.mr-9 {
  margin-right: 40px !important;
}

.mr-10 {
  margin-right: 45px !important;
}

.-mr-1 {
  margin-right: -2.5px !important;
}

.-mr-2 {
  margin-right: -5px !important;
}

.-mr-3 {
  margin-right: -10px !important;
}

.-mr-4 {
  margin-right: -15px !important;
}

.-mr-5 {
  margin-right: -20px !important;
}

.-mr-6 {
  margin-right: -25px !important;
}

.-mr-7 {
  margin-right: -30px !important;
}

.-mr-8 {
  margin-right: -35px !important;
}

.-mr-9 {
  margin-right: -40px !important;
}

.-mr-10 {
  margin-right: -45px !important;
}

.mr-auto {
  margin-right: auto !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-1 {
  margin-left: 2.5px !important;
}

.ml-2 {
  margin-left: 5px !important;
}

.ml-3 {
  margin-left: 10px !important;
}

.ml-4 {
  margin-left: 15px !important;
}

.ml-5 {
  margin-left: 20px !important;
}

.ml-6 {
  margin-left: 25px !important;
}

.ml-7 {
  margin-left: 30px !important;
}

.ml-8 {
  margin-left: 35px !important;
}

.ml-9 {
  margin-left: 40px !important;
}

.ml-10 {
  margin-left: 45px !important;
}

.-ml-1 {
  margin-left: -2.5px !important;
}

.-ml-2 {
  margin-left: -5px !important;
}

.-ml-3 {
  margin-left: -10px !important;
}

.-ml-4 {
  margin-left: -15px !important;
}

.-ml-5 {
  margin-left: -20px !important;
}

.-ml-6 {
  margin-left: -25px !important;
}

.-ml-7 {
  margin-left: -30px !important;
}

.-ml-8 {
  margin-left: -35px !important;
}

.-ml-9 {
  margin-left: -40px !important;
}

.-ml-10 {
  margin-left: -45px !important;
}

.ml-auto {
  margin-left: auto !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-1 {
  margin-bottom: 2.5px !important;
}

.mb-2 {
  margin-bottom: 5px !important;
}

.mb-3 {
  margin-bottom: 10px !important;
}

.mb-4 {
  margin-bottom: 15px !important;
}

.mb-5 {
  margin-bottom: 20px !important;
}

.mb-6 {
  margin-bottom: 25px !important;
}

.mb-7 {
  margin-bottom: 30px !important;
}

.mb-8 {
  margin-bottom: 35px !important;
}

.mb-9 {
  margin-bottom: 40px !important;
}

.mb-10 {
  margin-bottom: 45px !important;
}

.-mb-1 {
  margin-bottom: -2.5px !important;
}

.-mb-2 {
  margin-bottom: -5px !important;
}

.-mb-3 {
  margin-bottom: -10px !important;
}

.-mb-4 {
  margin-bottom: -15px !important;
}

.-mb-5 {
  margin-bottom: -20px !important;
}

.-mb-6 {
  margin-bottom: -25px !important;
}

.-mb-7 {
  margin-bottom: -30px !important;
}

.-mb-8 {
  margin-bottom: -35px !important;
}

.-mb-9 {
  margin-bottom: -40px !important;
}

.-mb-10 {
  margin-bottom: -45px !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mx-1 {
  margin-left: 2.5px !important;
  margin-right: 2.5px !important;
}

.mx-2 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.mx-3 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.mx-4 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.mx-5 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.mx-6 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.mx-7 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}

.mx-8 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}

.mx-9 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}

.mx-10 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}

.-mx-1 {
  margin-left: -2.5px !important;
  margin-right: -2.5px !important;
}

.-mx-2 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}

.-mx-3 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}

.-mx-4 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.-mx-5 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}

.-mx-6 {
  margin-left: -25px !important;
  margin-right: -25px !important;
}

.-mx-7 {
  margin-left: -30px !important;
  margin-right: -30px !important;
}

.-mx-8 {
  margin-left: -35px !important;
  margin-right: -35px !important;
}

.-mx-9 {
  margin-left: -40px !important;
  margin-right: -40px !important;
}

.-mx-10 {
  margin-left: -45px !important;
  margin-right: -45px !important;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.my-1 {
  margin-top: 2.5px !important;
  margin-bottom: 2.5px !important;
}

.my-2 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.my-3 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.my-4 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.my-6 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.my-7 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.my-8 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}

.my-9 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}

.my-10 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}

.-my-1 {
  margin-top: -2.5px !important;
  margin-bottom: -2.5px !important;
}

.-my-2 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}

.-my-3 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}

.-my-4 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}

.-my-5 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}

.-my-6 {
  margin-top: -25px !important;
  margin-bottom: -25px !important;
}

.-my-7 {
  margin-top: -30px !important;
  margin-bottom: -30px !important;
}

.-my-8 {
  margin-top: -35px !important;
  margin-bottom: -35px !important;
}

.-my-9 {
  margin-top: -40px !important;
  margin-bottom: -40px !important;
}

.-my-10 {
  margin-top: -45px !important;
  margin-bottom: -45px !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

@media screen and (min-width: 480px) {
  .xs\:m-0 {
    margin: 0px !important;
  }
  .xs\:m-1 {
    margin: 2.5px !important;
  }
  .xs\:m-2 {
    margin: 5px !important;
  }
  .xs\:m-3 {
    margin: 10px !important;
  }
  .xs\:m-4 {
    margin: 15px !important;
  }
  .xs\:m-5 {
    margin: 20px !important;
  }
  .xs\:m-6 {
    margin: 25px !important;
  }
  .xs\:m-7 {
    margin: 30px !important;
  }
  .xs\:m-8 {
    margin: 35px !important;
  }
  .xs\:m-9 {
    margin: 40px !important;
  }
  .xs\:m-10 {
    margin: 45px !important;
  }
  .xs\:-m-1 {
    margin: -2.5px !important;
  }
  .xs\:-m-2 {
    margin: -5px !important;
  }
  .xs\:-m-3 {
    margin: -10px !important;
  }
  .xs\:-m-4 {
    margin: -15px !important;
  }
  .xs\:-m-5 {
    margin: -20px !important;
  }
  .xs\:-m-6 {
    margin: -25px !important;
  }
  .xs\:-m-7 {
    margin: -30px !important;
  }
  .xs\:-m-8 {
    margin: -35px !important;
  }
  .xs\:-m-9 {
    margin: -40px !important;
  }
  .xs\:-m-10 {
    margin: -45px !important;
  }
  .xs\:m-auto {
    margin: auto !important;
  }
  .xs\:mt-0 {
    margin-top: 0px !important;
  }
  .xs\:mt-1 {
    margin-top: 2.5px !important;
  }
  .xs\:mt-2 {
    margin-top: 5px !important;
  }
  .xs\:mt-3 {
    margin-top: 10px !important;
  }
  .xs\:mt-4 {
    margin-top: 15px !important;
  }
  .xs\:mt-5 {
    margin-top: 20px !important;
  }
  .xs\:mt-6 {
    margin-top: 25px !important;
  }
  .xs\:mt-7 {
    margin-top: 30px !important;
  }
  .xs\:mt-8 {
    margin-top: 35px !important;
  }
  .xs\:mt-9 {
    margin-top: 40px !important;
  }
  .xs\:mt-10 {
    margin-top: 45px !important;
  }
  .xs\:-mt-1 {
    margin-top: -2.5px !important;
  }
  .xs\:-mt-2 {
    margin-top: -5px !important;
  }
  .xs\:-mt-3 {
    margin-top: -10px !important;
  }
  .xs\:-mt-4 {
    margin-top: -15px !important;
  }
  .xs\:-mt-5 {
    margin-top: -20px !important;
  }
  .xs\:-mt-6 {
    margin-top: -25px !important;
  }
  .xs\:-mt-7 {
    margin-top: -30px !important;
  }
  .xs\:-mt-8 {
    margin-top: -35px !important;
  }
  .xs\:-mt-9 {
    margin-top: -40px !important;
  }
  .xs\:-mt-10 {
    margin-top: -45px !important;
  }
  .xs\:mt-auto {
    margin-top: auto !important;
  }
  .xs\:mr-0 {
    margin-right: 0px !important;
  }
  .xs\:mr-1 {
    margin-right: 2.5px !important;
  }
  .xs\:mr-2 {
    margin-right: 5px !important;
  }
  .xs\:mr-3 {
    margin-right: 10px !important;
  }
  .xs\:mr-4 {
    margin-right: 15px !important;
  }
  .xs\:mr-5 {
    margin-right: 20px !important;
  }
  .xs\:mr-6 {
    margin-right: 25px !important;
  }
  .xs\:mr-7 {
    margin-right: 30px !important;
  }
  .xs\:mr-8 {
    margin-right: 35px !important;
  }
  .xs\:mr-9 {
    margin-right: 40px !important;
  }
  .xs\:mr-10 {
    margin-right: 45px !important;
  }
  .xs\:-mr-1 {
    margin-right: -2.5px !important;
  }
  .xs\:-mr-2 {
    margin-right: -5px !important;
  }
  .xs\:-mr-3 {
    margin-right: -10px !important;
  }
  .xs\:-mr-4 {
    margin-right: -15px !important;
  }
  .xs\:-mr-5 {
    margin-right: -20px !important;
  }
  .xs\:-mr-6 {
    margin-right: -25px !important;
  }
  .xs\:-mr-7 {
    margin-right: -30px !important;
  }
  .xs\:-mr-8 {
    margin-right: -35px !important;
  }
  .xs\:-mr-9 {
    margin-right: -40px !important;
  }
  .xs\:-mr-10 {
    margin-right: -45px !important;
  }
  .xs\:mr-auto {
    margin-right: auto !important;
  }
  .xs\:ml-0 {
    margin-left: 0px !important;
  }
  .xs\:ml-1 {
    margin-left: 2.5px !important;
  }
  .xs\:ml-2 {
    margin-left: 5px !important;
  }
  .xs\:ml-3 {
    margin-left: 10px !important;
  }
  .xs\:ml-4 {
    margin-left: 15px !important;
  }
  .xs\:ml-5 {
    margin-left: 20px !important;
  }
  .xs\:ml-6 {
    margin-left: 25px !important;
  }
  .xs\:ml-7 {
    margin-left: 30px !important;
  }
  .xs\:ml-8 {
    margin-left: 35px !important;
  }
  .xs\:ml-9 {
    margin-left: 40px !important;
  }
  .xs\:ml-10 {
    margin-left: 45px !important;
  }
  .xs\:-ml-1 {
    margin-left: -2.5px !important;
  }
  .xs\:-ml-2 {
    margin-left: -5px !important;
  }
  .xs\:-ml-3 {
    margin-left: -10px !important;
  }
  .xs\:-ml-4 {
    margin-left: -15px !important;
  }
  .xs\:-ml-5 {
    margin-left: -20px !important;
  }
  .xs\:-ml-6 {
    margin-left: -25px !important;
  }
  .xs\:-ml-7 {
    margin-left: -30px !important;
  }
  .xs\:-ml-8 {
    margin-left: -35px !important;
  }
  .xs\:-ml-9 {
    margin-left: -40px !important;
  }
  .xs\:-ml-10 {
    margin-left: -45px !important;
  }
  .xs\:ml-auto {
    margin-left: auto !important;
  }
  .xs\:mb-0 {
    margin-bottom: 0px !important;
  }
  .xs\:mb-1 {
    margin-bottom: 2.5px !important;
  }
  .xs\:mb-2 {
    margin-bottom: 5px !important;
  }
  .xs\:mb-3 {
    margin-bottom: 10px !important;
  }
  .xs\:mb-4 {
    margin-bottom: 15px !important;
  }
  .xs\:mb-5 {
    margin-bottom: 20px !important;
  }
  .xs\:mb-6 {
    margin-bottom: 25px !important;
  }
  .xs\:mb-7 {
    margin-bottom: 30px !important;
  }
  .xs\:mb-8 {
    margin-bottom: 35px !important;
  }
  .xs\:mb-9 {
    margin-bottom: 40px !important;
  }
  .xs\:mb-10 {
    margin-bottom: 45px !important;
  }
  .xs\:-mb-1 {
    margin-bottom: -2.5px !important;
  }
  .xs\:-mb-2 {
    margin-bottom: -5px !important;
  }
  .xs\:-mb-3 {
    margin-bottom: -10px !important;
  }
  .xs\:-mb-4 {
    margin-bottom: -15px !important;
  }
  .xs\:-mb-5 {
    margin-bottom: -20px !important;
  }
  .xs\:-mb-6 {
    margin-bottom: -25px !important;
  }
  .xs\:-mb-7 {
    margin-bottom: -30px !important;
  }
  .xs\:-mb-8 {
    margin-bottom: -35px !important;
  }
  .xs\:-mb-9 {
    margin-bottom: -40px !important;
  }
  .xs\:-mb-10 {
    margin-bottom: -45px !important;
  }
  .xs\:mb-auto {
    margin-bottom: auto !important;
  }
  .xs\:mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .xs\:mx-1 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
  }
  .xs\:mx-2 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .xs\:mx-3 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .xs\:mx-4 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .xs\:mx-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .xs\:mx-6 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .xs\:mx-7 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .xs\:mx-8 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .xs\:mx-9 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .xs\:mx-10 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .xs\:-mx-1 {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important;
  }
  .xs\:-mx-2 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .xs\:-mx-3 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .xs\:-mx-4 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .xs\:-mx-5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .xs\:-mx-6 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .xs\:-mx-7 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .xs\:-mx-8 {
    margin-left: -35px !important;
    margin-right: -35px !important;
  }
  .xs\:-mx-9 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .xs\:-mx-10 {
    margin-left: -45px !important;
    margin-right: -45px !important;
  }
  .xs\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xs\:my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .xs\:my-1 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important;
  }
  .xs\:my-2 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .xs\:my-3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .xs\:my-4 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .xs\:my-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .xs\:my-6 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .xs\:my-7 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .xs\:my-8 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .xs\:my-9 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .xs\:my-10 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .xs\:-my-1 {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important;
  }
  .xs\:-my-2 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .xs\:-my-3 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .xs\:-my-4 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .xs\:-my-5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .xs\:-my-6 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .xs\:-my-7 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .xs\:-my-8 {
    margin-top: -35px !important;
    margin-bottom: -35px !important;
  }
  .xs\:-my-9 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .xs\:-my-10 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .xs\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:m-0 {
    margin: 0px !important;
  }
  .sm\:m-1 {
    margin: 2.5px !important;
  }
  .sm\:m-2 {
    margin: 5px !important;
  }
  .sm\:m-3 {
    margin: 10px !important;
  }
  .sm\:m-4 {
    margin: 15px !important;
  }
  .sm\:m-5 {
    margin: 20px !important;
  }
  .sm\:m-6 {
    margin: 25px !important;
  }
  .sm\:m-7 {
    margin: 30px !important;
  }
  .sm\:m-8 {
    margin: 35px !important;
  }
  .sm\:m-9 {
    margin: 40px !important;
  }
  .sm\:m-10 {
    margin: 45px !important;
  }
  .sm\:-m-1 {
    margin: -2.5px !important;
  }
  .sm\:-m-2 {
    margin: -5px !important;
  }
  .sm\:-m-3 {
    margin: -10px !important;
  }
  .sm\:-m-4 {
    margin: -15px !important;
  }
  .sm\:-m-5 {
    margin: -20px !important;
  }
  .sm\:-m-6 {
    margin: -25px !important;
  }
  .sm\:-m-7 {
    margin: -30px !important;
  }
  .sm\:-m-8 {
    margin: -35px !important;
  }
  .sm\:-m-9 {
    margin: -40px !important;
  }
  .sm\:-m-10 {
    margin: -45px !important;
  }
  .sm\:m-auto {
    margin: auto !important;
  }
  .sm\:mt-0 {
    margin-top: 0px !important;
  }
  .sm\:mt-1 {
    margin-top: 2.5px !important;
  }
  .sm\:mt-2 {
    margin-top: 5px !important;
  }
  .sm\:mt-3 {
    margin-top: 10px !important;
  }
  .sm\:mt-4 {
    margin-top: 15px !important;
  }
  .sm\:mt-5 {
    margin-top: 20px !important;
  }
  .sm\:mt-6 {
    margin-top: 25px !important;
  }
  .sm\:mt-7 {
    margin-top: 30px !important;
  }
  .sm\:mt-8 {
    margin-top: 35px !important;
  }
  .sm\:mt-9 {
    margin-top: 40px !important;
  }
  .sm\:mt-10 {
    margin-top: 45px !important;
  }
  .sm\:-mt-1 {
    margin-top: -2.5px !important;
  }
  .sm\:-mt-2 {
    margin-top: -5px !important;
  }
  .sm\:-mt-3 {
    margin-top: -10px !important;
  }
  .sm\:-mt-4 {
    margin-top: -15px !important;
  }
  .sm\:-mt-5 {
    margin-top: -20px !important;
  }
  .sm\:-mt-6 {
    margin-top: -25px !important;
  }
  .sm\:-mt-7 {
    margin-top: -30px !important;
  }
  .sm\:-mt-8 {
    margin-top: -35px !important;
  }
  .sm\:-mt-9 {
    margin-top: -40px !important;
  }
  .sm\:-mt-10 {
    margin-top: -45px !important;
  }
  .sm\:mt-auto {
    margin-top: auto !important;
  }
  .sm\:mr-0 {
    margin-right: 0px !important;
  }
  .sm\:mr-1 {
    margin-right: 2.5px !important;
  }
  .sm\:mr-2 {
    margin-right: 5px !important;
  }
  .sm\:mr-3 {
    margin-right: 10px !important;
  }
  .sm\:mr-4 {
    margin-right: 15px !important;
  }
  .sm\:mr-5 {
    margin-right: 20px !important;
  }
  .sm\:mr-6 {
    margin-right: 25px !important;
  }
  .sm\:mr-7 {
    margin-right: 30px !important;
  }
  .sm\:mr-8 {
    margin-right: 35px !important;
  }
  .sm\:mr-9 {
    margin-right: 40px !important;
  }
  .sm\:mr-10 {
    margin-right: 45px !important;
  }
  .sm\:-mr-1 {
    margin-right: -2.5px !important;
  }
  .sm\:-mr-2 {
    margin-right: -5px !important;
  }
  .sm\:-mr-3 {
    margin-right: -10px !important;
  }
  .sm\:-mr-4 {
    margin-right: -15px !important;
  }
  .sm\:-mr-5 {
    margin-right: -20px !important;
  }
  .sm\:-mr-6 {
    margin-right: -25px !important;
  }
  .sm\:-mr-7 {
    margin-right: -30px !important;
  }
  .sm\:-mr-8 {
    margin-right: -35px !important;
  }
  .sm\:-mr-9 {
    margin-right: -40px !important;
  }
  .sm\:-mr-10 {
    margin-right: -45px !important;
  }
  .sm\:mr-auto {
    margin-right: auto !important;
  }
  .sm\:ml-0 {
    margin-left: 0px !important;
  }
  .sm\:ml-1 {
    margin-left: 2.5px !important;
  }
  .sm\:ml-2 {
    margin-left: 5px !important;
  }
  .sm\:ml-3 {
    margin-left: 10px !important;
  }
  .sm\:ml-4 {
    margin-left: 15px !important;
  }
  .sm\:ml-5 {
    margin-left: 20px !important;
  }
  .sm\:ml-6 {
    margin-left: 25px !important;
  }
  .sm\:ml-7 {
    margin-left: 30px !important;
  }
  .sm\:ml-8 {
    margin-left: 35px !important;
  }
  .sm\:ml-9 {
    margin-left: 40px !important;
  }
  .sm\:ml-10 {
    margin-left: 45px !important;
  }
  .sm\:-ml-1 {
    margin-left: -2.5px !important;
  }
  .sm\:-ml-2 {
    margin-left: -5px !important;
  }
  .sm\:-ml-3 {
    margin-left: -10px !important;
  }
  .sm\:-ml-4 {
    margin-left: -15px !important;
  }
  .sm\:-ml-5 {
    margin-left: -20px !important;
  }
  .sm\:-ml-6 {
    margin-left: -25px !important;
  }
  .sm\:-ml-7 {
    margin-left: -30px !important;
  }
  .sm\:-ml-8 {
    margin-left: -35px !important;
  }
  .sm\:-ml-9 {
    margin-left: -40px !important;
  }
  .sm\:-ml-10 {
    margin-left: -45px !important;
  }
  .sm\:ml-auto {
    margin-left: auto !important;
  }
  .sm\:mb-0 {
    margin-bottom: 0px !important;
  }
  .sm\:mb-1 {
    margin-bottom: 2.5px !important;
  }
  .sm\:mb-2 {
    margin-bottom: 5px !important;
  }
  .sm\:mb-3 {
    margin-bottom: 10px !important;
  }
  .sm\:mb-4 {
    margin-bottom: 15px !important;
  }
  .sm\:mb-5 {
    margin-bottom: 20px !important;
  }
  .sm\:mb-6 {
    margin-bottom: 25px !important;
  }
  .sm\:mb-7 {
    margin-bottom: 30px !important;
  }
  .sm\:mb-8 {
    margin-bottom: 35px !important;
  }
  .sm\:mb-9 {
    margin-bottom: 40px !important;
  }
  .sm\:mb-10 {
    margin-bottom: 45px !important;
  }
  .sm\:-mb-1 {
    margin-bottom: -2.5px !important;
  }
  .sm\:-mb-2 {
    margin-bottom: -5px !important;
  }
  .sm\:-mb-3 {
    margin-bottom: -10px !important;
  }
  .sm\:-mb-4 {
    margin-bottom: -15px !important;
  }
  .sm\:-mb-5 {
    margin-bottom: -20px !important;
  }
  .sm\:-mb-6 {
    margin-bottom: -25px !important;
  }
  .sm\:-mb-7 {
    margin-bottom: -30px !important;
  }
  .sm\:-mb-8 {
    margin-bottom: -35px !important;
  }
  .sm\:-mb-9 {
    margin-bottom: -40px !important;
  }
  .sm\:-mb-10 {
    margin-bottom: -45px !important;
  }
  .sm\:mb-auto {
    margin-bottom: auto !important;
  }
  .sm\:mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .sm\:mx-1 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
  }
  .sm\:mx-2 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .sm\:mx-3 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .sm\:mx-4 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .sm\:mx-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .sm\:mx-6 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .sm\:mx-7 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .sm\:mx-8 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .sm\:mx-9 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .sm\:mx-10 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .sm\:-mx-1 {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important;
  }
  .sm\:-mx-2 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .sm\:-mx-3 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .sm\:-mx-4 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .sm\:-mx-5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .sm\:-mx-6 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .sm\:-mx-7 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .sm\:-mx-8 {
    margin-left: -35px !important;
    margin-right: -35px !important;
  }
  .sm\:-mx-9 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .sm\:-mx-10 {
    margin-left: -45px !important;
    margin-right: -45px !important;
  }
  .sm\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .sm\:my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .sm\:my-1 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important;
  }
  .sm\:my-2 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .sm\:my-3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .sm\:my-4 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .sm\:my-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .sm\:my-6 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .sm\:my-7 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .sm\:my-8 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .sm\:my-9 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .sm\:my-10 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .sm\:-my-1 {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important;
  }
  .sm\:-my-2 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .sm\:-my-3 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .sm\:-my-4 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .sm\:-my-5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .sm\:-my-6 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .sm\:-my-7 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .sm\:-my-8 {
    margin-top: -35px !important;
    margin-bottom: -35px !important;
  }
  .sm\:-my-9 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .sm\:-my-10 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .sm\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:m-0 {
    margin: 0px !important;
  }
  .md\:m-1 {
    margin: 2.5px !important;
  }
  .md\:m-2 {
    margin: 5px !important;
  }
  .md\:m-3 {
    margin: 10px !important;
  }
  .md\:m-4 {
    margin: 15px !important;
  }
  .md\:m-5 {
    margin: 20px !important;
  }
  .md\:m-6 {
    margin: 25px !important;
  }
  .md\:m-7 {
    margin: 30px !important;
  }
  .md\:m-8 {
    margin: 35px !important;
  }
  .md\:m-9 {
    margin: 40px !important;
  }
  .md\:m-10 {
    margin: 45px !important;
  }
  .md\:-m-1 {
    margin: -2.5px !important;
  }
  .md\:-m-2 {
    margin: -5px !important;
  }
  .md\:-m-3 {
    margin: -10px !important;
  }
  .md\:-m-4 {
    margin: -15px !important;
  }
  .md\:-m-5 {
    margin: -20px !important;
  }
  .md\:-m-6 {
    margin: -25px !important;
  }
  .md\:-m-7 {
    margin: -30px !important;
  }
  .md\:-m-8 {
    margin: -35px !important;
  }
  .md\:-m-9 {
    margin: -40px !important;
  }
  .md\:-m-10 {
    margin: -45px !important;
  }
  .md\:m-auto {
    margin: auto !important;
  }
  .md\:mt-0 {
    margin-top: 0px !important;
  }
  .md\:mt-1 {
    margin-top: 2.5px !important;
  }
  .md\:mt-2 {
    margin-top: 5px !important;
  }
  .md\:mt-3 {
    margin-top: 10px !important;
  }
  .md\:mt-4 {
    margin-top: 15px !important;
  }
  .md\:mt-5 {
    margin-top: 20px !important;
  }
  .md\:mt-6 {
    margin-top: 25px !important;
  }
  .md\:mt-7 {
    margin-top: 30px !important;
  }
  .md\:mt-8 {
    margin-top: 35px !important;
  }
  .md\:mt-9 {
    margin-top: 40px !important;
  }
  .md\:mt-10 {
    margin-top: 45px !important;
  }
  .md\:-mt-1 {
    margin-top: -2.5px !important;
  }
  .md\:-mt-2 {
    margin-top: -5px !important;
  }
  .md\:-mt-3 {
    margin-top: -10px !important;
  }
  .md\:-mt-4 {
    margin-top: -15px !important;
  }
  .md\:-mt-5 {
    margin-top: -20px !important;
  }
  .md\:-mt-6 {
    margin-top: -25px !important;
  }
  .md\:-mt-7 {
    margin-top: -30px !important;
  }
  .md\:-mt-8 {
    margin-top: -35px !important;
  }
  .md\:-mt-9 {
    margin-top: -40px !important;
  }
  .md\:-mt-10 {
    margin-top: -45px !important;
  }
  .md\:mt-auto {
    margin-top: auto !important;
  }
  .md\:mr-0 {
    margin-right: 0px !important;
  }
  .md\:mr-1 {
    margin-right: 2.5px !important;
  }
  .md\:mr-2 {
    margin-right: 5px !important;
  }
  .md\:mr-3 {
    margin-right: 10px !important;
  }
  .md\:mr-4 {
    margin-right: 15px !important;
  }
  .md\:mr-5 {
    margin-right: 20px !important;
  }
  .md\:mr-6 {
    margin-right: 25px !important;
  }
  .md\:mr-7 {
    margin-right: 30px !important;
  }
  .md\:mr-8 {
    margin-right: 35px !important;
  }
  .md\:mr-9 {
    margin-right: 40px !important;
  }
  .md\:mr-10 {
    margin-right: 45px !important;
  }
  .md\:-mr-1 {
    margin-right: -2.5px !important;
  }
  .md\:-mr-2 {
    margin-right: -5px !important;
  }
  .md\:-mr-3 {
    margin-right: -10px !important;
  }
  .md\:-mr-4 {
    margin-right: -15px !important;
  }
  .md\:-mr-5 {
    margin-right: -20px !important;
  }
  .md\:-mr-6 {
    margin-right: -25px !important;
  }
  .md\:-mr-7 {
    margin-right: -30px !important;
  }
  .md\:-mr-8 {
    margin-right: -35px !important;
  }
  .md\:-mr-9 {
    margin-right: -40px !important;
  }
  .md\:-mr-10 {
    margin-right: -45px !important;
  }
  .md\:mr-auto {
    margin-right: auto !important;
  }
  .md\:ml-0 {
    margin-left: 0px !important;
  }
  .md\:ml-1 {
    margin-left: 2.5px !important;
  }
  .md\:ml-2 {
    margin-left: 5px !important;
  }
  .md\:ml-3 {
    margin-left: 10px !important;
  }
  .md\:ml-4 {
    margin-left: 15px !important;
  }
  .md\:ml-5 {
    margin-left: 20px !important;
  }
  .md\:ml-6 {
    margin-left: 25px !important;
  }
  .md\:ml-7 {
    margin-left: 30px !important;
  }
  .md\:ml-8 {
    margin-left: 35px !important;
  }
  .md\:ml-9 {
    margin-left: 40px !important;
  }
  .md\:ml-10 {
    margin-left: 45px !important;
  }
  .md\:-ml-1 {
    margin-left: -2.5px !important;
  }
  .md\:-ml-2 {
    margin-left: -5px !important;
  }
  .md\:-ml-3 {
    margin-left: -10px !important;
  }
  .md\:-ml-4 {
    margin-left: -15px !important;
  }
  .md\:-ml-5 {
    margin-left: -20px !important;
  }
  .md\:-ml-6 {
    margin-left: -25px !important;
  }
  .md\:-ml-7 {
    margin-left: -30px !important;
  }
  .md\:-ml-8 {
    margin-left: -35px !important;
  }
  .md\:-ml-9 {
    margin-left: -40px !important;
  }
  .md\:-ml-10 {
    margin-left: -45px !important;
  }
  .md\:ml-auto {
    margin-left: auto !important;
  }
  .md\:mb-0 {
    margin-bottom: 0px !important;
  }
  .md\:mb-1 {
    margin-bottom: 2.5px !important;
  }
  .md\:mb-2 {
    margin-bottom: 5px !important;
  }
  .md\:mb-3 {
    margin-bottom: 10px !important;
  }
  .md\:mb-4 {
    margin-bottom: 15px !important;
  }
  .md\:mb-5 {
    margin-bottom: 20px !important;
  }
  .md\:mb-6 {
    margin-bottom: 25px !important;
  }
  .md\:mb-7 {
    margin-bottom: 30px !important;
  }
  .md\:mb-8 {
    margin-bottom: 35px !important;
  }
  .md\:mb-9 {
    margin-bottom: 40px !important;
  }
  .md\:mb-10 {
    margin-bottom: 45px !important;
  }
  .md\:-mb-1 {
    margin-bottom: -2.5px !important;
  }
  .md\:-mb-2 {
    margin-bottom: -5px !important;
  }
  .md\:-mb-3 {
    margin-bottom: -10px !important;
  }
  .md\:-mb-4 {
    margin-bottom: -15px !important;
  }
  .md\:-mb-5 {
    margin-bottom: -20px !important;
  }
  .md\:-mb-6 {
    margin-bottom: -25px !important;
  }
  .md\:-mb-7 {
    margin-bottom: -30px !important;
  }
  .md\:-mb-8 {
    margin-bottom: -35px !important;
  }
  .md\:-mb-9 {
    margin-bottom: -40px !important;
  }
  .md\:-mb-10 {
    margin-bottom: -45px !important;
  }
  .md\:mb-auto {
    margin-bottom: auto !important;
  }
  .md\:mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .md\:mx-1 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
  }
  .md\:mx-2 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .md\:mx-3 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .md\:mx-4 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .md\:mx-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .md\:mx-6 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .md\:mx-7 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .md\:mx-8 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .md\:mx-9 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .md\:mx-10 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .md\:-mx-1 {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important;
  }
  .md\:-mx-2 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .md\:-mx-3 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .md\:-mx-4 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .md\:-mx-5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .md\:-mx-6 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .md\:-mx-7 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .md\:-mx-8 {
    margin-left: -35px !important;
    margin-right: -35px !important;
  }
  .md\:-mx-9 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .md\:-mx-10 {
    margin-left: -45px !important;
    margin-right: -45px !important;
  }
  .md\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .md\:my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .md\:my-1 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important;
  }
  .md\:my-2 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .md\:my-3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .md\:my-4 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .md\:my-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .md\:my-6 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .md\:my-7 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .md\:my-8 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .md\:my-9 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .md\:my-10 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .md\:-my-1 {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important;
  }
  .md\:-my-2 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .md\:-my-3 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .md\:-my-4 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .md\:-my-5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .md\:-my-6 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .md\:-my-7 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .md\:-my-8 {
    margin-top: -35px !important;
    margin-bottom: -35px !important;
  }
  .md\:-my-9 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .md\:-my-10 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .md\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:m-0 {
    margin: 0px !important;
  }
  .lg\:m-1 {
    margin: 2.5px !important;
  }
  .lg\:m-2 {
    margin: 5px !important;
  }
  .lg\:m-3 {
    margin: 10px !important;
  }
  .lg\:m-4 {
    margin: 15px !important;
  }
  .lg\:m-5 {
    margin: 20px !important;
  }
  .lg\:m-6 {
    margin: 25px !important;
  }
  .lg\:m-7 {
    margin: 30px !important;
  }
  .lg\:m-8 {
    margin: 35px !important;
  }
  .lg\:m-9 {
    margin: 40px !important;
  }
  .lg\:m-10 {
    margin: 45px !important;
  }
  .lg\:-m-1 {
    margin: -2.5px !important;
  }
  .lg\:-m-2 {
    margin: -5px !important;
  }
  .lg\:-m-3 {
    margin: -10px !important;
  }
  .lg\:-m-4 {
    margin: -15px !important;
  }
  .lg\:-m-5 {
    margin: -20px !important;
  }
  .lg\:-m-6 {
    margin: -25px !important;
  }
  .lg\:-m-7 {
    margin: -30px !important;
  }
  .lg\:-m-8 {
    margin: -35px !important;
  }
  .lg\:-m-9 {
    margin: -40px !important;
  }
  .lg\:-m-10 {
    margin: -45px !important;
  }
  .lg\:m-auto {
    margin: auto !important;
  }
  .lg\:mt-0 {
    margin-top: 0px !important;
  }
  .lg\:mt-1 {
    margin-top: 2.5px !important;
  }
  .lg\:mt-2 {
    margin-top: 5px !important;
  }
  .lg\:mt-3 {
    margin-top: 10px !important;
  }
  .lg\:mt-4 {
    margin-top: 15px !important;
  }
  .lg\:mt-5 {
    margin-top: 20px !important;
  }
  .lg\:mt-6 {
    margin-top: 25px !important;
  }
  .lg\:mt-7 {
    margin-top: 30px !important;
  }
  .lg\:mt-8 {
    margin-top: 35px !important;
  }
  .lg\:mt-9 {
    margin-top: 40px !important;
  }
  .lg\:mt-10 {
    margin-top: 45px !important;
  }
  .lg\:-mt-1 {
    margin-top: -2.5px !important;
  }
  .lg\:-mt-2 {
    margin-top: -5px !important;
  }
  .lg\:-mt-3 {
    margin-top: -10px !important;
  }
  .lg\:-mt-4 {
    margin-top: -15px !important;
  }
  .lg\:-mt-5 {
    margin-top: -20px !important;
  }
  .lg\:-mt-6 {
    margin-top: -25px !important;
  }
  .lg\:-mt-7 {
    margin-top: -30px !important;
  }
  .lg\:-mt-8 {
    margin-top: -35px !important;
  }
  .lg\:-mt-9 {
    margin-top: -40px !important;
  }
  .lg\:-mt-10 {
    margin-top: -45px !important;
  }
  .lg\:mt-auto {
    margin-top: auto !important;
  }
  .lg\:mr-0 {
    margin-right: 0px !important;
  }
  .lg\:mr-1 {
    margin-right: 2.5px !important;
  }
  .lg\:mr-2 {
    margin-right: 5px !important;
  }
  .lg\:mr-3 {
    margin-right: 10px !important;
  }
  .lg\:mr-4 {
    margin-right: 15px !important;
  }
  .lg\:mr-5 {
    margin-right: 20px !important;
  }
  .lg\:mr-6 {
    margin-right: 25px !important;
  }
  .lg\:mr-7 {
    margin-right: 30px !important;
  }
  .lg\:mr-8 {
    margin-right: 35px !important;
  }
  .lg\:mr-9 {
    margin-right: 40px !important;
  }
  .lg\:mr-10 {
    margin-right: 45px !important;
  }
  .lg\:-mr-1 {
    margin-right: -2.5px !important;
  }
  .lg\:-mr-2 {
    margin-right: -5px !important;
  }
  .lg\:-mr-3 {
    margin-right: -10px !important;
  }
  .lg\:-mr-4 {
    margin-right: -15px !important;
  }
  .lg\:-mr-5 {
    margin-right: -20px !important;
  }
  .lg\:-mr-6 {
    margin-right: -25px !important;
  }
  .lg\:-mr-7 {
    margin-right: -30px !important;
  }
  .lg\:-mr-8 {
    margin-right: -35px !important;
  }
  .lg\:-mr-9 {
    margin-right: -40px !important;
  }
  .lg\:-mr-10 {
    margin-right: -45px !important;
  }
  .lg\:mr-auto {
    margin-right: auto !important;
  }
  .lg\:ml-0 {
    margin-left: 0px !important;
  }
  .lg\:ml-1 {
    margin-left: 2.5px !important;
  }
  .lg\:ml-2 {
    margin-left: 5px !important;
  }
  .lg\:ml-3 {
    margin-left: 10px !important;
  }
  .lg\:ml-4 {
    margin-left: 15px !important;
  }
  .lg\:ml-5 {
    margin-left: 20px !important;
  }
  .lg\:ml-6 {
    margin-left: 25px !important;
  }
  .lg\:ml-7 {
    margin-left: 30px !important;
  }
  .lg\:ml-8 {
    margin-left: 35px !important;
  }
  .lg\:ml-9 {
    margin-left: 40px !important;
  }
  .lg\:ml-10 {
    margin-left: 45px !important;
  }
  .lg\:-ml-1 {
    margin-left: -2.5px !important;
  }
  .lg\:-ml-2 {
    margin-left: -5px !important;
  }
  .lg\:-ml-3 {
    margin-left: -10px !important;
  }
  .lg\:-ml-4 {
    margin-left: -15px !important;
  }
  .lg\:-ml-5 {
    margin-left: -20px !important;
  }
  .lg\:-ml-6 {
    margin-left: -25px !important;
  }
  .lg\:-ml-7 {
    margin-left: -30px !important;
  }
  .lg\:-ml-8 {
    margin-left: -35px !important;
  }
  .lg\:-ml-9 {
    margin-left: -40px !important;
  }
  .lg\:-ml-10 {
    margin-left: -45px !important;
  }
  .lg\:ml-auto {
    margin-left: auto !important;
  }
  .lg\:mb-0 {
    margin-bottom: 0px !important;
  }
  .lg\:mb-1 {
    margin-bottom: 2.5px !important;
  }
  .lg\:mb-2 {
    margin-bottom: 5px !important;
  }
  .lg\:mb-3 {
    margin-bottom: 10px !important;
  }
  .lg\:mb-4 {
    margin-bottom: 15px !important;
  }
  .lg\:mb-5 {
    margin-bottom: 20px !important;
  }
  .lg\:mb-6 {
    margin-bottom: 25px !important;
  }
  .lg\:mb-7 {
    margin-bottom: 30px !important;
  }
  .lg\:mb-8 {
    margin-bottom: 35px !important;
  }
  .lg\:mb-9 {
    margin-bottom: 40px !important;
  }
  .lg\:mb-10 {
    margin-bottom: 45px !important;
  }
  .lg\:-mb-1 {
    margin-bottom: -2.5px !important;
  }
  .lg\:-mb-2 {
    margin-bottom: -5px !important;
  }
  .lg\:-mb-3 {
    margin-bottom: -10px !important;
  }
  .lg\:-mb-4 {
    margin-bottom: -15px !important;
  }
  .lg\:-mb-5 {
    margin-bottom: -20px !important;
  }
  .lg\:-mb-6 {
    margin-bottom: -25px !important;
  }
  .lg\:-mb-7 {
    margin-bottom: -30px !important;
  }
  .lg\:-mb-8 {
    margin-bottom: -35px !important;
  }
  .lg\:-mb-9 {
    margin-bottom: -40px !important;
  }
  .lg\:-mb-10 {
    margin-bottom: -45px !important;
  }
  .lg\:mb-auto {
    margin-bottom: auto !important;
  }
  .lg\:mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .lg\:mx-1 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
  }
  .lg\:mx-2 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .lg\:mx-3 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .lg\:mx-4 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .lg\:mx-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .lg\:mx-6 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .lg\:mx-7 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .lg\:mx-8 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .lg\:mx-9 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .lg\:mx-10 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .lg\:-mx-1 {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important;
  }
  .lg\:-mx-2 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .lg\:-mx-3 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .lg\:-mx-4 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .lg\:-mx-5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .lg\:-mx-6 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .lg\:-mx-7 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .lg\:-mx-8 {
    margin-left: -35px !important;
    margin-right: -35px !important;
  }
  .lg\:-mx-9 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .lg\:-mx-10 {
    margin-left: -45px !important;
    margin-right: -45px !important;
  }
  .lg\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .lg\:my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .lg\:my-1 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important;
  }
  .lg\:my-2 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .lg\:my-3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .lg\:my-4 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .lg\:my-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .lg\:my-6 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .lg\:my-7 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .lg\:my-8 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .lg\:my-9 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .lg\:my-10 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .lg\:-my-1 {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important;
  }
  .lg\:-my-2 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .lg\:-my-3 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .lg\:-my-4 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .lg\:-my-5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .lg\:-my-6 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .lg\:-my-7 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .lg\:-my-8 {
    margin-top: -35px !important;
    margin-bottom: -35px !important;
  }
  .lg\:-my-9 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .lg\:-my-10 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .lg\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:m-0 {
    margin: 0px !important;
  }
  .xl\:m-1 {
    margin: 2.5px !important;
  }
  .xl\:m-2 {
    margin: 5px !important;
  }
  .xl\:m-3 {
    margin: 10px !important;
  }
  .xl\:m-4 {
    margin: 15px !important;
  }
  .xl\:m-5 {
    margin: 20px !important;
  }
  .xl\:m-6 {
    margin: 25px !important;
  }
  .xl\:m-7 {
    margin: 30px !important;
  }
  .xl\:m-8 {
    margin: 35px !important;
  }
  .xl\:m-9 {
    margin: 40px !important;
  }
  .xl\:m-10 {
    margin: 45px !important;
  }
  .xl\:-m-1 {
    margin: -2.5px !important;
  }
  .xl\:-m-2 {
    margin: -5px !important;
  }
  .xl\:-m-3 {
    margin: -10px !important;
  }
  .xl\:-m-4 {
    margin: -15px !important;
  }
  .xl\:-m-5 {
    margin: -20px !important;
  }
  .xl\:-m-6 {
    margin: -25px !important;
  }
  .xl\:-m-7 {
    margin: -30px !important;
  }
  .xl\:-m-8 {
    margin: -35px !important;
  }
  .xl\:-m-9 {
    margin: -40px !important;
  }
  .xl\:-m-10 {
    margin: -45px !important;
  }
  .xl\:m-auto {
    margin: auto !important;
  }
  .xl\:mt-0 {
    margin-top: 0px !important;
  }
  .xl\:mt-1 {
    margin-top: 2.5px !important;
  }
  .xl\:mt-2 {
    margin-top: 5px !important;
  }
  .xl\:mt-3 {
    margin-top: 10px !important;
  }
  .xl\:mt-4 {
    margin-top: 15px !important;
  }
  .xl\:mt-5 {
    margin-top: 20px !important;
  }
  .xl\:mt-6 {
    margin-top: 25px !important;
  }
  .xl\:mt-7 {
    margin-top: 30px !important;
  }
  .xl\:mt-8 {
    margin-top: 35px !important;
  }
  .xl\:mt-9 {
    margin-top: 40px !important;
  }
  .xl\:mt-10 {
    margin-top: 45px !important;
  }
  .xl\:-mt-1 {
    margin-top: -2.5px !important;
  }
  .xl\:-mt-2 {
    margin-top: -5px !important;
  }
  .xl\:-mt-3 {
    margin-top: -10px !important;
  }
  .xl\:-mt-4 {
    margin-top: -15px !important;
  }
  .xl\:-mt-5 {
    margin-top: -20px !important;
  }
  .xl\:-mt-6 {
    margin-top: -25px !important;
  }
  .xl\:-mt-7 {
    margin-top: -30px !important;
  }
  .xl\:-mt-8 {
    margin-top: -35px !important;
  }
  .xl\:-mt-9 {
    margin-top: -40px !important;
  }
  .xl\:-mt-10 {
    margin-top: -45px !important;
  }
  .xl\:mt-auto {
    margin-top: auto !important;
  }
  .xl\:mr-0 {
    margin-right: 0px !important;
  }
  .xl\:mr-1 {
    margin-right: 2.5px !important;
  }
  .xl\:mr-2 {
    margin-right: 5px !important;
  }
  .xl\:mr-3 {
    margin-right: 10px !important;
  }
  .xl\:mr-4 {
    margin-right: 15px !important;
  }
  .xl\:mr-5 {
    margin-right: 20px !important;
  }
  .xl\:mr-6 {
    margin-right: 25px !important;
  }
  .xl\:mr-7 {
    margin-right: 30px !important;
  }
  .xl\:mr-8 {
    margin-right: 35px !important;
  }
  .xl\:mr-9 {
    margin-right: 40px !important;
  }
  .xl\:mr-10 {
    margin-right: 45px !important;
  }
  .xl\:-mr-1 {
    margin-right: -2.5px !important;
  }
  .xl\:-mr-2 {
    margin-right: -5px !important;
  }
  .xl\:-mr-3 {
    margin-right: -10px !important;
  }
  .xl\:-mr-4 {
    margin-right: -15px !important;
  }
  .xl\:-mr-5 {
    margin-right: -20px !important;
  }
  .xl\:-mr-6 {
    margin-right: -25px !important;
  }
  .xl\:-mr-7 {
    margin-right: -30px !important;
  }
  .xl\:-mr-8 {
    margin-right: -35px !important;
  }
  .xl\:-mr-9 {
    margin-right: -40px !important;
  }
  .xl\:-mr-10 {
    margin-right: -45px !important;
  }
  .xl\:mr-auto {
    margin-right: auto !important;
  }
  .xl\:ml-0 {
    margin-left: 0px !important;
  }
  .xl\:ml-1 {
    margin-left: 2.5px !important;
  }
  .xl\:ml-2 {
    margin-left: 5px !important;
  }
  .xl\:ml-3 {
    margin-left: 10px !important;
  }
  .xl\:ml-4 {
    margin-left: 15px !important;
  }
  .xl\:ml-5 {
    margin-left: 20px !important;
  }
  .xl\:ml-6 {
    margin-left: 25px !important;
  }
  .xl\:ml-7 {
    margin-left: 30px !important;
  }
  .xl\:ml-8 {
    margin-left: 35px !important;
  }
  .xl\:ml-9 {
    margin-left: 40px !important;
  }
  .xl\:ml-10 {
    margin-left: 45px !important;
  }
  .xl\:-ml-1 {
    margin-left: -2.5px !important;
  }
  .xl\:-ml-2 {
    margin-left: -5px !important;
  }
  .xl\:-ml-3 {
    margin-left: -10px !important;
  }
  .xl\:-ml-4 {
    margin-left: -15px !important;
  }
  .xl\:-ml-5 {
    margin-left: -20px !important;
  }
  .xl\:-ml-6 {
    margin-left: -25px !important;
  }
  .xl\:-ml-7 {
    margin-left: -30px !important;
  }
  .xl\:-ml-8 {
    margin-left: -35px !important;
  }
  .xl\:-ml-9 {
    margin-left: -40px !important;
  }
  .xl\:-ml-10 {
    margin-left: -45px !important;
  }
  .xl\:ml-auto {
    margin-left: auto !important;
  }
  .xl\:mb-0 {
    margin-bottom: 0px !important;
  }
  .xl\:mb-1 {
    margin-bottom: 2.5px !important;
  }
  .xl\:mb-2 {
    margin-bottom: 5px !important;
  }
  .xl\:mb-3 {
    margin-bottom: 10px !important;
  }
  .xl\:mb-4 {
    margin-bottom: 15px !important;
  }
  .xl\:mb-5 {
    margin-bottom: 20px !important;
  }
  .xl\:mb-6 {
    margin-bottom: 25px !important;
  }
  .xl\:mb-7 {
    margin-bottom: 30px !important;
  }
  .xl\:mb-8 {
    margin-bottom: 35px !important;
  }
  .xl\:mb-9 {
    margin-bottom: 40px !important;
  }
  .xl\:mb-10 {
    margin-bottom: 45px !important;
  }
  .xl\:-mb-1 {
    margin-bottom: -2.5px !important;
  }
  .xl\:-mb-2 {
    margin-bottom: -5px !important;
  }
  .xl\:-mb-3 {
    margin-bottom: -10px !important;
  }
  .xl\:-mb-4 {
    margin-bottom: -15px !important;
  }
  .xl\:-mb-5 {
    margin-bottom: -20px !important;
  }
  .xl\:-mb-6 {
    margin-bottom: -25px !important;
  }
  .xl\:-mb-7 {
    margin-bottom: -30px !important;
  }
  .xl\:-mb-8 {
    margin-bottom: -35px !important;
  }
  .xl\:-mb-9 {
    margin-bottom: -40px !important;
  }
  .xl\:-mb-10 {
    margin-bottom: -45px !important;
  }
  .xl\:mb-auto {
    margin-bottom: auto !important;
  }
  .xl\:mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .xl\:mx-1 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
  }
  .xl\:mx-2 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .xl\:mx-3 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .xl\:mx-4 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .xl\:mx-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .xl\:mx-6 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .xl\:mx-7 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .xl\:mx-8 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .xl\:mx-9 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .xl\:mx-10 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .xl\:-mx-1 {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important;
  }
  .xl\:-mx-2 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .xl\:-mx-3 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .xl\:-mx-4 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .xl\:-mx-5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .xl\:-mx-6 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .xl\:-mx-7 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .xl\:-mx-8 {
    margin-left: -35px !important;
    margin-right: -35px !important;
  }
  .xl\:-mx-9 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .xl\:-mx-10 {
    margin-left: -45px !important;
    margin-right: -45px !important;
  }
  .xl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xl\:my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .xl\:my-1 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important;
  }
  .xl\:my-2 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .xl\:my-3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .xl\:my-4 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .xl\:my-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .xl\:my-6 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .xl\:my-7 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .xl\:my-8 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .xl\:my-9 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .xl\:my-10 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .xl\:-my-1 {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important;
  }
  .xl\:-my-2 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .xl\:-my-3 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .xl\:-my-4 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .xl\:-my-5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .xl\:-my-6 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .xl\:-my-7 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .xl\:-my-8 {
    margin-top: -35px !important;
    margin-bottom: -35px !important;
  }
  .xl\:-my-9 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .xl\:-my-10 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .xl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:m-0 {
    margin: 0px !important;
  }
  .xxl\:m-1 {
    margin: 2.5px !important;
  }
  .xxl\:m-2 {
    margin: 5px !important;
  }
  .xxl\:m-3 {
    margin: 10px !important;
  }
  .xxl\:m-4 {
    margin: 15px !important;
  }
  .xxl\:m-5 {
    margin: 20px !important;
  }
  .xxl\:m-6 {
    margin: 25px !important;
  }
  .xxl\:m-7 {
    margin: 30px !important;
  }
  .xxl\:m-8 {
    margin: 35px !important;
  }
  .xxl\:m-9 {
    margin: 40px !important;
  }
  .xxl\:m-10 {
    margin: 45px !important;
  }
  .xxl\:-m-1 {
    margin: -2.5px !important;
  }
  .xxl\:-m-2 {
    margin: -5px !important;
  }
  .xxl\:-m-3 {
    margin: -10px !important;
  }
  .xxl\:-m-4 {
    margin: -15px !important;
  }
  .xxl\:-m-5 {
    margin: -20px !important;
  }
  .xxl\:-m-6 {
    margin: -25px !important;
  }
  .xxl\:-m-7 {
    margin: -30px !important;
  }
  .xxl\:-m-8 {
    margin: -35px !important;
  }
  .xxl\:-m-9 {
    margin: -40px !important;
  }
  .xxl\:-m-10 {
    margin: -45px !important;
  }
  .xxl\:m-auto {
    margin: auto !important;
  }
  .xxl\:mt-0 {
    margin-top: 0px !important;
  }
  .xxl\:mt-1 {
    margin-top: 2.5px !important;
  }
  .xxl\:mt-2 {
    margin-top: 5px !important;
  }
  .xxl\:mt-3 {
    margin-top: 10px !important;
  }
  .xxl\:mt-4 {
    margin-top: 15px !important;
  }
  .xxl\:mt-5 {
    margin-top: 20px !important;
  }
  .xxl\:mt-6 {
    margin-top: 25px !important;
  }
  .xxl\:mt-7 {
    margin-top: 30px !important;
  }
  .xxl\:mt-8 {
    margin-top: 35px !important;
  }
  .xxl\:mt-9 {
    margin-top: 40px !important;
  }
  .xxl\:mt-10 {
    margin-top: 45px !important;
  }
  .xxl\:-mt-1 {
    margin-top: -2.5px !important;
  }
  .xxl\:-mt-2 {
    margin-top: -5px !important;
  }
  .xxl\:-mt-3 {
    margin-top: -10px !important;
  }
  .xxl\:-mt-4 {
    margin-top: -15px !important;
  }
  .xxl\:-mt-5 {
    margin-top: -20px !important;
  }
  .xxl\:-mt-6 {
    margin-top: -25px !important;
  }
  .xxl\:-mt-7 {
    margin-top: -30px !important;
  }
  .xxl\:-mt-8 {
    margin-top: -35px !important;
  }
  .xxl\:-mt-9 {
    margin-top: -40px !important;
  }
  .xxl\:-mt-10 {
    margin-top: -45px !important;
  }
  .xxl\:mt-auto {
    margin-top: auto !important;
  }
  .xxl\:mr-0 {
    margin-right: 0px !important;
  }
  .xxl\:mr-1 {
    margin-right: 2.5px !important;
  }
  .xxl\:mr-2 {
    margin-right: 5px !important;
  }
  .xxl\:mr-3 {
    margin-right: 10px !important;
  }
  .xxl\:mr-4 {
    margin-right: 15px !important;
  }
  .xxl\:mr-5 {
    margin-right: 20px !important;
  }
  .xxl\:mr-6 {
    margin-right: 25px !important;
  }
  .xxl\:mr-7 {
    margin-right: 30px !important;
  }
  .xxl\:mr-8 {
    margin-right: 35px !important;
  }
  .xxl\:mr-9 {
    margin-right: 40px !important;
  }
  .xxl\:mr-10 {
    margin-right: 45px !important;
  }
  .xxl\:-mr-1 {
    margin-right: -2.5px !important;
  }
  .xxl\:-mr-2 {
    margin-right: -5px !important;
  }
  .xxl\:-mr-3 {
    margin-right: -10px !important;
  }
  .xxl\:-mr-4 {
    margin-right: -15px !important;
  }
  .xxl\:-mr-5 {
    margin-right: -20px !important;
  }
  .xxl\:-mr-6 {
    margin-right: -25px !important;
  }
  .xxl\:-mr-7 {
    margin-right: -30px !important;
  }
  .xxl\:-mr-8 {
    margin-right: -35px !important;
  }
  .xxl\:-mr-9 {
    margin-right: -40px !important;
  }
  .xxl\:-mr-10 {
    margin-right: -45px !important;
  }
  .xxl\:mr-auto {
    margin-right: auto !important;
  }
  .xxl\:ml-0 {
    margin-left: 0px !important;
  }
  .xxl\:ml-1 {
    margin-left: 2.5px !important;
  }
  .xxl\:ml-2 {
    margin-left: 5px !important;
  }
  .xxl\:ml-3 {
    margin-left: 10px !important;
  }
  .xxl\:ml-4 {
    margin-left: 15px !important;
  }
  .xxl\:ml-5 {
    margin-left: 20px !important;
  }
  .xxl\:ml-6 {
    margin-left: 25px !important;
  }
  .xxl\:ml-7 {
    margin-left: 30px !important;
  }
  .xxl\:ml-8 {
    margin-left: 35px !important;
  }
  .xxl\:ml-9 {
    margin-left: 40px !important;
  }
  .xxl\:ml-10 {
    margin-left: 45px !important;
  }
  .xxl\:-ml-1 {
    margin-left: -2.5px !important;
  }
  .xxl\:-ml-2 {
    margin-left: -5px !important;
  }
  .xxl\:-ml-3 {
    margin-left: -10px !important;
  }
  .xxl\:-ml-4 {
    margin-left: -15px !important;
  }
  .xxl\:-ml-5 {
    margin-left: -20px !important;
  }
  .xxl\:-ml-6 {
    margin-left: -25px !important;
  }
  .xxl\:-ml-7 {
    margin-left: -30px !important;
  }
  .xxl\:-ml-8 {
    margin-left: -35px !important;
  }
  .xxl\:-ml-9 {
    margin-left: -40px !important;
  }
  .xxl\:-ml-10 {
    margin-left: -45px !important;
  }
  .xxl\:ml-auto {
    margin-left: auto !important;
  }
  .xxl\:mb-0 {
    margin-bottom: 0px !important;
  }
  .xxl\:mb-1 {
    margin-bottom: 2.5px !important;
  }
  .xxl\:mb-2 {
    margin-bottom: 5px !important;
  }
  .xxl\:mb-3 {
    margin-bottom: 10px !important;
  }
  .xxl\:mb-4 {
    margin-bottom: 15px !important;
  }
  .xxl\:mb-5 {
    margin-bottom: 20px !important;
  }
  .xxl\:mb-6 {
    margin-bottom: 25px !important;
  }
  .xxl\:mb-7 {
    margin-bottom: 30px !important;
  }
  .xxl\:mb-8 {
    margin-bottom: 35px !important;
  }
  .xxl\:mb-9 {
    margin-bottom: 40px !important;
  }
  .xxl\:mb-10 {
    margin-bottom: 45px !important;
  }
  .xxl\:-mb-1 {
    margin-bottom: -2.5px !important;
  }
  .xxl\:-mb-2 {
    margin-bottom: -5px !important;
  }
  .xxl\:-mb-3 {
    margin-bottom: -10px !important;
  }
  .xxl\:-mb-4 {
    margin-bottom: -15px !important;
  }
  .xxl\:-mb-5 {
    margin-bottom: -20px !important;
  }
  .xxl\:-mb-6 {
    margin-bottom: -25px !important;
  }
  .xxl\:-mb-7 {
    margin-bottom: -30px !important;
  }
  .xxl\:-mb-8 {
    margin-bottom: -35px !important;
  }
  .xxl\:-mb-9 {
    margin-bottom: -40px !important;
  }
  .xxl\:-mb-10 {
    margin-bottom: -45px !important;
  }
  .xxl\:mb-auto {
    margin-bottom: auto !important;
  }
  .xxl\:mx-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .xxl\:mx-1 {
    margin-left: 2.5px !important;
    margin-right: 2.5px !important;
  }
  .xxl\:mx-2 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .xxl\:mx-3 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .xxl\:mx-4 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .xxl\:mx-5 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .xxl\:mx-6 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .xxl\:mx-7 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .xxl\:mx-8 {
    margin-left: 35px !important;
    margin-right: 35px !important;
  }
  .xxl\:mx-9 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .xxl\:mx-10 {
    margin-left: 45px !important;
    margin-right: 45px !important;
  }
  .xxl\:-mx-1 {
    margin-left: -2.5px !important;
    margin-right: -2.5px !important;
  }
  .xxl\:-mx-2 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .xxl\:-mx-3 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .xxl\:-mx-4 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .xxl\:-mx-5 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .xxl\:-mx-6 {
    margin-left: -25px !important;
    margin-right: -25px !important;
  }
  .xxl\:-mx-7 {
    margin-left: -30px !important;
    margin-right: -30px !important;
  }
  .xxl\:-mx-8 {
    margin-left: -35px !important;
    margin-right: -35px !important;
  }
  .xxl\:-mx-9 {
    margin-left: -40px !important;
    margin-right: -40px !important;
  }
  .xxl\:-mx-10 {
    margin-left: -45px !important;
    margin-right: -45px !important;
  }
  .xxl\:mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .xxl\:my-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .xxl\:my-1 {
    margin-top: 2.5px !important;
    margin-bottom: 2.5px !important;
  }
  .xxl\:my-2 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .xxl\:my-3 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .xxl\:my-4 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .xxl\:my-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .xxl\:my-6 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .xxl\:my-7 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .xxl\:my-8 {
    margin-top: 35px !important;
    margin-bottom: 35px !important;
  }
  .xxl\:my-9 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .xxl\:my-10 {
    margin-top: 45px !important;
    margin-bottom: 45px !important;
  }
  .xxl\:-my-1 {
    margin-top: -2.5px !important;
    margin-bottom: -2.5px !important;
  }
  .xxl\:-my-2 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .xxl\:-my-3 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .xxl\:-my-4 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .xxl\:-my-5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .xxl\:-my-6 {
    margin-top: -25px !important;
    margin-bottom: -25px !important;
  }
  .xxl\:-my-7 {
    margin-top: -30px !important;
    margin-bottom: -30px !important;
  }
  .xxl\:-my-8 {
    margin-top: -35px !important;
    margin-bottom: -35px !important;
  }
  .xxl\:-my-9 {
    margin-top: -40px !important;
    margin-bottom: -40px !important;
  }
  .xxl\:-my-10 {
    margin-top: -45px !important;
    margin-bottom: -45px !important;
  }
  .xxl\:my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
.shadow-none {
  box-shadow: none !important;
}

.shadow-1 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.shadow-2 {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.shadow-3 {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.shadow-4 {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.shadow-5 {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.shadow-6 {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.shadow-7 {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.shadow-8 {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-none:focus {
  box-shadow: none !important;
}

.hover\:shadow-none:hover {
  box-shadow: none !important;
}

.active\:shadow-none:active {
  box-shadow: none !important;
}

.focus\:shadow-1:focus {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.hover\:shadow-1:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.active\:shadow-1:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
}

.focus\:shadow-2:focus {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.hover\:shadow-2:hover {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.active\:shadow-2:active {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
}

.focus\:shadow-3:focus {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.hover\:shadow-3:hover {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.active\:shadow-3:active {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
}

.focus\:shadow-4:focus {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-4:hover {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-4:active {
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-5:focus {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-5:hover {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-5:active {
  box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
}

.focus\:shadow-6:focus {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.hover\:shadow-6:hover {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.active\:shadow-6:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
}

.focus\:shadow-7:focus {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.hover\:shadow-7:hover {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.active\:shadow-7:active {
  box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
}

.focus\:shadow-8:focus {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.hover\:shadow-8:hover {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

.active\:shadow-8:active {
  box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
}

@media screen and (min-width: 480px) {
  .xs\:shadow-none {
    box-shadow: none !important;
  }
  .xs\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xs\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xs\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xs\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xs\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xs\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .xs\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .xs\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .xs\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xs\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xs\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xs\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xs\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xs\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xs\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xs\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xs\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xs\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xs\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xs\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xs\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xs\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xs\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xs\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xs\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:shadow-none {
    box-shadow: none !important;
  }
  .sm\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .sm\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .sm\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .sm\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .sm\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .sm\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .sm\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .sm\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .sm\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .sm\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:shadow-none {
    box-shadow: none !important;
  }
  .md\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .md\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .md\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .md\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .md\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .md\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .md\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .md\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .md\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .md\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:shadow-none {
    box-shadow: none !important;
  }
  .lg\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .lg\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .lg\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .lg\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .lg\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .lg\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .lg\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .lg\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .lg\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .lg\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:shadow-none {
    box-shadow: none !important;
  }
  .xl\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .xl\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .xl\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .xl\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xl\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xl\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xl\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xl\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xl\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xl\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:shadow-none {
    box-shadow: none !important;
  }
  .xxl\:shadow-1 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xxl\:shadow-2 {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xxl\:shadow-3 {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xxl\:shadow-4 {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:shadow-5 {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:shadow-6 {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xxl\:shadow-7 {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xxl\:shadow-8 {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:focus\:shadow-none:focus {
    box-shadow: none !important;
  }
  .xxl\:hover\:shadow-none:hover {
    box-shadow: none !important;
  }
  .xxl\:active\:shadow-none:active {
    box-shadow: none !important;
  }
  .xxl\:focus\:shadow-1:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xxl\:hover\:shadow-1:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xxl\:active\:shadow-1:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.05), 0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  }
  .xxl\:focus\:shadow-2:focus {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xxl\:hover\:shadow-2:hover {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xxl\:active\:shadow-2:active {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
  }
  .xxl\:focus\:shadow-3:focus {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xxl\:hover\:shadow-3:hover {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xxl\:active\:shadow-3:active {
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1) !important;
  }
  .xxl\:focus\:shadow-4:focus {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:hover\:shadow-4:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:active\:shadow-4:active {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:focus\:shadow-5:focus {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:hover\:shadow-5:hover {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:active\:shadow-5:active {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.1), 0px 4px 5px -2px rgba(0, 0, 0, 0.12), 0px 10px 15px -5px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:focus\:shadow-6:focus {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xxl\:hover\:shadow-6:hover {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xxl\:active\:shadow-6:active {
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.06), 0px 7px 9px rgba(0, 0, 0, 0.12), 0px 20px 25px -8px rgba(0, 0, 0, 0.18) !important;
  }
  .xxl\:focus\:shadow-7:focus {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xxl\:hover\:shadow-7:hover {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xxl\:active\:shadow-7:active {
    box-shadow: 0px 7px 30px rgba(0, 0, 0, 0.08), 0px 22px 30px 2px rgba(0, 0, 0, 0.15), 0px 8px 10px rgba(0, 0, 0, 0.15) !important;
  }
  .xxl\:focus\:shadow-8:focus {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:hover\:shadow-8:hover {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
  .xxl\:active\:shadow-8:active {
    box-shadow: 0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2) !important;
  }
}
.border-none {
  border-width: 0px !important;
  border-style: none;
}

.border-1 {
  border-width: 1px !important;
  border-style: solid;
}

.border-2 {
  border-width: 2px !important;
  border-style: solid;
}

.border-3 {
  border-width: 3px !important;
  border-style: solid;
}

.border-top-none {
  border-top-width: 0px !important;
  border-top-style: none;
}

.border-top-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
}

.border-top-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
}

.border-top-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
}

.border-right-none {
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-right-1 {
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-right-2 {
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-right-3 {
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-left-none {
  border-left-width: 0px !important;
  border-left-style: none;
}

.border-left-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
}

.border-left-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
}

.border-left-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
}

.border-bottom-none {
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-bottom-1 {
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-bottom-2 {
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-bottom-3 {
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

.border-x-none {
  border-left-width: 0px !important;
  border-left-style: none;
  border-right-width: 0px !important;
  border-right-style: none;
}

.border-x-1 {
  border-left-width: 1px !important;
  border-left-style: solid;
  border-right-width: 1px !important;
  border-right-style: solid;
}

.border-x-2 {
  border-left-width: 2px !important;
  border-left-style: solid;
  border-right-width: 2px !important;
  border-right-style: solid;
}

.border-x-3 {
  border-left-width: 3px !important;
  border-left-style: solid;
  border-right-width: 3px !important;
  border-right-style: solid;
}

.border-y-none {
  border-top-width: 0px !important;
  border-top-style: none;
  border-bottom-width: 0px !important;
  border-bottom-style: none;
}

.border-y-1 {
  border-top-width: 1px !important;
  border-top-style: solid;
  border-bottom-width: 1px !important;
  border-bottom-style: solid;
}

.border-y-2 {
  border-top-width: 2px !important;
  border-top-style: solid;
  border-bottom-width: 2px !important;
  border-bottom-style: solid;
}

.border-y-3 {
  border-top-width: 3px !important;
  border-top-style: solid;
  border-bottom-width: 3px !important;
  border-bottom-style: solid;
}

@media screen and (min-width: 480px) {
  .xs\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .xs\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .xs\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .xs\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .xs\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .xs\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .xs\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .xs\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .xs\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xs\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xs\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xs\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xs\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .xs\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .xs\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .xs\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .xs\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xs\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xs\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xs\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .xs\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xs\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xs\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xs\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xs\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xs\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xs\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xs\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 576px) {
  .sm\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .sm\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .sm\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .sm\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .sm\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .sm\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .sm\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .sm\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .sm\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .sm\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .sm\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .sm\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .sm\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .sm\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .sm\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .sm\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .sm\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .sm\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .sm\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .sm\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .md\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .md\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .md\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .md\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .md\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .md\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .md\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .md\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .md\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .md\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .md\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .md\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .md\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .md\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .md\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .md\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .md\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .md\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .md\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .lg\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .lg\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .lg\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .lg\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .lg\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .lg\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .lg\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .lg\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .lg\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .lg\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .lg\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .lg\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .lg\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .lg\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .lg\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .lg\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .lg\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .lg\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .lg\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .xl\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .xl\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .xl\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .xl\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .xl\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .xl\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .xl\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .xl\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .xl\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .xl\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .xl\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .xl\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .xl\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xl\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xl\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xl\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xl\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xl\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xl\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:border-none {
    border-width: 0px !important;
    border-style: none;
  }
  .xxl\:border-1 {
    border-width: 1px !important;
    border-style: solid;
  }
  .xxl\:border-2 {
    border-width: 2px !important;
    border-style: solid;
  }
  .xxl\:border-3 {
    border-width: 3px !important;
    border-style: solid;
  }
  .xxl\:border-top-none {
    border-top-width: 0px !important;
    border-top-style: none;
  }
  .xxl\:border-top-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
  }
  .xxl\:border-top-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
  }
  .xxl\:border-top-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
  }
  .xxl\:border-right-none {
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xxl\:border-right-1 {
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xxl\:border-right-2 {
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xxl\:border-right-3 {
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xxl\:border-left-none {
    border-left-width: 0px !important;
    border-left-style: none;
  }
  .xxl\:border-left-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
  }
  .xxl\:border-left-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
  }
  .xxl\:border-left-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
  }
  .xxl\:border-bottom-none {
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xxl\:border-bottom-1 {
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xxl\:border-bottom-2 {
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xxl\:border-bottom-3 {
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
  .xxl\:border-x-none {
    border-left-width: 0px !important;
    border-left-style: none;
    border-right-width: 0px !important;
    border-right-style: none;
  }
  .xxl\:border-x-1 {
    border-left-width: 1px !important;
    border-left-style: solid;
    border-right-width: 1px !important;
    border-right-style: solid;
  }
  .xxl\:border-x-2 {
    border-left-width: 2px !important;
    border-left-style: solid;
    border-right-width: 2px !important;
    border-right-style: solid;
  }
  .xxl\:border-x-3 {
    border-left-width: 3px !important;
    border-left-style: solid;
    border-right-width: 3px !important;
    border-right-style: solid;
  }
  .xxl\:border-y-none {
    border-top-width: 0px !important;
    border-top-style: none;
    border-bottom-width: 0px !important;
    border-bottom-style: none;
  }
  .xxl\:border-y-1 {
    border-top-width: 1px !important;
    border-top-style: solid;
    border-bottom-width: 1px !important;
    border-bottom-style: solid;
  }
  .xxl\:border-y-2 {
    border-top-width: 2px !important;
    border-top-style: solid;
    border-bottom-width: 2px !important;
    border-bottom-style: solid;
  }
  .xxl\:border-y-3 {
    border-top-width: 3px !important;
    border-top-style: solid;
    border-bottom-width: 3px !important;
    border-bottom-style: solid;
  }
}
.border-solid {
  border-style: solid !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-double {
  border-style: double !important;
}

@media screen and (min-width: 480px) {
  .xs\:border-solid {
    border-style: solid !important;
  }
  .xs\:border-dashed {
    border-style: dashed !important;
  }
  .xs\:border-dotted {
    border-style: dotted !important;
  }
  .xs\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:border-solid {
    border-style: solid !important;
  }
  .sm\:border-dashed {
    border-style: dashed !important;
  }
  .sm\:border-dotted {
    border-style: dotted !important;
  }
  .sm\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-solid {
    border-style: solid !important;
  }
  .md\:border-dashed {
    border-style: dashed !important;
  }
  .md\:border-dotted {
    border-style: dotted !important;
  }
  .md\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-solid {
    border-style: solid !important;
  }
  .lg\:border-dashed {
    border-style: dashed !important;
  }
  .lg\:border-dotted {
    border-style: dotted !important;
  }
  .lg\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-solid {
    border-style: solid !important;
  }
  .xl\:border-dashed {
    border-style: dashed !important;
  }
  .xl\:border-dotted {
    border-style: dotted !important;
  }
  .xl\:border-double {
    border-style: double !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:border-solid {
    border-style: solid !important;
  }
  .xxl\:border-dashed {
    border-style: dashed !important;
  }
  .xxl\:border-dotted {
    border-style: dotted !important;
  }
  .xxl\:border-double {
    border-style: double !important;
  }
}
.border-noround {
  border-radius: 0 !important;
}

.border-round {
  border-radius: var(--border-radius) !important;
}

.border-round-xs {
  border-radius: 0.125rem !important;
}

.border-round-sm {
  border-radius: 0.25rem !important;
}

.border-round-md {
  border-radius: 0.375rem !important;
}

.border-round-lg {
  border-radius: 0.5rem !important;
}

.border-round-xl {
  border-radius: 0.75rem !important;
}

.border-round-2xl {
  border-radius: 1rem !important;
}

.border-round-3xl {
  border-radius: 1.5rem !important;
}

.border-circle {
  border-radius: 50% !important;
}

@media screen and (min-width: 480px) {
  .xs\:border-noround {
    border-radius: 0 !important;
  }
  .xs\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .xs\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .xs\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .xs\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .xs\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .xs\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .xs\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .xs\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .xs\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:border-noround {
    border-radius: 0 !important;
  }
  .sm\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .sm\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .sm\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .sm\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .sm\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .sm\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .sm\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .sm\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .sm\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-noround {
    border-radius: 0 !important;
  }
  .md\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .md\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .md\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .md\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .md\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .md\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .md\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .md\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .md\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-noround {
    border-radius: 0 !important;
  }
  .lg\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .lg\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .lg\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .lg\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .lg\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .lg\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .lg\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .lg\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .lg\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-noround {
    border-radius: 0 !important;
  }
  .xl\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .xl\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .xl\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .xl\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .xl\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .xl\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .xl\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .xl\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .xl\:border-circle {
    border-radius: 50% !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:border-noround {
    border-radius: 0 !important;
  }
  .xxl\:border-round {
    border-radius: var(--border-radius) !important;
  }
  .xxl\:border-round-xs {
    border-radius: 0.125rem !important;
  }
  .xxl\:border-round-sm {
    border-radius: 0.25rem !important;
  }
  .xxl\:border-round-md {
    border-radius: 0.375rem !important;
  }
  .xxl\:border-round-lg {
    border-radius: 0.5rem !important;
  }
  .xxl\:border-round-xl {
    border-radius: 0.75rem !important;
  }
  .xxl\:border-round-2xl {
    border-radius: 1rem !important;
  }
  .xxl\:border-round-3xl {
    border-radius: 1.5rem !important;
  }
  .xxl\:border-circle {
    border-radius: 50% !important;
  }
}
.border-noround-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.border-noround-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.border-noround-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-noround-bottom {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.border-round-left {
  border-top-left-radius: var(--border-radius) !important;
  border-bottom-left-radius: var(--border-radius) !important;
}

.border-round-top {
  border-top-left-radius: var(--border-radius) !important;
  border-top-right-radius: var(--border-radius) !important;
}

.border-round-right {
  border-top-right-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-bottom {
  border-bottom-left-radius: var(--border-radius) !important;
  border-bottom-right-radius: var(--border-radius) !important;
}

.border-round-left-xs {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.border-round-top-xs {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.border-round-right-xs {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.border-round-bottom-xs {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.border-round-left-sm {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.border-round-top-sm {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.border-round-right-sm {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-round-bottom-sm {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.border-round-left-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.border-round-top-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.border-round-right-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-round-bottom-md {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.border-round-left-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.border-round-top-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.border-round-right-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.border-round-bottom-lg {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.border-round-left-xl {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.border-round-top-xl {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.border-round-right-xl {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.border-round-bottom-xl {
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.border-round-left-2xl {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.border-round-top-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.border-round-right-2xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-bottom-2xl {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.border-round-left-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.border-round-top-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.border-round-right-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-round-bottom-3xl {
  border-bottom-left-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.border-circle-left {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.border-circle-top {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.border-circle-right {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.border-circle-bottom {
  border-bottom-left-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

@media screen and (min-width: 480px) {
  .xs\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .xs\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .xs\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xs\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xs\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .xs\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .xs\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xs\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xs\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .xs\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .xs\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xs\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xs\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .xs\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .xs\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xs\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xs\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .xs\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .xs\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xs\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xs\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .xs\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .xs\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xs\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xs\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .xs\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .xs\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xs\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xs\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .xs\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .xs\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xs\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xs\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .xs\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .xs\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xs\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xs\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .xs\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .xs\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .xs\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .sm\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .sm\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .sm\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .sm\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .sm\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .sm\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .sm\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .sm\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .sm\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .sm\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .sm\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .sm\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .sm\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .sm\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .sm\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .sm\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .sm\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .sm\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .sm\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .sm\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .sm\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .sm\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .sm\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .sm\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .sm\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .sm\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .sm\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .sm\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .sm\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .sm\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .sm\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .sm\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .sm\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .sm\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .sm\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .sm\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .sm\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .md\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .md\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .md\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .md\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .md\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .md\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .md\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .md\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .md\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .md\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .md\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .md\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .md\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .md\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .md\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .md\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .md\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .md\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .md\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .md\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .md\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .md\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .md\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .md\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .md\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .md\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .md\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .md\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .md\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .md\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .md\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .md\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .md\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .md\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .md\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .md\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .md\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .lg\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .lg\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .lg\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .lg\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .lg\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .lg\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .lg\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .lg\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .lg\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .lg\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .lg\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .lg\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .lg\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .lg\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .lg\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .lg\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .lg\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .lg\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .lg\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .lg\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .lg\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .lg\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .lg\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .lg\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .lg\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .lg\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .lg\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .lg\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .lg\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .lg\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .lg\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .lg\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .lg\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .lg\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .lg\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .lg\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .lg\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .xl\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .xl\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xl\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xl\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .xl\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xl\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .xl\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .xl\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xl\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xl\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .xl\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .xl\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xl\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xl\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .xl\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .xl\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xl\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xl\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .xl\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .xl\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xl\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xl\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .xl\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .xl\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xl\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xl\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .xl\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .xl\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xl\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xl\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .xl\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .xl\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xl\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xl\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .xl\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .xl\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .xl\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:border-noround-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  .xxl\:border-noround-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
  .xxl\:border-noround-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xxl\:border-noround-bottom {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  .xxl\:border-round-left {
    border-top-left-radius: var(--border-radius) !important;
    border-bottom-left-radius: var(--border-radius) !important;
  }
  .xxl\:border-round-top {
    border-top-left-radius: var(--border-radius) !important;
    border-top-right-radius: var(--border-radius) !important;
  }
  .xxl\:border-round-right {
    border-top-right-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xxl\:border-round-bottom {
    border-bottom-left-radius: var(--border-radius) !important;
    border-bottom-right-radius: var(--border-radius) !important;
  }
  .xxl\:border-round-left-xs {
    border-top-left-radius: 0.125rem !important;
    border-bottom-left-radius: 0.125rem !important;
  }
  .xxl\:border-round-top-xs {
    border-top-left-radius: 0.125rem !important;
    border-top-right-radius: 0.125rem !important;
  }
  .xxl\:border-round-right-xs {
    border-top-right-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xxl\:border-round-bottom-xs {
    border-bottom-left-radius: 0.125rem !important;
    border-bottom-right-radius: 0.125rem !important;
  }
  .xxl\:border-round-left-sm {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .xxl\:border-round-top-sm {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
  }
  .xxl\:border-round-right-sm {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xxl\:border-round-bottom-sm {
    border-bottom-left-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .xxl\:border-round-left-md {
    border-top-left-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
  }
  .xxl\:border-round-top-md {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
  }
  .xxl\:border-round-right-md {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xxl\:border-round-bottom-md {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
  }
  .xxl\:border-round-left-lg {
    border-top-left-radius: 0.5rem !important;
    border-bottom-left-radius: 0.5rem !important;
  }
  .xxl\:border-round-top-lg {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
  }
  .xxl\:border-round-right-lg {
    border-top-right-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xxl\:border-round-bottom-lg {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
  }
  .xxl\:border-round-left-xl {
    border-top-left-radius: 0.75rem !important;
    border-bottom-left-radius: 0.75rem !important;
  }
  .xxl\:border-round-top-xl {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
  }
  .xxl\:border-round-right-xl {
    border-top-right-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xxl\:border-round-bottom-xl {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
  }
  .xxl\:border-round-left-2xl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
  }
  .xxl\:border-round-top-2xl {
    border-top-left-radius: 1rem !important;
    border-top-right-radius: 1rem !important;
  }
  .xxl\:border-round-right-2xl {
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xxl\:border-round-bottom-2xl {
    border-bottom-left-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
  .xxl\:border-round-left-3xl {
    border-top-left-radius: 1.5rem !important;
    border-bottom-left-radius: 1.5rem !important;
  }
  .xxl\:border-round-top-3xl {
    border-top-left-radius: 1.5rem !important;
    border-top-right-radius: 1.5rem !important;
  }
  .xxl\:border-round-right-3xl {
    border-top-right-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xxl\:border-round-bottom-3xl {
    border-bottom-left-radius: 1.5rem !important;
    border-bottom-right-radius: 1.5rem !important;
  }
  .xxl\:border-circle-left {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }
  .xxl\:border-circle-top {
    border-top-left-radius: 50% !important;
    border-top-right-radius: 50% !important;
  }
  .xxl\:border-circle-right {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
  .xxl\:border-circle-bottom {
    border-bottom-left-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }
}
.w-full {
  width: 100% !important;
}

.w-screen {
  width: 100vw !important;
}

.w-auto {
  width: auto !important;
}

.w-1 {
  width: 8.3333% !important;
}

.w-2 {
  width: 16.6667% !important;
}

.w-3 {
  width: 25% !important;
}

.w-4 {
  width: 33.3333% !important;
}

.w-5 {
  width: 41.6667% !important;
}

.w-6 {
  width: 50% !important;
}

.w-7 {
  width: 58.3333% !important;
}

.w-8 {
  width: 66.6667% !important;
}

.w-9 {
  width: 75% !important;
}

.w-10 {
  width: 83.3333% !important;
}

.w-11 {
  width: 91.6667% !important;
}

.w-12 {
  width: 100% !important;
}

.w-min {
  width: min-content !important;
}

.w-max {
  width: max-content !important;
}

.w-fit {
  width: fit-content !important;
}

.w-1rem {
  width: 1rem !important;
}

.w-2rem {
  width: 2rem !important;
}

.w-3rem {
  width: 3rem !important;
}

.w-4rem {
  width: 4rem !important;
}

.w-5rem {
  width: 5rem !important;
}

.w-6rem {
  width: 6rem !important;
}

.w-7rem {
  width: 7rem !important;
}

.w-8rem {
  width: 8rem !important;
}

.w-9rem {
  width: 9rem !important;
}

.w-10rem {
  width: 10rem !important;
}

.w-11rem {
  width: 11rem !important;
}

.w-12rem {
  width: 12rem !important;
}

.w-13rem {
  width: 13rem !important;
}

.w-14rem {
  width: 14rem !important;
}

.w-15rem {
  width: 15rem !important;
}

.w-16rem {
  width: 16rem !important;
}

.w-17rem {
  width: 17rem !important;
}

.w-18rem {
  width: 18rem !important;
}

.w-19rem {
  width: 19rem !important;
}

.w-20rem {
  width: 20rem !important;
}

.w-21rem {
  width: 21rem !important;
}

.w-22rem {
  width: 22rem !important;
}

.w-23rem {
  width: 23rem !important;
}

.w-24rem {
  width: 24rem !important;
}

.w-25rem {
  width: 25rem !important;
}

.w-26rem {
  width: 26rem !important;
}

.w-27rem {
  width: 27rem !important;
}

.w-28rem {
  width: 28rem !important;
}

.w-29rem {
  width: 29rem !important;
}

.w-30rem {
  width: 30rem !important;
}

@media screen and (min-width: 480px) {
  .xs\:w-full {
    width: 100% !important;
  }
  .xs\:w-screen {
    width: 100vw !important;
  }
  .xs\:w-auto {
    width: auto !important;
  }
  .xs\:w-1 {
    width: 8.3333% !important;
  }
  .xs\:w-2 {
    width: 16.6667% !important;
  }
  .xs\:w-3 {
    width: 25% !important;
  }
  .xs\:w-4 {
    width: 33.3333% !important;
  }
  .xs\:w-5 {
    width: 41.6667% !important;
  }
  .xs\:w-6 {
    width: 50% !important;
  }
  .xs\:w-7 {
    width: 58.3333% !important;
  }
  .xs\:w-8 {
    width: 66.6667% !important;
  }
  .xs\:w-9 {
    width: 75% !important;
  }
  .xs\:w-10 {
    width: 83.3333% !important;
  }
  .xs\:w-11 {
    width: 91.6667% !important;
  }
  .xs\:w-12 {
    width: 100% !important;
  }
  .xs\:w-min {
    width: min-content !important;
  }
  .xs\:w-max {
    width: max-content !important;
  }
  .xs\:w-fit {
    width: fit-content !important;
  }
  .xs\:w-1rem {
    width: 1rem !important;
  }
  .xs\:w-2rem {
    width: 2rem !important;
  }
  .xs\:w-3rem {
    width: 3rem !important;
  }
  .xs\:w-4rem {
    width: 4rem !important;
  }
  .xs\:w-5rem {
    width: 5rem !important;
  }
  .xs\:w-6rem {
    width: 6rem !important;
  }
  .xs\:w-7rem {
    width: 7rem !important;
  }
  .xs\:w-8rem {
    width: 8rem !important;
  }
  .xs\:w-9rem {
    width: 9rem !important;
  }
  .xs\:w-10rem {
    width: 10rem !important;
  }
  .xs\:w-11rem {
    width: 11rem !important;
  }
  .xs\:w-12rem {
    width: 12rem !important;
  }
  .xs\:w-13rem {
    width: 13rem !important;
  }
  .xs\:w-14rem {
    width: 14rem !important;
  }
  .xs\:w-15rem {
    width: 15rem !important;
  }
  .xs\:w-16rem {
    width: 16rem !important;
  }
  .xs\:w-17rem {
    width: 17rem !important;
  }
  .xs\:w-18rem {
    width: 18rem !important;
  }
  .xs\:w-19rem {
    width: 19rem !important;
  }
  .xs\:w-20rem {
    width: 20rem !important;
  }
  .xs\:w-21rem {
    width: 21rem !important;
  }
  .xs\:w-22rem {
    width: 22rem !important;
  }
  .xs\:w-23rem {
    width: 23rem !important;
  }
  .xs\:w-24rem {
    width: 24rem !important;
  }
  .xs\:w-25rem {
    width: 25rem !important;
  }
  .xs\:w-26rem {
    width: 26rem !important;
  }
  .xs\:w-27rem {
    width: 27rem !important;
  }
  .xs\:w-28rem {
    width: 28rem !important;
  }
  .xs\:w-29rem {
    width: 29rem !important;
  }
  .xs\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:w-full {
    width: 100% !important;
  }
  .sm\:w-screen {
    width: 100vw !important;
  }
  .sm\:w-auto {
    width: auto !important;
  }
  .sm\:w-1 {
    width: 8.3333% !important;
  }
  .sm\:w-2 {
    width: 16.6667% !important;
  }
  .sm\:w-3 {
    width: 25% !important;
  }
  .sm\:w-4 {
    width: 33.3333% !important;
  }
  .sm\:w-5 {
    width: 41.6667% !important;
  }
  .sm\:w-6 {
    width: 50% !important;
  }
  .sm\:w-7 {
    width: 58.3333% !important;
  }
  .sm\:w-8 {
    width: 66.6667% !important;
  }
  .sm\:w-9 {
    width: 75% !important;
  }
  .sm\:w-10 {
    width: 83.3333% !important;
  }
  .sm\:w-11 {
    width: 91.6667% !important;
  }
  .sm\:w-12 {
    width: 100% !important;
  }
  .sm\:w-min {
    width: min-content !important;
  }
  .sm\:w-max {
    width: max-content !important;
  }
  .sm\:w-fit {
    width: fit-content !important;
  }
  .sm\:w-1rem {
    width: 1rem !important;
  }
  .sm\:w-2rem {
    width: 2rem !important;
  }
  .sm\:w-3rem {
    width: 3rem !important;
  }
  .sm\:w-4rem {
    width: 4rem !important;
  }
  .sm\:w-5rem {
    width: 5rem !important;
  }
  .sm\:w-6rem {
    width: 6rem !important;
  }
  .sm\:w-7rem {
    width: 7rem !important;
  }
  .sm\:w-8rem {
    width: 8rem !important;
  }
  .sm\:w-9rem {
    width: 9rem !important;
  }
  .sm\:w-10rem {
    width: 10rem !important;
  }
  .sm\:w-11rem {
    width: 11rem !important;
  }
  .sm\:w-12rem {
    width: 12rem !important;
  }
  .sm\:w-13rem {
    width: 13rem !important;
  }
  .sm\:w-14rem {
    width: 14rem !important;
  }
  .sm\:w-15rem {
    width: 15rem !important;
  }
  .sm\:w-16rem {
    width: 16rem !important;
  }
  .sm\:w-17rem {
    width: 17rem !important;
  }
  .sm\:w-18rem {
    width: 18rem !important;
  }
  .sm\:w-19rem {
    width: 19rem !important;
  }
  .sm\:w-20rem {
    width: 20rem !important;
  }
  .sm\:w-21rem {
    width: 21rem !important;
  }
  .sm\:w-22rem {
    width: 22rem !important;
  }
  .sm\:w-23rem {
    width: 23rem !important;
  }
  .sm\:w-24rem {
    width: 24rem !important;
  }
  .sm\:w-25rem {
    width: 25rem !important;
  }
  .sm\:w-26rem {
    width: 26rem !important;
  }
  .sm\:w-27rem {
    width: 27rem !important;
  }
  .sm\:w-28rem {
    width: 28rem !important;
  }
  .sm\:w-29rem {
    width: 29rem !important;
  }
  .sm\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:w-full {
    width: 100% !important;
  }
  .md\:w-screen {
    width: 100vw !important;
  }
  .md\:w-auto {
    width: auto !important;
  }
  .md\:w-1 {
    width: 8.3333% !important;
  }
  .md\:w-2 {
    width: 16.6667% !important;
  }
  .md\:w-3 {
    width: 25% !important;
  }
  .md\:w-4 {
    width: 33.3333% !important;
  }
  .md\:w-5 {
    width: 41.6667% !important;
  }
  .md\:w-6 {
    width: 50% !important;
  }
  .md\:w-7 {
    width: 58.3333% !important;
  }
  .md\:w-8 {
    width: 66.6667% !important;
  }
  .md\:w-9 {
    width: 75% !important;
  }
  .md\:w-10 {
    width: 83.3333% !important;
  }
  .md\:w-11 {
    width: 91.6667% !important;
  }
  .md\:w-12 {
    width: 100% !important;
  }
  .md\:w-min {
    width: min-content !important;
  }
  .md\:w-max {
    width: max-content !important;
  }
  .md\:w-fit {
    width: fit-content !important;
  }
  .md\:w-1rem {
    width: 1rem !important;
  }
  .md\:w-2rem {
    width: 2rem !important;
  }
  .md\:w-3rem {
    width: 3rem !important;
  }
  .md\:w-4rem {
    width: 4rem !important;
  }
  .md\:w-5rem {
    width: 5rem !important;
  }
  .md\:w-6rem {
    width: 6rem !important;
  }
  .md\:w-7rem {
    width: 7rem !important;
  }
  .md\:w-8rem {
    width: 8rem !important;
  }
  .md\:w-9rem {
    width: 9rem !important;
  }
  .md\:w-10rem {
    width: 10rem !important;
  }
  .md\:w-11rem {
    width: 11rem !important;
  }
  .md\:w-12rem {
    width: 12rem !important;
  }
  .md\:w-13rem {
    width: 13rem !important;
  }
  .md\:w-14rem {
    width: 14rem !important;
  }
  .md\:w-15rem {
    width: 15rem !important;
  }
  .md\:w-16rem {
    width: 16rem !important;
  }
  .md\:w-17rem {
    width: 17rem !important;
  }
  .md\:w-18rem {
    width: 18rem !important;
  }
  .md\:w-19rem {
    width: 19rem !important;
  }
  .md\:w-20rem {
    width: 20rem !important;
  }
  .md\:w-21rem {
    width: 21rem !important;
  }
  .md\:w-22rem {
    width: 22rem !important;
  }
  .md\:w-23rem {
    width: 23rem !important;
  }
  .md\:w-24rem {
    width: 24rem !important;
  }
  .md\:w-25rem {
    width: 25rem !important;
  }
  .md\:w-26rem {
    width: 26rem !important;
  }
  .md\:w-27rem {
    width: 27rem !important;
  }
  .md\:w-28rem {
    width: 28rem !important;
  }
  .md\:w-29rem {
    width: 29rem !important;
  }
  .md\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:w-full {
    width: 100% !important;
  }
  .lg\:w-screen {
    width: 100vw !important;
  }
  .lg\:w-auto {
    width: auto !important;
  }
  .lg\:w-1 {
    width: 8.3333% !important;
  }
  .lg\:w-2 {
    width: 16.6667% !important;
  }
  .lg\:w-3 {
    width: 25% !important;
  }
  .lg\:w-4 {
    width: 33.3333% !important;
  }
  .lg\:w-5 {
    width: 41.6667% !important;
  }
  .lg\:w-6 {
    width: 50% !important;
  }
  .lg\:w-7 {
    width: 58.3333% !important;
  }
  .lg\:w-8 {
    width: 66.6667% !important;
  }
  .lg\:w-9 {
    width: 75% !important;
  }
  .lg\:w-10 {
    width: 83.3333% !important;
  }
  .lg\:w-11 {
    width: 91.6667% !important;
  }
  .lg\:w-12 {
    width: 100% !important;
  }
  .lg\:w-min {
    width: min-content !important;
  }
  .lg\:w-max {
    width: max-content !important;
  }
  .lg\:w-fit {
    width: fit-content !important;
  }
  .lg\:w-1rem {
    width: 1rem !important;
  }
  .lg\:w-2rem {
    width: 2rem !important;
  }
  .lg\:w-3rem {
    width: 3rem !important;
  }
  .lg\:w-4rem {
    width: 4rem !important;
  }
  .lg\:w-5rem {
    width: 5rem !important;
  }
  .lg\:w-6rem {
    width: 6rem !important;
  }
  .lg\:w-7rem {
    width: 7rem !important;
  }
  .lg\:w-8rem {
    width: 8rem !important;
  }
  .lg\:w-9rem {
    width: 9rem !important;
  }
  .lg\:w-10rem {
    width: 10rem !important;
  }
  .lg\:w-11rem {
    width: 11rem !important;
  }
  .lg\:w-12rem {
    width: 12rem !important;
  }
  .lg\:w-13rem {
    width: 13rem !important;
  }
  .lg\:w-14rem {
    width: 14rem !important;
  }
  .lg\:w-15rem {
    width: 15rem !important;
  }
  .lg\:w-16rem {
    width: 16rem !important;
  }
  .lg\:w-17rem {
    width: 17rem !important;
  }
  .lg\:w-18rem {
    width: 18rem !important;
  }
  .lg\:w-19rem {
    width: 19rem !important;
  }
  .lg\:w-20rem {
    width: 20rem !important;
  }
  .lg\:w-21rem {
    width: 21rem !important;
  }
  .lg\:w-22rem {
    width: 22rem !important;
  }
  .lg\:w-23rem {
    width: 23rem !important;
  }
  .lg\:w-24rem {
    width: 24rem !important;
  }
  .lg\:w-25rem {
    width: 25rem !important;
  }
  .lg\:w-26rem {
    width: 26rem !important;
  }
  .lg\:w-27rem {
    width: 27rem !important;
  }
  .lg\:w-28rem {
    width: 28rem !important;
  }
  .lg\:w-29rem {
    width: 29rem !important;
  }
  .lg\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:w-full {
    width: 100% !important;
  }
  .xl\:w-screen {
    width: 100vw !important;
  }
  .xl\:w-auto {
    width: auto !important;
  }
  .xl\:w-1 {
    width: 8.3333% !important;
  }
  .xl\:w-2 {
    width: 16.6667% !important;
  }
  .xl\:w-3 {
    width: 25% !important;
  }
  .xl\:w-4 {
    width: 33.3333% !important;
  }
  .xl\:w-5 {
    width: 41.6667% !important;
  }
  .xl\:w-6 {
    width: 50% !important;
  }
  .xl\:w-7 {
    width: 58.3333% !important;
  }
  .xl\:w-8 {
    width: 66.6667% !important;
  }
  .xl\:w-9 {
    width: 75% !important;
  }
  .xl\:w-10 {
    width: 83.3333% !important;
  }
  .xl\:w-11 {
    width: 91.6667% !important;
  }
  .xl\:w-12 {
    width: 100% !important;
  }
  .xl\:w-min {
    width: min-content !important;
  }
  .xl\:w-max {
    width: max-content !important;
  }
  .xl\:w-fit {
    width: fit-content !important;
  }
  .xl\:w-1rem {
    width: 1rem !important;
  }
  .xl\:w-2rem {
    width: 2rem !important;
  }
  .xl\:w-3rem {
    width: 3rem !important;
  }
  .xl\:w-4rem {
    width: 4rem !important;
  }
  .xl\:w-5rem {
    width: 5rem !important;
  }
  .xl\:w-6rem {
    width: 6rem !important;
  }
  .xl\:w-7rem {
    width: 7rem !important;
  }
  .xl\:w-8rem {
    width: 8rem !important;
  }
  .xl\:w-9rem {
    width: 9rem !important;
  }
  .xl\:w-10rem {
    width: 10rem !important;
  }
  .xl\:w-11rem {
    width: 11rem !important;
  }
  .xl\:w-12rem {
    width: 12rem !important;
  }
  .xl\:w-13rem {
    width: 13rem !important;
  }
  .xl\:w-14rem {
    width: 14rem !important;
  }
  .xl\:w-15rem {
    width: 15rem !important;
  }
  .xl\:w-16rem {
    width: 16rem !important;
  }
  .xl\:w-17rem {
    width: 17rem !important;
  }
  .xl\:w-18rem {
    width: 18rem !important;
  }
  .xl\:w-19rem {
    width: 19rem !important;
  }
  .xl\:w-20rem {
    width: 20rem !important;
  }
  .xl\:w-21rem {
    width: 21rem !important;
  }
  .xl\:w-22rem {
    width: 22rem !important;
  }
  .xl\:w-23rem {
    width: 23rem !important;
  }
  .xl\:w-24rem {
    width: 24rem !important;
  }
  .xl\:w-25rem {
    width: 25rem !important;
  }
  .xl\:w-26rem {
    width: 26rem !important;
  }
  .xl\:w-27rem {
    width: 27rem !important;
  }
  .xl\:w-28rem {
    width: 28rem !important;
  }
  .xl\:w-29rem {
    width: 29rem !important;
  }
  .xl\:w-30rem {
    width: 30rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:w-full {
    width: 100% !important;
  }
  .xxl\:w-screen {
    width: 100vw !important;
  }
  .xxl\:w-auto {
    width: auto !important;
  }
  .xxl\:w-1 {
    width: 8.3333% !important;
  }
  .xxl\:w-2 {
    width: 16.6667% !important;
  }
  .xxl\:w-3 {
    width: 25% !important;
  }
  .xxl\:w-4 {
    width: 33.3333% !important;
  }
  .xxl\:w-5 {
    width: 41.6667% !important;
  }
  .xxl\:w-6 {
    width: 50% !important;
  }
  .xxl\:w-7 {
    width: 58.3333% !important;
  }
  .xxl\:w-8 {
    width: 66.6667% !important;
  }
  .xxl\:w-9 {
    width: 75% !important;
  }
  .xxl\:w-10 {
    width: 83.3333% !important;
  }
  .xxl\:w-11 {
    width: 91.6667% !important;
  }
  .xxl\:w-12 {
    width: 100% !important;
  }
  .xxl\:w-min {
    width: min-content !important;
  }
  .xxl\:w-max {
    width: max-content !important;
  }
  .xxl\:w-fit {
    width: fit-content !important;
  }
  .xxl\:w-1rem {
    width: 1rem !important;
  }
  .xxl\:w-2rem {
    width: 2rem !important;
  }
  .xxl\:w-3rem {
    width: 3rem !important;
  }
  .xxl\:w-4rem {
    width: 4rem !important;
  }
  .xxl\:w-5rem {
    width: 5rem !important;
  }
  .xxl\:w-6rem {
    width: 6rem !important;
  }
  .xxl\:w-7rem {
    width: 7rem !important;
  }
  .xxl\:w-8rem {
    width: 8rem !important;
  }
  .xxl\:w-9rem {
    width: 9rem !important;
  }
  .xxl\:w-10rem {
    width: 10rem !important;
  }
  .xxl\:w-11rem {
    width: 11rem !important;
  }
  .xxl\:w-12rem {
    width: 12rem !important;
  }
  .xxl\:w-13rem {
    width: 13rem !important;
  }
  .xxl\:w-14rem {
    width: 14rem !important;
  }
  .xxl\:w-15rem {
    width: 15rem !important;
  }
  .xxl\:w-16rem {
    width: 16rem !important;
  }
  .xxl\:w-17rem {
    width: 17rem !important;
  }
  .xxl\:w-18rem {
    width: 18rem !important;
  }
  .xxl\:w-19rem {
    width: 19rem !important;
  }
  .xxl\:w-20rem {
    width: 20rem !important;
  }
  .xxl\:w-21rem {
    width: 21rem !important;
  }
  .xxl\:w-22rem {
    width: 22rem !important;
  }
  .xxl\:w-23rem {
    width: 23rem !important;
  }
  .xxl\:w-24rem {
    width: 24rem !important;
  }
  .xxl\:w-25rem {
    width: 25rem !important;
  }
  .xxl\:w-26rem {
    width: 26rem !important;
  }
  .xxl\:w-27rem {
    width: 27rem !important;
  }
  .xxl\:w-28rem {
    width: 28rem !important;
  }
  .xxl\:w-29rem {
    width: 29rem !important;
  }
  .xxl\:w-30rem {
    width: 30rem !important;
  }
}
.h-full {
  height: 100% !important;
}

.h-screen {
  height: 100vh !important;
}

.h-auto {
  height: auto !important;
}

.h-min {
  height: min-content !important;
}

.h-max {
  height: max-content !important;
}

.h-fit {
  height: fit-content !important;
}

.h-1rem {
  height: 1rem !important;
}

.h-2rem {
  height: 2rem !important;
}

.h-3rem {
  height: 3rem !important;
}

.h-4rem {
  height: 4rem !important;
}

.h-5rem {
  height: 5rem !important;
}

.h-6rem {
  height: 6rem !important;
}

.h-7rem {
  height: 7rem !important;
}

.h-8rem {
  height: 8rem !important;
}

.h-9rem {
  height: 9rem !important;
}

.h-10rem {
  height: 10rem !important;
}

.h-11rem {
  height: 11rem !important;
}

.h-12rem {
  height: 12rem !important;
}

.h-13rem {
  height: 13rem !important;
}

.h-14rem {
  height: 14rem !important;
}

.h-15rem {
  height: 15rem !important;
}

.h-16rem {
  height: 16rem !important;
}

.h-17rem {
  height: 17rem !important;
}

.h-18rem {
  height: 18rem !important;
}

.h-19rem {
  height: 19rem !important;
}

.h-20rem {
  height: 20rem !important;
}

.h-21rem {
  height: 21rem !important;
}

.h-22rem {
  height: 22rem !important;
}

.h-23rem {
  height: 23rem !important;
}

.h-24rem {
  height: 24rem !important;
}

.h-25rem {
  height: 25rem !important;
}

.h-26rem {
  height: 26rem !important;
}

.h-27rem {
  height: 27rem !important;
}

.h-28rem {
  height: 28rem !important;
}

.h-29rem {
  height: 29rem !important;
}

.h-30rem {
  height: 30rem !important;
}

@media screen and (min-width: 480px) {
  .xs\:h-full {
    height: 100% !important;
  }
  .xs\:h-screen {
    height: 100vh !important;
  }
  .xs\:h-auto {
    height: auto !important;
  }
  .xs\:h-min {
    height: min-content !important;
  }
  .xs\:h-max {
    height: max-content !important;
  }
  .xs\:h-fit {
    height: fit-content !important;
  }
  .xs\:h-1rem {
    height: 1rem !important;
  }
  .xs\:h-2rem {
    height: 2rem !important;
  }
  .xs\:h-3rem {
    height: 3rem !important;
  }
  .xs\:h-4rem {
    height: 4rem !important;
  }
  .xs\:h-5rem {
    height: 5rem !important;
  }
  .xs\:h-6rem {
    height: 6rem !important;
  }
  .xs\:h-7rem {
    height: 7rem !important;
  }
  .xs\:h-8rem {
    height: 8rem !important;
  }
  .xs\:h-9rem {
    height: 9rem !important;
  }
  .xs\:h-10rem {
    height: 10rem !important;
  }
  .xs\:h-11rem {
    height: 11rem !important;
  }
  .xs\:h-12rem {
    height: 12rem !important;
  }
  .xs\:h-13rem {
    height: 13rem !important;
  }
  .xs\:h-14rem {
    height: 14rem !important;
  }
  .xs\:h-15rem {
    height: 15rem !important;
  }
  .xs\:h-16rem {
    height: 16rem !important;
  }
  .xs\:h-17rem {
    height: 17rem !important;
  }
  .xs\:h-18rem {
    height: 18rem !important;
  }
  .xs\:h-19rem {
    height: 19rem !important;
  }
  .xs\:h-20rem {
    height: 20rem !important;
  }
  .xs\:h-21rem {
    height: 21rem !important;
  }
  .xs\:h-22rem {
    height: 22rem !important;
  }
  .xs\:h-23rem {
    height: 23rem !important;
  }
  .xs\:h-24rem {
    height: 24rem !important;
  }
  .xs\:h-25rem {
    height: 25rem !important;
  }
  .xs\:h-26rem {
    height: 26rem !important;
  }
  .xs\:h-27rem {
    height: 27rem !important;
  }
  .xs\:h-28rem {
    height: 28rem !important;
  }
  .xs\:h-29rem {
    height: 29rem !important;
  }
  .xs\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:h-full {
    height: 100% !important;
  }
  .sm\:h-screen {
    height: 100vh !important;
  }
  .sm\:h-auto {
    height: auto !important;
  }
  .sm\:h-min {
    height: min-content !important;
  }
  .sm\:h-max {
    height: max-content !important;
  }
  .sm\:h-fit {
    height: fit-content !important;
  }
  .sm\:h-1rem {
    height: 1rem !important;
  }
  .sm\:h-2rem {
    height: 2rem !important;
  }
  .sm\:h-3rem {
    height: 3rem !important;
  }
  .sm\:h-4rem {
    height: 4rem !important;
  }
  .sm\:h-5rem {
    height: 5rem !important;
  }
  .sm\:h-6rem {
    height: 6rem !important;
  }
  .sm\:h-7rem {
    height: 7rem !important;
  }
  .sm\:h-8rem {
    height: 8rem !important;
  }
  .sm\:h-9rem {
    height: 9rem !important;
  }
  .sm\:h-10rem {
    height: 10rem !important;
  }
  .sm\:h-11rem {
    height: 11rem !important;
  }
  .sm\:h-12rem {
    height: 12rem !important;
  }
  .sm\:h-13rem {
    height: 13rem !important;
  }
  .sm\:h-14rem {
    height: 14rem !important;
  }
  .sm\:h-15rem {
    height: 15rem !important;
  }
  .sm\:h-16rem {
    height: 16rem !important;
  }
  .sm\:h-17rem {
    height: 17rem !important;
  }
  .sm\:h-18rem {
    height: 18rem !important;
  }
  .sm\:h-19rem {
    height: 19rem !important;
  }
  .sm\:h-20rem {
    height: 20rem !important;
  }
  .sm\:h-21rem {
    height: 21rem !important;
  }
  .sm\:h-22rem {
    height: 22rem !important;
  }
  .sm\:h-23rem {
    height: 23rem !important;
  }
  .sm\:h-24rem {
    height: 24rem !important;
  }
  .sm\:h-25rem {
    height: 25rem !important;
  }
  .sm\:h-26rem {
    height: 26rem !important;
  }
  .sm\:h-27rem {
    height: 27rem !important;
  }
  .sm\:h-28rem {
    height: 28rem !important;
  }
  .sm\:h-29rem {
    height: 29rem !important;
  }
  .sm\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:h-full {
    height: 100% !important;
  }
  .md\:h-screen {
    height: 100vh !important;
  }
  .md\:h-auto {
    height: auto !important;
  }
  .md\:h-min {
    height: min-content !important;
  }
  .md\:h-max {
    height: max-content !important;
  }
  .md\:h-fit {
    height: fit-content !important;
  }
  .md\:h-1rem {
    height: 1rem !important;
  }
  .md\:h-2rem {
    height: 2rem !important;
  }
  .md\:h-3rem {
    height: 3rem !important;
  }
  .md\:h-4rem {
    height: 4rem !important;
  }
  .md\:h-5rem {
    height: 5rem !important;
  }
  .md\:h-6rem {
    height: 6rem !important;
  }
  .md\:h-7rem {
    height: 7rem !important;
  }
  .md\:h-8rem {
    height: 8rem !important;
  }
  .md\:h-9rem {
    height: 9rem !important;
  }
  .md\:h-10rem {
    height: 10rem !important;
  }
  .md\:h-11rem {
    height: 11rem !important;
  }
  .md\:h-12rem {
    height: 12rem !important;
  }
  .md\:h-13rem {
    height: 13rem !important;
  }
  .md\:h-14rem {
    height: 14rem !important;
  }
  .md\:h-15rem {
    height: 15rem !important;
  }
  .md\:h-16rem {
    height: 16rem !important;
  }
  .md\:h-17rem {
    height: 17rem !important;
  }
  .md\:h-18rem {
    height: 18rem !important;
  }
  .md\:h-19rem {
    height: 19rem !important;
  }
  .md\:h-20rem {
    height: 20rem !important;
  }
  .md\:h-21rem {
    height: 21rem !important;
  }
  .md\:h-22rem {
    height: 22rem !important;
  }
  .md\:h-23rem {
    height: 23rem !important;
  }
  .md\:h-24rem {
    height: 24rem !important;
  }
  .md\:h-25rem {
    height: 25rem !important;
  }
  .md\:h-26rem {
    height: 26rem !important;
  }
  .md\:h-27rem {
    height: 27rem !important;
  }
  .md\:h-28rem {
    height: 28rem !important;
  }
  .md\:h-29rem {
    height: 29rem !important;
  }
  .md\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:h-full {
    height: 100% !important;
  }
  .lg\:h-screen {
    height: 100vh !important;
  }
  .lg\:h-auto {
    height: auto !important;
  }
  .lg\:h-min {
    height: min-content !important;
  }
  .lg\:h-max {
    height: max-content !important;
  }
  .lg\:h-fit {
    height: fit-content !important;
  }
  .lg\:h-1rem {
    height: 1rem !important;
  }
  .lg\:h-2rem {
    height: 2rem !important;
  }
  .lg\:h-3rem {
    height: 3rem !important;
  }
  .lg\:h-4rem {
    height: 4rem !important;
  }
  .lg\:h-5rem {
    height: 5rem !important;
  }
  .lg\:h-6rem {
    height: 6rem !important;
  }
  .lg\:h-7rem {
    height: 7rem !important;
  }
  .lg\:h-8rem {
    height: 8rem !important;
  }
  .lg\:h-9rem {
    height: 9rem !important;
  }
  .lg\:h-10rem {
    height: 10rem !important;
  }
  .lg\:h-11rem {
    height: 11rem !important;
  }
  .lg\:h-12rem {
    height: 12rem !important;
  }
  .lg\:h-13rem {
    height: 13rem !important;
  }
  .lg\:h-14rem {
    height: 14rem !important;
  }
  .lg\:h-15rem {
    height: 15rem !important;
  }
  .lg\:h-16rem {
    height: 16rem !important;
  }
  .lg\:h-17rem {
    height: 17rem !important;
  }
  .lg\:h-18rem {
    height: 18rem !important;
  }
  .lg\:h-19rem {
    height: 19rem !important;
  }
  .lg\:h-20rem {
    height: 20rem !important;
  }
  .lg\:h-21rem {
    height: 21rem !important;
  }
  .lg\:h-22rem {
    height: 22rem !important;
  }
  .lg\:h-23rem {
    height: 23rem !important;
  }
  .lg\:h-24rem {
    height: 24rem !important;
  }
  .lg\:h-25rem {
    height: 25rem !important;
  }
  .lg\:h-26rem {
    height: 26rem !important;
  }
  .lg\:h-27rem {
    height: 27rem !important;
  }
  .lg\:h-28rem {
    height: 28rem !important;
  }
  .lg\:h-29rem {
    height: 29rem !important;
  }
  .lg\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:h-full {
    height: 100% !important;
  }
  .xl\:h-screen {
    height: 100vh !important;
  }
  .xl\:h-auto {
    height: auto !important;
  }
  .xl\:h-min {
    height: min-content !important;
  }
  .xl\:h-max {
    height: max-content !important;
  }
  .xl\:h-fit {
    height: fit-content !important;
  }
  .xl\:h-1rem {
    height: 1rem !important;
  }
  .xl\:h-2rem {
    height: 2rem !important;
  }
  .xl\:h-3rem {
    height: 3rem !important;
  }
  .xl\:h-4rem {
    height: 4rem !important;
  }
  .xl\:h-5rem {
    height: 5rem !important;
  }
  .xl\:h-6rem {
    height: 6rem !important;
  }
  .xl\:h-7rem {
    height: 7rem !important;
  }
  .xl\:h-8rem {
    height: 8rem !important;
  }
  .xl\:h-9rem {
    height: 9rem !important;
  }
  .xl\:h-10rem {
    height: 10rem !important;
  }
  .xl\:h-11rem {
    height: 11rem !important;
  }
  .xl\:h-12rem {
    height: 12rem !important;
  }
  .xl\:h-13rem {
    height: 13rem !important;
  }
  .xl\:h-14rem {
    height: 14rem !important;
  }
  .xl\:h-15rem {
    height: 15rem !important;
  }
  .xl\:h-16rem {
    height: 16rem !important;
  }
  .xl\:h-17rem {
    height: 17rem !important;
  }
  .xl\:h-18rem {
    height: 18rem !important;
  }
  .xl\:h-19rem {
    height: 19rem !important;
  }
  .xl\:h-20rem {
    height: 20rem !important;
  }
  .xl\:h-21rem {
    height: 21rem !important;
  }
  .xl\:h-22rem {
    height: 22rem !important;
  }
  .xl\:h-23rem {
    height: 23rem !important;
  }
  .xl\:h-24rem {
    height: 24rem !important;
  }
  .xl\:h-25rem {
    height: 25rem !important;
  }
  .xl\:h-26rem {
    height: 26rem !important;
  }
  .xl\:h-27rem {
    height: 27rem !important;
  }
  .xl\:h-28rem {
    height: 28rem !important;
  }
  .xl\:h-29rem {
    height: 29rem !important;
  }
  .xl\:h-30rem {
    height: 30rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:h-full {
    height: 100% !important;
  }
  .xxl\:h-screen {
    height: 100vh !important;
  }
  .xxl\:h-auto {
    height: auto !important;
  }
  .xxl\:h-min {
    height: min-content !important;
  }
  .xxl\:h-max {
    height: max-content !important;
  }
  .xxl\:h-fit {
    height: fit-content !important;
  }
  .xxl\:h-1rem {
    height: 1rem !important;
  }
  .xxl\:h-2rem {
    height: 2rem !important;
  }
  .xxl\:h-3rem {
    height: 3rem !important;
  }
  .xxl\:h-4rem {
    height: 4rem !important;
  }
  .xxl\:h-5rem {
    height: 5rem !important;
  }
  .xxl\:h-6rem {
    height: 6rem !important;
  }
  .xxl\:h-7rem {
    height: 7rem !important;
  }
  .xxl\:h-8rem {
    height: 8rem !important;
  }
  .xxl\:h-9rem {
    height: 9rem !important;
  }
  .xxl\:h-10rem {
    height: 10rem !important;
  }
  .xxl\:h-11rem {
    height: 11rem !important;
  }
  .xxl\:h-12rem {
    height: 12rem !important;
  }
  .xxl\:h-13rem {
    height: 13rem !important;
  }
  .xxl\:h-14rem {
    height: 14rem !important;
  }
  .xxl\:h-15rem {
    height: 15rem !important;
  }
  .xxl\:h-16rem {
    height: 16rem !important;
  }
  .xxl\:h-17rem {
    height: 17rem !important;
  }
  .xxl\:h-18rem {
    height: 18rem !important;
  }
  .xxl\:h-19rem {
    height: 19rem !important;
  }
  .xxl\:h-20rem {
    height: 20rem !important;
  }
  .xxl\:h-21rem {
    height: 21rem !important;
  }
  .xxl\:h-22rem {
    height: 22rem !important;
  }
  .xxl\:h-23rem {
    height: 23rem !important;
  }
  .xxl\:h-24rem {
    height: 24rem !important;
  }
  .xxl\:h-25rem {
    height: 25rem !important;
  }
  .xxl\:h-26rem {
    height: 26rem !important;
  }
  .xxl\:h-27rem {
    height: 27rem !important;
  }
  .xxl\:h-28rem {
    height: 28rem !important;
  }
  .xxl\:h-29rem {
    height: 29rem !important;
  }
  .xxl\:h-30rem {
    height: 30rem !important;
  }
}
.min-w-0 {
  min-width: 0px !important;
}

.min-w-full {
  min-width: 100% !important;
}

.min-w-screen {
  min-width: 100vw !important;
}

.min-w-min {
  min-width: min-content !important;
}

.min-w-max {
  min-width: max-content !important;
}

@media screen and (min-width: 480px) {
  .xs\:min-w-0 {
    min-width: 0px !important;
  }
  .xs\:min-w-full {
    min-width: 100% !important;
  }
  .xs\:min-w-screen {
    min-width: 100vw !important;
  }
  .xs\:min-w-min {
    min-width: min-content !important;
  }
  .xs\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:min-w-0 {
    min-width: 0px !important;
  }
  .sm\:min-w-full {
    min-width: 100% !important;
  }
  .sm\:min-w-screen {
    min-width: 100vw !important;
  }
  .sm\:min-w-min {
    min-width: min-content !important;
  }
  .sm\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:min-w-0 {
    min-width: 0px !important;
  }
  .md\:min-w-full {
    min-width: 100% !important;
  }
  .md\:min-w-screen {
    min-width: 100vw !important;
  }
  .md\:min-w-min {
    min-width: min-content !important;
  }
  .md\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:min-w-0 {
    min-width: 0px !important;
  }
  .lg\:min-w-full {
    min-width: 100% !important;
  }
  .lg\:min-w-screen {
    min-width: 100vw !important;
  }
  .lg\:min-w-min {
    min-width: min-content !important;
  }
  .lg\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:min-w-0 {
    min-width: 0px !important;
  }
  .xl\:min-w-full {
    min-width: 100% !important;
  }
  .xl\:min-w-screen {
    min-width: 100vw !important;
  }
  .xl\:min-w-min {
    min-width: min-content !important;
  }
  .xl\:min-w-max {
    min-width: max-content !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:min-w-0 {
    min-width: 0px !important;
  }
  .xxl\:min-w-full {
    min-width: 100% !important;
  }
  .xxl\:min-w-screen {
    min-width: 100vw !important;
  }
  .xxl\:min-w-min {
    min-width: min-content !important;
  }
  .xxl\:min-w-max {
    min-width: max-content !important;
  }
}
.max-w-0 {
  max-width: 0px !important;
}

.max-w-full {
  max-width: 100% !important;
}

.max-w-screen {
  max-width: 100vw !important;
}

.max-w-min {
  max-width: min-content !important;
}

.max-w-max {
  max-width: max-content !important;
}

.max-w-fit {
  max-width: fit-content !important;
}

.max-w-1rem {
  max-width: 1rem !important;
}

.max-w-2rem {
  max-width: 2rem !important;
}

.max-w-3rem {
  max-width: 3rem !important;
}

.max-w-4rem {
  max-width: 4rem !important;
}

.max-w-5rem {
  max-width: 5rem !important;
}

.max-w-6rem {
  max-width: 6rem !important;
}

.max-w-7rem {
  max-width: 7rem !important;
}

.max-w-8rem {
  max-width: 8rem !important;
}

.max-w-9rem {
  max-width: 9rem !important;
}

.max-w-10rem {
  max-width: 10rem !important;
}

.max-w-11rem {
  max-width: 11rem !important;
}

.max-w-12rem {
  max-width: 12rem !important;
}

.max-w-13rem {
  max-width: 13rem !important;
}

.max-w-14rem {
  max-width: 14rem !important;
}

.max-w-15rem {
  max-width: 15rem !important;
}

.max-w-16rem {
  max-width: 16rem !important;
}

.max-w-17rem {
  max-width: 17rem !important;
}

.max-w-18rem {
  max-width: 18rem !important;
}

.max-w-19rem {
  max-width: 19rem !important;
}

.max-w-20rem {
  max-width: 20rem !important;
}

.max-w-21rem {
  max-width: 21rem !important;
}

.max-w-22rem {
  max-width: 22rem !important;
}

.max-w-23rem {
  max-width: 23rem !important;
}

.max-w-24rem {
  max-width: 24rem !important;
}

.max-w-25rem {
  max-width: 25rem !important;
}

.max-w-26rem {
  max-width: 26rem !important;
}

.max-w-27rem {
  max-width: 27rem !important;
}

.max-w-28rem {
  max-width: 28rem !important;
}

.max-w-29rem {
  max-width: 29rem !important;
}

.max-w-30rem {
  max-width: 30rem !important;
}

@media screen and (min-width: 480px) {
  .xs\:max-w-0 {
    max-width: 0px !important;
  }
  .xs\:max-w-full {
    max-width: 100% !important;
  }
  .xs\:max-w-screen {
    max-width: 100vw !important;
  }
  .xs\:max-w-min {
    max-width: min-content !important;
  }
  .xs\:max-w-max {
    max-width: max-content !important;
  }
  .xs\:max-w-fit {
    max-width: fit-content !important;
  }
  .xs\:max-w-1rem {
    max-width: 1rem !important;
  }
  .xs\:max-w-2rem {
    max-width: 2rem !important;
  }
  .xs\:max-w-3rem {
    max-width: 3rem !important;
  }
  .xs\:max-w-4rem {
    max-width: 4rem !important;
  }
  .xs\:max-w-5rem {
    max-width: 5rem !important;
  }
  .xs\:max-w-6rem {
    max-width: 6rem !important;
  }
  .xs\:max-w-7rem {
    max-width: 7rem !important;
  }
  .xs\:max-w-8rem {
    max-width: 8rem !important;
  }
  .xs\:max-w-9rem {
    max-width: 9rem !important;
  }
  .xs\:max-w-10rem {
    max-width: 10rem !important;
  }
  .xs\:max-w-11rem {
    max-width: 11rem !important;
  }
  .xs\:max-w-12rem {
    max-width: 12rem !important;
  }
  .xs\:max-w-13rem {
    max-width: 13rem !important;
  }
  .xs\:max-w-14rem {
    max-width: 14rem !important;
  }
  .xs\:max-w-15rem {
    max-width: 15rem !important;
  }
  .xs\:max-w-16rem {
    max-width: 16rem !important;
  }
  .xs\:max-w-17rem {
    max-width: 17rem !important;
  }
  .xs\:max-w-18rem {
    max-width: 18rem !important;
  }
  .xs\:max-w-19rem {
    max-width: 19rem !important;
  }
  .xs\:max-w-20rem {
    max-width: 20rem !important;
  }
  .xs\:max-w-21rem {
    max-width: 21rem !important;
  }
  .xs\:max-w-22rem {
    max-width: 22rem !important;
  }
  .xs\:max-w-23rem {
    max-width: 23rem !important;
  }
  .xs\:max-w-24rem {
    max-width: 24rem !important;
  }
  .xs\:max-w-25rem {
    max-width: 25rem !important;
  }
  .xs\:max-w-26rem {
    max-width: 26rem !important;
  }
  .xs\:max-w-27rem {
    max-width: 27rem !important;
  }
  .xs\:max-w-28rem {
    max-width: 28rem !important;
  }
  .xs\:max-w-29rem {
    max-width: 29rem !important;
  }
  .xs\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:max-w-0 {
    max-width: 0px !important;
  }
  .sm\:max-w-full {
    max-width: 100% !important;
  }
  .sm\:max-w-screen {
    max-width: 100vw !important;
  }
  .sm\:max-w-min {
    max-width: min-content !important;
  }
  .sm\:max-w-max {
    max-width: max-content !important;
  }
  .sm\:max-w-fit {
    max-width: fit-content !important;
  }
  .sm\:max-w-1rem {
    max-width: 1rem !important;
  }
  .sm\:max-w-2rem {
    max-width: 2rem !important;
  }
  .sm\:max-w-3rem {
    max-width: 3rem !important;
  }
  .sm\:max-w-4rem {
    max-width: 4rem !important;
  }
  .sm\:max-w-5rem {
    max-width: 5rem !important;
  }
  .sm\:max-w-6rem {
    max-width: 6rem !important;
  }
  .sm\:max-w-7rem {
    max-width: 7rem !important;
  }
  .sm\:max-w-8rem {
    max-width: 8rem !important;
  }
  .sm\:max-w-9rem {
    max-width: 9rem !important;
  }
  .sm\:max-w-10rem {
    max-width: 10rem !important;
  }
  .sm\:max-w-11rem {
    max-width: 11rem !important;
  }
  .sm\:max-w-12rem {
    max-width: 12rem !important;
  }
  .sm\:max-w-13rem {
    max-width: 13rem !important;
  }
  .sm\:max-w-14rem {
    max-width: 14rem !important;
  }
  .sm\:max-w-15rem {
    max-width: 15rem !important;
  }
  .sm\:max-w-16rem {
    max-width: 16rem !important;
  }
  .sm\:max-w-17rem {
    max-width: 17rem !important;
  }
  .sm\:max-w-18rem {
    max-width: 18rem !important;
  }
  .sm\:max-w-19rem {
    max-width: 19rem !important;
  }
  .sm\:max-w-20rem {
    max-width: 20rem !important;
  }
  .sm\:max-w-21rem {
    max-width: 21rem !important;
  }
  .sm\:max-w-22rem {
    max-width: 22rem !important;
  }
  .sm\:max-w-23rem {
    max-width: 23rem !important;
  }
  .sm\:max-w-24rem {
    max-width: 24rem !important;
  }
  .sm\:max-w-25rem {
    max-width: 25rem !important;
  }
  .sm\:max-w-26rem {
    max-width: 26rem !important;
  }
  .sm\:max-w-27rem {
    max-width: 27rem !important;
  }
  .sm\:max-w-28rem {
    max-width: 28rem !important;
  }
  .sm\:max-w-29rem {
    max-width: 29rem !important;
  }
  .sm\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:max-w-0 {
    max-width: 0px !important;
  }
  .md\:max-w-full {
    max-width: 100% !important;
  }
  .md\:max-w-screen {
    max-width: 100vw !important;
  }
  .md\:max-w-min {
    max-width: min-content !important;
  }
  .md\:max-w-max {
    max-width: max-content !important;
  }
  .md\:max-w-fit {
    max-width: fit-content !important;
  }
  .md\:max-w-1rem {
    max-width: 1rem !important;
  }
  .md\:max-w-2rem {
    max-width: 2rem !important;
  }
  .md\:max-w-3rem {
    max-width: 3rem !important;
  }
  .md\:max-w-4rem {
    max-width: 4rem !important;
  }
  .md\:max-w-5rem {
    max-width: 5rem !important;
  }
  .md\:max-w-6rem {
    max-width: 6rem !important;
  }
  .md\:max-w-7rem {
    max-width: 7rem !important;
  }
  .md\:max-w-8rem {
    max-width: 8rem !important;
  }
  .md\:max-w-9rem {
    max-width: 9rem !important;
  }
  .md\:max-w-10rem {
    max-width: 10rem !important;
  }
  .md\:max-w-11rem {
    max-width: 11rem !important;
  }
  .md\:max-w-12rem {
    max-width: 12rem !important;
  }
  .md\:max-w-13rem {
    max-width: 13rem !important;
  }
  .md\:max-w-14rem {
    max-width: 14rem !important;
  }
  .md\:max-w-15rem {
    max-width: 15rem !important;
  }
  .md\:max-w-16rem {
    max-width: 16rem !important;
  }
  .md\:max-w-17rem {
    max-width: 17rem !important;
  }
  .md\:max-w-18rem {
    max-width: 18rem !important;
  }
  .md\:max-w-19rem {
    max-width: 19rem !important;
  }
  .md\:max-w-20rem {
    max-width: 20rem !important;
  }
  .md\:max-w-21rem {
    max-width: 21rem !important;
  }
  .md\:max-w-22rem {
    max-width: 22rem !important;
  }
  .md\:max-w-23rem {
    max-width: 23rem !important;
  }
  .md\:max-w-24rem {
    max-width: 24rem !important;
  }
  .md\:max-w-25rem {
    max-width: 25rem !important;
  }
  .md\:max-w-26rem {
    max-width: 26rem !important;
  }
  .md\:max-w-27rem {
    max-width: 27rem !important;
  }
  .md\:max-w-28rem {
    max-width: 28rem !important;
  }
  .md\:max-w-29rem {
    max-width: 29rem !important;
  }
  .md\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:max-w-0 {
    max-width: 0px !important;
  }
  .lg\:max-w-full {
    max-width: 100% !important;
  }
  .lg\:max-w-screen {
    max-width: 100vw !important;
  }
  .lg\:max-w-min {
    max-width: min-content !important;
  }
  .lg\:max-w-max {
    max-width: max-content !important;
  }
  .lg\:max-w-fit {
    max-width: fit-content !important;
  }
  .lg\:max-w-1rem {
    max-width: 1rem !important;
  }
  .lg\:max-w-2rem {
    max-width: 2rem !important;
  }
  .lg\:max-w-3rem {
    max-width: 3rem !important;
  }
  .lg\:max-w-4rem {
    max-width: 4rem !important;
  }
  .lg\:max-w-5rem {
    max-width: 5rem !important;
  }
  .lg\:max-w-6rem {
    max-width: 6rem !important;
  }
  .lg\:max-w-7rem {
    max-width: 7rem !important;
  }
  .lg\:max-w-8rem {
    max-width: 8rem !important;
  }
  .lg\:max-w-9rem {
    max-width: 9rem !important;
  }
  .lg\:max-w-10rem {
    max-width: 10rem !important;
  }
  .lg\:max-w-11rem {
    max-width: 11rem !important;
  }
  .lg\:max-w-12rem {
    max-width: 12rem !important;
  }
  .lg\:max-w-13rem {
    max-width: 13rem !important;
  }
  .lg\:max-w-14rem {
    max-width: 14rem !important;
  }
  .lg\:max-w-15rem {
    max-width: 15rem !important;
  }
  .lg\:max-w-16rem {
    max-width: 16rem !important;
  }
  .lg\:max-w-17rem {
    max-width: 17rem !important;
  }
  .lg\:max-w-18rem {
    max-width: 18rem !important;
  }
  .lg\:max-w-19rem {
    max-width: 19rem !important;
  }
  .lg\:max-w-20rem {
    max-width: 20rem !important;
  }
  .lg\:max-w-21rem {
    max-width: 21rem !important;
  }
  .lg\:max-w-22rem {
    max-width: 22rem !important;
  }
  .lg\:max-w-23rem {
    max-width: 23rem !important;
  }
  .lg\:max-w-24rem {
    max-width: 24rem !important;
  }
  .lg\:max-w-25rem {
    max-width: 25rem !important;
  }
  .lg\:max-w-26rem {
    max-width: 26rem !important;
  }
  .lg\:max-w-27rem {
    max-width: 27rem !important;
  }
  .lg\:max-w-28rem {
    max-width: 28rem !important;
  }
  .lg\:max-w-29rem {
    max-width: 29rem !important;
  }
  .lg\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:max-w-0 {
    max-width: 0px !important;
  }
  .xl\:max-w-full {
    max-width: 100% !important;
  }
  .xl\:max-w-screen {
    max-width: 100vw !important;
  }
  .xl\:max-w-min {
    max-width: min-content !important;
  }
  .xl\:max-w-max {
    max-width: max-content !important;
  }
  .xl\:max-w-fit {
    max-width: fit-content !important;
  }
  .xl\:max-w-1rem {
    max-width: 1rem !important;
  }
  .xl\:max-w-2rem {
    max-width: 2rem !important;
  }
  .xl\:max-w-3rem {
    max-width: 3rem !important;
  }
  .xl\:max-w-4rem {
    max-width: 4rem !important;
  }
  .xl\:max-w-5rem {
    max-width: 5rem !important;
  }
  .xl\:max-w-6rem {
    max-width: 6rem !important;
  }
  .xl\:max-w-7rem {
    max-width: 7rem !important;
  }
  .xl\:max-w-8rem {
    max-width: 8rem !important;
  }
  .xl\:max-w-9rem {
    max-width: 9rem !important;
  }
  .xl\:max-w-10rem {
    max-width: 10rem !important;
  }
  .xl\:max-w-11rem {
    max-width: 11rem !important;
  }
  .xl\:max-w-12rem {
    max-width: 12rem !important;
  }
  .xl\:max-w-13rem {
    max-width: 13rem !important;
  }
  .xl\:max-w-14rem {
    max-width: 14rem !important;
  }
  .xl\:max-w-15rem {
    max-width: 15rem !important;
  }
  .xl\:max-w-16rem {
    max-width: 16rem !important;
  }
  .xl\:max-w-17rem {
    max-width: 17rem !important;
  }
  .xl\:max-w-18rem {
    max-width: 18rem !important;
  }
  .xl\:max-w-19rem {
    max-width: 19rem !important;
  }
  .xl\:max-w-20rem {
    max-width: 20rem !important;
  }
  .xl\:max-w-21rem {
    max-width: 21rem !important;
  }
  .xl\:max-w-22rem {
    max-width: 22rem !important;
  }
  .xl\:max-w-23rem {
    max-width: 23rem !important;
  }
  .xl\:max-w-24rem {
    max-width: 24rem !important;
  }
  .xl\:max-w-25rem {
    max-width: 25rem !important;
  }
  .xl\:max-w-26rem {
    max-width: 26rem !important;
  }
  .xl\:max-w-27rem {
    max-width: 27rem !important;
  }
  .xl\:max-w-28rem {
    max-width: 28rem !important;
  }
  .xl\:max-w-29rem {
    max-width: 29rem !important;
  }
  .xl\:max-w-30rem {
    max-width: 30rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:max-w-0 {
    max-width: 0px !important;
  }
  .xxl\:max-w-full {
    max-width: 100% !important;
  }
  .xxl\:max-w-screen {
    max-width: 100vw !important;
  }
  .xxl\:max-w-min {
    max-width: min-content !important;
  }
  .xxl\:max-w-max {
    max-width: max-content !important;
  }
  .xxl\:max-w-fit {
    max-width: fit-content !important;
  }
  .xxl\:max-w-1rem {
    max-width: 1rem !important;
  }
  .xxl\:max-w-2rem {
    max-width: 2rem !important;
  }
  .xxl\:max-w-3rem {
    max-width: 3rem !important;
  }
  .xxl\:max-w-4rem {
    max-width: 4rem !important;
  }
  .xxl\:max-w-5rem {
    max-width: 5rem !important;
  }
  .xxl\:max-w-6rem {
    max-width: 6rem !important;
  }
  .xxl\:max-w-7rem {
    max-width: 7rem !important;
  }
  .xxl\:max-w-8rem {
    max-width: 8rem !important;
  }
  .xxl\:max-w-9rem {
    max-width: 9rem !important;
  }
  .xxl\:max-w-10rem {
    max-width: 10rem !important;
  }
  .xxl\:max-w-11rem {
    max-width: 11rem !important;
  }
  .xxl\:max-w-12rem {
    max-width: 12rem !important;
  }
  .xxl\:max-w-13rem {
    max-width: 13rem !important;
  }
  .xxl\:max-w-14rem {
    max-width: 14rem !important;
  }
  .xxl\:max-w-15rem {
    max-width: 15rem !important;
  }
  .xxl\:max-w-16rem {
    max-width: 16rem !important;
  }
  .xxl\:max-w-17rem {
    max-width: 17rem !important;
  }
  .xxl\:max-w-18rem {
    max-width: 18rem !important;
  }
  .xxl\:max-w-19rem {
    max-width: 19rem !important;
  }
  .xxl\:max-w-20rem {
    max-width: 20rem !important;
  }
  .xxl\:max-w-21rem {
    max-width: 21rem !important;
  }
  .xxl\:max-w-22rem {
    max-width: 22rem !important;
  }
  .xxl\:max-w-23rem {
    max-width: 23rem !important;
  }
  .xxl\:max-w-24rem {
    max-width: 24rem !important;
  }
  .xxl\:max-w-25rem {
    max-width: 25rem !important;
  }
  .xxl\:max-w-26rem {
    max-width: 26rem !important;
  }
  .xxl\:max-w-27rem {
    max-width: 27rem !important;
  }
  .xxl\:max-w-28rem {
    max-width: 28rem !important;
  }
  .xxl\:max-w-29rem {
    max-width: 29rem !important;
  }
  .xxl\:max-w-30rem {
    max-width: 30rem !important;
  }
}
.min-h-0 {
  min-height: 0px !important;
}

.min-h-full {
  min-height: 100% !important;
}

.min-h-screen {
  min-height: 100vh !important;
}

@media screen and (min-width: 480px) {
  .xs\:min-h-0 {
    min-height: 0px !important;
  }
  .xs\:min-h-full {
    min-height: 100% !important;
  }
  .xs\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:min-h-0 {
    min-height: 0px !important;
  }
  .sm\:min-h-full {
    min-height: 100% !important;
  }
  .sm\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:min-h-0 {
    min-height: 0px !important;
  }
  .md\:min-h-full {
    min-height: 100% !important;
  }
  .md\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:min-h-0 {
    min-height: 0px !important;
  }
  .lg\:min-h-full {
    min-height: 100% !important;
  }
  .lg\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:min-h-0 {
    min-height: 0px !important;
  }
  .xl\:min-h-full {
    min-height: 100% !important;
  }
  .xl\:min-h-screen {
    min-height: 100vh !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:min-h-0 {
    min-height: 0px !important;
  }
  .xxl\:min-h-full {
    min-height: 100% !important;
  }
  .xxl\:min-h-screen {
    min-height: 100vh !important;
  }
}
.max-h-0 {
  max-height: 0px !important;
}

.max-h-full {
  max-height: 100% !important;
}

.max-h-screen {
  max-height: 100vh !important;
}

.max-h-min {
  max-height: min-content !important;
}

.max-h-max {
  max-height: max-content !important;
}

.max-h-fit {
  max-height: fit-content !important;
}

.max-h-1rem {
  max-height: 1rem !important;
}

.max-h-2rem {
  max-height: 2rem !important;
}

.max-h-3rem {
  max-height: 3rem !important;
}

.max-h-4rem {
  max-height: 4rem !important;
}

.max-h-5rem {
  max-height: 5rem !important;
}

.max-h-6rem {
  max-height: 6rem !important;
}

.max-h-7rem {
  max-height: 7rem !important;
}

.max-h-8rem {
  max-height: 8rem !important;
}

.max-h-9rem {
  max-height: 9rem !important;
}

.max-h-10rem {
  max-height: 10rem !important;
}

.max-h-11rem {
  max-height: 11rem !important;
}

.max-h-12rem {
  max-height: 12rem !important;
}

.max-h-13rem {
  max-height: 13rem !important;
}

.max-h-14rem {
  max-height: 14rem !important;
}

.max-h-15rem {
  max-height: 15rem !important;
}

.max-h-16rem {
  max-height: 16rem !important;
}

.max-h-17rem {
  max-height: 17rem !important;
}

.max-h-18rem {
  max-height: 18rem !important;
}

.max-h-19rem {
  max-height: 19rem !important;
}

.max-h-20rem {
  max-height: 20rem !important;
}

.max-h-21rem {
  max-height: 21rem !important;
}

.max-h-22rem {
  max-height: 22rem !important;
}

.max-h-23rem {
  max-height: 23rem !important;
}

.max-h-24rem {
  max-height: 24rem !important;
}

.max-h-25rem {
  max-height: 25rem !important;
}

.max-h-26rem {
  max-height: 26rem !important;
}

.max-h-27rem {
  max-height: 27rem !important;
}

.max-h-28rem {
  max-height: 28rem !important;
}

.max-h-29rem {
  max-height: 29rem !important;
}

.max-h-30rem {
  max-height: 30rem !important;
}

@media screen and (min-width: 480px) {
  .xs\:max-h-0 {
    max-height: 0px !important;
  }
  .xs\:max-h-full {
    max-height: 100% !important;
  }
  .xs\:max-h-screen {
    max-height: 100vh !important;
  }
  .xs\:max-h-min {
    max-height: min-content !important;
  }
  .xs\:max-h-max {
    max-height: max-content !important;
  }
  .xs\:max-h-fit {
    max-height: fit-content !important;
  }
  .xs\:max-h-1rem {
    max-height: 1rem !important;
  }
  .xs\:max-h-2rem {
    max-height: 2rem !important;
  }
  .xs\:max-h-3rem {
    max-height: 3rem !important;
  }
  .xs\:max-h-4rem {
    max-height: 4rem !important;
  }
  .xs\:max-h-5rem {
    max-height: 5rem !important;
  }
  .xs\:max-h-6rem {
    max-height: 6rem !important;
  }
  .xs\:max-h-7rem {
    max-height: 7rem !important;
  }
  .xs\:max-h-8rem {
    max-height: 8rem !important;
  }
  .xs\:max-h-9rem {
    max-height: 9rem !important;
  }
  .xs\:max-h-10rem {
    max-height: 10rem !important;
  }
  .xs\:max-h-11rem {
    max-height: 11rem !important;
  }
  .xs\:max-h-12rem {
    max-height: 12rem !important;
  }
  .xs\:max-h-13rem {
    max-height: 13rem !important;
  }
  .xs\:max-h-14rem {
    max-height: 14rem !important;
  }
  .xs\:max-h-15rem {
    max-height: 15rem !important;
  }
  .xs\:max-h-16rem {
    max-height: 16rem !important;
  }
  .xs\:max-h-17rem {
    max-height: 17rem !important;
  }
  .xs\:max-h-18rem {
    max-height: 18rem !important;
  }
  .xs\:max-h-19rem {
    max-height: 19rem !important;
  }
  .xs\:max-h-20rem {
    max-height: 20rem !important;
  }
  .xs\:max-h-21rem {
    max-height: 21rem !important;
  }
  .xs\:max-h-22rem {
    max-height: 22rem !important;
  }
  .xs\:max-h-23rem {
    max-height: 23rem !important;
  }
  .xs\:max-h-24rem {
    max-height: 24rem !important;
  }
  .xs\:max-h-25rem {
    max-height: 25rem !important;
  }
  .xs\:max-h-26rem {
    max-height: 26rem !important;
  }
  .xs\:max-h-27rem {
    max-height: 27rem !important;
  }
  .xs\:max-h-28rem {
    max-height: 28rem !important;
  }
  .xs\:max-h-29rem {
    max-height: 29rem !important;
  }
  .xs\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:max-h-0 {
    max-height: 0px !important;
  }
  .sm\:max-h-full {
    max-height: 100% !important;
  }
  .sm\:max-h-screen {
    max-height: 100vh !important;
  }
  .sm\:max-h-min {
    max-height: min-content !important;
  }
  .sm\:max-h-max {
    max-height: max-content !important;
  }
  .sm\:max-h-fit {
    max-height: fit-content !important;
  }
  .sm\:max-h-1rem {
    max-height: 1rem !important;
  }
  .sm\:max-h-2rem {
    max-height: 2rem !important;
  }
  .sm\:max-h-3rem {
    max-height: 3rem !important;
  }
  .sm\:max-h-4rem {
    max-height: 4rem !important;
  }
  .sm\:max-h-5rem {
    max-height: 5rem !important;
  }
  .sm\:max-h-6rem {
    max-height: 6rem !important;
  }
  .sm\:max-h-7rem {
    max-height: 7rem !important;
  }
  .sm\:max-h-8rem {
    max-height: 8rem !important;
  }
  .sm\:max-h-9rem {
    max-height: 9rem !important;
  }
  .sm\:max-h-10rem {
    max-height: 10rem !important;
  }
  .sm\:max-h-11rem {
    max-height: 11rem !important;
  }
  .sm\:max-h-12rem {
    max-height: 12rem !important;
  }
  .sm\:max-h-13rem {
    max-height: 13rem !important;
  }
  .sm\:max-h-14rem {
    max-height: 14rem !important;
  }
  .sm\:max-h-15rem {
    max-height: 15rem !important;
  }
  .sm\:max-h-16rem {
    max-height: 16rem !important;
  }
  .sm\:max-h-17rem {
    max-height: 17rem !important;
  }
  .sm\:max-h-18rem {
    max-height: 18rem !important;
  }
  .sm\:max-h-19rem {
    max-height: 19rem !important;
  }
  .sm\:max-h-20rem {
    max-height: 20rem !important;
  }
  .sm\:max-h-21rem {
    max-height: 21rem !important;
  }
  .sm\:max-h-22rem {
    max-height: 22rem !important;
  }
  .sm\:max-h-23rem {
    max-height: 23rem !important;
  }
  .sm\:max-h-24rem {
    max-height: 24rem !important;
  }
  .sm\:max-h-25rem {
    max-height: 25rem !important;
  }
  .sm\:max-h-26rem {
    max-height: 26rem !important;
  }
  .sm\:max-h-27rem {
    max-height: 27rem !important;
  }
  .sm\:max-h-28rem {
    max-height: 28rem !important;
  }
  .sm\:max-h-29rem {
    max-height: 29rem !important;
  }
  .sm\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:max-h-0 {
    max-height: 0px !important;
  }
  .md\:max-h-full {
    max-height: 100% !important;
  }
  .md\:max-h-screen {
    max-height: 100vh !important;
  }
  .md\:max-h-min {
    max-height: min-content !important;
  }
  .md\:max-h-max {
    max-height: max-content !important;
  }
  .md\:max-h-fit {
    max-height: fit-content !important;
  }
  .md\:max-h-1rem {
    max-height: 1rem !important;
  }
  .md\:max-h-2rem {
    max-height: 2rem !important;
  }
  .md\:max-h-3rem {
    max-height: 3rem !important;
  }
  .md\:max-h-4rem {
    max-height: 4rem !important;
  }
  .md\:max-h-5rem {
    max-height: 5rem !important;
  }
  .md\:max-h-6rem {
    max-height: 6rem !important;
  }
  .md\:max-h-7rem {
    max-height: 7rem !important;
  }
  .md\:max-h-8rem {
    max-height: 8rem !important;
  }
  .md\:max-h-9rem {
    max-height: 9rem !important;
  }
  .md\:max-h-10rem {
    max-height: 10rem !important;
  }
  .md\:max-h-11rem {
    max-height: 11rem !important;
  }
  .md\:max-h-12rem {
    max-height: 12rem !important;
  }
  .md\:max-h-13rem {
    max-height: 13rem !important;
  }
  .md\:max-h-14rem {
    max-height: 14rem !important;
  }
  .md\:max-h-15rem {
    max-height: 15rem !important;
  }
  .md\:max-h-16rem {
    max-height: 16rem !important;
  }
  .md\:max-h-17rem {
    max-height: 17rem !important;
  }
  .md\:max-h-18rem {
    max-height: 18rem !important;
  }
  .md\:max-h-19rem {
    max-height: 19rem !important;
  }
  .md\:max-h-20rem {
    max-height: 20rem !important;
  }
  .md\:max-h-21rem {
    max-height: 21rem !important;
  }
  .md\:max-h-22rem {
    max-height: 22rem !important;
  }
  .md\:max-h-23rem {
    max-height: 23rem !important;
  }
  .md\:max-h-24rem {
    max-height: 24rem !important;
  }
  .md\:max-h-25rem {
    max-height: 25rem !important;
  }
  .md\:max-h-26rem {
    max-height: 26rem !important;
  }
  .md\:max-h-27rem {
    max-height: 27rem !important;
  }
  .md\:max-h-28rem {
    max-height: 28rem !important;
  }
  .md\:max-h-29rem {
    max-height: 29rem !important;
  }
  .md\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:max-h-0 {
    max-height: 0px !important;
  }
  .lg\:max-h-full {
    max-height: 100% !important;
  }
  .lg\:max-h-screen {
    max-height: 100vh !important;
  }
  .lg\:max-h-min {
    max-height: min-content !important;
  }
  .lg\:max-h-max {
    max-height: max-content !important;
  }
  .lg\:max-h-fit {
    max-height: fit-content !important;
  }
  .lg\:max-h-1rem {
    max-height: 1rem !important;
  }
  .lg\:max-h-2rem {
    max-height: 2rem !important;
  }
  .lg\:max-h-3rem {
    max-height: 3rem !important;
  }
  .lg\:max-h-4rem {
    max-height: 4rem !important;
  }
  .lg\:max-h-5rem {
    max-height: 5rem !important;
  }
  .lg\:max-h-6rem {
    max-height: 6rem !important;
  }
  .lg\:max-h-7rem {
    max-height: 7rem !important;
  }
  .lg\:max-h-8rem {
    max-height: 8rem !important;
  }
  .lg\:max-h-9rem {
    max-height: 9rem !important;
  }
  .lg\:max-h-10rem {
    max-height: 10rem !important;
  }
  .lg\:max-h-11rem {
    max-height: 11rem !important;
  }
  .lg\:max-h-12rem {
    max-height: 12rem !important;
  }
  .lg\:max-h-13rem {
    max-height: 13rem !important;
  }
  .lg\:max-h-14rem {
    max-height: 14rem !important;
  }
  .lg\:max-h-15rem {
    max-height: 15rem !important;
  }
  .lg\:max-h-16rem {
    max-height: 16rem !important;
  }
  .lg\:max-h-17rem {
    max-height: 17rem !important;
  }
  .lg\:max-h-18rem {
    max-height: 18rem !important;
  }
  .lg\:max-h-19rem {
    max-height: 19rem !important;
  }
  .lg\:max-h-20rem {
    max-height: 20rem !important;
  }
  .lg\:max-h-21rem {
    max-height: 21rem !important;
  }
  .lg\:max-h-22rem {
    max-height: 22rem !important;
  }
  .lg\:max-h-23rem {
    max-height: 23rem !important;
  }
  .lg\:max-h-24rem {
    max-height: 24rem !important;
  }
  .lg\:max-h-25rem {
    max-height: 25rem !important;
  }
  .lg\:max-h-26rem {
    max-height: 26rem !important;
  }
  .lg\:max-h-27rem {
    max-height: 27rem !important;
  }
  .lg\:max-h-28rem {
    max-height: 28rem !important;
  }
  .lg\:max-h-29rem {
    max-height: 29rem !important;
  }
  .lg\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:max-h-0 {
    max-height: 0px !important;
  }
  .xl\:max-h-full {
    max-height: 100% !important;
  }
  .xl\:max-h-screen {
    max-height: 100vh !important;
  }
  .xl\:max-h-min {
    max-height: min-content !important;
  }
  .xl\:max-h-max {
    max-height: max-content !important;
  }
  .xl\:max-h-fit {
    max-height: fit-content !important;
  }
  .xl\:max-h-1rem {
    max-height: 1rem !important;
  }
  .xl\:max-h-2rem {
    max-height: 2rem !important;
  }
  .xl\:max-h-3rem {
    max-height: 3rem !important;
  }
  .xl\:max-h-4rem {
    max-height: 4rem !important;
  }
  .xl\:max-h-5rem {
    max-height: 5rem !important;
  }
  .xl\:max-h-6rem {
    max-height: 6rem !important;
  }
  .xl\:max-h-7rem {
    max-height: 7rem !important;
  }
  .xl\:max-h-8rem {
    max-height: 8rem !important;
  }
  .xl\:max-h-9rem {
    max-height: 9rem !important;
  }
  .xl\:max-h-10rem {
    max-height: 10rem !important;
  }
  .xl\:max-h-11rem {
    max-height: 11rem !important;
  }
  .xl\:max-h-12rem {
    max-height: 12rem !important;
  }
  .xl\:max-h-13rem {
    max-height: 13rem !important;
  }
  .xl\:max-h-14rem {
    max-height: 14rem !important;
  }
  .xl\:max-h-15rem {
    max-height: 15rem !important;
  }
  .xl\:max-h-16rem {
    max-height: 16rem !important;
  }
  .xl\:max-h-17rem {
    max-height: 17rem !important;
  }
  .xl\:max-h-18rem {
    max-height: 18rem !important;
  }
  .xl\:max-h-19rem {
    max-height: 19rem !important;
  }
  .xl\:max-h-20rem {
    max-height: 20rem !important;
  }
  .xl\:max-h-21rem {
    max-height: 21rem !important;
  }
  .xl\:max-h-22rem {
    max-height: 22rem !important;
  }
  .xl\:max-h-23rem {
    max-height: 23rem !important;
  }
  .xl\:max-h-24rem {
    max-height: 24rem !important;
  }
  .xl\:max-h-25rem {
    max-height: 25rem !important;
  }
  .xl\:max-h-26rem {
    max-height: 26rem !important;
  }
  .xl\:max-h-27rem {
    max-height: 27rem !important;
  }
  .xl\:max-h-28rem {
    max-height: 28rem !important;
  }
  .xl\:max-h-29rem {
    max-height: 29rem !important;
  }
  .xl\:max-h-30rem {
    max-height: 30rem !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:max-h-0 {
    max-height: 0px !important;
  }
  .xxl\:max-h-full {
    max-height: 100% !important;
  }
  .xxl\:max-h-screen {
    max-height: 100vh !important;
  }
  .xxl\:max-h-min {
    max-height: min-content !important;
  }
  .xxl\:max-h-max {
    max-height: max-content !important;
  }
  .xxl\:max-h-fit {
    max-height: fit-content !important;
  }
  .xxl\:max-h-1rem {
    max-height: 1rem !important;
  }
  .xxl\:max-h-2rem {
    max-height: 2rem !important;
  }
  .xxl\:max-h-3rem {
    max-height: 3rem !important;
  }
  .xxl\:max-h-4rem {
    max-height: 4rem !important;
  }
  .xxl\:max-h-5rem {
    max-height: 5rem !important;
  }
  .xxl\:max-h-6rem {
    max-height: 6rem !important;
  }
  .xxl\:max-h-7rem {
    max-height: 7rem !important;
  }
  .xxl\:max-h-8rem {
    max-height: 8rem !important;
  }
  .xxl\:max-h-9rem {
    max-height: 9rem !important;
  }
  .xxl\:max-h-10rem {
    max-height: 10rem !important;
  }
  .xxl\:max-h-11rem {
    max-height: 11rem !important;
  }
  .xxl\:max-h-12rem {
    max-height: 12rem !important;
  }
  .xxl\:max-h-13rem {
    max-height: 13rem !important;
  }
  .xxl\:max-h-14rem {
    max-height: 14rem !important;
  }
  .xxl\:max-h-15rem {
    max-height: 15rem !important;
  }
  .xxl\:max-h-16rem {
    max-height: 16rem !important;
  }
  .xxl\:max-h-17rem {
    max-height: 17rem !important;
  }
  .xxl\:max-h-18rem {
    max-height: 18rem !important;
  }
  .xxl\:max-h-19rem {
    max-height: 19rem !important;
  }
  .xxl\:max-h-20rem {
    max-height: 20rem !important;
  }
  .xxl\:max-h-21rem {
    max-height: 21rem !important;
  }
  .xxl\:max-h-22rem {
    max-height: 22rem !important;
  }
  .xxl\:max-h-23rem {
    max-height: 23rem !important;
  }
  .xxl\:max-h-24rem {
    max-height: 24rem !important;
  }
  .xxl\:max-h-25rem {
    max-height: 25rem !important;
  }
  .xxl\:max-h-26rem {
    max-height: 26rem !important;
  }
  .xxl\:max-h-27rem {
    max-height: 27rem !important;
  }
  .xxl\:max-h-28rem {
    max-height: 28rem !important;
  }
  .xxl\:max-h-29rem {
    max-height: 29rem !important;
  }
  .xxl\:max-h-30rem {
    max-height: 30rem !important;
  }
}
.static {
  position: static !important;
}

.fixed {
  position: fixed !important;
}

.absolute {
  position: absolute !important;
}

.relative {
  position: relative !important;
}

.sticky {
  position: sticky !important;
}

@media screen and (min-width: 480px) {
  .xs\:static {
    position: static !important;
  }
  .xs\:fixed {
    position: fixed !important;
  }
  .xs\:absolute {
    position: absolute !important;
  }
  .xs\:relative {
    position: relative !important;
  }
  .xs\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:static {
    position: static !important;
  }
  .sm\:fixed {
    position: fixed !important;
  }
  .sm\:absolute {
    position: absolute !important;
  }
  .sm\:relative {
    position: relative !important;
  }
  .sm\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:static {
    position: static !important;
  }
  .md\:fixed {
    position: fixed !important;
  }
  .md\:absolute {
    position: absolute !important;
  }
  .md\:relative {
    position: relative !important;
  }
  .md\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:static {
    position: static !important;
  }
  .lg\:fixed {
    position: fixed !important;
  }
  .lg\:absolute {
    position: absolute !important;
  }
  .lg\:relative {
    position: relative !important;
  }
  .lg\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:static {
    position: static !important;
  }
  .xl\:fixed {
    position: fixed !important;
  }
  .xl\:absolute {
    position: absolute !important;
  }
  .xl\:relative {
    position: relative !important;
  }
  .xl\:sticky {
    position: sticky !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:static {
    position: static !important;
  }
  .xxl\:fixed {
    position: fixed !important;
  }
  .xxl\:absolute {
    position: absolute !important;
  }
  .xxl\:relative {
    position: relative !important;
  }
  .xxl\:sticky {
    position: sticky !important;
  }
}
.top-auto {
  top: auto !important;
}

.top-0 {
  top: 0px !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

@media screen and (min-width: 480px) {
  .xs\:top-auto {
    top: auto !important;
  }
  .xs\:top-0 {
    top: 0px !important;
  }
  .xs\:top-50 {
    top: 50% !important;
  }
  .xs\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:top-auto {
    top: auto !important;
  }
  .sm\:top-0 {
    top: 0px !important;
  }
  .sm\:top-50 {
    top: 50% !important;
  }
  .sm\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:top-auto {
    top: auto !important;
  }
  .md\:top-0 {
    top: 0px !important;
  }
  .md\:top-50 {
    top: 50% !important;
  }
  .md\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:top-auto {
    top: auto !important;
  }
  .lg\:top-0 {
    top: 0px !important;
  }
  .lg\:top-50 {
    top: 50% !important;
  }
  .lg\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:top-auto {
    top: auto !important;
  }
  .xl\:top-0 {
    top: 0px !important;
  }
  .xl\:top-50 {
    top: 50% !important;
  }
  .xl\:top-100 {
    top: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:top-auto {
    top: auto !important;
  }
  .xxl\:top-0 {
    top: 0px !important;
  }
  .xxl\:top-50 {
    top: 50% !important;
  }
  .xxl\:top-100 {
    top: 100% !important;
  }
}
.left-auto {
  left: auto !important;
}

.left-0 {
  left: 0px !important;
}

.left-50 {
  left: 50% !important;
}

.left-100 {
  left: 100% !important;
}

@media screen and (min-width: 480px) {
  .xs\:left-auto {
    left: auto !important;
  }
  .xs\:left-0 {
    left: 0px !important;
  }
  .xs\:left-50 {
    left: 50% !important;
  }
  .xs\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:left-auto {
    left: auto !important;
  }
  .sm\:left-0 {
    left: 0px !important;
  }
  .sm\:left-50 {
    left: 50% !important;
  }
  .sm\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:left-auto {
    left: auto !important;
  }
  .md\:left-0 {
    left: 0px !important;
  }
  .md\:left-50 {
    left: 50% !important;
  }
  .md\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:left-auto {
    left: auto !important;
  }
  .lg\:left-0 {
    left: 0px !important;
  }
  .lg\:left-50 {
    left: 50% !important;
  }
  .lg\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:left-auto {
    left: auto !important;
  }
  .xl\:left-0 {
    left: 0px !important;
  }
  .xl\:left-50 {
    left: 50% !important;
  }
  .xl\:left-100 {
    left: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:left-auto {
    left: auto !important;
  }
  .xxl\:left-0 {
    left: 0px !important;
  }
  .xxl\:left-50 {
    left: 50% !important;
  }
  .xxl\:left-100 {
    left: 100% !important;
  }
}
.right-auto {
  right: auto !important;
}

.right-0 {
  right: 0px !important;
}

.right-50 {
  right: 50% !important;
}

.right-100 {
  right: 100% !important;
}

@media screen and (min-width: 480px) {
  .xs\:right-auto {
    right: auto !important;
  }
  .xs\:right-0 {
    right: 0px !important;
  }
  .xs\:right-50 {
    right: 50% !important;
  }
  .xs\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:right-auto {
    right: auto !important;
  }
  .sm\:right-0 {
    right: 0px !important;
  }
  .sm\:right-50 {
    right: 50% !important;
  }
  .sm\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:right-auto {
    right: auto !important;
  }
  .md\:right-0 {
    right: 0px !important;
  }
  .md\:right-50 {
    right: 50% !important;
  }
  .md\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:right-auto {
    right: auto !important;
  }
  .lg\:right-0 {
    right: 0px !important;
  }
  .lg\:right-50 {
    right: 50% !important;
  }
  .lg\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:right-auto {
    right: auto !important;
  }
  .xl\:right-0 {
    right: 0px !important;
  }
  .xl\:right-50 {
    right: 50% !important;
  }
  .xl\:right-100 {
    right: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:right-auto {
    right: auto !important;
  }
  .xxl\:right-0 {
    right: 0px !important;
  }
  .xxl\:right-50 {
    right: 50% !important;
  }
  .xxl\:right-100 {
    right: 100% !important;
  }
}
.bottom-auto {
  bottom: auto !important;
}

.bottom-0 {
  bottom: 0px !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

@media screen and (min-width: 480px) {
  .xs\:bottom-auto {
    bottom: auto !important;
  }
  .xs\:bottom-0 {
    bottom: 0px !important;
  }
  .xs\:bottom-50 {
    bottom: 50% !important;
  }
  .xs\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:bottom-auto {
    bottom: auto !important;
  }
  .sm\:bottom-0 {
    bottom: 0px !important;
  }
  .sm\:bottom-50 {
    bottom: 50% !important;
  }
  .sm\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bottom-auto {
    bottom: auto !important;
  }
  .md\:bottom-0 {
    bottom: 0px !important;
  }
  .md\:bottom-50 {
    bottom: 50% !important;
  }
  .md\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bottom-auto {
    bottom: auto !important;
  }
  .lg\:bottom-0 {
    bottom: 0px !important;
  }
  .lg\:bottom-50 {
    bottom: 50% !important;
  }
  .lg\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bottom-auto {
    bottom: auto !important;
  }
  .xl\:bottom-0 {
    bottom: 0px !important;
  }
  .xl\:bottom-50 {
    bottom: 50% !important;
  }
  .xl\:bottom-100 {
    bottom: 100% !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:bottom-auto {
    bottom: auto !important;
  }
  .xxl\:bottom-0 {
    bottom: 0px !important;
  }
  .xxl\:bottom-50 {
    bottom: 50% !important;
  }
  .xxl\:bottom-100 {
    bottom: 100% !important;
  }
}
.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

@media screen and (min-width: 480px) {
  .xs\:overflow-auto {
    overflow: auto !important;
  }
  .xs\:overflow-hidden {
    overflow: hidden !important;
  }
  .xs\:overflow-visible {
    overflow: visible !important;
  }
  .xs\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:overflow-auto {
    overflow: auto !important;
  }
  .sm\:overflow-hidden {
    overflow: hidden !important;
  }
  .sm\:overflow-visible {
    overflow: visible !important;
  }
  .sm\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-auto {
    overflow: auto !important;
  }
  .md\:overflow-hidden {
    overflow: hidden !important;
  }
  .md\:overflow-visible {
    overflow: visible !important;
  }
  .md\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-auto {
    overflow: auto !important;
  }
  .lg\:overflow-hidden {
    overflow: hidden !important;
  }
  .lg\:overflow-visible {
    overflow: visible !important;
  }
  .lg\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-auto {
    overflow: auto !important;
  }
  .xl\:overflow-hidden {
    overflow: hidden !important;
  }
  .xl\:overflow-visible {
    overflow: visible !important;
  }
  .xl\:overflow-scroll {
    overflow: scroll !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:overflow-auto {
    overflow: auto !important;
  }
  .xxl\:overflow-hidden {
    overflow: hidden !important;
  }
  .xxl\:overflow-visible {
    overflow: visible !important;
  }
  .xxl\:overflow-scroll {
    overflow: scroll !important;
  }
}
.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

@media screen and (min-width: 480px) {
  .xs\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .xs\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .xs\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .xs\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .sm\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .sm\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .md\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .md\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .md\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .lg\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .lg\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .lg\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .xl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .xl\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .xl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:overflow-x-auto {
    overflow-x: auto !important;
  }
  .xxl\:overflow-x-hidden {
    overflow-x: hidden !important;
  }
  .xxl\:overflow-x-visible {
    overflow-x: visible !important;
  }
  .xxl\:overflow-x-scroll {
    overflow-x: scroll !important;
  }
}
.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

@media screen and (min-width: 480px) {
  .xs\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .xs\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .xs\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .xs\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .sm\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .sm\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .sm\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .md\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .md\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .md\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .lg\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .lg\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .lg\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .xl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .xl\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .xl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:overflow-y-auto {
    overflow-y: auto !important;
  }
  .xxl\:overflow-y-hidden {
    overflow-y: hidden !important;
  }
  .xxl\:overflow-y-visible {
    overflow-y: visible !important;
  }
  .xxl\:overflow-y-scroll {
    overflow-y: scroll !important;
  }
}
.z-auto {
  z-index: auto !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

@media screen and (min-width: 480px) {
  .xs\:z-auto {
    z-index: auto !important;
  }
  .xs\:z-0 {
    z-index: 0 !important;
  }
  .xs\:z-1 {
    z-index: 1 !important;
  }
  .xs\:z-2 {
    z-index: 2 !important;
  }
  .xs\:z-3 {
    z-index: 3 !important;
  }
  .xs\:z-4 {
    z-index: 4 !important;
  }
  .xs\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:z-auto {
    z-index: auto !important;
  }
  .sm\:z-0 {
    z-index: 0 !important;
  }
  .sm\:z-1 {
    z-index: 1 !important;
  }
  .sm\:z-2 {
    z-index: 2 !important;
  }
  .sm\:z-3 {
    z-index: 3 !important;
  }
  .sm\:z-4 {
    z-index: 4 !important;
  }
  .sm\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:z-auto {
    z-index: auto !important;
  }
  .md\:z-0 {
    z-index: 0 !important;
  }
  .md\:z-1 {
    z-index: 1 !important;
  }
  .md\:z-2 {
    z-index: 2 !important;
  }
  .md\:z-3 {
    z-index: 3 !important;
  }
  .md\:z-4 {
    z-index: 4 !important;
  }
  .md\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:z-auto {
    z-index: auto !important;
  }
  .lg\:z-0 {
    z-index: 0 !important;
  }
  .lg\:z-1 {
    z-index: 1 !important;
  }
  .lg\:z-2 {
    z-index: 2 !important;
  }
  .lg\:z-3 {
    z-index: 3 !important;
  }
  .lg\:z-4 {
    z-index: 4 !important;
  }
  .lg\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:z-auto {
    z-index: auto !important;
  }
  .xl\:z-0 {
    z-index: 0 !important;
  }
  .xl\:z-1 {
    z-index: 1 !important;
  }
  .xl\:z-2 {
    z-index: 2 !important;
  }
  .xl\:z-3 {
    z-index: 3 !important;
  }
  .xl\:z-4 {
    z-index: 4 !important;
  }
  .xl\:z-5 {
    z-index: 5 !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:z-auto {
    z-index: auto !important;
  }
  .xxl\:z-0 {
    z-index: 0 !important;
  }
  .xxl\:z-1 {
    z-index: 1 !important;
  }
  .xxl\:z-2 {
    z-index: 2 !important;
  }
  .xxl\:z-3 {
    z-index: 3 !important;
  }
  .xxl\:z-4 {
    z-index: 4 !important;
  }
  .xxl\:z-5 {
    z-index: 5 !important;
  }
}
.bg-repeat {
  background-repeat: repeat !important;
}

.bg-no-repeat {
  background-repeat: no-repeat !important;
}

.bg-repeat-x {
  background-repeat: repeat-x !important;
}

.bg-repeat-y {
  background-repeat: repeat-y !important;
}

.bg-repeat-round {
  background-repeat: round !important;
}

.bg-repeat-space {
  background-repeat: space !important;
}

@media screen and (min-width: 480px) {
  .xs\:bg-repeat {
    background-repeat: repeat !important;
  }
  .xs\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .xs\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .xs\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .xs\:bg-repeat-round {
    background-repeat: round !important;
  }
  .xs\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:bg-repeat {
    background-repeat: repeat !important;
  }
  .sm\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .sm\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .sm\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .sm\:bg-repeat-round {
    background-repeat: round !important;
  }
  .sm\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-repeat {
    background-repeat: repeat !important;
  }
  .md\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .md\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .md\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .md\:bg-repeat-round {
    background-repeat: round !important;
  }
  .md\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-repeat {
    background-repeat: repeat !important;
  }
  .lg\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .lg\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .lg\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .lg\:bg-repeat-round {
    background-repeat: round !important;
  }
  .lg\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-repeat {
    background-repeat: repeat !important;
  }
  .xl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .xl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .xl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .xl\:bg-repeat-round {
    background-repeat: round !important;
  }
  .xl\:bg-repeat-space {
    background-repeat: space !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:bg-repeat {
    background-repeat: repeat !important;
  }
  .xxl\:bg-no-repeat {
    background-repeat: no-repeat !important;
  }
  .xxl\:bg-repeat-x {
    background-repeat: repeat-x !important;
  }
  .xxl\:bg-repeat-y {
    background-repeat: repeat-y !important;
  }
  .xxl\:bg-repeat-round {
    background-repeat: round !important;
  }
  .xxl\:bg-repeat-space {
    background-repeat: space !important;
  }
}
.bg-auto {
  background-size: auto !important;
}

.bg-cover {
  background-size: cover !important;
}

.bg-contain {
  background-size: contain !important;
}

@media screen and (min-width: 480px) {
  .xs\:bg-auto {
    background-size: auto !important;
  }
  .xs\:bg-cover {
    background-size: cover !important;
  }
  .xs\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:bg-auto {
    background-size: auto !important;
  }
  .sm\:bg-cover {
    background-size: cover !important;
  }
  .sm\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-auto {
    background-size: auto !important;
  }
  .md\:bg-cover {
    background-size: cover !important;
  }
  .md\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-auto {
    background-size: auto !important;
  }
  .lg\:bg-cover {
    background-size: cover !important;
  }
  .lg\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-auto {
    background-size: auto !important;
  }
  .xl\:bg-cover {
    background-size: cover !important;
  }
  .xl\:bg-contain {
    background-size: contain !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:bg-auto {
    background-size: auto !important;
  }
  .xxl\:bg-cover {
    background-size: cover !important;
  }
  .xxl\:bg-contain {
    background-size: contain !important;
  }
}
.bg-bottom {
  background-position: bottom !important;
}

.bg-center {
  background-position: center !important;
}

.bg-left {
  background-position: left !important;
}

.bg-left-bottom {
  background-position: left bottom !important;
}

.bg-left-top {
  background-position: left top !important;
}

.bg-right {
  background-position: right !important;
}

.bg-right-bottom {
  background-position: right bottom !important;
}

.bg-right-top {
  background-position: right top !important;
}

.bg-top {
  background-position: top !important;
}

@media screen and (min-width: 480px) {
  .xs\:bg-bottom {
    background-position: bottom !important;
  }
  .xs\:bg-center {
    background-position: center !important;
  }
  .xs\:bg-left {
    background-position: left !important;
  }
  .xs\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .xs\:bg-left-top {
    background-position: left top !important;
  }
  .xs\:bg-right {
    background-position: right !important;
  }
  .xs\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .xs\:bg-right-top {
    background-position: right top !important;
  }
  .xs\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:bg-bottom {
    background-position: bottom !important;
  }
  .sm\:bg-center {
    background-position: center !important;
  }
  .sm\:bg-left {
    background-position: left !important;
  }
  .sm\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .sm\:bg-left-top {
    background-position: left top !important;
  }
  .sm\:bg-right {
    background-position: right !important;
  }
  .sm\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .sm\:bg-right-top {
    background-position: right top !important;
  }
  .sm\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:bg-bottom {
    background-position: bottom !important;
  }
  .md\:bg-center {
    background-position: center !important;
  }
  .md\:bg-left {
    background-position: left !important;
  }
  .md\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .md\:bg-left-top {
    background-position: left top !important;
  }
  .md\:bg-right {
    background-position: right !important;
  }
  .md\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .md\:bg-right-top {
    background-position: right top !important;
  }
  .md\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:bg-bottom {
    background-position: bottom !important;
  }
  .lg\:bg-center {
    background-position: center !important;
  }
  .lg\:bg-left {
    background-position: left !important;
  }
  .lg\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .lg\:bg-left-top {
    background-position: left top !important;
  }
  .lg\:bg-right {
    background-position: right !important;
  }
  .lg\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .lg\:bg-right-top {
    background-position: right top !important;
  }
  .lg\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:bg-bottom {
    background-position: bottom !important;
  }
  .xl\:bg-center {
    background-position: center !important;
  }
  .xl\:bg-left {
    background-position: left !important;
  }
  .xl\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .xl\:bg-left-top {
    background-position: left top !important;
  }
  .xl\:bg-right {
    background-position: right !important;
  }
  .xl\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .xl\:bg-right-top {
    background-position: right top !important;
  }
  .xl\:bg-top {
    background-position: top !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:bg-bottom {
    background-position: bottom !important;
  }
  .xxl\:bg-center {
    background-position: center !important;
  }
  .xxl\:bg-left {
    background-position: left !important;
  }
  .xxl\:bg-left-bottom {
    background-position: left bottom !important;
  }
  .xxl\:bg-left-top {
    background-position: left top !important;
  }
  .xxl\:bg-right {
    background-position: right !important;
  }
  .xxl\:bg-right-bottom {
    background-position: right bottom !important;
  }
  .xxl\:bg-right-top {
    background-position: right top !important;
  }
  .xxl\:bg-top {
    background-position: top !important;
  }
}
.select-none {
  user-select: none !important;
}

.select-text {
  user-select: text !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.list-none {
  list-style: none !important;
}

.list-disc {
  list-style: disc !important;
}

.list-decimal {
  list-style: decimal !important;
}

.appearance-none {
  appearance: none !important;
}

.outline-none {
  outline: none !important;
}

.pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.cursor-auto {
  cursor: auto !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-move {
  cursor: move !important;
}

.select-none {
  user-select: none !important;
}

.select-text {
  user-select: text !important;
}

.select-all {
  user-select: all !important;
}

.select-auto {
  user-select: auto !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: .1 !important;
}

.opacity-20 {
  opacity: .2 !important;
}

.opacity-30 {
  opacity: .3 !important;
}

.opacity-40 {
  opacity: .4 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-60 {
  opacity: .6 !important;
}

.opacity-70 {
  opacity: .7 !important;
}

.opacity-80 {
  opacity: .8 !important;
}

.opacity-90 {
  opacity: .9 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.reset {
  all: unset;
}

.transition-none {
  transition-property: none !important;
}

.transition-all {
  transition-property: all !important;
}

.transition-colors {
  transition-property: background-color,border-color,color !important;
}

.transition-transform {
  transition-property: transform !important;
}

.transition-duration-100 {
  transition-duration: 100ms !important;
}

.transition-duration-150 {
  transition-duration: 150ms !important;
}

.transition-duration-200 {
  transition-duration: 200ms !important;
}

.transition-duration-300 {
  transition-duration: 300ms !important;
}

.transition-duration-400 {
  transition-duration: 400ms !important;
}

.transition-duration-500 {
  transition-duration: 500ms !important;
}

.transition-duration-1000 {
  transition-duration: 1000ms !important;
}

.transition-duration-2000 {
  transition-duration: 2000ms !important;
}

.transition-duration-3000 {
  transition-duration: 3000ms !important;
}

.transition-linear {
  transition-timing-function: linear !important;
}

.transition-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.transition-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.transition-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.transition-delay-100 {
  transition-delay: 100ms !important;
}

.transition-delay-150 {
  transition-delay: 150ms !important;
}

.transition-delay-200 {
  transition-delay: 200ms !important;
}

.transition-delay-300 {
  transition-delay: 300ms !important;
}

.transition-delay-400 {
  transition-delay: 400ms !important;
}

.transition-delay-500 {
  transition-delay: 500ms !important;
}

.transition-delay-1000 {
  transition-delay: 1000ms !important;
}

.translate-x-0 {
  transform: translateX(0%) !important;
}

.translate-x-100 {
  transform: translateX(100%) !important;
}

.-translate-x-100 {
  transform: translateX(-100%) !important;
}

.translate-y-0 {
  transform: translateY(0%) !important;
}

.translate-y-100 {
  transform: translateY(100%) !important;
}

.-translate-y-100 {
  transform: translateY(-100%) !important;
}

@media screen and (min-width: 480px) {
  .xs\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .xs\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .xs\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .xs\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .xs\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .xs\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .sm\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .sm\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .sm\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .sm\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .sm\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .md\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .md\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .md\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .md\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .md\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .lg\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .lg\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .lg\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .lg\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .lg\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .xl\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .xl\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .xl\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .xl\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .xl\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:translate-x-0 {
    transform: translateX(0%) !important;
  }
  .xxl\:translate-x-100 {
    transform: translateX(100%) !important;
  }
  .xxl\:-translate-x-100 {
    transform: translateX(-100%) !important;
  }
  .xxl\:translate-y-0 {
    transform: translateY(0%) !important;
  }
  .xxl\:translate-y-100 {
    transform: translateY(100%) !important;
  }
  .xxl\:-translate-y-100 {
    transform: translateY(-100%) !important;
  }
}
.rotate-45 {
  transform: rotate(45deg) !important;
}

.-rotate-45 {
  transform: rotate(-45deg) !important;
}

.rotate-90 {
  transform: rotate(90deg) !important;
}

.-rotate-90 {
  transform: rotate(-90deg) !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.-rotate-180 {
  transform: rotate(-180deg) !important;
}

@media screen and (min-width: 480px) {
  .xs\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .xs\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .xs\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .xs\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .xs\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .xs\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .sm\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .sm\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .sm\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .sm\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .sm\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .md\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .md\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .md\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .md\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .md\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .lg\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .lg\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .lg\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .lg\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .lg\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .xl\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .xl\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .xl\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .xl\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .xl\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:rotate-45 {
    transform: rotate(45deg) !important;
  }
  .xxl\:-rotate-45 {
    transform: rotate(-45deg) !important;
  }
  .xxl\:rotate-90 {
    transform: rotate(90deg) !important;
  }
  .xxl\:-rotate-90 {
    transform: rotate(-90deg) !important;
  }
  .xxl\:rotate-180 {
    transform: rotate(180deg) !important;
  }
  .xxl\:-rotate-180 {
    transform: rotate(-180deg) !important;
  }
}
.origin-center {
  transform-origin: center !important;
}

.origin-top {
  transform-origin: top !important;
}

.origin-top-right {
  transform-origin: top right !important;
}

.origin-right {
  transform-origin: right !important;
}

.origin-bottom-right {
  transform-origin: bottom right !important;
}

.origin-bottom {
  transform-origin: bottom !important;
}

.origin-bottom-left {
  transform-origin: bottom left !important;
}

.origin-left {
  transform-origin: left !important;
}

.origin-top-left {
  transform-origin: top-left !important;
}

@media screen and (min-width: 480px) {
  .xs\:origin-center {
    transform-origin: center !important;
  }
  .xs\:origin-top {
    transform-origin: top !important;
  }
  .xs\:origin-top-right {
    transform-origin: top right !important;
  }
  .xs\:origin-right {
    transform-origin: right !important;
  }
  .xs\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .xs\:origin-bottom {
    transform-origin: bottom !important;
  }
  .xs\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .xs\:origin-left {
    transform-origin: left !important;
  }
  .xs\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 576px) {
  .sm\:origin-center {
    transform-origin: center !important;
  }
  .sm\:origin-top {
    transform-origin: top !important;
  }
  .sm\:origin-top-right {
    transform-origin: top right !important;
  }
  .sm\:origin-right {
    transform-origin: right !important;
  }
  .sm\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .sm\:origin-bottom {
    transform-origin: bottom !important;
  }
  .sm\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .sm\:origin-left {
    transform-origin: left !important;
  }
  .sm\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 768px) {
  .md\:origin-center {
    transform-origin: center !important;
  }
  .md\:origin-top {
    transform-origin: top !important;
  }
  .md\:origin-top-right {
    transform-origin: top right !important;
  }
  .md\:origin-right {
    transform-origin: right !important;
  }
  .md\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .md\:origin-bottom {
    transform-origin: bottom !important;
  }
  .md\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .md\:origin-left {
    transform-origin: left !important;
  }
  .md\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 992px) {
  .lg\:origin-center {
    transform-origin: center !important;
  }
  .lg\:origin-top {
    transform-origin: top !important;
  }
  .lg\:origin-top-right {
    transform-origin: top right !important;
  }
  .lg\:origin-right {
    transform-origin: right !important;
  }
  .lg\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .lg\:origin-bottom {
    transform-origin: bottom !important;
  }
  .lg\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .lg\:origin-left {
    transform-origin: left !important;
  }
  .lg\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 1200px) {
  .xl\:origin-center {
    transform-origin: center !important;
  }
  .xl\:origin-top {
    transform-origin: top !important;
  }
  .xl\:origin-top-right {
    transform-origin: top right !important;
  }
  .xl\:origin-right {
    transform-origin: right !important;
  }
  .xl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .xl\:origin-bottom {
    transform-origin: bottom !important;
  }
  .xl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .xl\:origin-left {
    transform-origin: left !important;
  }
  .xl\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@media screen and (min-width: 1400px) {
  .xxl\:origin-center {
    transform-origin: center !important;
  }
  .xxl\:origin-top {
    transform-origin: top !important;
  }
  .xxl\:origin-top-right {
    transform-origin: top right !important;
  }
  .xxl\:origin-right {
    transform-origin: right !important;
  }
  .xxl\:origin-bottom-right {
    transform-origin: bottom right !important;
  }
  .xxl\:origin-bottom {
    transform-origin: bottom !important;
  }
  .xxl\:origin-bottom-left {
    transform-origin: bottom left !important;
  }
  .xxl\:origin-left {
    transform-origin: left !important;
  }
  .xxl\:origin-top-left {
    transform-origin: top-left !important;
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scalein {
  0% {
    opacity: 0;
    transform: scaleY(0.8);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: scaleY(1);
  }
}
@keyframes slidedown {
  0% {
    max-height: 0;
  }
  100% {
    max-height: auto;
  }
}
@keyframes slideup {
  0% {
    max-height: 1000px;
  }
  100% {
    max-height: 0;
  }
}
@keyframes fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeoutleft {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
}
@keyframes fadeinright {
  0% {
    opacity: 0;
    transform: translateX(100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(100%);
  }
}
@keyframes fadeinup {
  0% {
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeoutup {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
@keyframes fadeindown {
  0% {
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0%);
    transition: transform 0.12s cubic-bezier(0, 0, 0.2, 1), opacity 0.12s cubic-bezier(0, 0, 0.2, 1);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
@keyframes animate-width {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes flip {
  from {
    transform: perspective(2000px) rotateX(-100deg);
  }
  to {
    transform: perspective(2000px) rotateX(0);
  }
}
@keyframes flipleft {
  from {
    transform: perspective(2000px) rotateY(-100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@keyframes flipright {
  from {
    transform: perspective(2000px) rotateY(100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateY(0);
    opacity: 1;
  }
}
@keyframes flipup {
  from {
    transform: perspective(2000px) rotateX(-100deg);
    opacity: 0;
  }
  to {
    transform: perspective(2000px) rotateX(0);
    opacity: 1;
  }
}
@keyframes zoomin {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes zoomindown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
  }
}
@keyframes zoominleft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
  }
}
@keyframes zoominright {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
  }
}
@keyframes zoominup {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
  }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
  }
}
.fadein {
  animation: fadein 0.15s linear;
}

.fadeout {
  animation: fadeout 0.15s linear;
}

.slidedown {
  animation: slidedown 0.45s ease-in-out;
}

.slideup {
  animation: slideup 0.45s cubic-bezier(0, 1, 0, 1);
}

.scalein {
  animation: scalein 0.15s linear;
}

.fadeinleft {
  animation: fadeinleft 0.15s linear;
}

.fadeoutleft {
  animation: fadeoutleft 0.15s linear;
}

.fadeinright {
  animation: fadeinright 0.15s linear;
}

.fadeoutright {
  animation: fadeoutright 0.15s linear;
}

.fadeinup {
  animation: fadeinup 0.15s linear;
}

.fadeoutup {
  animation: fadeoutup 0.15s linear;
}

.fadeindown {
  animation: fadeindown 0.15s linear;
}

.fadeoutdown {
  animation: fadeoutdown 0.15s linear;
}

.animate-width {
  animation: animate-width 1000ms linear;
}

.flip {
  backface-visibility: visible;
  animation: flip 0.15s linear;
}

.flipup {
  backface-visibility: visible;
  animation: flipup 0.15s linear;
}

.flipleft {
  backface-visibility: visible;
  animation: flipleft 0.15s linear;
}

.flipright {
  backface-visibility: visible;
  animation: flipright 0.15s linear;
}

.zoomin {
  animation: zoomin 0.15s linear;
}

.zoomindown {
  animation: zoomindown 0.15s linear;
}

.zoominleft {
  animation: zoominleft 0.15s linear;
}

.zoominright {
  animation: zoominright 0.15s linear;
}

.zoominup {
  animation: zoominup 0.15s linear;
}

.animation-duration-100 {
  animation-duration: 100ms !important;
}

.animation-duration-150 {
  animation-duration: 150ms !important;
}

.animation-duration-200 {
  animation-duration: 200ms !important;
}

.animation-duration-300 {
  animation-duration: 300ms !important;
}

.animation-duration-400 {
  animation-duration: 400ms !important;
}

.animation-duration-500 {
  animation-duration: 500ms !important;
}

.animation-duration-1000 {
  animation-duration: 1000ms !important;
}

.animation-duration-2000 {
  animation-duration: 2000ms !important;
}

.animation-duration-3000 {
  animation-duration: 3000ms !important;
}

.animation-delay-100 {
  animation-delay: 100ms !important;
}

.animation-delay-150 {
  animation-delay: 150ms !important;
}

.animation-delay-200 {
  animation-delay: 200ms !important;
}

.animation-delay-300 {
  animation-delay: 300ms !important;
}

.animation-delay-400 {
  animation-delay: 400ms !important;
}

.animation-delay-500 {
  animation-delay: 500ms !important;
}

.animation-delay-1000 {
  animation-delay: 1000ms !important;
}

.animation-iteration-1 {
  animation-iteration-count: 1 !important;
}

.animation-iteration-2 {
  animation-iteration-count: 2 !important;
}

.animation-iteration-infinite {
  animation-iteration-count: infinite !important;
}

.animation-linear {
  animation-timing-function: linear !important;
}

.animation-ease-in {
  animation-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.animation-ease-out {
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.animation-ease-in-out {
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.animation-fill-none {
  animation-fill-mode: none !important;
}

.animation-fill-forwards {
  animation-fill-mode: forwards !important;
}

.animation-fill-backwards {
  animation-fill-mode: backwards !important;
}

.animation-fill-both {
  animation-fill-mode: both !important;
}

@font-face {
  font-family: "rutsio";
  src: url("../../assets/fonts/icons/rutsio.ttf?natsyu") format("truetype"), url("../../assets/fonts/icons/rutsio.woff?natsyu") format("woff"), url("../../assets/fonts/icons/rutsio.svg?natsyu#rutsio") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
.pi {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "rutsio" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-angle-down:before {
  content: "\e91e";
}

.pi-angle-left:before {
  content: "\e91f";
}

.pi-angle-right:before {
  content: "\e91d";
}

.pi-angle-up:before {
  content: "\e91b";
}

.pi-arrow-left:before {
  content: "\e900";
}

.pi-arrow-left-empty:before {
  content: "\e934";
}

.pi-arrow-right:before {
  content: "\e901";
}

.pi-bang:before {
  content: "\e928";
}

.pi-bars:before {
  content: "\e91c";
}

.pi-book:before {
  content: "\e929";
}

.pi-books-stack:before {
  content: "\e92d";
}

.pi-calendar:before {
  content: "\e919";
}

.pi-check:before {
  content: "\e91a";
}

.pi-chevron-down:before {
  content: "\e924";
}

.pi-chevron-left:before {
  content: "\e921";
}

.pi-chevron-right:before {
  content: "\e922";
}

.pi-chevron-up:before {
  content: "\e923";
}

.pi-circle-fill:before {
  content: "\e93f";
}

.pi-clock:before {
  content: "\e90c";
}

.pi-comment:before {
  content: "\e907";
}

.pi-comment-add:before {
  content: "\e92b";
}

.pi-comment-fill:before {
  content: "\e940";
}

.pi-copy:before {
  content: "\e937";
}

.pi-director:before {
  content: "\e939";
}

.pi-document-pack:before {
  content: "\e931";
}

.pi-download:before {
  content: "\e918";
}

.pi-exclamation:before {
  content: "\e938";
}

.pi-exclamation-circle:before {
  content: "\e903";
}

.pi-exclamation-triangle:before {
  content: "\e905";
}

.pi-fish:before {
  content: "\e935";
}

.pi-hammer:before {
  content: "\e92a";
}

.pi-history:before {
  content: "\e93a";
}

.pi-key:before {
  content: "\e90d";
}

.pi-list:before {
  content: "\e917";
}

.pi-lock:before {
  content: "\e902";
}

.pi-lock-fill:before {
  content: "\e926";
}

.pi-lock-open:before {
  content: "\e904";
}

.pi-lock-open-fill:before {
  content: "\e925";
}

.pi-mail-open:before {
  content: "\e930";
}

.pi-more-square:before {
  content: "\e92e";
}

.pi-pause:before {
  content: "\e90a";
}

.pi-pencil:before {
  content: "\e90f";
}

.pi-play:before {
  content: "\e90b";
}

.pi-plus:before {
  content: "\e910";
}

.pi-plus-circle:before {
  content: "\e908";
}

.pi-question-circle:before {
  content: "\e916";
}

.pi-search:before {
  content: "\e914";
}

.pi-shit:before {
  content: "\e92c";
}

.pi-sign-out:before {
  content: "\e93b";
}

.pi-sort-alt:before {
  content: "\e920";
}

.pi-spin:before {
  content: "\e932";
}

.pi-star:before {
  content: "\e906";
}

.pi-star-fill:before {
  content: "\e93e";
}

.pi-status-approve:before {
  content: "\e933";
}

.pi-sync:before {
  content: "\e909";
}

.pi-telegram:before {
  content: "\e915";
}

.pi-times:before {
  content: "\e913";
}

.pi-times-circle:before {
  content: "\e927";
}

.pi-trash:before {
  content: "\e90e";
}

.pi-upload:before {
  content: "\e93d";
}

.pi-user:before {
  content: "\e912";
}

.pi-user-card:before {
  content: "\e92f";
}

.pi-users:before {
  content: "\e911";
}

.pi-users-fill:before {
  content: "\e936";
}

.pi-window:before {
  content: "\e93c";
}

.pi-info-circle:before {
  content: "\e941";
}

* {
  box-sizing: border-box;
}

.p-component {
  font-family: "Golos UI", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  font-weight: normal;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.2s;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-error {
  color: #e4677e;
}

.p-text-secondary {
  color: var(--gray-600);
}

.pi {
  font-size: 1rem;
}

.p-link {
  font-size: 16px;
  font-family: "Golos UI", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: var(--border-radius);
}
.p-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: var(--maskbg);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: var(--maskbg);
  }
  to {
    background-color: transparent;
  }
}

.pi-spin {
  animation: fa-spin 2s infinite linear;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
:root {
  --blue-910: #5454E8;
  --blue-900: #3F74DB;
  --blue-850: #4A87F4;
  --blue-830: #6298F8;
  --blue-800: #5F9EFF;
  --blue-790: #7EB1FE;
  --blue-785: #97C1FF;
  --blue-780: #4194C7;
  --blue-770: #69ADD7;
  --blue-760: #5BB7EE;
  --blue-600: #88B6EC;
  --blue-550: #89D5FF;
  --blue-300: #B0D8FE;
  --blue-200: #CDECFF;
  --blue-120: #EEEEFD;
  --blue-100: #E1F4FF;
  --gray-900: #212121;
  --gray-700: #98A3AA;
  --gray-650: #BAC7CF;
  --gray-600: #CCD6DC;
  --gray-500: #DFE7EC;
  --gray-200: #F0F1F3;
  --gray-150: #F5F6F8;
  --gray-100: #FAFAFC;
  --gray-blue-900: #3279A4;
  --gray-blue-850: #658EA7;
  --gray-blue-830: #6F95AF;
  --gray-blue-700: #A0BCCF;
  --gray-blue-670: #C2D3DF;
  --gray-blue-650: #BED4E2;
  --gray-blue-600: #CCE1F0;
  --gray-blue-550: #C8E2F2;
  --gray-blue-300: #DEEBF3;
  --gray-blue-250: #E7F1F8;
  --gray-blue-200: #EBF4F6;
  --gray-blue-100: #F1F7F9;
  --gray-blue-50: #F5FCFF;
  --green-900: #18CFB0;
  --green-860: #62CD63;
  --green-850: #62D9C5;
  --green-830: #4FE2CA;
  --green-800: #52F9DA;
  --green-750: #93E0D3;
  --green-740: #A7E7DC;
  --green-730: #ADEEDE;
  --green-650: #B5F2E3;
  --green-300: #C6EFE5;
  --green-200: #D6F4ED;
  --green-120: #EFFAEF;
  --green-100: #E5FBF5;
  --orange-900: #F2994A;
  --orange-850: #FFAD64;
  --orange-830: #FFB879;
  --orange-700: #FDC798;
  --orange-600: #FFCDA1;
  --orange-500: #FFD3AB;
  --orange-100: #FEF5ED;
  --purple-900: #A565D8;
  --purple-850: #BB7FEB;
  --purple-830: #CB9CF0;
  --purple-600: #E2BEFF;
  --purple-400: #E8D6FF;
  --purple-300: #EAE1F5;
  --purple-200: #EEE3FC;
  --red-950: #F12B2C;
  --red-900: #F84565;
  --red-850: #FD637F;
  --red-830: #FF7E95;
  --red-700: #F48FA1;
  --red-650: #FF98AA;
  --red-300: #FBCAD2;
  --red-200: #FFE2E7;
  --red-100: #FFF3F5;
  --yellow-900: #F4B438;
  --yellow-850: #FFBE41;
  --yellow-800: #E4C632;
  --yellow-700: #FECF56;
  --yellow-600: #FFDD85;
  --yellow-570: #FFE5A4;
  --yellow-550: #E3E746;
  --yellow-500: #F5F870;
  --yellow-400: #ECEDAF;
  --yellow-300: #F7F9B8;
  --surface-0: #ffffff;
  --surface-50: #fafafa;
  --surface-100: #f5f5f5;
  --surface-200: var(--gray-blue-300);
  --surface-300: #e0e0e0;
  --surface-400: #bdbdbd;
  --surface-500: #9e9e9e;
  --surface-600: #757575;
  --surface-700: #616161;
  --surface-750: #ffffff26;
  --surface-800: #424242;
  --surface-900: #212121;
  --white: #ffffff;
  --black: var(--gray-900);
  --breadcrumb-color: var(--gray-blue-850);
  --breadcrumb-color-hover: var(--blue-760);
  --link-color: var(--blue-770);
  --link-color-dark: #477896;
  --focus-outline-color: var(--blue-900);
  --text-color: var(--black);
  --text-color-light: var(--gray-700);
  --shadow-color-9: rgba(150, 183, 203, 0.9);
  --shadow-color-8: rgba(150, 183, 203, 0.8);
  --shadow-color-8-light: rgba(205, 218, 226, 0.8);
  --shadow-color-7: rgba(150, 183, 203, 0.7);
  --shadow-color-6: rgba(150, 183, 203, 0.6);
  --shadow-color-5: rgba(150, 183, 203, 0.5);
  --shadow-color-3: rgba(150, 183, 203, 0.3);
  --background-color: var(--gray-blue-200);
  --border-color: var(--gray-500);
  --table-border-color: var(--gray-500);
  --maskbg: rgba(0, 0, 0, 0.4);
  --border-radius: 10px;
  --border-radius-sm: 6px;
  --input-height: 40px;
  --input-bg: var(--white);
  --input-bg-hover: var(--gray-100);
  --input-border: none;
  --input-border-hover: none;
  --input-box-shadow: 0px 4px 20px 0px #96b7cb80;
  --input-box-shadow-hover: 0px 2px 10px 0px #96b7cbb2;
  --calendar-input-width: 160px;
  --calendar-input-height: calc(var(--input-height) - 10px);
}

.p-autocomplete .p-inputtext {
  border-color: transparent !important;
}
.p-autocomplete .p-autocomplete-loader {
  right: 1rem;
}
.p-autocomplete.p-autocomplete-dd .p-autocomplete-loader {
  right: 3.357rem;
}
.p-autocomplete .p-autocomplete-multiple-container {
  padding: 0.25rem 1rem;
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled):hover {
  border-color: var(--blue-760);
}
.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  border-color: var(--blue-760);
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token {
  padding: 0.25rem 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-input-token input {
  font-family: "Golos UI", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  color: #000;
  padding: 0;
  margin: 0;
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token {
  padding: 0.25rem 1rem;
  margin-right: 0.5rem;
  background: #3f74db;
  color: var(--white);
  border-radius: var(--border-radius);
}
.p-autocomplete .p-autocomplete-multiple-container .p-autocomplete-token .p-autocomplete-token-icon {
  margin-left: 0.5rem;
}
.p-autocomplete ::-webkit-scrollbar {
  width: 15px;
}
.p-autocomplete ::-webkit-scrollbar:horizontal {
  height: 15px;
}
.p-autocomplete ::-webkit-scrollbar-thumb {
  background: var(--gray-600);
  border: 5px solid transparent;
  border-radius: 7px;
  background-clip: content-box;
}
.p-autocomplete ::-webkit-scrollbar-thumb,
.p-autocomplete ::-webkit-scrollbar-thumb:hover {
  background: var(--gray-600);
  border: 5px solid transparent;
  border-radius: 7px;
  background-clip: content-box;
}
.p-autocomplete ::-webkit-scrollbar-track:hover {
  border: 1px solid var(--gray-650);
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
  border-color: #e4677e !important;
}

.p-autocomplete-panel {
  background: var(--white);
  color: #000;
  border: 2px solid var(--gray-500);
  border-radius: var(--border-radius);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-autocomplete-panel .p-autocomplete-items:not(.p-autocomplete-virtualscroll) {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items.p-autocomplete-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0.5rem 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #000;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item:hover {
  color: #000;
  background: var(--green-730);
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  color: var(--white);
  background: #3f74db;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-empty-message {
  padding: 0.5rem 1rem;
  color: #000;
  background: transparent;
}
.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #000;
  background: var(--white);
  font-weight: 600;
}

.p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #e4677e !important;
}

.p-calendar input {
  width: var(--calendar-input-width);
  height: var(--calendar-input-height);
  padding: 0 10px !important;
  font-size: 14px;
  border: var(--input-border);
  border-radius: var(--border-radius) !important;
}

.p-datepicker {
  width: 300px !important;
  padding: 0.5rem;
  background: var(--white);
  color: #000;
  border: 2px solid var(--gray-500);
  border-radius: var(--border-radius);
  z-index: 10007 !important;
}
.p-datepicker:not(.p-datepicker-inline) {
  background: var(--white);
  border: none;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: var(--white);
}
.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: #000;
  background: var(--white);
  font-weight: 600;
  margin: 0;
  border-bottom: 1px solid var(--gray-blue-670);
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  width: 36px;
  height: 36px;
  color: #6f95ae;
  background: none !important;
  border: 0 none;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev:focus,
.p-datepicker .p-datepicker-header .p-datepicker-next:focus {
  box-shadow: none !important;
}
.p-datepicker .p-datepicker-header .p-datepicker-prev .pi,
.p-datepicker .p-datepicker-header .p-datepicker-next .pi {
  font-size: 20px;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  padding: 7px 25px;
  color: #333333;
  font-weight: 700;
  font-size: 18px;
  border-radius: 4px;
  background: var(--background-color);
}
.p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datepicker .p-datepicker-header .p-datepicker-title select:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  border-color: var(--blue-760);
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 0.5rem;
}
.p-datepicker table {
  font-size: 16px;
  margin: 0.5rem 0;
}
.p-datepicker table th {
  color: var(--gray-blue-830);
  font-weight: 500;
  padding: 0.5rem;
}
.p-datepicker table th:nth-last-child(1), .p-datepicker table th:nth-last-child(2) {
  color: var(--red-900);
}
.p-datepicker table th > span {
  width: 34px;
  height: 34px;
}
.p-datepicker table td > span > span {
  box-shadow: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  color: var(--text-color);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border: 2px solid transparent;
}
.p-datepicker table td > span.p-disabled span {
  color: var(--gray-blue-670);
}
.p-datepicker table td > span.p-highlight span {
  color: var(--text-color);
  background: var(--green-730) !important;
  border: 2px solid var(--green-900);
}
.p-datepicker table td > span:focus span {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-datepicker table td.p-datepicker-today > span span {
  height: 34px;
  width: 34px;
  background: var(--gray-blue-200) !important;
  color: #000;
  border-radius: 4px;
  border-color: transparent;
}
.p-datepicker table td.p-datepicker-today > span span.p-highlight span {
  background: var(--green-730);
  border-color: var(--green-900);
  box-shadow: none;
}
.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid var(--gray-blue-670);
}
.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}
.p-datepicker .p-timepicker {
  border-top: 1px solid var(--gray-blue-670);
  padding: 0.5rem;
}
.p-datepicker .p-timepicker button {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  width: 34px;
  height: 34px;
  background-color: transparent !important;
  color: var(--gray-600);
}
.p-datepicker .p-timepicker button:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-datepicker .p-timepicker button:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-datepicker .p-timepicker button:last-child {
  margin-top: 0.2em;
}
.p-datepicker .p-timepicker .p-hour-picker > span,
.p-datepicker .p-timepicker .p-minute-picker > span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: var(--text-color);
  background: var(--background-color);
  border-radius: 4px;
}
.p-datepicker .p-timepicker .p-separator span {
  color: var(--text-color);
}
.p-datepicker .p-timepicker span {
  color: var(--gray-600);
  font-size: 20px;
}
.p-datepicker .p-timepicker span:hover {
  color: var(--text-color);
}
.p-datepicker .p-timepicker > div {
  padding: 0 0.429rem;
}
.p-datepicker.p-datepicker-timeonly .p-timepicker {
  border-top: 0 none;
}
.p-datepicker .p-monthpicker {
  margin: 0.5rem 0;
}
.p-datepicker .p-monthpicker .p-monthpicker-month {
  padding: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
}
.p-datepicker .p-monthpicker .p-monthpicker-month.p-highlight {
  color: var(--white);
  background: #3f74db;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group {
  border-right: 1px solid var(--gray-blue-670);
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:first-child {
  padding-left: 0;
}
.p-datepicker.p-datepicker-multiple-month .p-datepicker-group:last-child {
  padding-right: 0;
  border-right: 0 none;
}
.p-datepicker:not(.p-disabled) table td > span:not(.p-highlight):not(.p-disabled):hover span {
  background: var(--green-100) !important;
}
.p-datepicker:not(.p-disabled) table td > span:not(.p-highlight):not(.p-disabled):focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):hover {
  background: none;
}
.p-datepicker:not(.p-disabled) .p-monthpicker .p-monthpicker-month:not(.p-highlight):not(.p-disabled):focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

@media screen and (max-width: 769px) {
  .p-datepicker table th,
  .p-datepicker table td {
    padding: 0;
  }
}
.p-cascadeselect {
  background: var(--gray-100);
  border: 2px solid var(--gray-500);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
}
.p-cascadeselect:not(.p-disabled):hover {
  border-color: var(--blue-760);
}
.p-cascadeselect:not(.p-disabled).p-focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  border-color: var(--blue-760);
}
.p-cascadeselect .p-cascadeselect-label {
  background: transparent;
  border: 0 none;
  padding: 0.5rem 1rem;
}
.p-cascadeselect .p-cascadeselect-label.p-placeholder {
  color: var(--gray-700);
}
.p-cascadeselect .p-cascadeselect-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-cascadeselect .p-cascadeselect-trigger {
  background: transparent;
  color: #c4c4c4;
  width: 2.357rem;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.p-cascadeselect-panel {
  background: var(--white);
  color: #000;
  border: 2px solid var(--gray-500);
  border-radius: var(--border-radius);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-cascadeselect-panel .p-cascadeselect-items {
  padding: 0.5rem 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item {
  margin: 0;
  border: 0 none;
  color: #000;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content {
  padding: 0.5rem 1rem;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-item-content:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item.p-highlight {
  color: var(--white);
  background: #3f74db;
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #000;
  background: var(--green-730);
}
.p-cascadeselect-panel .p-cascadeselect-items .p-cascadeselect-item .p-cascadeselect-group-icon {
  font-size: 0.875rem;
}

p-cascadeselect.ng-dirty.ng-invalid > .p-cascadeselect {
  border-color: #e4677e !important;
}

.p-input-filled .p-cascadeselect {
  background: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-cascadeselect:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

.p-checkbox {
  width: 24px;
  height: 24px;
}
.p-checkbox .p-checkbox-box {
  border: 2px solid var(--gray-500);
  background: var(--gray-100);
  width: 24px;
  height: 24px;
  color: #000;
  border-radius: 6px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-checkbox .p-checkbox-box:hover {
  border: 2px solid var(--gray-600);
}
.p-checkbox .p-checkbox-box .p-checkbox-icon {
  margin: 0;
  transition-duration: 0.2s;
  color: var(--white);
  font-size: 14px;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--gray-500);
  background: var(--green-900);
}
p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #e4677e !important;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  background: var(--green-900);
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--green-900);
}

.p-checkbox-label {
  margin-left: 0.5rem;
}

.p-chips .p-chips-multiple-container {
  padding: 0.25rem 1rem;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: var(--blue-760);
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  border-color: var(--blue-760);
}
.p-chips .p-chips-multiple-container .p-chips-token {
  padding: 0.25rem 1rem;
  margin-right: 0.5rem;
  background: #3f74db;
  color: var(--white);
  border-radius: var(--border-radius);
}
.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
  margin-left: 0.5rem;
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
  padding: 0.25rem 0;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-family: "Golos UI", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 16px;
  color: #000;
  padding: 0;
  margin: 0;
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
  border-color: #e4677e !important;
}

.p-colorpicker-preview,
.p-fluid .p-colorpicker-preview.p-inputtext {
  width: 2rem;
  height: 2rem;
}

.p-colorpicker-panel {
  background: #323232;
  border-color: #191919;
}
.p-colorpicker-panel .p-colorpicker-color-handle,
.p-colorpicker-panel .p-colorpicker-hue-handle {
  border-color: var(--white);
}

.p-colorpicker-overlay-panel {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

.p-dropdown {
  width: 100%;
  text-align: left;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
  background-color: var(--input-bg);
  border: var(--input-border);
  box-shadow: var(--input-box-shadow);
}
.p-dropdown:hover {
  background-color: var(--input-bg-hover);
  border: var(--input-border-hover);
  box-shadow: var(--input-box-shadow-hover);
}
.p-dropdown:not(.p-disabled).p-focus, .p-dropdown:not(.p-disabled).p-inputwrapper-focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  border-color: var(--blue-760);
}
.p-dropdown.p-dropdown-clearable .p-dropdown-label {
  padding-right: 2rem;
}
.p-dropdown .p-dropdown-label {
  height: var(--input-height);
  line-height: 24px;
  --input-bg: none;
  --input-bg-hover: none;
  --input-border: none;
  --input-border-hover: none;
  --input-box-shadow: none;
  --input-box-shadow-hover: none;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  margin-top: 0;
  color: var(--gray-700);
  line-height: 23px;
}
.p-dropdown .p-dropdown-label:enabled:focus {
  outline: 0 none;
  box-shadow: none;
}
.p-dropdown .p-dropdown-label:focus-visible {
  outline: 0 none;
}
.p-dropdown .p-dropdown-trigger {
  background: transparent;
  color: var(--gray-600);
  border-left: 2px solid var(--gray-500);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.p-dropdown .p-dropdown-clear-icon {
  color: var(--gray-600);
  right: 3rem;
}

.p-inputwrapper-focus span.p-inputtext {
  visibility: hidden;
}

.p-dropdown-panel {
  margin-top: 3px;
  background: var(--white);
  color: #000;
  border: none;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 20px var(--shadow-color-5);
}
.p-dropdown-panel ::-webkit-scrollbar {
  width: 15px;
}
.p-dropdown-panel ::-webkit-scrollbar:horizontal {
  height: 15px;
}
.p-dropdown-panel ::-webkit-scrollbar-thumb {
  background: var(--gray-600);
  border: 5px solid transparent;
  border-radius: 7px;
  background-clip: content-box;
}
.p-dropdown-panel ::-webkit-scrollbar-thumb,
.p-dropdown-panel ::-webkit-scrollbar-thumb:hover {
  background: var(--gray-600);
  border: 5px solid transparent;
  border-radius: 7px;
  background-clip: content-box;
}
.p-dropdown-panel ::-webkit-scrollbar-track:hover {
  border: 1px solid var(--gray-650);
}
.p-dropdown-panel .p-dropdown-header {
  position: absolute;
  left: 0;
  width: 90%;
  padding: 0;
  background: transparent !important;
  border-bottom: 0 none;
  color: #000;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  margin: calc(-1 * var(--input-height) - 3px) 0 0;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter {
  padding-right: 2rem;
  margin-right: -2rem;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter.p-inputtext.p-component {
  padding: 0 16px;
  border: 0;
  transition: none;
  background: transparent !important;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter.p-inputtext:enabled:focus {
  box-shadow: none;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon {
  right: 1rem;
  color: #c4c4c4;
}
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon.pi.pi-search {
  display: none;
}
.p-dropdown-panel .p-dropdown-items {
  list-style: none;
}
.p-dropdown-panel .p-dropdown-items:not(.p-dropdown-virtualscroll) {
  padding: 0.5rem 0;
  max-height: 200px;
}
.p-dropdown-panel .p-dropdown-items.p-dropdown-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0.5rem 0;
}
.p-dropdown-panel .p-dropdown-items p-dropdownitem .p-dropdown-item {
  background-color: var(--gray-100) !important;
}
.p-dropdown-panel .p-dropdown-items p-dropdownitem:nth-child(odd) .p-dropdown-item {
  background-color: var(--gray-150) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #000;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: inherit;
  background: #3f74db;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.editable {
  background-color: var(--yellow-300);
  border-color: var(--yellow-300) !important;
  color: #000;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight.editable:hover {
  color: #000;
  background-color: var(--yellow-300);
  border-color: var(--yellow-300);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  color: #000;
  background: var(--green-730) !important;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message {
  padding: 0.5rem 1rem;
  color: #000;
  background: transparent;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #000;
  background: var(--white);
  font-weight: 600;
}
.p-dropdown-panel .p-dropdown-items #p-highlighted-option {
  background: var(--green-730) !important;
}
.p-dropdown-panel .p-dropdown-items-wrapper {
  border-radius: var(--border-radius);
}

p-dropdown.ng-dirty.ng-invalid > .p-dropdown {
  border-color: #e4677e !important;
}

.p-input-filled .p-dropdown {
  background: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-dropdown:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

.c-select-icon .p-dropdown-trigger {
  border-left: none !important;
}
.c-select-icon chevrondownicon svg {
  width: 18px;
  height: 18px;
  color: var(--gray-600);
}

.p-editor-container .p-editor-toolbar {
  background: #f8f9fa;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-editor-container .p-editor-toolbar.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-stroke {
  stroke: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-fill {
  fill: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label {
  border: 0 none;
  color: #6c757d;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker .ql-picker-label:hover .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  color: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
  stroke: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
  fill: #495057;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: var(--white);
  border: 2px solid var(--gray-500);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
  padding: 0.5rem 0;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item {
  color: #000;
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options .ql-picker-item:hover {
  color: #000;
  background: var(--green-730);
}
.p-editor-container .p-editor-toolbar.ql-snow .ql-picker.ql-expanded:not(.ql-icon-picker) .ql-picker-item {
  padding: 0.5rem 1rem;
}
.p-editor-container .p-editor-content {
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-editor-container .p-editor-content.ql-snow {
  border: 1px solid #dee2e6;
}
.p-editor-container .p-editor-content .ql-editor {
  background: var(--gray-100);
  color: #000;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-editor-container .ql-snow.ql-toolbar button:hover,
.p-editor-container .ql-snow.ql-toolbar button:focus {
  color: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button:hover .ql-fill,
.p-editor-container .ql-snow.ql-toolbar button:focus .ql-fill {
  fill: #495057;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
  color: #3f74db;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke {
  stroke: #3f74db;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: #3f74db;
}
.p-editor-container .ql-snow.ql-toolbar button.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-picker-label,
.p-editor-container .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-picker-label {
  color: #3f74db;
}

.p-inputgroup-addon {
  background: #e9ecef;
  color: var(--gray-600);
  border-top: 2px solid var(--gray-500);
  border-left: 2px solid var(--gray-500);
  border-bottom: 2px solid var(--gray-500);
  padding: 0.5rem 1rem;
  min-width: 2.357rem;
}
.p-inputgroup-addon:last-child {
  border-right: 2px solid var(--gray-500);
}

.p-inputgroup > .p-component,
.p-inputgroup > .p-element,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext,
.p-inputgroup > .p-float-label > .p-component {
  border-radius: 0;
  margin: 0;
}
.p-inputgroup > .p-component + .p-inputgroup-addon,
.p-inputgroup > .p-element + .p-inputgroup-addon,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext + .p-inputgroup-addon,
.p-inputgroup > .p-float-label > .p-component + .p-inputgroup-addon {
  border-left: 0 none;
}
.p-inputgroup > .p-component:focus,
.p-inputgroup > .p-element:focus,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus,
.p-inputgroup > .p-float-label > .p-component:focus {
  z-index: 1;
}
.p-inputgroup > .p-component:focus ~ label,
.p-inputgroup > .p-element:focus ~ label,
.p-inputgroup > .p-inputwrapper > .p-component > .p-inputtext:focus ~ label,
.p-inputgroup > .p-float-label > .p-component:focus ~ label {
  z-index: 1;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child,
.p-inputgroup > .p-inputwrapper:first-child > .p-component,
.p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.p-inputgroup .p-float-label:first-child input {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.p-inputgroup-addon:last-child,
.p-inputgroup button:last-child,
.p-inputgroup input:last-child,
.p-inputgroup > .p-inputwrapper:last-child > .p-component,
.p-inputgroup > .p-inputwrapper:last-child > .p-component > .p-inputtext {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.p-inputgroup .p-float-label:last-child input {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.p-fluid .p-inputgroup .p-button {
  width: auto;
}
.p-fluid .p-inputgroup .p-button.p-button-icon-only {
  width: 2.357rem;
}

p-inputmask.ng-dirty.ng-invalid > .p-inputtext {
  border-color: #e4677e !important;
}

p-inputnumber.ng-dirty.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #e4677e !important;
}

.p-inputswitch {
  width: 36px;
  height: 18px;
}
.p-inputswitch .p-inputswitch-slider {
  background: var(--red-900);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border: 1px solid var(--gray-500);
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: var(--gray-100);
  width: 16.5px;
  height: 16.5px;
  left: 0;
  margin-top: -8px;
  border-radius: 50%;
  transition-duration: 0.2s;
  box-shadow: -0.2px 0 var(--gray-500);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(16.5px);
}
.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #ff778f;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: var(--green-900);
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  box-shadow: 0.6px 0 var(--gray-500);
}
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background: #3fe1c5;
}

p-inputswitch.ng-dirty.ng-invalid > .p-inputswitch {
  border-color: #e4677e !important;
}

.p-inputtext {
  height: var(--input-height);
  border-radius: var(--border-radius);
  background-color: var(--input-bg);
  border: var(--input-border);
  box-shadow: var(--input-box-shadow);
  width: 100%;
  font-family: "Golos UI", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #000;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  appearance: none;
}
.p-inputtext:hover {
  background-color: var(--input-bg-hover);
  border: var(--input-border-hover);
  box-shadow: var(--input-box-shadow-hover);
}
.p-inputtext:enabled:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  border-color: var(--blue-760);
}
.p-inputtext.ng-dirty.ng-invalid {
  border-color: #e4677e !important;
}
.p-inputtext.p-inputtext-sm {
  font-size: 0.875rem;
  padding: 0.4375rem 0.875rem;
}
.p-inputtext.p-inputtext-lg {
  font-size: 1.25rem;
  padding: 0.625rem 1.25rem;
}
.p-inputtext.ng-invalid.ng-touched {
  background: #fcf2f6;
  border: 2px solid var(--red-850);
}

.p-float-label > label {
  left: 1rem;
  color: var(--gray-700);
  transition-duration: 0.2s;
}

.p-float-label > .ng-invalid.ng-dirty + label {
  color: #e4677e;
}

.p-input-icon-left.p-float-label > label {
  left: 3rem;
}

.p-input-icon-right > i:last-of-type {
  right: 1rem;
  color: #c4c4c4;
}

.p-input-icon-right > .p-inputtext {
  padding-right: 3rem;
}

::-webkit-input-placeholder {
  color: var(--gray-700);
}

:-moz-placeholder {
  color: var(--gray-700);
}

::-moz-placeholder {
  color: var(--gray-700);
}

:-ms-input-placeholder {
  color: var(--gray-700);
}

.p-input-filled .p-inputtext {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-inputtext:enabled:focus {
  background-color: #f8f9fa;
}

.p-inputtext-sm .p-inputtext {
  font-size: 0.875rem;
  padding: 0.4375rem 0.875rem;
}

.p-inputtext-lg .p-inputtext {
  font-size: 1.25rem;
  padding: 0.625rem 1.25rem;
}

.p-listbox {
  background: var(--white);
  color: #000;
  border: 2px solid var(--gray-500);
  border-radius: var(--border-radius);
}
.p-listbox .p-listbox-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #000;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-listbox .p-listbox-header .p-listbox-filter {
  padding-right: 2rem;
}
.p-listbox .p-listbox-header .p-listbox-filter-icon {
  right: 1rem;
  color: #c4c4c4;
}
.p-listbox .p-listbox-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list {
  padding: 0.5rem 0;
}
.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #000;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-listbox .p-listbox-list .p-listbox-item.p-highlight {
  color: var(--white);
  background: #3f74db;
}
.p-listbox .p-listbox-list .p-listbox-item:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-listbox .p-listbox-list .p-listbox-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-listbox .p-listbox-list .p-listbox-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #000;
  background: var(--white);
  font-weight: 600;
}
.p-listbox .p-listbox-list .p-listbox-empty-message {
  padding: 0.5rem 1rem;
  color: #000;
  background: transparent;
}
.p-listbox:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover {
  color: #000;
  background: var(--green-730);
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: #e4677e !important;
}

.p-multiselect {
  background: var(--gray-100);
  border: 2px solid var(--gray-500);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
  width: 100%;
  border-radius: var(--border-radius);
  background-color: var(--input-bg);
  border: var(--input-border);
  box-shadow: var(--input-box-shadow);
}
.p-multiselect:hover {
  background-color: var(--input-bg-hover);
  border: var(--input-border-hover);
  box-shadow: var(--input-box-shadow-hover);
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  border-color: var(--blue-760);
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--gray-700);
}
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 0.25rem 1rem;
  margin-right: 0.5rem;
  background: #3f74db;
  color: var(--white);
  border-radius: var(--border-radius);
}
.p-multiselect.p-multiselect-chip .p-multiselect-token .p-multiselect-token-icon {
  margin-left: 0.5rem;
}
.p-multiselect .p-multiselect-trigger {
  background: transparent;
  color: #c4c4c4;
  width: 2.357rem;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.p-inputwrapper-filled .p-multiselect.p-multiselect-chip .p-multiselect-label {
  padding: 0.25rem 1rem;
}

.p-multiselect-panel {
  margin-top: 3px;
  background: var(--white);
  color: #000;
  border: 2px solid var(--gray-500);
  border-radius: var(--border-radius);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-multiselect-panel .p-multiselect-header {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #000;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext {
  padding-right: 2rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 1rem;
  color: #c4c4c4;
}
.p-multiselect-panel .p-multiselect-header .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
  margin-left: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-multiselect-panel .p-multiselect-header .p-multiselect-close:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items.p-multiselect-virtualscroll .cdk-virtual-scroll-content-wrapper {
  padding: 0.5rem 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #000;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #000;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  color: #000;
  background: var(--green-100);
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
  margin-right: 0.5rem;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #000;
  background: var(--white);
  font-weight: 600;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
  padding: 0.5rem 1rem;
  color: #000;
  background: transparent;
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
  border-color: #e4677e !important;
}

.p-input-filled .p-multiselect {
  background: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-multiselect:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

p-password.ng-invalid.ng-dirty > .p-password > .p-inputtext {
  border-color: #e4677e !important;
}

.p-password-panel {
  padding: 1rem;
  background: var(--white);
  color: #000;
  border: 0 none;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
}
.p-password-panel .p-password-meter {
  margin-bottom: 0.5rem;
  background: #dee2e6;
}
.p-password-panel .p-password-meter .p-password-strength.weak {
  background: #e53935;
}
.p-password-panel .p-password-meter .p-password-strength.medium {
  background: #ffb300;
}
.p-password-panel .p-password-meter .p-password-strength.strong {
  background: #43a047;
}

p-password.p-password-clearable .p-password-input {
  padding-right: 3rem;
}
p-password.p-password-clearable .p-password-clear-icon {
  color: #c4c4c4;
  right: 1rem;
}

p-password.p-password-clearable.p-password-mask .p-password-input {
  padding-right: 5rem;
}
p-password.p-password-clearable.p-password-mask .p-password-clear-icon {
  color: #c4c4c4;
  right: 3rem;
}

.p-radiobutton {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
@media only screen and (max-width:768px) {
  .p-radiobutton {
    width: 26px;
    height: 26px;
  }
}
.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: var(--gray-100);
  color: #000;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  width: 20px;
  height: 20px;
}
.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: var(--blue-760);
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 8px;
  height: 8px;
  transition-duration: 0.2s;
  background-color: var(--white);
}
@media only screen and (max-width:768px) {
  .p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
    width: 10px;
    height: 10px;
  }
}
.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: var(--green-900);
  background: var(--green-900);
}
.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--green-900);
  background: var(--green-900);
  color: var(--white);
}
@media only screen and (max-width:768px) {
  .p-radiobutton .p-radiobutton-box {
    width: 26px;
    height: 26px;
  }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
  border-color: #e4677e !important;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  background: var(--green-900);
}
.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  background: var(--green-900);
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.p-rating .p-rating-icon {
  color: #000;
  margin-left: 0.5rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  font-size: 1.143rem;
}
.p-rating .p-rating-icon.p-rating-cancel {
  color: #e74c3c;
}
.p-rating .p-rating-icon:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-rating .p-rating-icon:first-child {
  margin-left: 0;
}
.p-rating .p-rating-icon.pi-star {
  color: #3f74db;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #3f74db;
}
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon.p-rating-cancel:hover {
  color: #c0392b;
}

.p-highlight .p-rating .p-rating-icon {
  color: var(--white);
}

.p-selectbutton .p-button {
  display: inline-flex;
  justify-content: center;
  width: 180px;
  margin-right: 10px !important;
  margin-bottom: 10px !important;
  border: 2px solid var(--gray-500) !important;
  border-radius: 10px !important;
  text-align: center;
  font-weight: 500;
  background: var(--white);
  color: #000;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-selectbutton .p-button:focus {
  box-shadow: none !important;
}
.p-selectbutton .p-button .p-button-icon-left,
.p-selectbutton .p-button .p-button-icon-right {
  color: var(--gray-600);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
  background: var(--white);
  border-color: var(--gray-500);
  color: var(--text-color);
}
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: var(--gray-600);
}
.p-selectbutton .p-button.p-highlight {
  background: var(--green-900);
  border-color: var(--green-900) !important;
  color: var(--white);
}
.p-selectbutton .p-button.p-highlight .p-button-icon-left,
.p-selectbutton .p-button.p-highlight .p-button-icon-right {
  color: var(--white);
}
.p-selectbutton .p-button.p-highlight:hover {
  background: var(--green-900);
  border-color: var(--green-900);
  color: var(--white);
}
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-left,
.p-selectbutton .p-button.p-highlight:hover .p-button-icon-right {
  color: var(--white);
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
  border-color: #e4677e !important;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: var(--border-radius);
}
.p-slider.p-slider-horizontal {
  height: 0.286rem;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}
.p-slider.p-slider-vertical {
  width: 0.286rem;
}
.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}
.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: var(--white);
  border: 2px solid #3f74db;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-slider .p-slider-handle:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-slider .p-slider-range {
  background: #3f74db;
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: #3f74db;
  border-color: #3f74db;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, left 0.2s;
}
.p-slider.p-slider-animate.p-slider-horizontal .p-slider-range {
  transition: width 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-handle {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s, bottom 0.2s;
}
.p-slider.p-slider-animate.p-slider-vertical .p-slider-range {
  transition: height 0.2s;
}

.p-togglebutton.p-button {
  background: var(--white);
  border: 1px solid #ced4da;
  color: #000;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-togglebutton.p-button .p-button-icon-left,
.p-togglebutton.p-button .p-button-icon-right {
  color: var(--gray-600);
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #000;
}
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-left,
.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover .p-button-icon-right {
  color: var(--gray-600);
}
.p-togglebutton.p-button.p-highlight {
  background: var(--green-900);
  border-color: var(--green-900);
  color: var(--white);
}
.p-togglebutton.p-button.p-highlight .p-button-icon-left,
.p-togglebutton.p-button.p-highlight .p-button-icon-right {
  color: var(--white);
}
.p-togglebutton.p-button.p-highlight:hover {
  background: var(--green-900);
  border-color: var(--green-900);
  color: var(--white);
}
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-left,
.p-togglebutton.p-button.p-highlight:hover .p-button-icon-right {
  color: var(--white);
}

p-togglebutton.ng-dirty.ng-invalid > .p-togglebutton.p-button {
  border-color: #e4677e !important;
}

.p-treeselect {
  display: flex;
  align-items: center;
  height: 50px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
  background-color: var(--input-bg);
  border: var(--input-border);
  box-shadow: var(--input-box-shadow);
}
.p-treeselect:hover {
  background-color: var(--input-bg-hover);
  border: var(--input-border-hover);
  box-shadow: var(--input-box-shadow-hover);
}
.p-treeselect .p-treeselect-label {
  padding: 0.5rem 1rem;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treeselect .p-treeselect-label.p-placeholder {
  color: var(--gray-700);
}
.p-treeselect .p-treeselect-label-container {
  border-right: 2px solid var(--gray-500);
}
.p-treeselect.p-treeselect-chip .p-treeselect-token {
  padding: 0.25rem 1rem;
  margin-right: 0.5rem;
  background: #dee2e6;
  color: #000;
  border-radius: 16px;
}
.p-treeselect .p-treeselect-trigger {
  background: transparent;
  color: var(--gray-600);
  width: 50px;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.p-treeselect .p-treeselect-trigger .p-icon-wrapper {
  transform: rotate(180deg);
}
.p-treeselect .p-treeselect-trigger:not([aria-expanded=true]) .p-icon-wrapper {
  transform: initial;
}
.p-treeselect .p-treeselect-trigger svg {
  width: 20px;
  height: 20px;
}
.p-treeselect .p-treeselect-trigger svg:hover {
  color: var(--gray-700) !important;
}
.p-treeselect.p-invalid.p-component {
  border-color: #e4677e !important;
}

.p-inputwrapper-filled .p-treeselect.p-treeselect-chip .p-treeselect-label {
  padding: 0.25rem 1rem;
}

.p-treeselect-panel {
  background: var(--white);
  color: #000;
  border: 2px solid var(--gray-500);
  border-radius: var(--border-radius);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-treeselect-panel .p-treeselect-items-wrapper {
  border-radius: 10px;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  border: 0 none;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-treeselect-empty-message, .p-treeselect-panel .p-treeselect-items-wrapper .p-tree-empty-message {
  padding: 0.5rem 1rem;
  color: #000;
  background: transparent;
}

.p-input-filled .p-treeselect {
  background: #f8f9fa;
}
.p-input-filled .p-treeselect:not(.p-disabled):hover {
  background-color: #f8f9fa;
}
.p-input-filled .p-treeselect:not(.p-disabled).p-focus {
  background-color: #f8f9fa;
}

.p-button {
  color: var(--white);
  background: var(--blue-900);
  border: 1px solid var(--blue-900);
  padding: 0.5rem 1rem;
  height: 40px;
  font-size: 16px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 20px;
  box-shadow: 0 2px 10px var(--shadow-color-6);
}
.p-button:disabled {
  background-color: var(--gray-blue-650) !important;
  border-color: var(--gray-blue-650) !important;
}
.p-button:disabled:hover {
  background-color: var(--gray-blue-670) !important;
  border-color: var(--gray-blue-670) !important;
}
.p-button.bg-transparent {
  background-color: transparent !important;
}
.p-button:enabled:hover {
  background: var(--blue-850);
  color: var(--white);
  border-color: var(--blue-850);
}
.p-button:enabled:active {
  background: var(--blue-900);
  color: var(--white);
  border-color: var(--blue-900);
}
.p-button.p-button-outlined {
  background-color: transparent;
  color: var(--blue-770);
  border: none;
  box-shadow: 0 2px 10px var(--shadow-color-5);
}
.p-button.p-button-outlined:enabled:hover {
  background: rgba(var(--blue-900), 0.04);
  color: var(--blue-770);
  border: 0;
}
.p-button.p-button-outlined:enabled:active {
  background: rgba(var(--blue-900), 0.16);
  color: var(--blue-770);
}
.p-button.p-button-outlined.p-button-plain {
  color: #6c757d;
  border-color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-outlined.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button.p-button-text {
  background-color: var(--white);
  color: var(--blue-770);
  border-color: transparent;
}
.p-button.p-button-text:enabled:hover {
  background: #f2faff;
  color: var(--blue-770);
  border-color: transparent;
}
.p-button.p-button-text:enabled:active {
  background: #f2faff;
  color: var(--blue-770);
  border-color: transparent;
}
.p-button.p-button-text.p-button-plain {
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:hover {
  background: #e9ecef;
  color: #6c757d;
}
.p-button.p-button-text.p-button-plain:enabled:active {
  background: #dee2e6;
  color: #6c757d;
}
.p-button:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-button .p-button-icon-left {
  margin-right: 0.5rem;
}
.p-button .p-button-icon-right {
  margin-left: 0.5rem;
}
.p-button .p-button-icon-bottom {
  margin-top: 0.5rem;
}
.p-button .p-button-icon-top {
  margin-bottom: 0.5rem;
}
.p-button .p-badge {
  margin-left: 0.5rem;
  min-width: 16px;
  height: 16px;
  line-height: 16px;
  color: var(--blue-900);
  background-color: var(--white);
}
.p-button.p-button-raised {
  box-shadow: 0 2px 10px var(--shadow-color-5);
}
.p-button.p-button-rounded {
  border-radius: 2rem;
}
.p-button.p-button-icon-only {
  width: 2.357rem;
  padding: 0.5rem 0;
}
.p-button.p-button-icon-only .p-button-icon-left,
.p-button.p-button-icon-only .p-button-icon-right {
  margin: 0;
}
.p-button.p-button-icon-only.p-button-rounded {
  border-radius: 50%;
  height: 2.357rem;
}
.p-button.p-button-sm {
  font-size: 14px;
  padding: 0.4375rem 0.875rem;
}
.p-button.p-button-sm .p-button-icon {
  font-size: 0.875rem;
}
.p-button.p-button-lg {
  font-size: 20px;
  padding: 0.625rem 1.25rem;
}
.p-button.p-button-lg .p-button-icon {
  font-size: 1.25rem;
}
.p-button.p-button-loading-label-only .p-button-label {
  margin-left: 0.5rem;
}
.p-button.p-button-loading-label-only .p-button-loading-icon {
  margin-right: 0;
}

.p-fluid .p-button {
  width: 100%;
}
.p-fluid .p-button-icon-only {
  width: 2.357rem;
}
.p-fluid .p-buttonset {
  display: flex;
}
.p-fluid .p-buttonset .p-button {
  flex: 1;
}

.p-button.p-button-secondary,
.p-buttonset.p-button-secondary > .p-button,
.p-splitbutton.p-button-secondary > .p-button {
  color: var(--blue-770);
  background: var(--white);
  border: 1px solid var(--white);
}
.p-button.p-button-secondary:enabled:hover,
.p-buttonset.p-button-secondary > .p-button:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button:enabled:hover {
  background: var(--gray-150);
  color: var(--blue-770);
  border-color: var(--gray-150);
}
.p-button.p-button-secondary:enabled:active,
.p-buttonset.p-button-secondary > .p-button:enabled:active,
.p-splitbutton.p-button-secondary > .p-button:enabled:active {
  background: var(--gray-200);
  color: var(--blue-770);
  border-color: var(--gray-200);
}
.p-button.p-button-secondary.p-button-outlined,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined {
  background-color: transparent;
  color: var(--white);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:hover {
  background: rgba(var(--white), 0.04);
  color: var(--white);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-outlined:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-outlined:enabled:active {
  background: rgba(var(--white), 0.16);
  color: var(--white);
  border: 1px solid;
}
.p-button.p-button-secondary.p-button-text,
.p-buttonset.p-button-secondary > .p-button.p-button-text,
.p-splitbutton.p-button-secondary > .p-button.p-button-text {
  background-color: transparent;
  color: var(--white);
  border-color: transparent;
}
.p-button.p-button-secondary.p-button-text:enabled:hover,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:hover {
  background: rgba(var(--white), 0.04);
  border-color: transparent;
  color: var(--white);
}
.p-button.p-button-secondary.p-button-text:enabled:active,
.p-buttonset.p-button-secondary > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-secondary > .p-button.p-button-text:enabled:active {
  background: rgba(var(--white), 0.16);
  border-color: transparent;
  color: var(--white);
}

.p-button.p-button-info,
.p-buttonset.p-button-info > .p-button,
.p-splitbutton.p-button-info > .p-button {
  color: var(--white);
  background: var(--gray-blue-900);
  border: 1px solid var(--gray-blue-900);
}
.p-button.p-button-info:enabled:hover,
.p-buttonset.p-button-info > .p-button:enabled:hover,
.p-splitbutton.p-button-info > .p-button:enabled:hover {
  background: var(--gray-blue-850);
  color: var(--white);
  border-color: var(--gray-blue-850);
}
.p-button.p-button-info:enabled:active,
.p-buttonset.p-button-info > .p-button:enabled:active,
.p-splitbutton.p-button-info > .p-button:enabled:active {
  background: var(--gray-blue-900);
  color: var(--white);
  border-color: var(--gray-blue-900);
}
.p-button.p-button-info.p-button-outlined,
.p-buttonset.p-button-info > .p-button.p-button-outlined,
.p-splitbutton.p-button-info > .p-button.p-button-outlined {
  background-color: transparent;
  color: var(--gray-blue-900);
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:hover {
  background: rgba(var(--gray-blue-900), 0.04);
  color: var(--gray-blue-900);
  border: 1px solid;
}
.p-button.p-button-info.p-button-outlined:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-outlined:enabled:active {
  background: rgba(var(--gray-blue-900), 0.16);
  color: var(--gray-blue-900);
  border: 1px solid;
}
.p-button.p-button-info.p-button-text,
.p-buttonset.p-button-info > .p-button.p-button-text,
.p-splitbutton.p-button-info > .p-button.p-button-text {
  background-color: transparent;
  color: var(--gray-blue-900);
  border-color: transparent;
}
.p-button.p-button-info.p-button-text:enabled:hover,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:hover {
  background: rgba(var(--gray-blue-900), 0.04);
  border-color: transparent;
  color: var(--gray-blue-900);
}
.p-button.p-button-info.p-button-text:enabled:active,
.p-buttonset.p-button-info > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-info > .p-button.p-button-text:enabled:active {
  background: rgba(var(--gray-blue-900), 0.16);
  border-color: transparent;
  color: var(--gray-blue-900);
}

.p-button.p-button-success,
.p-buttonset.p-button-success > .p-button,
.p-splitbutton.p-button-success > .p-button {
  color: var(--white);
  background: var(--green-900);
  border: 1px solid var(--green-900);
}
.p-button.p-button-success:enabled:hover,
.p-buttonset.p-button-success > .p-button:enabled:hover,
.p-splitbutton.p-button-success > .p-button:enabled:hover {
  background: var(--green-850);
  color: var(--white);
  border-color: var(--green-850);
}
.p-button.p-button-success:enabled:active,
.p-buttonset.p-button-success > .p-button:enabled:active,
.p-splitbutton.p-button-success > .p-button:enabled:active {
  background: var(--green-900);
  color: var(--white);
  border-color: var(--green-900);
}
.p-button.p-button-success.p-button-outlined,
.p-buttonset.p-button-success > .p-button.p-button-outlined,
.p-splitbutton.p-button-success > .p-button.p-button-outlined {
  background-color: transparent;
  color: var(--green-900);
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:hover {
  background: rgba(var(--green-900), 0.04);
  color: var(--green-900);
  border: 1px solid;
}
.p-button.p-button-success.p-button-outlined:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-outlined:enabled:active {
  background: rgba(var(--green-900), 0.16);
  color: var(--green-900);
  border: 1px solid;
}
.p-button.p-button-success.p-button-text,
.p-buttonset.p-button-success > .p-button.p-button-text,
.p-splitbutton.p-button-success > .p-button.p-button-text {
  background-color: transparent;
  color: var(--green-900);
  border-color: transparent;
}
.p-button.p-button-success.p-button-text:enabled:hover,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:hover {
  background: rgba(var(--green-900), 0.04);
  border-color: transparent;
  color: var(--green-900);
}
.p-button.p-button-success.p-button-text:enabled:active,
.p-buttonset.p-button-success > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-success > .p-button.p-button-text:enabled:active {
  background: rgba(var(--green-900), 0.16);
  border-color: transparent;
  color: var(--green-900);
}

.p-button.p-button-warning,
.p-buttonset.p-button-warning > .p-button,
.p-splitbutton.p-button-warning > .p-button {
  color: var(--white);
  background: var(--orange-900);
  border: 1px solid var(--orange-900);
}
.p-button.p-button-warning:enabled:hover,
.p-buttonset.p-button-warning > .p-button:enabled:hover,
.p-splitbutton.p-button-warning > .p-button:enabled:hover {
  background: var(--orange-850);
  color: var(--white);
  border-color: var(--orange-850);
}
.p-button.p-button-warning:enabled:active,
.p-buttonset.p-button-warning > .p-button:enabled:active,
.p-splitbutton.p-button-warning > .p-button:enabled:active {
  background: var(--orange-900);
  color: var(--white);
  border-color: var(--orange-900);
}
.p-button.p-button-warning.p-button-outlined,
.p-buttonset.p-button-warning > .p-button.p-button-outlined,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined {
  background-color: transparent;
  color: var(--orange-900);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:hover {
  background: rgba(var(--orange-900), 0.04);
  color: var(--orange-900);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-outlined:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-outlined:enabled:active {
  background: rgba(var(--orange-900), 0.16);
  color: var(--orange-900);
  border: 1px solid;
}
.p-button.p-button-warning.p-button-text,
.p-buttonset.p-button-warning > .p-button.p-button-text,
.p-splitbutton.p-button-warning > .p-button.p-button-text {
  background-color: transparent;
  color: var(--orange-900);
  border-color: transparent;
}
.p-button.p-button-warning.p-button-text:enabled:hover,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:hover {
  background: rgba(var(--orange-900), 0.04);
  border-color: transparent;
  color: var(--orange-900);
}
.p-button.p-button-warning.p-button-text:enabled:active,
.p-buttonset.p-button-warning > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-warning > .p-button.p-button-text:enabled:active {
  background: rgba(var(--orange-900), 0.16);
  border-color: transparent;
  color: var(--orange-900);
}

.p-button.p-button-help,
.p-buttonset.p-button-help > .p-button,
.p-splitbutton.p-button-help > .p-button {
  color: var(--white);
  background: var(--purple-900);
  border: 1px solid var(--purple-900);
}
.p-button.p-button-help:enabled:hover,
.p-buttonset.p-button-help > .p-button:enabled:hover,
.p-splitbutton.p-button-help > .p-button:enabled:hover {
  background: var(--purple-850);
  color: var(--white);
  border-color: var(--purple-850);
}
.p-button.p-button-help:enabled:active,
.p-buttonset.p-button-help > .p-button:enabled:active,
.p-splitbutton.p-button-help > .p-button:enabled:active {
  background: var(--purple-900);
  color: var(--white);
  border-color: var(--purple-900);
}
.p-button.p-button-help.p-button-outlined,
.p-buttonset.p-button-help > .p-button.p-button-outlined,
.p-splitbutton.p-button-help > .p-button.p-button-outlined {
  background-color: transparent;
  color: var(--purple-900);
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:hover {
  background: rgba(var(--purple-900), 0.04);
  color: var(--purple-900);
  border: 1px solid;
}
.p-button.p-button-help.p-button-outlined:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-outlined:enabled:active {
  background: rgba(var(--purple-900), 0.16);
  color: var(--purple-900);
  border: 1px solid;
}
.p-button.p-button-help.p-button-text,
.p-buttonset.p-button-help > .p-button.p-button-text,
.p-splitbutton.p-button-help > .p-button.p-button-text {
  background-color: transparent;
  color: var(--purple-900);
  border-color: transparent;
}
.p-button.p-button-help.p-button-text:enabled:hover,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:hover {
  background: rgba(var(--purple-900), 0.04);
  border-color: transparent;
  color: var(--purple-900);
}
.p-button.p-button-help.p-button-text:enabled:active,
.p-buttonset.p-button-help > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-help > .p-button.p-button-text:enabled:active {
  background: rgba(var(--purple-900), 0.16);
  border-color: transparent;
  color: var(--purple-900);
}

.p-button.p-button-danger,
.p-buttonset.p-button-danger > .p-button,
.p-splitbutton.p-button-danger > .p-button {
  color: var(--white);
  background: var(--red-900);
  border: 1px solid var(--red-900);
}
.p-button.p-button-danger:enabled:hover,
.p-buttonset.p-button-danger > .p-button:enabled:hover,
.p-splitbutton.p-button-danger > .p-button:enabled:hover {
  background: var(--red-850);
  color: var(--white);
  border-color: var(--red-850);
}
.p-button.p-button-danger:enabled:active,
.p-buttonset.p-button-danger > .p-button:enabled:active,
.p-splitbutton.p-button-danger > .p-button:enabled:active {
  background: var(--red-900);
  color: var(--white);
  border-color: var(--red-900);
}
.p-button.p-button-danger.p-button-outlined,
.p-buttonset.p-button-danger > .p-button.p-button-outlined,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined {
  background-color: transparent;
  color: var(--red-900);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:hover {
  background: rgba(var(--red-900), 0.04);
  color: var(--red-900);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-outlined:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-outlined:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-outlined:enabled:active {
  background: rgba(var(--red-900), 0.16);
  color: var(--red-900);
  border: 1px solid;
}
.p-button.p-button-danger.p-button-text,
.p-buttonset.p-button-danger > .p-button.p-button-text,
.p-splitbutton.p-button-danger > .p-button.p-button-text {
  background-color: transparent;
  color: var(--red-900);
  border-color: transparent;
}
.p-button.p-button-danger.p-button-text:enabled:hover,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:hover,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:hover {
  background: rgba(var(--red-900), 0.04);
  border-color: transparent;
  color: var(--red-900);
}
.p-button.p-button-danger.p-button-text:enabled:active,
.p-buttonset.p-button-danger > .p-button.p-button-text:enabled:active,
.p-splitbutton.p-button-danger > .p-button.p-button-text:enabled:active {
  background: rgba(var(--red-900), 0.16);
  border-color: transparent;
  color: var(--red-900);
}

.p-button.p-button-link {
  color: #1976d2;
  background: transparent;
  border: transparent;
}
.p-button.p-button-link:enabled:hover {
  background: transparent;
  color: #1976d2;
  border-color: transparent;
}
.p-button.p-button-link:enabled:hover .p-button-label {
  text-decoration: underline;
}
.p-button.p-button-link:enabled:focus {
  background: transparent;
  box-shadow: 0 0 0 0.1rem var(--blue-760);
  border-color: transparent;
}
.p-button.p-button-link:enabled:active {
  background: transparent;
  color: #1976d2;
  border-color: transparent;
}

.p-speeddial-button.p-button.p-button-icon-only {
  width: 4rem;
  height: 4rem;
}
.p-speeddial-button.p-button.p-button-icon-only .p-button-icon {
  font-size: 1.3rem;
}

.p-speeddial-action {
  width: 3rem;
  height: 3rem;
  background: #495057;
  color: var(--white);
}
.p-speeddial-action:hover {
  background: #343a40;
  color: var(--white);
}

.p-speeddial-direction-up .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-up .p-speeddial-item:first-child {
  margin-bottom: 0.5rem;
}

.p-speeddial-direction-down .p-speeddial-item {
  margin: 0.25rem 0;
}
.p-speeddial-direction-down .p-speeddial-item:first-child {
  margin-top: 0.5rem;
}

.p-speeddial-direction-left .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-left .p-speeddial-item:first-child {
  margin-right: 0.5rem;
}

.p-speeddial-direction-right .p-speeddial-item {
  margin: 0 0.25rem;
}
.p-speeddial-direction-right .p-speeddial-item:first-child {
  margin-left: 0.5rem;
}

.p-speeddial-circle .p-speeddial-item,
.p-speeddial-semi-circle .p-speeddial-item,
.p-speeddial-quarter-circle .p-speeddial-item {
  margin: 0;
}
.p-speeddial-circle .p-speeddial-item:first-child, .p-speeddial-circle .p-speeddial-item:last-child,
.p-speeddial-semi-circle .p-speeddial-item:first-child,
.p-speeddial-semi-circle .p-speeddial-item:last-child,
.p-speeddial-quarter-circle .p-speeddial-item:first-child,
.p-speeddial-quarter-circle .p-speeddial-item:last-child {
  margin: 0;
}

.p-speeddial-mask {
  background-color: rgba(0, 0, 0, 0.4);
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin: 0.5rem;
}
.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-carousel .p-carousel-indicators {
  padding: 1rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: #e9ecef;
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}
.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: #3f74db;
  color: var(--white);
}

.p-datatable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-datatable .p-datatable-header {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-footer {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 10px 15px;
  border: 1px solid var(--white);
  border-width: 0px 2px 0 0;
  font-weight: 500;
  color: #000;
  background: var(--gray-blue-300);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #000;
  background: #f8f9fa;
}
.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: var(--gray-600);
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: var(--white);
  background: #3f74db;
  margin-left: 0.5rem;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #000;
}
.p-datatable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--gray-600);
}
.p-datatable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #3f74db;
}
.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #3f74db;
}
.p-datatable .p-sortable-column.p-highlight:hover {
  background: #e9ecef;
  color: #3f74db;
}
.p-datatable .p-sortable-column.p-highlight:hover .p-sortable-column-icon {
  color: #3f74db;
}
.p-datatable .p-sortable-column:focus {
  box-shadow: none;
  outline: 0 none;
}
.p-datatable .p-datatable-tbody > tr {
  background: var(--white);
  color: #000;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: var(--blue-760);
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid var(--white);
  border-width: 0 2px 2px 0;
  padding: 10px 10px;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:enabled:hover,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-datatable .p-datatable-tbody > tr > td .p-row-toggler:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-init:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save:focus,
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-cancel:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-datatable .p-datatable-tbody > tr > td .p-row-editor-save {
  margin-right: 0.5rem;
}
.p-datatable .p-datatable-tbody > tr.p-highlight {
  background: #3f74db;
  color: var(--white);
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #3f74db;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #3f74db;
}
.p-datatable.p-datatable-hoverable-rows .p-datatable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #000;
}
.p-datatable .p-column-resizer-helper {
  background: #3f74db;
}
.p-datatable .p-datatable-scrollable-header,
.p-datatable .p-datatable-scrollable-footer {
  background: #f8f9fa;
}
.p-datatable .p-datatable-loading-icon {
  font-size: 2rem;
}
.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-footer {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-top {
  border-width: 0 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-paginator-bottom {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px;
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even) {
  background: var(--white);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight {
  background: #3f74db;
  color: var(--white);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler {
  color: var(--white);
}
.p-datatable.p-datatable-striped .p-datatable-tbody > tr:nth-child(even).p-highlight .p-row-toggler:hover {
  color: var(--white);
}
.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 5px 7.5px;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 5px 5px;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-lg .p-datatable-header {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-thead > tr > th {
  padding: 12.5px 18.75px;
}
.p-datatable.p-datatable-lg .p-datatable-tbody > tr > td {
  padding: 12.5px 12.5px;
}
.p-datatable.p-datatable-lg .p-datatable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-datatable.p-datatable-lg .p-datatable-footer {
  padding: 1.25rem 1.25rem;
}
.p-datatable.modern-table .p-datatable-thead > tr > th {
  vertical-align: top;
}
.p-datatable.modern-table .td-content {
  display: flex;
  align-items: center;
  height: 100%;
  background-color: var(--gray-150);
}
.p-datatable.modern-table .td-content .p-button-text.p-link {
  background-color: transparent;
  height: 47px;
}
.p-datatable.modern-table td {
  height: 47px;
  padding: 10px 0 0 0 !important;
  border: none;
}
.p-datatable.modern-table td:first-child .td-content {
  border-radius: 4px 0 0 4px;
}
.p-datatable.modern-table td:last-child .td-content {
  border-radius: 0 4px 4px 0;
}
.p-datatable.modern-table tr:nth-child(even) .td-content {
  background-color: var(--gray-100);
}
.p-datatable.modern-table .collapsed-row td {
  padding-top: 0 !important;
}

.mobile-table-wrapper {
  min-width: 992px;
}

.p-dataview .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-dataview .p-dataview-header {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-dataview .p-dataview-content {
  background: var(--white);
  color: #000;
  border: 0 none;
  padding: 0;
}
.p-dataview.p-dataview-list .p-dataview-content > .p-grid > div {
  border: solid #e9ecef;
  border-width: 0 0 1px 0;
}
.p-dataview .p-dataview-footer {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.p-dataview .p-dataview-loading-icon {
  font-size: 2rem;
}
.p-dataview .p-dataview-emptymessage {
  padding: 1rem;
}

.p-column-filter-row .p-column-filter-menu-button,
.p-column-filter-row .p-column-filter-clear-button {
  margin-left: 0.5rem;
}

.p-column-filter-menu-button {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-menu-button:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-column-filter-menu-button.p-column-filter-menu-button-open, .p-column-filter-menu-button.p-column-filter-menu-button-open:hover {
  background: #eeeeee;
  color: #000;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active, .p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  background: #3f74db;
  color: var(--white);
}
.p-column-filter-menu-button:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

.p-column-filter-clear-button {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-column-filter-clear-button:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-column-filter-clear-button:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

.p-column-filter-overlay {
  background: var(--white);
  color: #000;
  border: 2px solid var(--gray-500);
  border-radius: var(--border-radius);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  min-width: 12.5rem;
}
.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0.5rem 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  margin: 0;
  padding: 0.5rem 1rem;
  border: 0 none;
  color: #000;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
  color: var(--white);
  background: #3f74db;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:not(.p-highlight):not(.p-disabled):hover {
  color: #000;
  background: var(--green-730);
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  border-top: 1px solid var(--gray-blue-670);
  margin: 0.25rem 0;
}

.p-column-filter-overlay-menu .p-column-filter-operator {
  padding: 0.5rem 1rem;
  border-bottom: 0 none;
  color: #000;
  background: #f8f9fa;
  margin: 0;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-column-filter-overlay-menu .p-column-filter-constraint {
  padding: 1rem;
  border-bottom: 1px solid var(--gray-blue-670);
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint .p-column-filter-remove-button {
  margin-top: 0.5rem;
}
.p-column-filter-overlay-menu .p-column-filter-constraint:last-child {
  border-bottom: 0 none;
}
.p-column-filter-overlay-menu .p-column-filter-add-rule {
  padding: 0.5rem 1rem;
}
.p-column-filter-overlay-menu .p-column-filter-buttonbar {
  padding: 1rem;
}

.fc {
  /* FullCalendar 4 */
  /* FullCalendar 5 */
}
.fc.fc-unthemed .fc-view-container th {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #000;
}
.fc.fc-unthemed .fc-view-container td.fc-widget-content {
  background: var(--white);
  border: 1px solid #dee2e6;
  color: #000;
}
.fc.fc-unthemed .fc-view-container td.fc-head-container {
  border: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-view-container .fc-row {
  border-right: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-view-container .fc-event {
  background: #3467cc;
  border: 1px solid #3467cc;
  color: var(--white);
}
.fc.fc-unthemed .fc-view-container .fc-divider {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
}
.fc.fc-unthemed .fc-toolbar .fc-button {
  color: var(--white);
  background: var(--blue-900);
  border: 1px solid var(--blue-900);
  font-size: 16px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:hover {
  background: var(--blue-850);
  color: var(--white);
  border-color: var(--blue-850);
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active {
  background: var(--blue-900);
  color: var(--white);
  border-color: var(--blue-900);
}
.fc.fc-unthemed .fc-toolbar .fc-button:enabled:active:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "rutsio", serif !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "rutsio", serif !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-unthemed .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}
.fc.fc-unthemed .fc-toolbar .fc-button:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: var(--white);
  border: 1px solid #ced4da;
  color: #000;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #000;
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--green-900);
  border-color: var(--green-900);
  color: var(--white);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: var(--green-900);
  border-color: var(--green-900);
  color: var(--white);
}
.fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  z-index: 1;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.fc.fc-unthemed .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.fc.fc-theme-standard .fc-view-harness .fc-scrollgrid {
  border-color: rgba(221, 238, 238, 0.1333333333);
}
.fc.fc-theme-standard .fc-view-harness th {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #000;
}
.fc.fc-theme-standard .fc-view-harness td {
  color: #000;
  border-color: rgba(221, 238, 238, 0.1333333333);
}
.fc.fc-theme-standard .fc-view-harness .fc-view {
  background: var(--white);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover {
  background: none;
  border: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #f8f9fa;
  color: #000;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close {
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  font-family: "rutsio", serif !important;
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:before {
  content: "\e90b";
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-header .fc-popover-close:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.fc.fc-theme-standard .fc-view-harness .fc-popover .fc-popover-body {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  border-top: 0 none;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event {
  color: var(--white);
  background: #3467cc;
  border-color: #3467cc;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-block-event .fc-event-main {
  color: var(--white);
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event .fc-daygrid-event-dot {
  background: #3467cc;
  border-color: #3467cc;
}
.fc.fc-theme-standard .fc-view-harness .fc-event.fc-daygrid-dot-event:hover {
  background: var(--green-730);
  color: #000;
}
.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded {
  background: #f8f9fa;
}
.fc.fc-theme-standard .fc-toolbar .fc-button {
  color: var(--white);
  background: var(--blue-900);
  border: 1px solid var(--blue-900);
  font-size: 16px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:hover {
  background: var(--blue-850);
  color: var(--white);
  border-color: var(--blue-850);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active {
  background: var(--blue-900);
  color: var(--white);
  border-color: var(--blue-900);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:enabled:active:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.fc.fc-theme-standard .fc-toolbar .fc-button:disabled {
  opacity: 0.8;
  color: var(--white);
  background: var(--blue-900);
  border: 1px solid var(--blue-900);
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left {
  font-family: "rutsio", serif !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-left:before {
  content: "\e900";
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right {
  font-family: "rutsio", serif !important;
  text-indent: 0;
  font-size: 1rem;
}
.fc.fc-theme-standard .fc-toolbar .fc-button .fc-icon-chevron-right:before {
  content: "\e901";
}
.fc.fc-theme-standard .fc-toolbar .fc-button:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button {
  background: var(--white);
  border: 1px solid #ced4da;
  color: #000;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover {
  background: #e9ecef;
  border-color: #ced4da;
  color: #000;
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active {
  background: var(--green-900);
  border-color: var(--green-900);
  color: var(--white);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover {
  background: var(--green-900);
  border-color: var(--green-900);
  color: var(--white);
}
.fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
  z-index: 1;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button {
  border-radius: 0;
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:first-child {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.fc.fc-theme-standard .fc-toolbar .fc-button-group .fc-button:last-child {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.fc.fc-theme-standard a {
  color: #000;
}

.p-orderlist .p-orderlist-controls {
  padding: 1rem;
}
.p-orderlist .p-orderlist-controls .p-button {
  margin-bottom: 0.5rem;
}
.p-orderlist .p-orderlist-header {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-orderlist .p-orderlist-header .p-orderlist-title {
  font-weight: 600;
}
.p-orderlist .p-orderlist-filter-container {
  padding: 1rem;
  background: var(--white);
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-input {
  padding-right: 2rem;
}
.p-orderlist .p-orderlist-filter-container .p-orderlist-filter-icon {
  right: 1rem;
  color: #c4c4c4;
}
.p-orderlist .p-orderlist-list {
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  padding: 0.5rem 0;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-orderlist .p-orderlist-list .p-orderlist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #000;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-orderlist .p-orderlist-list .p-orderlist-item:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight {
  color: var(--white);
  background: #3f74db;
}
.p-orderlist .p-orderlist-list .p-orderlist-empty-message {
  padding: 0.5rem 1rem;
  color: #000;
}
.p-orderlist .p-orderlist-list:not(.cdk-drop-list-dragging) .p-orderlist-item:not(.p-highlight):hover {
  background: var(--green-730);
  color: #000;
}

.p-orderlist-item.cdk-drag-preview {
  padding: 0.5rem 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: 0 none;
  color: #000;
  background: var(--white);
  margin: 0;
}

.p-organizationchart .p-organizationchart-node-content.p-organizationchart-selectable-node:not(.p-highlight):hover {
  background: var(--green-730);
  color: #000;
}
.p-organizationchart .p-organizationchart-node-content.p-highlight {
  background: #3f74db;
  color: var(--white);
}
.p-organizationchart .p-organizationchart-node-content.p-highlight .p-node-toggler i {
  color: #183c82;
}
.p-organizationchart .p-organizationchart-line-down {
  background: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-left {
  border-right: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-line-top {
  border-top: 1px solid #dee2e6;
  border-color: #dee2e6;
}
.p-organizationchart .p-organizationchart-node-content {
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  padding: 1rem;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler {
  background: inherit;
  color: inherit;
  border-radius: 50%;
}
.p-organizationchart .p-organizationchart-node-content .p-node-toggler:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

.p-paginator {
  background: var(--white);
  color: var(--gray-600);
  border: solid #e9ecef;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: var(--gray-600);
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: var(--gray-600);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-paginator .p-paginator-last {
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 2.357rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 2.357rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: var(--gray-600);
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: var(--gray-600);
  min-width: 2.357rem;
  height: 2.357rem;
  margin: 0.143rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #3f74db;
  border-color: #3f74db;
  color: var(--white);
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: #e9ecef;
  border-color: transparent;
  color: var(--gray-600);
}

.p-picklist .p-picklist-buttons {
  padding: 1rem;
}
.p-picklist .p-picklist-buttons .p-button {
  margin-bottom: 0.5rem;
}
.p-picklist .p-picklist-header {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-bottom: 0 none;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-picklist .p-picklist-header .p-picklist-title {
  font-weight: 600;
}
.p-picklist .p-picklist-filter-container {
  padding: 1rem;
  background: var(--white);
  border: 1px solid #dee2e6;
  border-bottom: 0 none;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-input {
  padding-right: 2rem;
}
.p-picklist .p-picklist-filter-container .p-picklist-filter-icon {
  right: 1rem;
  color: #c4c4c4;
}
.p-picklist .p-picklist-list {
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  padding: 0.5rem 0;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-picklist .p-picklist-list .p-picklist-item {
  padding: 0.5rem 1rem;
  margin: 0;
  border: 0 none;
  color: #000;
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-picklist .p-picklist-list .p-picklist-item:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-picklist .p-picklist-list .p-picklist-item.p-highlight {
  color: var(--white);
  background: #3f74db;
}
.p-picklist .p-picklist-list .p-picklist-empty-message {
  padding: 0.5rem 1rem;
  color: #000;
}
.p-picklist .p-picklist-list:not(.cdk-drop-list-dragging) .p-picklist-item:not(.p-highlight):hover {
  background: var(--green-730);
  color: #000;
}

.p-picklist-item.cdk-drag-preview {
  padding: 0.5rem 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: 0 none;
  color: #000;
  background: var(--white);
  margin: 0;
}

.p-timeline .p-timeline-event-marker {
  border: 2px solid #3f74db;
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  background-color: var(--white);
}
.p-timeline .p-timeline-event-connector {
  background-color: #dee2e6;
}
.p-timeline.p-timeline-vertical .p-timeline-event-opposite,
.p-timeline.p-timeline-vertical .p-timeline-event-content {
  padding: 0 1rem;
}
.p-timeline.p-timeline-vertical .p-timeline-event-connector {
  width: 2px;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-opposite,
.p-timeline.p-timeline-horizontal .p-timeline-event-content {
  padding: 1rem 0;
}
.p-timeline.p-timeline-horizontal .p-timeline-event-connector {
  height: 2px;
}

.p-tree {
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  padding: 1rem;
  border-radius: var(--border-radius);
}
.p-tree .p-tree-container .p-treenode {
  padding: 0.143rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: var(--border-radius);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  padding: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-tree-toggler:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-treenode-icon {
  margin-right: 0.5rem;
  color: var(--gray-600);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #000;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  color: var(--white);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: var(--white);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-tree-toggler:hover,
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight .p-treenode-icon:hover {
  color: var(--white);
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: var(--green-730);
  color: #000;
}
.p-tree .p-tree-container .p-treenode .p-treenode-content.p-treenode-dragover {
  background: var(--green-730);
  color: #000;
}
.p-tree .p-tree-filter-container {
  margin-bottom: 0.5rem;
}
.p-tree .p-tree-filter-container .p-tree-filter {
  width: 100%;
  padding-right: 2rem;
}
.p-tree .p-tree-filter-container .p-tree-filter-icon {
  right: 1rem;
  color: #c4c4c4;
}
.p-tree .p-treenode-children {
  padding: 0 0 0 1rem;
}
.p-tree .p-tree-loading-icon {
  font-size: 2rem;
}
.p-tree .p-treenode-droppoint.p-treenode-droppoint-active {
  background-color: #2458be;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content {
  border-radius: var(--border-radius);
  border: 1px solid #dee2e6;
  background-color: var(--white);
  color: #000;
  padding: 0.5rem;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight {
  background-color: #3f74db;
  color: var(--white);
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-highlight .p-treenode-icon {
  color: var(--white);
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-tree-toggler {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-checkbox {
  margin-right: 0.5rem;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content .p-treenode-label:not(.p-highlight):hover {
  background-color: inherit;
  color: inherit;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content.p-treenode-selectable:not(.p-highlight):hover {
  background: var(--green-730);
  color: #000;
}
.p-tree.p-tree-horizontal .p-treenode .p-treenode-content:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

.p-treetable .p-paginator-top {
  border-width: 1px 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-paginator-bottom {
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.p-treetable .p-treetable-header {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-footer {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-treetable .p-treetable-thead > tr > th {
  text-align: left;
  padding: 10px 15px;
  border: 1px solid var(--white);
  vertical-align: top;
  border-width: 0px 2px 0 0;
  font-weight: 500;
  color: #000;
  background: var(--gray-blue-300);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-treetable .p-treetable-tfoot > tr > td {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #000;
  background: #f8f9fa;
}
.p-treetable .p-sortable-column {
  outline-color: var(--blue-760);
}
.p-treetable .p-sortable-column .p-sortable-column-icon {
  color: var(--gray-600);
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column .p-sortable-column-badge {
  border-radius: 50%;
  height: 1.143rem;
  min-width: 1.143rem;
  line-height: 1.143rem;
  color: var(--white);
  background: #3f74db;
  margin-left: 0.5rem;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover {
  background: #e9ecef;
  color: #000;
}
.p-treetable .p-sortable-column:not(.p-highlight):hover .p-sortable-column-icon {
  color: var(--gray-600);
}
.p-treetable .p-sortable-column.p-highlight {
  background: #f8f9fa;
  color: #3f74db;
}
.p-treetable .p-sortable-column.p-highlight .p-sortable-column-icon {
  color: #3f74db;
}
.p-treetable .p-treetable-tbody > tr {
  background: var(--white);
  color: #000;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  outline-color: var(--blue-760);
}
.p-treetable .p-treetable-tbody > tr:nth-child(2n) td {
  background-color: var(--gray-100);
}
.p-treetable .p-treetable-tbody > tr > td {
  font-size: 14px;
  word-break: break-word;
  text-align: left;
  border: 1px solid var(--white);
  border-width: 0 2px 2px 0;
  padding: 10px 10px;
  background-color: var(--gray-150);
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
  color: var(--blue-770) !important;
  background: none !important;
  box-shadow: none !important;
  width: unset;
  min-width: 2.5rem;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox {
  margin-right: 0.5rem;
}
.p-treetable .p-treetable-tbody > tr > td p-treetablecheckbox .p-checkbox .p-indeterminate .p-checkbox-icon {
  color: #000;
}
.p-treetable .p-treetable-tbody > tr.p-highlight {
  background: #3f74db;
  color: var(--white);
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler {
  color: var(--white);
}
.p-treetable .p-treetable-tbody > tr.p-highlight .p-treetable-toggler:hover {
  color: var(--white);
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover {
  background: #e9ecef;
  color: #000;
}
.p-treetable.p-treetable-hoverable-rows .p-treetable-tbody > tr:not(.p-highlight):hover .p-treetable-toggler {
  color: #000;
}
.p-treetable .p-column-resizer-helper {
  background: #3f74db;
}
.p-treetable .p-treetable-scrollable-header,
.p-treetable .p-treetable-scrollable-footer {
  background: #f8f9fa;
}
.p-treetable .p-treetable-loading-icon {
  font-size: 2rem;
}
.p-treetable.p-treetable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-footer {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-top {
  border-width: 0 1px 0 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-bottom {
  border-width: 0 1px 1px 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-thead > tr > th {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tbody > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-gridlines .p-treetable-tfoot > tr > td {
  border-width: 1px;
}
.p-treetable.p-treetable-sm .p-treetable-header {
  padding: 0.875rem 0.875rem;
}
.p-treetable.p-treetable-sm .p-treetable-thead > tr > th {
  padding: 5px 7.5px;
}
.p-treetable.p-treetable-sm .p-treetable-tbody > tr > td {
  padding: 5px 5px;
}
.p-treetable.p-treetable-sm .p-treetable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-treetable.p-treetable-sm .p-treetable-footer {
  padding: 0.5rem 0.5rem;
}
.p-treetable tr.spacer {
  height: 8px;
}
.p-treetable.p-treetable-lg .p-treetable-header {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-thead > tr > th {
  padding: 12.5px 18.75px;
}
.p-treetable.p-treetable-lg .p-treetable-tbody > tr > td {
  padding: 12.5px 12.5px;
}
.p-treetable.p-treetable-lg .p-treetable-tfoot > tr > td {
  padding: 1.25rem 1.25rem;
}
.p-treetable.p-treetable-lg .p-treetable-footer {
  padding: 1.25rem 1.25rem;
}

.p-virtualscroller .p-virtualscroller-header {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
}
.p-virtualscroller .p-virtualscroller-content {
  background: var(--white);
  color: #000;
  border: 0 none;
  padding: 0;
}
.p-virtualscroller .p-virtualscroller-footer {
  background: #f8f9fa;
  color: #000;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 600;
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #000;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: var(--border-radius);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}
.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #e9ecef;
  border-color: 1px solid #dee2e6;
  color: #000;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #000;
}
.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-accordion p-accordiontab .p-accordion-tab {
  margin-bottom: 0;
}
.p-accordion p-accordiontab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}
.p-accordion p-accordiontab .p-accordion-content {
  border-radius: 0;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link, .p-accordion p-accordiontab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}
.p-accordion p-accordiontab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-accordion p-accordiontab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-accordion p-accordiontab:last-child .p-accordion-content {
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.p-card {
  background: var(--white);
  color: #000;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius);
}
.p-card .p-card-body {
  padding: 1rem;
}
.p-card .p-card-title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.p-card .p-card-subtitle {
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--gray-600);
}
.p-card .p-card-content {
  padding: 1rem 0;
}
.p-card .p-card-footer {
  padding: 1rem 0 0 0;
}

.p-divider .p-divider-content {
  background-color: var(--white);
}
.p-divider.p-divider-horizontal {
  margin: 1rem 0;
  padding: 0 1rem;
}
.p-divider.p-divider-horizontal:before {
  border-top: 1px #dee2e6;
}
.p-divider.p-divider-horizontal .p-divider-content {
  padding: 0 0.5rem;
}
.p-divider.p-divider-vertical {
  margin: 0 1rem;
  padding: 1rem 0;
}
.p-divider.p-divider-vertical:before {
  border-left: 1px #dee2e6;
}
.p-divider.p-divider-vertical .p-divider-content {
  padding: 0.5rem 0;
}

.p-fieldset {
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  border-radius: var(--border-radius);
}
.p-fieldset .p-fieldset-legend {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #000;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: var(--border-radius);
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend {
  padding: 0;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
  padding: 1rem;
  color: #000;
  border-radius: var(--border-radius);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a .p-fieldset-toggler {
  margin-right: 0.5rem;
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-fieldset.p-fieldset-toggleable .p-fieldset-legend:hover {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #000;
}
.p-fieldset .p-fieldset-content {
  padding: 1rem;
}

.p-panel .p-panel-header {
  border: 1px solid #dee2e6;
  padding: 1rem;
  background: #f8f9fa;
  color: #000;
  border-radius: var(--border-radius);
}
.p-panel .p-panel-header .p-panel-title {
  font-weight: 600;
}
.p-panel .p-panel-header .p-panel-header-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-panel .p-panel-header .p-panel-header-icon:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-panel .p-panel-header .p-panel-header-icon:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-panel.p-panel-toggleable .p-panel-header {
  padding: 0.5rem 1rem;
}
.p-panel.p-panel-expanded .p-panel-header {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}
.p-panel .p-panel-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  border-top: 0 none;
}
.p-panel .p-panel-footer {
  padding: 0.5rem 1rem;
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  border-top: 0 none;
}
.p-panel.panel-white {
  box-shadow: 0 4px 20px 0 var(--shadow-color-3);
}
.p-panel.panel-white .p-panel-header,
.p-panel.panel-white .p-panel-content,
.p-panel.panel-white .p-panel-footer {
  border: none;
  background-color: var(--white);
}
.p-panel.panel-white .p-panel-header {
  padding: 1rem 2rem;
}
.p-panel.panel-white .p-panel-header .p-panel-header-icon {
  width: auto;
  height: auto;
  color: var(--blue-770);
  background-color: var(--white);
}
.p-panel.panel-white .p-panel-header .p-panel-header-icon:enabled:hover {
  color: var(--blue-770);
  background-color: var(--white);
  border: none;
}
.p-panel.panel-white .p-panel-header .p-panel-header-icon:focus {
  box-shadow: none;
}
.p-panel.panel-white .panel-title {
  min-width: 170px;
}
.p-panel.panel-white .p-panel-content {
  padding-top: 0;
}

.p-scrollpanel .p-scrollpanel-bar {
  background: #f8f9fa;
  border: 0 none;
}

.p-splitter {
  border: 1px solid #dee2e6;
  background: var(--white);
  border-radius: var(--border-radius);
  color: #000;
}
.p-splitter .p-splitter-gutter {
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background: #f8f9fa;
}
.p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background: #dee2e6;
}
.p-splitter .p-splitter-gutter-resizing {
  background: #dee2e6;
}

.p-tabview .p-tabview-nav {
  background: var(--white);
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabview .p-tabview-nav li {
  margin-right: 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: var(--white);
  color: var(--gray-600);
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  outline: none;
  outline-offset: 0;
  box-shadow: inset 0 2px 10px var(--shadow-color-8);
}
.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
  background: var(--white);
  border-color: #9ba2aa;
  color: var(--gray-600);
}
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  background: var(--white);
  border-color: #3f74db;
  color: #3f74db;
}
.p-tabview .p-tabview-left-icon {
  margin-right: 0.5rem;
}
.p-tabview .p-tabview-right-icon {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-close {
  margin-left: 0.5rem;
}
.p-tabview .p-tabview-nav-btn.p-link {
  background: var(--white);
  color: #3f74db;
  width: 2.357rem;
  box-shadow: 0 2px 10px var(--shadow-color-5);
  border-radius: 0;
}
.p-tabview .p-tabview-nav-btn.p-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: inset 0 2px 10px var(--shadow-color-8);
}
.p-tabview .p-tabview-panels {
  background: var(--white);
  padding: 1rem;
  border: 0 none;
  color: #000;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.p-toolbar {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  padding: 1rem;
  border-radius: var(--border-radius);
}
.p-toolbar .p-toolbar-separator {
  margin: 0 0.5rem;
}

.p-confirm-popup {
  background: var(--white);
  color: #000;
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}
.p-confirm-popup .p-confirm-popup-content {
  padding: 1rem;
}
.p-confirm-popup .p-confirm-popup-footer {
  text-align: right;
  padding: 0.5rem 1rem;
}
.p-confirm-popup .p-confirm-popup-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-confirm-popup .p-confirm-popup-footer button:last-child {
  margin: 0;
}
.p-confirm-popup:after {
  border: solid rgba(var(--white), 0);
  border-bottom-color: var(--white);
}
.p-confirm-popup:before {
  border: solid transparent;
  border-color: rgba(var(--white), 0);
  border-bottom-color: var(--white);
}
.p-confirm-popup.p-confirm-popup-flipped:after {
  border-top-color: var(--white);
}
.p-confirm-popup.p-confirm-popup-flipped:before {
  border-top-color: var(--white);
}
.p-confirm-popup .p-confirm-popup-icon {
  font-size: 1.5rem;
}
.p-confirm-popup .p-confirm-popup-message {
  margin-left: 1rem;
}

.p-dialog {
  max-height: 100% !important;
  max-width: calc(100% - 20px);
  background: var(--white);
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
  padding: 0 30px;
  --input-height: 50px;
  --input-bg: var(--gray-100) !important;
  --input-bg-hover: var(--gray-150) !important;
  --input-border: 2px solid var(--gray-blue-300) !important;
  --input-border-hover: 2px solid var(--gray-blue-650) !important;
  --input-box-shadow: none;
  --input-box-shadow-hover: none;
  --calendar-input-height: calc(var(--input-height));
}
.p-dialog-mask {
  z-index: 10006 !important;
  pointer-events: initial !important;
}
.p-dialog .p-dialog-header {
  align-items: flex-start !important;
  padding: 30px 0 20px 0;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  color: #000;
  font-size: 1.5rem;
  border-bottom: 2px solid var(--gray-blue-200);
}
.p-dialog .p-dialog-header .p-dialog-title {
  margin-right: 15px;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-top: 10px;
  margin-right: 10px;
  box-shadow: none;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:hover {
  color: var(--blue-760);
  background-color: var(--gray-150);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:active {
  color: var(--white);
  background-color: var(--blue-900);
}
.p-dialog .p-dialog-header .p-dialog-header-icon svg {
  width: 23px;
  height: 23px;
}
.p-dialog .p-dialog-content {
  padding: 20px 0 30px 0;
  color: #000;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  overflow: visible;
}
.p-dialog .p-dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px 30px;
  color: #000;
  text-align: right;
}
.p-dialog .p-dialog-footer button {
  padding: 5px 20px;
  margin-right: 20px;
  box-shadow: 0 2px 10px var(--shadow-color-5);
}
.p-dialog .p-dialog-footer button:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-footer button svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}
.p-dialog.p-confirm-dialog {
  max-width: 536px;
  z-index: 100009 !important;
}
@media only screen and (max-width: 480px) {
  .p-dialog.p-confirm-dialog {
    max-width: 300px;
  }
  .p-dialog.p-confirm-dialog .p-dialog-footer {
    flex-direction: column;
  }
  .p-dialog.p-confirm-dialog .p-dialog-footer button {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .p-dialog.p-confirm-dialog .p-dialog-footer button:last-child {
    margin-bottom: 0;
  }
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  font-size: 20px;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message strong {
  font-size: 24px;
}
.p-dialog.hidden-header .p-dialog-header {
  border-bottom: none;
}

.p-dialog .p-dropdown:hover {
  background: var(--input-bg-hover) !important;
  border: var(--input-border-hover) !important;
}
.p-dialog .p-dropdown .p-inputtext {
  border: none !important;
  background: none !important;
  transition: none;
}
.p-dialog .p-dropdown .p-dropdown-trigger {
  height: 30px;
}
.p-dialog .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
  display: block;
  margin-top: 5px;
}
.p-dialog .p-dropdown .p-dropdown-panel {
  box-shadow: 0 4px 20px var(--shadow-color-5);
}
.p-dialog .p-dropdown-filter-icon {
  top: 20px !important;
  width: 20px;
  height: 20px;
  color: #c4c4c4;
}
.p-dialog .p-dropdown-label.p-placeholder {
  height: calc(var(--input-height) - 4px);
}

.p-dialog .p-calendar input {
  font-size: 16px;
}

.p-dialog .c-page {
  padding: 0;
}

.p-dialog .p-multiselect {
  display: flex;
  align-items: center;
  height: var(--input-height);
}
.p-dialog .p-multiselect:hover {
  background: var(--input-bg-hover) !important;
  border: var(--input-border-hover) !important;
}
.p-dialog .p-multiselect .p-multiselect-trigger {
  width: 41px;
  border-left: 2px solid var(--gray-500);
  height: 30px;
}

.p-overlaypanel {
  max-width: 300px;
  background: var(--white);
  color: #000;
  border: 0 none;
  border-radius: var(--border-radius);
  box-shadow: 0 2px 20px var(--shadow-color-5);
}
.p-overlaypanel .p-overlaypanel-content {
  max-height: 300px;
  min-width: 100px;
  padding: 1rem;
  overflow: auto;
}
.p-overlaypanel .p-overlaypanel-title {
  margin-bottom: 16px;
}
.p-overlaypanel .p-overlaypanel-close {
  position: absolute;
  top: 0;
  right: 10px;
  width: 30px;
  height: 30px;
  color: var(--gray-600);
  box-shadow: none;
  background: transparent !important;
}
.p-overlaypanel .p-overlaypanel-close:enabled:hover {
  background: var(--blue-850);
  color: var(--blue-900);
}
.p-overlaypanel:before {
  border: solid transparent;
  border-color: transparent;
  border-bottom-color: var(--white);
}
.p-overlaypanel.p-overlaypanel-flipped:after {
  border-top-color: var(--white);
}
.p-overlaypanel.p-overlaypanel-flipped:before {
  border-top-color: var(--white);
}
.p-overlaypanel ::-webkit-scrollbar {
  width: 15px;
}
.p-overlaypanel ::-webkit-scrollbar:horizontal {
  height: 15px;
}
.p-overlaypanel ::-webkit-scrollbar-thumb,
.p-overlaypanel ::-webkit-scrollbar-thumb:hover {
  background: var(--gray-600);
  border: 5px solid transparent;
  border-radius: 7px;
  background-clip: content-box;
}
.p-overlaypanel ::-webkit-scrollbar-track:hover {
  border: 1px solid var(--gray-650);
}

.p-overlaypanel-flipped {
  z-index: 10007 !important;
}

.p-sidebar {
  background: var(--white);
  color: #000;
  border: 0 none;
  box-shadow: 0 0 14px 0 rgba(0, 0, 0, 0.1);
}
.p-sidebar .p-sidebar-header {
  padding: 1rem;
}
.p-sidebar .p-sidebar-header .p-sidebar-close,
.p-sidebar .p-sidebar-header .p-sidebar-icon {
  width: 2.5rem;
  height: 2.5rem;
  color: var(--gray-600);
  border: 0 none;
  background: var(--gray-100);
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:enabled:hover,
.p-sidebar .p-sidebar-header .p-sidebar-icon:enabled:hover {
  color: #000;
  border-color: transparent;
  background: #eeeeee;
}
.p-sidebar .p-sidebar-header .p-sidebar-close:focus,
.p-sidebar .p-sidebar-header .p-sidebar-icon:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-sidebar .p-sidebar-header + .p-sidebar-content {
  padding-top: 0;
}
.p-sidebar .p-sidebar-content {
  padding: 1rem;
}

.p-tooltip {
  display: inline-table !important;
  z-index: 10006 !important;
}
.p-tooltip .p-tooltip-text {
  background: var(--white);
  color: #000000;
  padding: 0.5rem 1rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  left: calc(-12px + 4px);
  margin-top: -12px;
  border-width: 18px 12px 18px 0;
  border-right-color: var(--white);
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  right: calc(-12px + 4px);
  margin-top: -12px;
  border-width: 18px 0 18px 12px;
  border-left-color: var(--white);
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  bottom: calc(-12px - 2px);
  border-width: 18px 12px 0;
  border-top-color: var(--white);
  margin-left: -12px;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  top: calc(-12px - 2px);
  border-width: 0 12px 18px;
  border-bottom-color: var(--white);
  margin-left: -12px;
}

.p-fileupload .p-fileupload-buttonbar {
  background: #f8f9fa;
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #000;
  border-bottom: 0 none;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-fileupload .p-fileupload-buttonbar .p-button {
  margin-right: 0.5rem;
}
.p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-fileupload .p-fileupload-content {
  background: var(--white);
  padding: 2rem 1rem;
  border: 1px solid #dee2e6;
  color: #000;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-fileupload .p-fileupload-content:hover {
  background: var(--gray-blue-200);
}
.p-fileupload .p-progressbar {
  height: 0.25rem;
}
.p-fileupload .p-fileupload-row > div {
  padding: 10px 10px;
}
.p-fileupload.p-fileupload-advanced .p-message {
  margin-top: 0;
}

.p-fileupload-choose:not(.p-disabled):hover {
  background: var(--blue-850);
  color: var(--white);
  border-color: var(--blue-850);
}
.p-fileupload-choose:not(.p-disabled):active {
  background: var(--blue-900);
  color: var(--white);
  border-color: var(--blue-900);
}

.p-breadcrumb {
  overflow-x: visible !important;
}
.p-breadcrumb .p-menuitem-link {
  font-size: 14px;
  color: var(--breadcrumb-color);
  text-decoration: underline;
  text-underline-offset: 2px;
}
.p-breadcrumb .p-menuitem-link:hover {
  color: var(--breadcrumb-color-hover);
}

.p-contextmenu {
  padding: 0.25rem 0;
  background: var(--white);
  color: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 12.5rem;
}
.p-contextmenu .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
  font-size: 15px;
  background-color: var(--gray-100);
  margin-bottom: 5px;
}
.p-contextmenu .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-contextmenu .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: white;
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-contextmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-contextmenu .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-contextmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: var(--white);
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-contextmenu .p-menuitem {
  margin: 0;
}
.p-contextmenu .p-menuitem:last-child {
  margin: 0;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #000;
}
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-contextmenu .p-menu-separator {
  border-top: 1px solid var(--gray-blue-670);
  margin: 0.25rem 0;
}
.p-contextmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-dock .p-dock-list {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem 0.5rem;
  border-radius: 0.5rem;
}
.p-dock .p-dock-item {
  padding: 0.5rem;
}
.p-dock .p-dock-action {
  width: 4rem;
  height: 4rem;
}
.p-dock.p-dock-top .p-dock-item-second-prev,
.p-dock.p-dock-top .p-dock-item-second-next, .p-dock.p-dock-bottom .p-dock-item-second-prev,
.p-dock.p-dock-bottom .p-dock-item-second-next {
  margin: 0 0.9rem;
}
.p-dock.p-dock-top .p-dock-item-prev,
.p-dock.p-dock-top .p-dock-item-next, .p-dock.p-dock-bottom .p-dock-item-prev,
.p-dock.p-dock-bottom .p-dock-item-next {
  margin: 0 1.3rem;
}
.p-dock.p-dock-top .p-dock-item-current, .p-dock.p-dock-bottom .p-dock-item-current {
  margin: 0 1.5rem;
}
.p-dock.p-dock-left .p-dock-item-second-prev,
.p-dock.p-dock-left .p-dock-item-second-next, .p-dock.p-dock-right .p-dock-item-second-prev,
.p-dock.p-dock-right .p-dock-item-second-next {
  margin: 0.9rem 0;
}
.p-dock.p-dock-left .p-dock-item-prev,
.p-dock.p-dock-left .p-dock-item-next, .p-dock.p-dock-right .p-dock-item-prev,
.p-dock.p-dock-right .p-dock-item-next {
  margin: 1.3rem 0;
}
.p-dock.p-dock-left .p-dock-item-current, .p-dock.p-dock-right .p-dock-item-current {
  margin: 1.5rem 0;
}

@media screen and (max-width: 960px) {
  .p-dock.p-dock-top .p-dock-list-container, .p-dock.p-dock-bottom .p-dock-list-container {
    overflow-x: auto;
    width: 100%;
  }
  .p-dock.p-dock-top .p-dock-list-container .p-dock-list, .p-dock.p-dock-bottom .p-dock-list-container .p-dock-list {
    margin: 0 auto;
  }
  .p-dock.p-dock-left .p-dock-list-container, .p-dock.p-dock-right .p-dock-list-container {
    overflow-y: auto;
    height: 100%;
  }
  .p-dock.p-dock-left .p-dock-list-container .p-dock-list, .p-dock.p-dock-right .p-dock-list-container .p-dock-list {
    margin: auto 0;
  }
  .p-dock .p-dock-list .p-dock-item {
    transform: none;
    margin: 0;
  }
}
.p-megamenu {
  padding: 0.5rem;
  background: #f8f9fa;
  color: white;
  border: none;
  border-radius: var(--border-radius);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: var(--border-radius);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
  margin-left: 0.5rem;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #000;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #000;
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-megamenu .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
  font-size: 15px;
  background-color: var(--gray-100);
  margin-bottom: 5px;
}
.p-megamenu .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-megamenu .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-megamenu .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: white;
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-megamenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-megamenu .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-megamenu .p-megamenu-panel {
  background: var(--white);
  color: white;
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-megamenu .p-megamenu-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #000;
  background: var(--white);
  font-weight: 600;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-megamenu .p-megamenu-submenu {
  padding: 0.25rem 0;
  width: 12.5rem;
}
.p-megamenu .p-megamenu-submenu .p-menu-separator {
  border-top: 1px solid var(--gray-blue-670);
  margin: 0.25rem 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem {
  margin: 0;
}
.p-megamenu .p-megamenu-submenu .p-menuitem:last-child {
  margin: 0;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #000;
}
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-megamenu.p-megamenu-vertical {
  width: 12.5rem;
  padding: 0.25rem 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem {
  margin: 0;
}
.p-megamenu.p-megamenu-vertical .p-menuitem:last-child {
  margin: 0;
}

.p-menu {
  padding: 0.25rem 0;
  background: var(--white);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  width: 12.5rem;
}
.p-menu .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
  font-size: 15px;
  background-color: var(--gray-100);
  margin-bottom: 5px;
}
.p-menu .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-menu .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: white;
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-menu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-menu .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menu .p-menuitem-link .p-menuitem-icon {
  margin: 0 10px 0 15px;
}
.p-menu .p-menuitem-link-active, .p-menu .p-menuitem-link:hover {
  background-color: var(--blue-900);
  box-shadow: 0 4px 24px rgba(132, 185, 217, 0.4);
}
.p-menu .p-menuitem-link-active .p-menuitem-text, .p-menu .p-menuitem-link:hover .p-menuitem-text {
  color: white;
}
.p-menu.p-menu-overlay {
  background: var(--white);
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-menu .p-submenu-header {
  margin: 0;
  padding: 0.75rem 1rem;
  color: #000;
  background: var(--white);
  font-weight: 600;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}
.p-menu .p-menu-separator {
  border-top: 1px solid var(--gray-blue-670);
  margin: 0.25rem 0;
}
.p-menu .p-menuitem {
  margin: 0;
}
.p-menu .p-menuitem:last-child {
  margin: 0;
}

.p-menubar {
  padding: 0.5rem;
  background: #f8f9fa;
  color: white;
  border: none;
  border-radius: var(--border-radius);
}
.p-menubar .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
  font-size: 15px;
  background-color: var(--gray-100);
  margin-bottom: 5px;
}
.p-menubar .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-menubar .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-menubar .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: white;
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-menubar .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-menubar .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: var(--border-radius);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
  margin-left: 0.5rem;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #000;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover {
  background: #e9ecef;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: #000;
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon,
.p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-menubar .p-submenu-list {
  padding: 0.25rem 0;
  background: var(--white);
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
  width: 12.5rem;
}
.p-menubar .p-submenu-list .p-menu-separator {
  border-top: 1px solid var(--gray-blue-670);
  margin: 0.25rem 0;
}
.p-menubar .p-submenu-list .p-submenu-icon {
  font-size: 0.875rem;
}
.p-menubar .p-submenu-list .p-menuitem {
  margin: 0;
}
.p-menubar .p-submenu-list .p-menuitem:last-child {
  margin: 0;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #000;
}
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-menubar .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}

@media screen and (max-width: 960px) {
  .p-menubar {
    position: relative;
  }
  .p-menubar .p-menubar-button {
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    color: var(--gray-600);
    border-radius: 10px;
    transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  }
  .p-menubar .p-menubar-button:hover {
    color: var(--gray-600);
    background: var(--blue-900);
  }
  .p-menubar .p-menubar-button:focus {
    outline: none;
    outline-offset: 0;
    box-shadow: var(--input-box-shadow);
  }
  .p-menubar .p-menubar-root-list {
    position: absolute;
    display: none;
    padding: 0.25rem 0;
    background: var(--white);
    border: 0 none;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  .p-menubar .p-menubar-root-list .p-menu-separator {
    border-top: 1px solid var(--gray-blue-670);
    margin: 0.25rem 0;
  }
  .p-menubar .p-menubar-root-list .p-submenu-icon {
    font-size: 0.875rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link {
    padding: 11px 0;
    color: var(--gray-700);
    border-radius: 4px;
    transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
    user-select: none;
    font-size: 15px;
    background-color: var(--gray-100);
    margin-bottom: 5px;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
    color: var(--gray-700);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-icon {
    color: var(--gray-600);
    margin-right: 0.5rem;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-submenu-icon {
    color: var(--gray-600);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover {
    background: var(--blue-900);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
    color: white;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
    color: var(--gray-600);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
    color: var(--gray-600);
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link:focus {
    outline: none;
    outline-offset: 0;
    box-shadow: none;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link > .p-submenu-icon {
    margin-left: auto;
    transition: transform 0.2s;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-180deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list {
    width: 100%;
    position: static;
    box-shadow: none;
    border: 0 none;
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-submenu-icon {
    transition: transform 0.2s;
    transform: rotate(90deg);
  }
  .p-menubar .p-menubar-root-list .p-submenu-list .p-menuitem-active > .p-menuitem-link > .p-submenu-icon {
    transform: rotate(-90deg);
  }
  .p-menubar .p-menubar-root-list .p-menuitem {
    width: 100%;
    position: static;
  }
  .p-menubar .p-menubar-root-list ul li a {
    padding-left: 33px;
  }
  .p-menubar .p-menubar-root-list ul li ul li a {
    padding-left: 55px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li a {
    padding-left: 77px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li a {
    padding-left: 99px;
  }
  .p-menubar .p-menubar-root-list ul li ul li ul li ul li ul li a {
    padding-left: 121px;
  }
  .p-menubar.p-menubar-mobile-active .p-menubar-root-list {
    display: flex;
    flex-direction: column;
    top: 100%;
    left: 0;
    z-index: 1;
  }
}
.p-panelmenu .p-panelmenu-header > a {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #000;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: var(--border-radius);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-panelmenu .p-panelmenu-header > a .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-header > a:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-panelmenu .p-panelmenu-header:not(.p-highlight):not(.p-disabled) > a:hover {
  background: #e9ecef;
  border-color: 1px solid #dee2e6;
  color: #000;
}
.p-panelmenu .p-panelmenu-header.p-highlight {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight > a {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #000;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled) > a:hover {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #000;
}
.p-panelmenu .p-panelmenu-content {
  padding: 0.25rem 0;
  border: 1px solid #dee2e6;
  background: var(--white);
  color: #000;
  margin-bottom: 0;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
  font-size: 15px;
  background-color: var(--gray-100);
  margin-bottom: 5px;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: white;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link .p-panelmenu-icon {
  margin-right: 0.5rem;
}
.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  padding: 0 0 0 1rem;
}
.p-panelmenu .p-panelmenu-panel {
  margin-bottom: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-header > a {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border-radius: 0;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-highlight):not(.p-disabled):hover > a, .p-panelmenu .p-panelmenu-panel:not(:first-child) .p-panelmenu-header:not(.p-disabled).p-highlight:hover > a {
  border-top: 0 none;
}
.p-panelmenu .p-panelmenu-panel:first-child .p-panelmenu-header > a {
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-header:not(.p-highlight) > a {
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.p-panelmenu .p-panelmenu-panel:last-child .p-panelmenu-content {
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}

.p-slidemenu {
  padding: 0.25rem 0;
  background: var(--white);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  width: 12.5rem;
}
.p-slidemenu .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
  font-size: 15px;
  background-color: var(--gray-100);
  margin-bottom: 5px;
}
.p-slidemenu .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-slidemenu .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-slidemenu .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: white;
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-slidemenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-slidemenu .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-slidemenu.p-slidemenu-overlay {
  background: var(--white);
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu-list {
  padding: 0.25rem 0;
  background: var(--white);
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link {
  background: #e9ecef;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-text {
  color: #000;
}
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon,
.p-slidemenu .p-slidemenu.p-slidemenu-active > .p-slidemenu-link .p-slidemenu-icon {
  color: var(--gray-600);
}
.p-slidemenu .p-slidemenu-separator {
  border-top: 1px solid var(--gray-blue-670);
  margin: 0.25rem 0;
}
.p-slidemenu .p-slidemenu-icon {
  font-size: 0.875rem;
}
.p-slidemenu .p-slidemenu-backward {
  padding: 11px 0;
  color: var(--gray-700);
}

.p-steps .p-steps-item .p-menuitem-link {
  background: transparent;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
  background: var(--white);
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  color: #000;
  border: 1px solid #c8c8c8;
  background: var(--white);
  min-width: 2rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 1.143rem;
  z-index: 1;
  border-radius: 50%;
}
.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: var(--gray-600);
}
.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: #3f74db;
  color: var(--white);
}
.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: #000;
}
.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid var(--gray-blue-670);
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-tabmenu .p-tabmenu-nav {
  background: var(--white);
  border: 1px solid #dee2e6;
  border-width: 0 0 2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin-right: 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  border: solid #dee2e6;
  border-width: 0 0 2px 0;
  border-color: transparent transparent #dee2e6 transparent;
  background: var(--white);
  color: var(--gray-600);
  padding: 1rem;
  font-weight: 600;
  border-top-right-radius: var(--border-radius);
  border-top-left-radius: var(--border-radius);
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  margin: 0 0 -2px 0;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link .p-menuitem-icon {
  margin-right: 0.5rem;
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:not(.p-disabled):focus {
  outline: none;
  outline-offset: 0;
  box-shadow: inset 0 2px 10px var(--shadow-color-8);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link {
  background: var(--white);
  border-color: #9ba2aa;
  color: var(--gray-600);
}
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  background: var(--white);
  border-color: #3f74db;
  color: #3f74db;
}

.p-tieredmenu {
  padding: 0.25rem 0;
  background: var(--white);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  width: 12.5rem;
}
.p-tieredmenu .p-menuitem-link {
  padding: 11px 0;
  color: var(--gray-700);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s, box-shadow 0.2s;
  user-select: none;
  font-size: 15px;
  background-color: var(--gray-100);
  margin-bottom: 5px;
}
.p-tieredmenu .p-menuitem-link .p-menuitem-text {
  color: var(--gray-700);
}
.p-tieredmenu .p-menuitem-link .p-menuitem-icon {
  color: var(--gray-600);
  margin-right: 0.5rem;
}
.p-tieredmenu .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover {
  background: var(--blue-900);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-text {
  color: white;
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-menuitem-icon {
  color: var(--gray-600);
}
.p-tieredmenu .p-menuitem-link:not(.p-disabled):hover .p-submenu-icon {
  color: var(--gray-600);
}
.p-tieredmenu .p-menuitem-link:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}
.p-tieredmenu.p-tieredmenu-overlay {
  background: var(--white);
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-submenu-list {
  padding: 0.25rem 0;
  background: var(--white);
  border: 0 none;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.1);
}
.p-tieredmenu .p-menuitem {
  margin: 0;
}
.p-tieredmenu .p-menuitem:last-child {
  margin: 0;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: #e9ecef;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-text {
  color: #000;
}
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-menuitem-icon,
.p-tieredmenu .p-menuitem.p-menuitem-active > .p-menuitem-link .p-submenu-icon {
  color: var(--gray-600);
}
.p-tieredmenu .p-menu-separator {
  border-top: 1px solid var(--gray-blue-670);
  margin: 0.25rem 0;
}
.p-tieredmenu .p-submenu-icon {
  font-size: 0.875rem;
}

.p-inline-message {
  padding: 0.5rem 1rem;
  margin: 0;
  border-radius: var(--border-radius);
}
.p-inline-message.p-inline-message-info {
  background: #039be5;
  border: 0 none;
  border-width: 1px;
  color: #039be5;
}
.p-inline-message.p-inline-message-info .p-inline-message-icon {
  color: #039be5;
}
.p-inline-message.p-inline-message-success {
  background: #43a047;
  border: 0 none;
  border-width: 1px;
  color: var(--green-900);
}
.p-inline-message.p-inline-message-success .p-inline-message-icon {
  color: var(--green-900);
}
.p-inline-message.p-inline-message-warn {
  background: #ffb300;
  border: 0 none;
  border-width: 1px;
  color: var(--orange-900);
}
.p-inline-message.p-inline-message-warn .p-inline-message-icon {
  color: var(--orange-900);
}
.p-inline-message.p-inline-message-error {
  background: #e53935;
  border: 0 none;
  border-width: 1px;
  color: var(--red-850);
}
.p-inline-message.p-inline-message-error .p-inline-message-icon {
  color: var(--white);
}
.p-inline-message .p-inline-message-icon {
  font-size: 1rem;
  margin-right: 0.5rem;
}
.p-inline-message .p-inline-message-text {
  font-size: 1rem;
}
.p-inline-message.p-inline-message-icon-only .p-inline-message-icon {
  margin-right: 0;
}

.p-message {
  margin: 1rem 0;
  border-radius: var(--border-radius);
}
.p-message .p-message-wrapper {
  padding: 1rem 1.5rem;
}
.p-message .p-message-close {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10px;
  background: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-message .p-message-close:hover {
  background: rgba(255, 255, 255, 0.3);
}
.p-message .p-message-close:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}
.p-message.p-message-info {
  background: #039be5;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #039be5;
}
.p-message.p-message-info .p-message-icon {
  color: #039be5;
}
.p-message.p-message-info .p-message-close {
  color: #039be5;
}
.p-message.p-message-success {
  background: #43a047;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: var(--green-900);
}
.p-message.p-message-success .p-message-icon {
  color: var(--green-900);
}
.p-message.p-message-success .p-message-close {
  color: var(--green-900);
}
.p-message.p-message-warn {
  background: #ffb300;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: var(--orange-900);
}
.p-message.p-message-warn .p-message-icon {
  color: var(--orange-900);
}
.p-message.p-message-warn .p-message-close {
  color: var(--orange-900);
}
.p-message.p-message-error {
  background: #e53935;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: var(--red-850);
}
.p-message.p-message-error .p-message-icon {
  color: var(--white);
}
.p-message.p-message-error .p-message-close {
  color: var(--white);
}
.p-message .p-message-text {
  font-size: 1rem;
  font-weight: 500;
}
.p-message .p-message-icon {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}
.p-message .p-message-summary {
  font-weight: 700;
}
.p-message .p-message-detail {
  margin-left: 0.5rem;
}

.p-toast {
  z-index: 10050 !important;
  opacity: 0.9;
  max-width: 25rem;
  width: 100% !important;
}
.p-toast .p-toast-message {
  margin: 0 0 1rem 0;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.3);
  border-radius: var(--border-radius);
  background-color: var(--white) !important;
}
.p-toast .p-toast-message .p-toast-message-content {
  padding: 20px;
  padding-right: 2.2rem;
  font-size: 20px;
  font-weight: 500;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-message-icon {
  display: none;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 700;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0;
  word-wrap: break-word;
  max-width: 20rem;
  line-height: 20px;
}
@media only screen and (max-width: 480px) {
  .p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
    max-width: 250px;
  }
}
.p-toast .p-toast-message .p-toast-icon-close {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10px;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  background-color: var(--gray-100);
  color: var(--gray-600);
  position: absolute;
  top: 5px;
  right: 5px;
  border: 0 none;
  background: var(--gray-100);
  box-shadow: none;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  color: var(--blue-760);
  background-color: var(--gray-150);
}
.p-toast .p-toast-message .p-toast-icon-close:focus {
  color: var(--white);
  background-color: var(--blue-900);
}
.p-toast .p-toast-message.p-toast-message-info {
  background: #039be5;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: #039be5;
}
.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon {
  color: #039be5;
}
.p-toast .p-toast-message.p-toast-message-success {
  background: #43a047;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: var(--green-900);
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  color: var(--green-900);
}
.p-toast .p-toast-message.p-toast-message-warn {
  background: #ffb300;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: var(--orange-900);
}
.p-toast .p-toast-message.p-toast-message-warn .p-toast-message-icon {
  color: var(--orange-900);
}
.p-toast .p-toast-message.p-toast-message-error {
  background: #e53935;
  border: 0 none;
  border-width: 0 0 0 4px;
  color: var(--red-850);
}
.p-toast .p-toast-message.p-toast-message-error .p-toast-message-icon {
  color: var(--red-850);
}

.p-galleria .p-galleria-close {
  margin: 0.5rem;
  background: transparent;
  color: #ebedef;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-close .p-galleria-close-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-close:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #ebedef;
}
.p-galleria .p-galleria-item-nav {
  background: rgba(0, 0, 0, 0.2);
  color: #aeb6bf;
  width: 4rem;
  height: 4rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: var(--border-radius);
  margin: 0.5rem 0;
}
.p-galleria .p-galleria-item-nav .p-galleria-item-prev-icon,
.p-galleria .p-galleria-item-nav .p-galleria-item-next-icon {
  font-size: 2rem;
}
.p-galleria .p-galleria-item-nav:not(.p-disabled):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #ebedef;
}
.p-galleria .p-galleria-caption {
  background: rgba(0, 0, 0, 0.5);
  color: #ebedef;
  padding: 1rem;
}
.p-galleria .p-galleria-indicators {
  padding: 1rem;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button {
  background-color: #e9ecef;
  width: 1rem;
  height: 1rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator button:hover {
  background: #dee2e6;
}
.p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #3f74db;
  color: var(--white);
}
.p-galleria.p-galleria-indicators-bottom .p-galleria-indicator, .p-galleria.p-galleria-indicators-top .p-galleria-indicator {
  margin-right: 0.5rem;
}
.p-galleria.p-galleria-indicators-left .p-galleria-indicator, .p-galleria.p-galleria-indicators-right .p-galleria-indicator {
  margin-bottom: 0.5rem;
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
  background: rgba(0, 0, 0, 0.5);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
  background: rgba(255, 255, 255, 0.4);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button:hover {
  background: rgba(255, 255, 255, 0.6);
}
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
  background: #3f74db;
  color: var(--white);
}
.p-galleria .p-galleria-thumbnail-container {
  background: rgba(0, 0, 0, 0.9);
  padding: 1rem 0.25rem;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next {
  margin: 0.5rem;
  background-color: transparent;
  color: #aeb6bf;
  width: 2rem;
  height: 2rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 50%;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-prev:hover,
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-next:hover {
  background: rgba(255, 255, 255, 0.1);
  color: #aeb6bf;
}
.p-galleria .p-galleria-thumbnail-container .p-galleria-thumbnail-item-content:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

.p-galleria-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.9);
}

.p-image-preview-indicator {
  background-color: transparent;
  color: #f8f9fa;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-image-preview-container:hover > .p-image-preview-indicator {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-image-toolbar {
  padding: 1rem;
}

.p-image-action.p-link {
  color: #f8f9fa;
  background-color: transparent;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-image-action.p-link:last-child {
  margin-right: 0;
}
.p-image-action.p-link:hover {
  color: #f8f9fa;
  background-color: rgba(255, 255, 255, 0.1);
}
.p-image-action.p-link i {
  font-size: 1.5rem;
}

.p-avatar {
  background-color: #dee2e6;
  border-radius: var(--border-radius);
}
.p-avatar.p-avatar-lg {
  width: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}
.p-avatar.p-avatar-lg .p-avatar-icon {
  font-size: 1.5rem;
}
.p-avatar.p-avatar-xl {
  width: 4rem;
  height: 4rem;
  font-size: 2rem;
}
.p-avatar.p-avatar-xl .p-avatar-icon {
  font-size: 2rem;
}

.p-avatar-group .p-avatar {
  border: 2px solid var(--white);
}

.p-badge {
  background: #3f74db;
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 700;
  min-width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
}
.p-badge.p-badge-secondary {
  background-color: var(--white);
  color: var(--blue-770);
}
.p-badge.p-badge-success {
  background-color: var(--green-900);
  color: var(--white);
}
.p-badge.p-badge-info {
  background-color: var(--gray-blue-900);
  color: var(--white);
}
.p-badge.p-badge-warning {
  background-color: var(--orange-900);
  color: var(--white);
}
.p-badge.p-badge-danger {
  background-color: var(--red-900);
  color: var(--white);
}
.p-badge.p-badge-lg {
  font-size: 1.125rem;
  min-width: 2.25rem;
  height: 2.25rem;
  line-height: 2.25rem;
}
.p-badge.p-badge-xl {
  font-size: 1.5rem;
  min-width: 3rem;
  height: 3rem;
  line-height: 3rem;
}

.p-chip {
  background-color: #dee2e6;
  color: #000;
  border-radius: 16px;
  padding: 0 1rem;
}
.p-chip .p-chip-text {
  line-height: 1.5;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.p-chip .p-chip-icon {
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  margin-left: 0.5rem;
}
.p-chip img {
  width: 2rem;
  height: 2rem;
  margin-left: -1rem;
  margin-right: 0.5rem;
}
.p-chip .pi-chip-remove-icon {
  border-radius: var(--border-radius);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-chip .pi-chip-remove-icon:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

.p-inplace .p-inplace-display {
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}
.p-inplace .p-inplace-display:not(.p-disabled):hover {
  background: #e9ecef;
  color: #000;
}
.p-inplace .p-inplace-display:focus {
  outline: none;
  outline-offset: 0;
  box-shadow: var(--input-box-shadow);
}

.p-progressbar {
  border: 0 none;
  height: 1.5rem;
  background: #dee2e6;
  border-radius: var(--border-radius);
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: linear-gradient(270deg, var(--blue-900) 0%, #2bdfbe 100%);
}
.p-progressbar .p-progressbar-label {
  color: #000;
  line-height: 1.5rem;
}

.p-scrolltop {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}
.p-scrolltop.p-link {
  background: rgba(0, 0, 0, 0.7);
}
.p-scrolltop.p-link:hover {
  background: rgba(0, 0, 0, 0.8);
}
.p-scrolltop .p-scrolltop-icon {
  font-size: 1.5rem;
  color: #f8f9fa;
}

.p-skeleton {
  background-color: #e9ecef;
  border-radius: var(--border-radius);
}
.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.p-tag {
  background: #3f74db;
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: var(--border-radius);
}
.p-tag.p-tag-success {
  background-color: var(--green-900);
  color: var(--white);
}
.p-tag.p-tag-info {
  background-color: var(--gray-blue-900);
  color: var(--white);
}
.p-tag.p-tag-warning {
  background-color: var(--orange-900);
  color: var(--white);
}
.p-tag.p-tag-danger {
  background-color: var(--red-900);
  color: var(--white);
}
.p-tag .p-tag-icon {
  margin-right: 0.25rem;
  font-size: 0.75rem;
}

.p-terminal {
  background: var(--white);
  color: #000;
  border: 1px solid #dee2e6;
  padding: 1rem;
}
.p-terminal .p-terminal-input {
  font-size: 16px;
  font-family: "Golos UI", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.c-page {
  height: 100%;
  min-height: 45px;
  width: 100%;
  max-width: 1380px;
  padding: 0 40px;
  margin: 0 auto;
}
@media only screen and (max-width:1080px) {
  .c-page {
    padding: 0 15px;
  }
}

.ruts-icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ruts-icon.icon-logo {
  width: 190px;
  height: 36px;
  background-image: url("../assets/img/logo-ruts.svg");
  background-size: cover;
}
.ruts-icon.icon-logo-test {
  width: 190px;
  height: 36px;
  background-image: url("../assets/img/logo-test-ruts.svg");
  background-size: cover;
}
.ruts-icon.icon-shem-kvo {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-kvo.svg");
}
.ruts-icon.icon-shem-l1000 {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-l1000.svg");
}
.ruts-icon.icon-shem-lm {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-lm.svg");
}
.ruts-icon.icon-shem-l1000-no-l {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-l1000-no-l.svg");
}
.ruts-icon.icon-shem-fiver {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-fiver.svg");
}
.ruts-icon.icon-shem-fiver-v2 {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-fiver-v2.svg");
}
.ruts-icon.icon-shem-fiver-v3 {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-fiver-v3.svg");
}
.ruts-icon.icon-shem-journals {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-journals.svg");
}
.ruts-icon.icon-shem-2 {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-2.svg");
}
.ruts-icon.icon-shem-intermediary {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/shem-intermediary.svg");
}
.ruts-icon.icon-attention-warning {
  display: block;
  width: 40px;
  height: 40px;
  background-image: url("../assets/img/attention-warning.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.ruts-icon.icon-attention-warning-yellow {
  display: block;
  width: 40px;
  height: 40px;
  background-color: var(--orange-900);
  -webkit-mask-image: url("../assets/img/attention-warning.svg");
  mask-image: url("../assets/img/attention-warning.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.ruts-icon.icon-arrow-separator {
  width: 31px;
  height: 16px;
  background-image: url("../assets/img/arrow-separator.svg");
  background-position: center;
}
.ruts-icon.icon-1c {
  width: 24px;
  height: 25px;
  background-image: url("../assets/img/1c.svg");
}
.ruts-icon.icon-long-chains {
  width: 46px;
  height: 24px;
  background-image: url("../assets/img/long-chains.svg");
}
.ruts-icon.icon-breach-in-bottom {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/breach-in-bottom.svg");
}
.ruts-icon.icon-breach-lost {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/breach-lost.svg");
}
.ruts-icon.icon-breach-lost-back {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/breach-lost-back.svg");
}
.ruts-icon.icon-breach-intermediary {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/breach-intermediary.svg");
}
.ruts-icon.icon-tariff-elite-sm {
  width: 18px;
  height: 18px;
  background-image: url("../assets/img/tariff-elite.svg");
}
.ruts-icon.icon-tariff-premium-sm {
  width: 18px;
  height: 18px;
  background-image: url("../assets/img/tariff-premium-new.svg");
}
.ruts-icon.icon-tariff-standard-sm {
  width: 18px;
  height: 18px;
  background-image: url("../assets/img/tariff-standard.svg");
}
.ruts-icon.icon-tariff-elite {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/tariff-elite.svg");
}
.ruts-icon.icon-tariff-premium {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/tariff-premium-new.svg");
}
.ruts-icon.icon-tariff-standard {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/tariff-standard.svg");
}
.ruts-icon.icon-tariff-new {
  width: 56px;
  height: 58px;
  background-image: url("../assets/img/new-label.svg");
}
.ruts-icon.icon-tariff-elite-full {
  width: 70px;
  height: 24px;
  background-image: url("../assets/img/tariff-elite-full.svg");
}
.ruts-icon.icon-tariff-premium-full {
  width: 70px;
  height: 24px;
  background-image: url("../assets/img/tariff-premium-full.svg");
}
.ruts-icon.icon-tariff-standard-full {
  width: 70px;
  height: 24px;
  background-image: url("../assets/img/tariff-standard-full.svg");
}
.ruts-icon.icon-gear-gray {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/config-gray.svg");
}

.p-dialog-content .c-page {
  margin: 0 !important;
}

.datepicker-tochno-day {
  background-color: var(--purple-600);
}

td.p-datepicker-other-month .datepicker-tochno-day {
  background-color: #f6eaff;
}

p-table.p-element.blue-table th,
p-table.p-element.blue-table td {
  height: 40px;
  padding: 10px;
  color: var(--text-color);
  border-right: 2px solid var(--white);
  border-bottom: 2px solid var(--white);
}
p-table.p-element.blue-table th:last-child,
p-table.p-element.blue-table td:last-child {
  border-right: 0;
}
p-table.p-element.blue-table th.align-right,
p-table.p-element.blue-table td.align-right {
  text-align: right;
}
p-table.p-element.blue-table thead tr th {
  color: var(--white);
  font-size: 16px;
  font-weight: 500;
  background-color: var(--blue-900);
}
p-table.p-element.blue-table thead tr th:first-child {
  border-radius: 6px 0 0 0;
}
p-table.p-element.blue-table thead tr th:last-child {
  border-radius: 0 6px 0 0;
}
p-table.p-element.blue-table tbody tr:nth-child(odd) {
  background-color: var(--gray-150);
}
p-table.p-element.blue-table tbody tr:nth-child(odd):hover {
  background-color: var(--gray-200);
}
p-table.p-element.blue-table tbody tr:nth-child(even) {
  background-color: var(--gray-100);
}
p-table.p-element.blue-table tbody tr:nth-child(even):hover {
  background-color: var(--gray-150);
}
p-table.p-element.blue-table tbody tr.corrected {
  background-color: var(--green-100);
}
p-table.p-element.blue-table tbody tr.corrected:hover {
  background-color: var(--green-200);
}
p-table.p-element.blue-table tbody tr.deleted {
  background-color: var(--gray-500);
}
p-table.p-element.blue-table tbody tr.deleted:hover {
  background-color: #DAE0E4;
}
p-table.p-element.blue-table tbody tr.deleted td {
  text-decoration: line-through;
}
p-table.p-element.blue-table tbody tr.edited {
  background-color: var(--green-730);
}
p-table.p-element.blue-table tbody tr.edited:hover {
  background-color: var(--green-740);
}
p-table.p-element.blue-table tbody tr.lost {
  background-color: var(--red-700);
}
p-table.p-element.blue-table tbody tr.lost:hover {
  background-color: var(--red-850);
}
p-table.p-element.blue-table tbody tr.nulled, p-table.p-element.blue-table tbody tr.cancellation {
  background-color: var(--orange-700);
}
p-table.p-element.blue-table tbody tr.nulled:hover, p-table.p-element.blue-table tbody tr.cancellation:hover {
  background-color: var(--orange-830);
}
p-table.p-element.blue-table tbody tr.lost-back, p-table.p-element.blue-table tbody tr.loss-back {
  background-color: var(--yellow-600);
}
p-table.p-element.blue-table tbody tr.lost-back:hover, p-table.p-element.blue-table tbody tr.loss-back:hover {
  background-color: var(--yellow-700);
}
p-table.p-element.blue-table tbody tr.personal-substitution {
  background-color: var(--blue-790);
}
p-table.p-element.blue-table tbody tr.personal-substitution:hover {
  background-color: var(--blue-830);
}
p-table.p-element.blue-table tbody tr.in-bottom {
  background-color: var(--purple-600);
}
p-table.p-element.blue-table tbody tr.in-bottom:hover {
  background-color: var(--purple-830);
}
p-table.p-element.blue-table tbody tr td {
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  white-space: inherit;
}
p-table.p-element.blue-table tfoot td {
  font-size: 14px;
  font-weight: 500;
}

p-fileupload .p-fileupload-buttonbar {
  display: none;
}
p-fileupload .p-fileupload-row {
  flex-direction: column;
}
p-fileupload .p-fileupload-row > div {
  width: 100%;
  padding: 5px 0;
  text-align: center;
}
p-fileupload .p-fileupload-row > div:first-child {
  display: none;
}
p-fileupload .p-fileupload-row > div:last-child {
  padding: 0;
}
p-fileupload .p-fileupload-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  padding: 30px 40px;
  background-color: var(--white);
  border: 2px dashed var(--blue-770) !important;
  border-radius: 6px !important;
  cursor: pointer;
}
p-fileupload .p-fileupload-content .p-progressbar {
  display: none;
}
p-fileupload .p-fileupload-content.p-fileupload-highlight {
  background-color: var(--blue-100);
  border-color: var(--blue-770);
}

.ruts-confirm-dialog .p-dialog-footer {
  padding-top: 0 !important;
}
.ruts-confirm-dialog .p-button-icon,
.ruts-confirm-dialog .p-dialog-title {
  display: none;
}
.ruts-confirm-dialog .p-dialog-header {
  padding: 0;
  border: none;
}
.ruts-confirm-dialog .p-dialog-header-icons {
  position: absolute;
  top: 0;
  right: 0;
}
.ruts-confirm-dialog .p-dialog-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.ruts-confirm-dialog .p-confirm-dialog-message {
  margin: 20px 0 0 !important;
  line-height: 30px;
  font-size: 20px;
}

tr.marked {
  background-color: var(--yellow-300) !important;
}
tr.marked.target {
  background-color: var(--yellow-500) !important;
}

p-confirmdialog {
  z-index: 10055 !important;
  position: relative;
}

@media only screen and (max-width: 480px) {
  .p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
    display: none;
  }
}
[hidden] {
  display: none !important;
}

.--h-40 {
  height: 40px;
  max-height: 40px;
}

@media screen and (min-width: 576px) {
  .--sm-h-40 {
    height: 40px;
    max-height: 40px;
  }
}

.--shadow-3 {
  box-shadow: 0 2px 20px var(--shadow-color-3);
}

.--shadow-5 {
  box-shadow: 0 2px 20px var(--shadow-color-5);
}

.--shadow-8 {
  box-shadow: 0 2px 20px var(--shadow-color-8);
}

.--shadow-9 {
  box-shadow: 4px 0 40px 0 var(--shadow-color-9);
}

.--down-arrow-hover:hover {
  color: var(--gray-700) !important;
}

.--icon-color-hover:hover {
  color: var(--blue-760) !important;
}

.--icon-bg-hover:hover {
  background: var(--blue-760) !important;
}

.hover-card-shadow:hover {
  box-shadow: 0 2px 5px var(--shadow-color-7);
}

.item-card {
  background: var(--white);
  box-shadow: 0 2px 20px var(--shadow-color-3);
}
.item-card:hover {
  background: var(--gray-100);
  box-shadow: 0 2px 5px var(--shadow-color-7);
}

.item-card-number {
  color: var(--gray-900);
  background: var(--gray-blue-200);
  padding: 2px 6px;
}
.item-card-number:hover {
  color: var(--blue-760);
  background: var(--gray-blue-300);
}

.item-card-header {
  padding: 2px 6px;
}

.search-marked {
  background: #f7f9b8 !important;
}

.block-info {
  color: var(--blue-900);
  font-weight: 500;
  background-color: var(--gray-blue-300);
  border: 2px solid var(--white);
  border-radius: 6px;
}
.block-info a {
  color: var(--blue-900);
}

@font-face {
  font-family: "Golos UI";
  font-weight: 400;
  src: url("../assets/fonts/Golos-UI_Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Golos UI";
  font-weight: 500;
  src: url("../assets/fonts/Golos-UI_Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Golos UI";
  font-weight: 700;
  src: url("../assets/fonts/Golos-UI_Bold.ttf") format("truetype");
}
body {
  color: var(--text-color);
  font-size: 16px;
  line-height: 16px;
  font-family: "Golos UI", sans-serif;
}