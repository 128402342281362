@mixin icon-override($icon) {
    &:before {
        content: $icon;
    }
}

@mixin focused() {
    outline: $focusOutline;
    outline-offset: $focusOutlineOffset;
    box-shadow: var(--input-box-shadow);
}

@mixin focused-inset() {
    outline: $focusOutline;
    outline-offset: $focusOutlineOffset;
    box-shadow: inset $focusShadow;
}

@mixin focused-input() {
    @include focused();
    border-color: $inputFocusBorderColor;
}

@mixin focused-listitem() {
    outline: $focusOutline;
    outline-offset: $focusOutlineOffset;
    box-shadow: $inputListItemFocusShadow;
}

@mixin invalid-input() {
    border-color: $inputErrorBorderColor !important;
}


// привязать бордер, бг, тени к переменным. Применяется для полей инпутов или блоков инпутов
@mixin input-field() {
    border-radius: var(--border-radius);
    background-color: var(--input-bg);
    border: var(--input-border);
    box-shadow: var(--input-box-shadow);

    &:hover {
        background-color: var(--input-bg-hover);
        border: var(--input-border-hover);
        box-shadow: var(--input-box-shadow-hover);
    }
}

// убрать стили инпута. применяется для инпутов, находящихся внутри блоков, которые стилизованы сами
// а инпут выступает в роли учатка блока, куда вводятся данные и сам стилизован быть не должен
@mixin input-field-clear() {
    --input-bg: none;
    --input-bg-hover: none;
    --input-border: none;
    --input-border-hover: none;
    --input-box-shadow: none;
    --input-box-shadow-hover: none;
}

@mixin menuitem-link {
    padding: $menuitemPadding;
    color: $menuitemTextColor;
    border-radius: $menuitemBorderRadius;
    transition: $listItemTransition;
    user-select: none;
    font-size: 15px;
    background-color: var(--gray-100);
    margin-bottom: 5px;

    .p-menuitem-text {
        color: $menuitemTextColor;
    }

    .p-menuitem-icon {
        color: $menuitemIconColor;
        margin-right: $inlineSpacing;
    }

    .p-submenu-icon {
        color: $menuitemIconColor;
    }

    &:not(.p-disabled):hover {
        background: $menuitemHoverBg;

        .p-menuitem-text {
            color: white;
        }

        .p-menuitem-icon {
            color: $menuitemIconHoverColor;
        }

        .p-submenu-icon {
            color: $menuitemIconHoverColor;
        }
    }

    &:focus {
        @include focused-listitem();
    }
}

@mixin horizontal-rootmenuitem-link {
    padding: $horizontalMenuRootMenuitemPadding;
    color: $horizontalMenuRootMenuitemTextColor;
    border-radius: $horizontalMenuRootMenuitemBorderRadius;
    transition: $listItemTransition;
    user-select: none;

    .p-menuitem-text {
        color: $horizontalMenuRootMenuitemTextColor;
    }

    .p-menuitem-icon {
        color: $horizontalMenuRootMenuitemIconColor;
        margin-right: $inlineSpacing;
    }

    .p-submenu-icon {
        color: $horizontalMenuRootMenuitemIconColor;
        margin-left: $inlineSpacing;
    }

    &:not(.p-disabled):hover {
        background: $horizontalMenuRootMenuitemHoverBg;

        .p-menuitem-text {
            color: $horizontalMenuRootMenuitemTextHoverColor;
        }

        .p-menuitem-icon {
            color: $horizontalMenuRootMenuitemIconHoverColor;
        }

        .p-submenu-icon {
            color: $horizontalMenuRootMenuitemIconHoverColor;
        }
    }

    &:focus {
        @include focused-listitem();
    }
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }
    :-moz-placeholder {
        @content;
    }
    ::-moz-placeholder {
        @content;
    }
    :-ms-input-placeholder {
        @content;
    }
}

@mixin scaledPadding($val, $scale) {
    padding: nth($val, 1) * $scale nth($val, 2) * $scale;
}

@mixin scaledFontSize($val, $scale) {
    font-size: $val * $scale;
}

@mixin nested-submenu-indents($val, $index, $length) {
    ul {
        li {
            a {
                padding-left: $val * ($index + 1);
            }

            @if $index < $length {
                @include nested-submenu-indents($val, $index + 2, $length);
            }
        }
    }
}

@mixin action-icon($enabled: true) {
    width: $actionIconWidth;
    height: $actionIconHeight;
    color: $actionIconColor;
    border: $actionIconBorder;
    background: $actionIconBg;
    border-radius: $actionIconBorderRadius;
    transition: $actionIconTransition;

    &:enabled:hover {
        color: $actionIconHoverColor;
        border-color: $actionIconHoverBorderColor;
        background: $actionIconHoverBg;
    }

    &:focus {
        @include focused();
    }
}

@function tint($color, $percentage) {
    @return mix(#fff, $color, $percentage);
}

@function shade($color, $percentage) {
    @return mix(#000, $color, $percentage);
}
