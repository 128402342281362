@mixin circle-size($size) {
    width: $size;
    height: $size;
}

.p-radiobutton {
    border-radius: 50%;
    @include circle-size($radiobuttonSize);

    @media #{$tablet} {
        @include circle-size($radiobuttonMobileSize);
    }

    .p-radiobutton-box {
        border: $radiobuttonBorder;
        background: $inputBg;
        color: $textColor;
        border-radius: 50%;
        transition: $formElementTransition;

        @include circle-size($radiobuttonSize);

        &:not(.p-disabled):not(.p-highlight):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            //@include focused-input();
        }

        .p-radiobutton-icon {
            @include circle-size($radiobuttonIconSize);
            transition-duration: $transitionDuration;
            background-color: $radiobuttonIconActiveColor;

            @media #{$tablet} {
                @include circle-size($radiobuttonIconMobileSize);
            }
        }

        &.p-highlight {
            border-color: $radiobuttonActiveBorderColor;
            background: $radiobuttonActiveBg;

            &:not(.p-disabled):hover {
                border-color: $radiobuttonActiveHoverBorderColor;
                background: $radiobuttonActiveHoverBg;
                color: $radiobuttonIconActiveHoverColor;
            }
        }

        @media #{$tablet} {
            @include circle-size($radiobuttonMobileSize);
        }
    }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    @include invalid-input();
}

.p-input-filled {
    .p-radiobutton {
        .p-radiobutton-box {
            background-color: $inputFilledBg;

            &:not(.p-disabled):hover {
                background-color: $inputFilledHoverBg;
            }

            &.p-highlight {
                background: $radiobuttonActiveBg;

                &:not(.p-disabled):hover {
                    background: $radiobuttonActiveHoverBg;
                }
            }
        }
    }
}

.p-radiobutton-label {
    margin-left: $inlineSpacing;
}

@if ($highlightBg == $radiobuttonActiveBg) {
    .p-highlight {
        .p-radiobutton {
            .p-radiobutton-box {
                border-color: $radiobuttonIconActiveColor;
            }
        }
    }
}
