.p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    @include invalid-input();
}

.p-calendar input {
    width: var(--calendar-input-width);
    height: var(--calendar-input-height);
    padding: 0 10px !important;
    font-size: 14px;
    border: var(--input-border);
    border-radius: var(--border-radius) !important;
}

.p-datepicker {
    width: 300px !important;
    padding: $calendarPadding;
    background: $calendarInlineBg;
    color: $calendarTextColor;
    border: $calendarBorder;
    border-radius: var(--border-radius);
    z-index: 10007 !important;

    &:not(.p-datepicker-inline) {
        background: $calendarBg;
        border: none;
        border-radius: 4px;
        box-shadow: $inputOverlayShadow;

        .p-datepicker-header {
            background: $calendarHeaderBg;
        }
    }

    .p-datepicker-header {
        padding: $calendarHeaderPadding;
        color: $calendarHeaderTextColor;
        background: $calendarInlineHeaderBg;
        font-weight: $calendarHeaderFontWeight;
        margin: $inputListHeaderMargin;
        border-bottom: $calendarHeaderBorder;
        border-top-right-radius: var(--border-radius);
        border-top-left-radius: var(--border-radius);

        .p-datepicker-prev,
        .p-datepicker-next {
            width: 36px;
            height: 36px;
            color: #6f95ae;
            background: none !important;
            border: 0 none;
            transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;

            &:focus {
                box-shadow: none !important;
            }

            .pi {
                font-size: 20px;
            }
        }

        .p-datepicker-title {
            padding: 7px 25px;
            color: #333333;
            font-weight: 700;
            font-size: 18px;
            border-radius: 4px;
            background: var(--background-color);

            select {
                transition: $formElementTransition;

                &:focus {
                    @include focused-input();
                }
            }

            .p-datepicker-month {
                margin-right: $inlineSpacing;
            }
        }
    }

    table {
        font-size: $fontSize;
        margin: $calendarTableMargin;

        th {
            color: var(--gray-blue-830);
            font-weight: 500;
            padding: $calendarHeaderCellPadding;

            &:nth-last-child(1),
            &:nth-last-child(2) {
                color: var(--red-900);
            }

            > span {
                width: $calendarCellDateWidth;
                height: $calendarCellDateHeight;
            }
        }

        td {
            //padding: $calendarCellDatePadding;

            > span {
                & > span {
                    box-shadow: none !important;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: $calendarCellDateWidth;
                    height: $calendarCellDateHeight;
                    color: var(--text-color);
                    border-radius: $calendarCellDateBorderRadius;
                    transition: $listItemTransition;
                    border: $calendarCellDateBorder;
                }

                &.p-disabled span {
                    color: var(--gray-blue-670);
                }

                &.p-highlight span {
                    color: var(--text-color);
                    background: var(--green-730) !important;
                    border: 2px solid var(--green-900);
                }

                &:focus span {
                    @include focused();
                }
            }

            &.p-datepicker-today {
                > span span {
                    height: 34px;
                    width: 34px;
                    background: $calendarCellDateTodayBg !important;
                    color: $calendarCellDateTodayTextColor;
                    border-radius: 4px;
                    border-color: $calendarCellDateTodayBorderColor;

                    &.p-highlight span {
                        background: var(--green-730);
                        border-color: var(--green-900);
                        box-shadow: none;
                    }
                }
            }
        }
    }

    .p-datepicker-buttonbar {
        padding: $calendarButtonBarPadding;
        border-top: $divider;

        .p-button {
            width: auto;
        }
    }

    .p-timepicker {
        border-top: $divider;
        padding: $calendarTimePickerPadding;

        button {
            @include action-icon();
            width: 34px;
            height: 34px;
            background-color: transparent !important;
            color: var(--gray-600);

            &:last-child {
                margin-top: 0.2em;
            }
        }

        .p-hour-picker > span,
        .p-minute-picker > span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            color: var(--text-color);
            background: var(--background-color);
            border-radius: 4px;
        }

        .p-separator span {
            color: var(--text-color);
        }

        span {
            color: var(--gray-600);
            font-size: $calendarTimePickerTimeFontSize;

            &:hover {
                color: var(--text-color);
            }
        }

        > div {
            padding: $calendarTimePickerElementPadding;
        }
    }

    &.p-datepicker-timeonly {
        .p-timepicker {
            border-top: 0 none;
        }
    }

    .p-monthpicker {
        margin: $calendarTableMargin;

        .p-monthpicker-month {
            padding: $calendarCellDatePadding;
            transition: $listItemTransition;
            border-radius: $borderRadius;

            &.p-highlight {
                color: $highlightTextColor;
                background: $highlightBg;
            }
        }
    }

    &.p-datepicker-multiple-month {
        .p-datepicker-group {
            border-right: $divider;
            padding-right: $calendarPadding;
            padding-left: $calendarPadding;
            padding-top: 0;
            padding-bottom: 0;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: 0 none;
            }
        }
    }

    &:not(.p-disabled) {
        table {
            td {
                & > span:not(.p-highlight):not(.p-disabled) {
                    &:hover span {
                        background: $calendarCellDateHoverBg !important;
                    }

                    &:focus {
                        @include focused();
                    }
                }
            }
        }

        .p-monthpicker {
            .p-monthpicker-month {
                &:not(.p-highlight):not(.p-disabled) {
                    &:hover {
                        background: none;
                    }

                    &:focus {
                        @include focused();
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $calendarBreakpoint) {
    .p-datepicker {
        table {
            th,
            td {
                padding: $calendarCellDatePaddingSM;
            }
        }
    }
}
