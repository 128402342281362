$primary: var(--blue-900);
$secondary: var(--green-900);
$info: var(--purple-900);
$danger: var(--red-900);
$warn: var(--orange-900);

$colors: (
    'blue': $primary,
    'green': $secondary,
    'purple': $info,
    'red': $danger,
    'orange': $warn,
);

// Theme Specific Variables
$primaryColor: #3f74db; // заменить на $primary
$primaryDarkColor: #3467cc;
$primaryDarkerColor: #1976d2;
$primaryTextColor: var(--white);
$primaryGreenColor: var(--green-900);

// Mandatory Designer Variables
@import 'variables/general';
@import 'variables/form';
@import 'variables/button';
@import 'variables/panel';
@import 'variables/data';
@import 'variables/overlay';
@import 'variables/message';
@import 'variables/menu';
@import 'variables/media';
@import 'variables/misc';

$breakpointsOld: (
    'small-mobile': 320,
    'mobile': 480,
    'mobile-landscape': 680,
    'tablet': 768,
    'tablet-landscape': 1080,
    'desktop': 1380,
    'desktop-wide': 1640,
    'fullhd': 1920,
    'ultra': 2560,
);
$screenOld: 'only screen';
$small-mobile: "#{$screenOld} and (max-width: #{map-get($breakpointsOld, 'small-mobile')}px)";
$mobile-only: "#{$screenOld} and (max-width: #{map-get($breakpointsOld, 'mobile')}px)";
$mobile-landscape: "#{$screenOld} and (max-width:#{map-get($breakpointsOld, 'mobile-landscape')}px)";
$tablet: "#{$screenOld} and (max-width:#{map-get($breakpointsOld, 'tablet')}px)";
$tablet-landscape: "#{$screenOld} and (max-width:#{map-get($breakpointsOld, 'tablet-landscape') }px)";
$desktop: "#{$screenOld} and (max-width:#{map-get($breakpointsOld, 'desktop')}px)";
$desktop-wide: "#{$screenOld} and (max-width:#{map-get($breakpointsOld, 'desktop-wide')}px)";
