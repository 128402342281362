@import "../mytheme/variables/icons";

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?natsyu') format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?natsyu') format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?natsyu##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.pi {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.pi-angle-down {
    &:before {
        content: $pi-angle-down;
    }
}
.pi-angle-left {
    &:before {
        content: $pi-angle-left;
    }
}
.pi-angle-right {
    &:before {
        content: $pi-angle-right;
    }
}
.pi-angle-up {
    &:before {
        content: $pi-angle-up;
    }
}
.pi-arrow-left {
    &:before {
        content: $pi-arrow-left;
    }
}
.pi-arrow-left-empty {
    &:before {
        content: $pi-arrow-left-empty;
    }
}
.pi-arrow-right {
    &:before {
        content: $pi-arrow-right;
    }
}
.pi-bang {
    &:before {
        content: $pi-bang;
    }
}
.pi-bars {
    &:before {
        content: $pi-bars;
    }
}
.pi-book {
    &:before {
        content: $pi-book;
    }
}
.pi-books-stack {
    &:before {
        content: $pi-books-stack;
    }
}
.pi-calendar {
    &:before {
        content: $pi-calendar;
    }
}
.pi-check {
    &:before {
        content: $pi-check;
    }
}
.pi-chevron-down {
    &:before {
        content: $pi-chevron-down;
    }
}
.pi-chevron-left {
    &:before {
        content: $pi-chevron-left;
    }
}
.pi-chevron-right {
    &:before {
        content: $pi-chevron-right;
    }
}
.pi-chevron-up {
    &:before {
        content: $pi-chevron-up;
    }
}
.pi-circle-fill {
    &:before {
        content: $pi-circle-fill;
    }
}
.pi-clock {
    &:before {
        content: $pi-clock;
    }
}
.pi-comment {
    &:before {
        content: $pi-comment;
    }
}
.pi-comment-add {
    &:before {
        content: $pi-comment-add;
    }
}
.pi-comment-fill {
    &:before {
        content: $pi-comment-fill;
    }
}
.pi-copy {
    &:before {
        content: $pi-copy;
    }
}
.pi-director {
    &:before {
        content: $pi-director;
    }
}
.pi-document-pack {
    &:before {
        content: $pi-document-pack;
    }
}
.pi-download {
    &:before {
        content: $pi-download;
    }
}
.pi-exclamation {
    &:before {
        content: $pi-exclamation;
    }
}
.pi-exclamation-circle {
    &:before {
        content: $pi-exclamation-circle;
    }
}
.pi-exclamation-triangle {
    &:before {
        content: $pi-exclamation-triangle;
    }
}
.pi-fish {
    &:before {
        content: $pi-fish;
    }
}
.pi-hammer {
    &:before {
        content: $pi-hammer;
    }
}
.pi-history {
    &:before {
        content: $pi-history;
    }
}
.pi-key {
    &:before {
        content: $pi-key;
    }
}
.pi-list {
    &:before {
        content: $pi-list;
    }
}
.pi-lock {
    &:before {
        content: $pi-lock;
    }
}
.pi-lock-fill {
    &:before {
        content: $pi-lock-fill;
    }
}
.pi-lock-open {
    &:before {
        content: $pi-lock-open;
    }
}
.pi-lock-open-fill {
    &:before {
        content: $pi-lock-open-fill;
    }
}
.pi-mail-open {
    &:before {
        content: $pi-mail-open;
    }
}
.pi-more-square {
    &:before {
        content: $pi-more-square;
    }
}
.pi-pause {
    &:before {
        content: $pi-pause;
    }
}
.pi-pencil {
    &:before {
        content: $pi-pencil;
    }
}
.pi-play {
    &:before {
        content: $pi-play;
    }
}
.pi-plus {
    &:before {
        content: $pi-plus;
    }
}
.pi-plus-circle {
    &:before {
        content: $pi-plus-circle;
    }
}
.pi-question-circle {
    &:before {
        content: $pi-question-circle;
    }
}
.pi-search {
    &:before {
        content: $pi-search;
    }
}
.pi-shit {
    &:before {
        content: $pi-shit;
    }
}
.pi-sign-out {
    &:before {
        content: $pi-sign-out;
    }
}
.pi-sort-alt {
    &:before {
        content: $pi-sort-alt;
    }
}
.pi-spin {
    &:before {
        content: $pi-spin;
    }
}
.pi-star {
    &:before {
        content: $pi-star;
    }
}
.pi-star-fill {
    &:before {
        content: $pi-star-fill;
    }
}
.pi-status-approve {
    &:before {
        content: $pi-status-approve;
    }
}
.pi-sync {
    &:before {
        content: $pi-sync;
    }
}
.pi-telegram {
    &:before {
        content: $pi-telegram;
    }
}
.pi-times {
    &:before {
        content: $pi-times;
    }
}
.pi-times-circle {
    &:before {
        content: $pi-times-circle;
    }
}
.pi-trash {
    &:before {
        content: $pi-trash;
    }
}
.pi-upload {
    &:before {
        content: $pi-upload;
    }
}
.pi-user {
    &:before {
        content: $pi-user;
    }
}
.pi-user-card {
    &:before {
        content: $pi-user-card;
    }
}
.pi-users {
    &:before {
        content: $pi-users;
    }
}
.pi-users-fill {
    &:before {
        content: $pi-users-fill;
    }
}
.pi-window {
    &:before {
        content: $pi-window;
    }
}
.pi-info-circle {
    &:before {
        content: $pi-info-circle;
    }
}
