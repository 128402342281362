.p-menu {
    padding: $verticalMenuPadding;
    background: $menuBg;
    color: $menuTextColor;
    border: $menuBorder;
    border-radius: $borderRadius;
    width: $menuWidth;

    .p-menuitem-link {
        @include menuitem-link();

        .p-menuitem-icon {
            margin: 0 10px 0 15px;
        }

        &-active,
        &:hover {
            background-color: var(--blue-900);
            box-shadow: 0 4px 24px rgba(132, 185, 217, 0.4);

            .p-menuitem-text {
                color: white;
            }
        }
    }

    &.p-menu-overlay {
        background: $overlayMenuBg;
        border: $overlayMenuBorder;
        box-shadow: $overlayMenuShadow;
    }

    .p-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $submenuHeaderPadding;
        color: $submenuHeaderTextColor;
        background: $submenuHeaderBg;
        font-weight: $submenuHeaderFontWeight;
        border-top-right-radius: $submenuHeaderBorderRadius;
        border-top-left-radius: $submenuHeaderBorderRadius;
    }

    .p-menu-separator {
        border-top: $divider;
        margin: $menuSeparatorMargin;
    }

    .p-menuitem {
        margin: $verticalMenuitemMargin;

        &:last-child {
            margin: 0;
        }
    }
}
