.ruts-icon {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    &.icon-logo {
        width: 190px;
        height: 36px;
        background-image: url("../assets/img/logo-ruts.svg");
        background-size: cover;
    }

    &.icon-logo-test {
        width: 190px;
        height: 36px;
        background-image: url("../assets/img/logo-test-ruts.svg");
        background-size: cover;
    }

    &.icon-shem-kvo {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-kvo.svg");
    }

    &.icon-shem-l1000 {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-l1000.svg");
    }

    &.icon-shem-lm {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-lm.svg");
    }

    &.icon-shem-l1000-no-l {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-l1000-no-l.svg");
    }

    &.icon-shem-fiver {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-fiver.svg");
    }

    &.icon-shem-fiver-v2 {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-fiver-v2.svg");
    }

    &.icon-shem-fiver-v3 {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-fiver-v3.svg");
    }

    &.icon-shem-journals {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-journals.svg");
    }

    &.icon-shem-2 {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-2.svg");
    }

    &.icon-shem-intermediary {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/shem-intermediary.svg");
    }

    &.icon-attention-warning {
        display: block;
        width: 40px;
        height: 40px;
        background-image: url("../assets/img/attention-warning.svg");
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-attention-warning-yellow {
        display: block;
        width: 40px;
        height: 40px;
        background-color: var(--orange-900);
        -webkit-mask-image: url("../assets/img/attention-warning.svg");
        mask-image: url("../assets/img/attention-warning.svg");
        background-repeat: no-repeat;
        background-position: center;
    }

    &.icon-arrow-separator {
        width: 31px;
        height: 16px;
        background-image: url("../assets/img/arrow-separator.svg");
        background-position: center;
    }

    &.icon-1c {
        width: 24px;
        height: 25px;
        background-image: url("../assets/img/1c.svg");
    }

    &.icon-long-chains {
        width: 46px;
        height: 24px;
        background-image: url("../assets/img/long-chains.svg");
    }

    &.icon-breach-in-bottom {
        width: 24px;
        height: 24px;
        background-image: url("../assets/img/breach-in-bottom.svg");
    }

    &.icon-breach-lost {
        width: 24px;
        height: 24px;
        background-image: url("../assets/img/breach-lost.svg");
    }

    &.icon-breach-lost-back {
        width: 24px;
        height: 24px;
        background-image: url("../assets/img/breach-lost-back.svg");
    }

    &.icon-breach-intermediary {
        width: 24px;
        height: 24px;
        background-image: url("../assets/img/breach-intermediary.svg");
    }

    &.icon-tariff-elite-sm {
        width: 18px;
        height: 18px;
        background-image: url("../assets/img/tariff-elite.svg");
    }

    &.icon-tariff-premium-sm {
        width: 18px;
        height: 18px;
        background-image: url("../assets/img/tariff-premium-new.svg");
    }

    &.icon-tariff-standard-sm {
        width: 18px;
        height: 18px;
        background-image: url("../assets/img/tariff-standard.svg");
    }

    &.icon-tariff-elite {
        width: 24px;
        height: 24px;
        background-image: url("../assets/img/tariff-elite.svg");
    }

    &.icon-tariff-premium {
        width: 24px;
        height: 24px;
        background-image: url("../assets/img/tariff-premium-new.svg");
    }

    &.icon-tariff-standard {
        width: 24px;
        height: 24px;
        background-image: url("../assets/img/tariff-standard.svg");
    }

    &.icon-tariff-new {
        width: 56px;
        height: 58px;
        background-image: url("../assets/img/new-label.svg");
    }

    &.icon-tariff-elite-full {
        width: 70px;
        height: 24px;
        background-image: url("../assets/img/tariff-elite-full.svg");
    }

    &.icon-tariff-premium-full {
        width: 70px;
        height: 24px;
        background-image: url("../assets/img/tariff-premium-full.svg");
    }

    &.icon-tariff-standard-full {
        width: 70px;
        height: 24px;
        background-image: url("../assets/img/tariff-standard-full.svg");
    }

    &.icon-gear-gray {
        width: 24px;
        height: 24px;
        background-image: url("../assets/img/config-gray.svg");
    }
}
