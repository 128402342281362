$icomoon-font-family: "rutsio" !default;
$icomoon-font-path: "../../assets/fonts/icons" !default;

$pi-angle-down: unquote('"\\e91e"');
$pi-angle-left: unquote('"\\e91f"');
$pi-angle-right: unquote('"\\e91d"');
$pi-angle-up: unquote('"\\e91b"');
$pi-arrow-left: unquote('"\\e900"');
$pi-arrow-left-empty: unquote('"\\e934"');
$pi-arrow-right: unquote('"\\e901"');
$pi-bang: unquote('"\\e928"');
$pi-bars: unquote('"\\e91c"');
$pi-book: unquote('"\\e929"');
$pi-book-fill: unquote('"\\e932"');
$pi-books-stack: unquote('"\\e92d"');
$pi-calendar: unquote('"\\e919"');
$pi-check: unquote('"\\e91a"');
$pi-chevron-down: unquote('"\\e924"');
$pi-chevron-left: unquote('"\\e921"');
$pi-chevron-right: unquote('"\\e922"');
$pi-chevron-up: unquote('"\\e923"');
$pi-circle-fill: unquote('"\\e93f"');
$pi-clock: unquote('"\\e90c"');
$pi-comment: unquote('"\\e907"');
$pi-comment-add: unquote('"\\e92b"');
$pi-comment-fill: unquote('"\\e940"');
$pi-copy: unquote('"\\e937"');
$pi-director: unquote('"\\e939"');
$pi-document-pack: unquote('"\\e931"');
$pi-download: unquote('"\\e918"');
$pi-exclamation: unquote('"\\e938"');
$pi-exclamation-circle: unquote('"\\e903"');
$pi-exclamation-triangle: unquote('"\\e905"');
$pi-fish: unquote('"\\e935"');
$pi-hammer: unquote('"\\e92a"');
$pi-history: unquote('"\\e93a"');
$pi-key: unquote('"\\e90d"');
$pi-list: unquote('"\\e917"');
$pi-lock: unquote('"\\e902"');
$pi-lock-fill: unquote('"\\e926"');
$pi-lock-open: unquote('"\\e904"');
$pi-lock-open-fill: unquote('"\\e925"');
$pi-mail-open: unquote('"\\e930"');
$pi-more-square: unquote('"\\e92e"');
$pi-pause: unquote('"\\e90a"');
$pi-pencil: unquote('"\\e90f"');
$pi-play: unquote('"\\e90b"');
$pi-plus: unquote('"\\e910"');
$pi-plus-circle: unquote('"\\e908"');
$pi-question-circle: unquote('"\\e916"');
$pi-search: unquote('"\\e914"');
$pi-shit: unquote('"\\e92c"');
$pi-sign-out: unquote('"\\e93b"');
$pi-sort-alt: unquote('"\\e920"');
$pi-spin: unquote('"\\e932"');
$pi-star: unquote('"\\e906"');
$pi-star-fill: unquote('"\\e93e"');
$pi-status-approve: unquote('"\\e933"');
$pi-sync: unquote('"\\e909"');
$pi-telegram: unquote('"\\e915"');
$pi-times: unquote('"\\e913"');
$pi-times-circle: unquote('"\\e927"');
$pi-trash: unquote('"\\e90e"');
$pi-upload: unquote('"\\e93d"');
$pi-user: unquote('"\\e912"');
$pi-user-card: unquote('"\\e92f"');
$pi-users: unquote('"\\e911"');
$pi-users-fill: unquote('"\\e936"');
$pi-window: unquote('"\\e93c"');
$pi-info-circle: unquote('"\\e941"');
