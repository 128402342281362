@mixin ruts-color-class($name, $prop, $colors: $palette) {
  @each $family, $set in $colors {
    @each $saturate, $clr in $set {
      .#{$name}-#{"" + $family}-#{$saturate} {
        #{$prop}: var(getCssVar($family, $saturate)) !important;
      }
    }

    @each $saturate, $clr in $set {
      .focus#{$separator} {
        &#{$name}-#{"" + $family}-#{$saturate}:focus {
          #{$prop}: var(getCssVar($family, $saturate)) !important;
        }
      }

      .hover#{$separator} {
        &#{$name}-#{"" + $family}-#{$saturate}:hover {
          #{$prop}: var(getCssVar($family, $saturate)) !important;
        }
      }

      .active#{$separator} {
        &#{$name}-#{"" + $family}-#{$saturate}:active {
          #{$prop}: var(getCssVar($family, $saturate)) !important;
        }
      }
    }
  }
}


@mixin media-breakpoint-up($name, $breakpoints: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-between($lower, $upper, $breakpoints: $breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

@import '../../../../node_modules/primeflex/core/mixins';
