.p-dialog {
    max-height: 100% !important;
    max-width: calc(100% - 20px);
    background: var(--white);
    border: $overlayContentBorder;
    border-radius: $borderRadius;
    box-shadow: $overlayContainerShadow;
    padding: 0 30px;

    // переопределение переменных для инпута внутри диалогов
    --input-height: 50px;

    --input-bg: var(--gray-100) !important;
    --input-bg-hover: var(--gray-150) !important;

    --input-border: 2px solid var(--gray-blue-300) !important;
    --input-border-hover: 2px solid var(--gray-blue-650) !important;

    --input-box-shadow: none;
    --input-box-shadow-hover: none;

    --calendar-input-height: calc(var(--input-height));


    &-mask {
        z-index: 10006 !important;
        pointer-events: initial !important;
    }

    .p-dialog-header {
        align-items: flex-start !important;
        padding: $dialogHeaderPadding;
        border-top-right-radius: $borderRadius;
        border-top-left-radius: $borderRadius;
        color: $dialogHeaderTextColor;
        font-size: 1.5rem;
        border-bottom: $dialogHeaderBorder;

        .p-dialog-title {
            margin-right: 15px;
            font-size: $dialogHeaderFontSize;
            font-weight: $dialogHeaderFontWeight;
            line-height: 36px;
        }

        .p-dialog-header-icon {
            position: absolute;
            top: 0;
            right: 0;
            width: $actionIconWidth;
            height: $actionIconHeight;
            color: $actionIconColor;
            border: $actionIconBorder;
            background: $actionIconBg;
            border-radius: $actionIconBorderRadius;
            transition: $actionIconTransition;
            margin-top: 10px;
            margin-right: 10px;
            box-shadow: none;

            &:hover {
                color: var(--blue-760);
                background-color: var(--gray-150);
            }

            &:active {
                color: var(--white);
                background-color: var(--blue-900);
            }

            svg {
                width: 23px;
                height: 23px;
            }
        }
    }

    .p-dialog-content {
        padding: $dialogContentPadding;
        color: $panelContentTextColor;
        border-bottom-right-radius: $borderRadius;
        border-bottom-left-radius: $borderRadius;
        overflow: visible;
    }

    .p-dialog-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 30px 30px;
        color: $panelFooterTextColor;
        text-align: right;

        button {
            padding: 5px 20px;
            margin-right: 20px;
            box-shadow: 0 2px 10px var(--shadow-color-5);

            &:last-child {
                margin-right: 0;
            }

            svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
            }
        }
    }

    &.p-confirm-dialog {
        max-width: 536px;
        z-index: 100009 !important;

        @media #{$mobile-only} {
            max-width: 300px;
            .p-dialog-footer {
                flex-direction: column;

                button {
                    margin-right: 0;
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .p-confirm-dialog-icon {
            font-size: $primeIconFontSize * 2;
        }

        .p-confirm-dialog-message {
            font-size: 20px;

            strong {
                font-size: 24px;
            }
        }
    }

    &.hidden-header {
        .p-dialog-header {
            border-bottom: none;
        }
    }
}

// инпуты в диалогах

// дропдаун в диалогах
.p-dialog {
    .p-dropdown {
        &:hover {
            background: var(--input-bg-hover) !important;
            border: var(--input-border-hover) !important;
        }

        & .p-inputtext {
            border: none !important;
            background: none !important;
            transition: none;
        }

        .p-dropdown-trigger {
            height: 30px;

            .p-dropdown-trigger-icon {
                display: block;
                margin-top: 5px;
            }
        }

        .p-dropdown-panel {
            box-shadow: 0 4px 20px var(--shadow-color-5);
        }
    }

    .p-dropdown-filter-icon {
        top: 20px !important;
        width: 20px;
        height: 20px;
        color: $inputIconColor;
    }

    .p-dropdown-label.p-placeholder {
        height: calc(var(--input-height) - 4px);
    }
}

.p-dialog {
    .p-calendar input {
        font-size: 16px;
    }
}

// страница внутри диалога
.p-dialog {
    .c-page {
        padding: 0;
    }
}

.p-dialog {
    .p-multiselect{
        display: flex;
        align-items: center;
        height: var(--input-height);

        &:hover {
            background: var(--input-bg-hover) !important;
            border: var(--input-border-hover) !important;
        }

        .p-multiselect-trigger {
            width: 41px;
            border-left: 2px solid var(--gray-500);
            height: 30px;
        }
    }
}
