@use 'sass:math';

.p-autocomplete {
    .p-inputtext {
        border-color: transparent !important;
    }

    .p-autocomplete-loader {
        right: nth($inputPadding, 2);
    }

    &.p-autocomplete-dd {
        .p-autocomplete-loader {
            right: nth($inputPadding, 2) + $buttonIconOnlyWidth;
        }
    }

    .p-autocomplete-multiple-container {
        padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);

        &:not(.p-disabled):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include focused-input();
        }

        .p-autocomplete-input-token {
            padding: math.div(nth($inputPadding, 1), 2) 0;

            input {
                font-family: $fontFamily;
                font-size: $fontSize;
                color: $textColor;
                padding: 0;
                margin: 0;
            }
        }

        .p-autocomplete-token {
            padding: math.div(nth($inputPadding, 1), 2) nth($inputPadding, 2);
            margin-right: $inlineSpacing;
            background: $highlightBg;
            color: $highlightTextColor;
            border-radius: $borderRadius;

            .p-autocomplete-token-icon {
                margin-left: $inlineSpacing;
            }
        }
    }

    ::-webkit-scrollbar {
        width: 15px;
    }

    ::-webkit-scrollbar:horizontal {
        height: 15px;
    }

    ::-webkit-scrollbar-thumb {
        background: var(--gray-600);
        border: 5px solid transparent;
        border-radius: 7px;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb:hover {
        background: var(--gray-600);
        border: 5px solid transparent;
        border-radius: 7px;
        background-clip: content-box;
    }

    ::-webkit-scrollbar-track:hover {
        border: 1px solid var(--gray-650);
    }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    @include invalid-input();
}

.p-autocomplete-panel {
    background: $inputOverlayBg;
    color: $inputListTextColor;
    border: $inputOverlayBorder;
    border-radius: $borderRadius;
    box-shadow: $inputOverlayShadow;

    .p-autocomplete-items {
        &:not(.p-autocomplete-virtualscroll) {
            padding: $inputListPadding;
        }

        &.p-autocomplete-virtualscroll .cdk-virtual-scroll-content-wrapper {
            padding: $inputListPadding;
        }

        .p-autocomplete-item {
            margin: $inputListItemMargin;
            padding: $inputListItemPadding;
            border: $inputListItemBorder;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
            transition: $listItemTransition;
            border-radius: $inputListItemBorderRadius;

            &:hover {
                color: $inputListItemTextHoverColor;
                background: $inputListItemHoverBg;
            }

            &.p-highlight {
                color: $highlightTextColor;
                background: $highlightBg;
            }
        }

        .p-autocomplete-empty-message {
            padding: $inputListItemPadding;
            color: $inputListItemTextColor;
            background: $inputListItemBg;
        }

        .p-autocomplete-item-group {
            margin: $submenuHeaderMargin;
            padding: $submenuHeaderPadding;
            color: $submenuHeaderTextColor;
            background: $submenuHeaderBg;
            font-weight: $submenuHeaderFontWeight;
        }
    }
}
