@import 'src/styles/mytheme/variables';

.c-page {
    height: 100%;
    min-height: 45px;

    width: 100%;
    max-width: 1380px;

    padding: 0 40px;
    margin: 0 auto;

    @media #{$tablet-landscape} {
        padding: 0 20px;
    }
}
